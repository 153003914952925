import { Environment } from '@/config/Environment';
import { ColorModeContext, InfinitySpinner } from '@/modules/application';
import { AuthUserContext, useFirebaseAuth } from '@/modules/auth';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheming } from './config/theme/useTheming';

// Toasts
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from 'firebase/auth';
import { isEmpty, isNumber } from 'lodash-es';
import AlErrorBoundary from './components/feedback/AlErrorBoundary';
import { LayoutProvider } from './contexts/LayoutContext';
import { ActiveTeamProvider } from './modules/teams/contexts/ActiveTeamContext';
import { ReportsProvider } from './modules/teams/contexts/ReportsContext';
import { UserModel, UserProvider, UserSettingKey, UserSettingModel, UserSettingsProvider, userSettingService } from './modules/users';
import { userService } from './modules/users/api/users/users.service';
import { APP_DEFAULT_PATH, isPrivatePath } from './router/router';
import { Routes } from './router/router-paths';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, //ms
    },
  },
});

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [initialUserSettings, setInitialUserSettings] = useState<UserSettingModel[]>([]);
  const [initialUser, setInitialUser] = useState<UserModel>();

  const [initialTeamId, setInitialTeamId] = useState<number>();
  const [initialProfileId, setInitialProfileId] = useState<string>();

  const { colorMode, mode, theme } = useTheming();

  const auth = useFirebaseAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (Environment.isDev()) {
    console.log(`Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`);
  }

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const autoLoggedInUser = await auth.autoLogin();
      if (autoLoggedInUser) {
        await loadUserAndSettings(autoLoggedInUser);
      } else {
        handleNoAutoLogin();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadUserAndSettings = async (autoLoggedInUser: User) => {
    const getAdLabsUserResponse = await userService.getUserByFirebaseId(autoLoggedInUser.uid);
    if (getAdLabsUserResponse.isSuccess) {
      const user = getAdLabsUserResponse.payload;
      if (user && user.id) {
        setInitialUser(user);
        const userSettingsResponse = await userSettingService.getAll(user.id);
        if (userSettingsResponse.isSuccess) {
          const settings = userSettingsResponse.payload;
          setInitialUserSettings(settings);
          setActiveTeamAndProfileIds(settings);
        } else {
          throw new Error('Error loading user settings');
        }
      }
    } else {
      console.log('Error loading user', getAdLabsUserResponse.message);
    }
    navigateToAppropriateLocation();
  };

  const handleNoAutoLogin = () => {
    if (!location.pathname.startsWith(Routes.REGISTER) || location.pathname === '/') {
      navigate(Routes.LOGIN);
    }
  };

  const setActiveTeamAndProfileIds = (settings: UserSettingModel[]) => {
    const activeTeamId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_TEAM_ID)?.value;
    const activeProfileId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_PROFILE_ID)?.value;
    setInitialTeamId(isNumber(activeTeamId) ? activeTeamId : undefined);
    setInitialProfileId(isNumber(activeProfileId) || !isEmpty(activeProfileId) ? activeProfileId?.toString() : undefined);
  };

  const navigateToAppropriateLocation = () => {
    if (
      location.pathname.startsWith(Routes.REGISTER) ||
      location.pathname.startsWith(Routes.GETTING_STARTED) ||
      location.pathname.startsWith(Routes.PROFILES)
    ) {
      navigate(location);
    } else {
      navigate(isPrivatePath(location.pathname) ? location.pathname : APP_DEFAULT_PATH);
    }
  };

  useEffect(() => {
    // Don't log in automatically if there is no connection
    if (searchParams.get('noConnection')) {
      setIsLoading(false);
      return;
    }

    handleLogin();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AlErrorBoundary>
        <div className={`${mode} flex h-full flex-grow overflow-hidden font-sans text-sm`}>
          <StyledEngineProvider injectFirst>
            <ColorModeContext.Provider value={{ toggleColorMode: colorMode.toggleColorMode, mode, isDarkMode: mode === 'dark' }}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                {isLoading ? (
                  <div className="flex h-screen w-full items-center justify-center">
                    <InfinitySpinner></InfinitySpinner>
                  </div>
                ) : (
                  <AuthUserContext.Provider value={auth}>
                    <UserProvider initialUser={initialUser}>
                      <UserSettingsProvider initialSettings={initialUserSettings}>
                        <ActiveTeamProvider initialActiveTeamId={initialTeamId} initialActiveProfileId={initialProfileId}>
                          <ReportsProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <LayoutProvider>
                                <Outlet />
                              </LayoutProvider>
                            </LocalizationProvider>
                            <ToastContainer theme={mode === 'dark' ? 'dark' : 'light'} position={'bottom-left'} />
                          </ReportsProvider>
                        </ActiveTeamProvider>
                      </UserSettingsProvider>
                    </UserProvider>
                  </AuthUserContext.Provider>
                )}
              </ThemeProvider>
            </ColorModeContext.Provider>
          </StyledEngineProvider>
        </div>
      </AlErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
