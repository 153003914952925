import { APP_DEFAULT_PATH } from '@/router/router';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const ErrorPage: FunctionComponent = () => {
  const error = useRouteError() as Error;
  if (isRouteErrorResponse(error)) {
    return (
      <div className="flex h-screen w-screen  items-center  bg-gray-50     font-sans dark:bg-gray-900">
        <div className="container flex flex-col items-center justify-center px-5 text-gray-700 dark:text-gray-400 md:flex-row">
          <div className="max-w-md">
            <Typography variant="h4">Oops! An error occurred.</Typography>
            <p className="mt-2  text-xl font-light leading-normal md:text-2xl">AdStein might have lost the page.</p>

            <Typography className="mb-4 mt-2">If the issue continues, please reach out to us with the details below</Typography>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>
                  Error Details<span className="opacity-70"> (code: {error.status})</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6">{error.statusText}</Typography>
                <Typography>{error.message && <p>{error.message}</p>}</Typography>
              </AccordionDetails>
            </Accordion>
            <div className=" mt-5 ">
              <Button
                size="large"
                color="warning"
                variant="contained"
                href={APP_DEFAULT_PATH}
                className="rounded-md normal-case"
                disableElevation
              >
                Back to homepage
              </Button>
            </div>
          </div>
          <div className="max-w-xs">
            <img src="/src/assets/adstein.jpg" className="w-96" alt="AdStein" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="-m-2 flex h-screen  w-screen  items-center     bg-gray-50 font-sans dark:bg-gray-900">
        <div className="container flex flex-col items-center justify-center px-5 text-gray-700 dark:text-gray-400 md:flex-row">
          <div className="max-w-md">
            <Typography variant="h4">Oops! An error occurred.</Typography>
            <p className="mt-2  text-xl font-light leading-normal md:text-2xl">AdStein is at it again!</p>

            <Typography className="mb-4 mt-2">If the issue continues, please reach out to us with the details below</Typography>

            <div className="mb-2 font-bold">Error Details (Copy this to us)</div>

            <div className="h-44 overflow-y-auto rounded-lg border border-gray-400 bg-gray-200 px-3 py-px dark:bg-gray-700 dark:text-gray-200">
              <pre className="whitespace-pre-wrap leading-5">{error.stack || error.message || JSON.stringify(error)}</pre>
            </div>

            <div className=" mt-5 ">
              <Button
                size="large"
                color="primary"
                variant="contained"
                href={APP_DEFAULT_PATH}
                className="rounded-md normal-case"
                disableElevation
              >
                Back to homepage
              </Button>
            </div>
          </div>
          <div className="ml-8 max-w-xs">
            <img src="/src/assets/adstein.jpg" className="w-96" alt="AdStein" />
          </div>
        </div>
      </div>
    );
  }
};
