/* eslint-disable react/prop-types */
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ButtonPopoverCellRenderer from '@/components/grid/cells/ButtonPopoverCellRenderer';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import EditableCellRenderer, { IEditableCellRendererParams } from '@/components/grid/cells/EditableCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { ComparisonUnit, ExpandedGridContext } from '@/components/grid/types';
import { MetricField } from '@/components/metrics/models/CommonMetricsModel';
import { MetricAggregates } from '@/components/metrics/models/MetricsModel';
import { AdLabsColorVariant, TailwindColorVariant } from '@/config/theme/color.type';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib';
import { getErrorMessage } from '@/modules/application/utils';
import { MetricData } from '@/modules/targeting/models/TargetsModel';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { SentimentColors } from '@/types/colors.enum';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@mui/material';
import { CellClickedEvent, CellValueChangedEvent, GridReadyEvent, ITooltipParams, SelectionChangedEvent } from 'ag-grid-community';
import {
  CellClassParams,
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { isNil, isNumber, round } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AlFilterModel } from '../../../../components/filter-builder/models/AlFilterModel';
import { getConfigForMetric, getMetricColumns } from '../../../../components/metrics/MetricsConfig';
import { OptimizationApplyData, OptimizationPreset } from './OptimizerConfig';
import { OptimizationReason } from './api/optimization-contracts';
import useBidLimits from './bid-limits';
import { AdGroupDebugRow } from './models/AdGroupDebugRow';
import { CampaignDebugRow } from './models/CampaignDebugRow';
import { BiddingEntity, OptimizationModel, isCurrencyEntity, isPercentEntity } from './models/OptimizationModel';
import { PreviewDataRow } from './models/PreviewDataRow';
import { generateOptimizationTableDefaultColumnState } from './optimization-table.default-column-state';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 60;
const MODIFYING_NOT_SUPPORTED_TOOLTIP = 'Top of Search cannot be modified for Sponsored Brands';

interface OptimizationTableProps {
  optimizationResults: OptimizationModel;
  setOptimizationPreviewSelection: (optimizationPreviewSelection: OptimizationApplyData[]) => void;
  externalFilters: AlFilterModel[];
  setVisibleRowCount: (visibleRowCount: number) => void;
  onInternalGridReady?: (gridApi: GridApi<PreviewDataRow>) => void;
  comparisonUnit: ComparisonUnit;
}

const OptimizationTable: FunctionComponent<OptimizationTableProps> = ({
  optimizationResults,
  setOptimizationPreviewSelection,
  externalFilters,
  setVisibleRowCount,
  onInternalGridReady,
  comparisonUnit,
}) => {
  const { isAdminModeActive } = useUserContext();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.OPTIMIZATION_TABLE_COLUMN_STATE,
    generateOptimizationTableDefaultColumnState(isAdminModeActive),
  );
  const { t } = useTranslation();
  const { formatDateStringTimeNoSeconds } = useFormatting();

  const [gridApi, setGridApi] = useState<GridApi<PreviewDataRow>>(); // TODO: remove and only use gridApiRef
  const gridApiRef = useRef<GridApi<PreviewDataRow>>();
  const gridContextRef = useRef<ExpandedGridContext>();
  const { toastWarnWithSetMessages } = useHelperComponents();
  const { metricFieldWithChangePercentage, matchLongColumnType } = useColumnTypes();
  const { getCalculatedMetricAggData } = useColDefsFunctions();

  const [metricColumnAggregates, setMetricColumnAggregates] = useState<MetricAggregates | undefined>(undefined);
  useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  const { getCampaignAdTypeCellRendererParams } = useColDefsFunctions();

  const greyCellClass = 'bg-slate-400 bg-opacity-10';

  const { formatCurrency, formatPercentWithArrow, formatWholePercent } = useFormatting();

  const { getNewBidValue_byCurrentProfileMarketplaceLimits, getNewPlacementValue_byCurrentProfileMarketplaceLimits } = useBidLimits();

  const formatBasedOnBiddingEntity = (params: ValueFormatterParams<PreviewDataRow, number>): string => {
    const biddingEntity = params.data?.biddingEntity;

    // isNil is used because 0 is a valid value for placement
    if (isNil(params.value)) return '';

    if (!biddingEntity) return params.value.toString();

    if (isCurrencyEntity(biddingEntity)) {
      return formatCurrency(params.value);
    }

    if (isPercentEntity(biddingEntity)) {
      return formatWholePercent(params.value);
    }

    return params.value ? params.value.toString() : '';
  };

  const getColumnAggregates = (gridApi: GridApi<PreviewDataRow>): MetricAggregates => {
    type AggDataType = Record<MetricField, MetricData>;
    const sums: AggDataType = Object.values(MetricField).reduce(
      (acc, field) => ({
        ...acc,
        [field]: [0, 0],
      }),
      {} as AggDataType,
    );

    const notCalculatedMetricFields = Object.values(MetricField).filter((metricField) => !getConfigForMetric(metricField).isCalculatedMetric);

    gridApi.forEachNodeAfterFilter((node) => {
      const rowData = node.data;

      if (rowData && !isPercentEntity(rowData.biddingEntity)) {
        for (const field of notCalculatedMetricFields) {
          const value = rowData[field][0];
          if (typeof value === 'number') {
            sums[field][0] += value;
          }
        }
      }
    });

    // Aggregate data for calculated metrics
    const calculatedMetricFields = Object.values(MetricField).filter((metricField) => getConfigForMetric(metricField).isCalculatedMetric);

    calculatedMetricFields.forEach((field) => {
      const metricData = getCalculatedMetricAggData(field, sums);
      sums[field][0] = metricData[0]; // Assuming the first element is 'current'
    });

    const output: MetricAggregates = {} as MetricAggregates;

    Object.entries(sums).forEach(([key, [current, _]]) => {
      // Only extract the 'current' part and ignore the 'previous'
      output[key as MetricField] = { current };
    });

    return output;
  };

  function disabledEditingTooltip(params: ITooltipParams) {
    if (params.data?.isSBToS) {
      return 'Top of Search is not editable for Sponsored Brands';
    } else if (params.data?.isPlacement && params.data?.campaignIsVideo) {
      return 'Amazon does not apply bid adjustments to Video ads';
    }

    return undefined;
  }

  const columnDefs: ColDef<PreviewDataRow>[] = useMemo(() => {
    const colDefsToReturn: ColDef<PreviewDataRow>[] = [
      {
        colId: ColumnId.CHECKBOX,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        minWidth: 50,
        maxWidth: 50,
        suppressColumnsToolPanel: true,
        lockVisible: true,
        cellClass: `checkbox-column-cell ${greyCellClass}`,
        pinned: 'left',
        headerClass: 'checkbox-column-header',
        tooltipValueGetter: disabledEditingTooltip,
      },
      {
        colId: ColumnId.ID,
        headerName: 'Campaign/Keyword ID',
        field: 'id',
        hide: true,
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Campaign Ad Type',
        field: 'campaignAdType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'campaignName',
        cellClass: greyCellClass,
        tooltipField: 'campaignName',
        minWidth: 150,
        width: 150,
        pinned: 'left',
      },
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Opt. Group',
        field: 'campaignGroup',
        cellClass: greyCellClass,
        width: 150,
        hide: true,
      },
      {
        colId: ColumnId.BIDDING_ENTITY,
        headerName: 'Bidding Entity',
        field: 'biddingEntity',
        width: 160,
        cellClass: greyCellClass,
        cellRenderer: TextCellRenderer,
        valueGetter: (params: ValueGetterParams<PreviewDataRow>) => {
          return GetBiddingEntityByExpression(params.data?.biddingEntity, params.data?.targeting);
        },
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>): ITextCellRendererParams => {
          let color: TailwindColorVariant;

          switch (params.value) {
            case BiddingEntity.PLACEMENT_PRODUCT_PAGE:
              color = TailwindColorVariant.BLUE;
              break;
            case BiddingEntity.PLACEMENT_REST_OF_SEARCH:
              color = TailwindColorVariant.BLUE;
              break;
            case BiddingEntity.PLACEMENT_TOP:
              color = TailwindColorVariant.BLUE;
              break;
            case BiddingEntity.KEYWORD:
              color = TailwindColorVariant.GREEN;
              break;
            case BiddingEntity.PRODUCT_TARGET:
              color = TailwindColorVariant.GREEN;
              break;
            case 'AUDIENCE': // Product targets where targeting begins with audience=, views=, purchases=
              color = TailwindColorVariant.VIOLET;
              break;
            case BiddingEntity.HOME:
              color = TailwindColorVariant.BLUE;
              break;
            case BiddingEntity.DETAIL_PAGE:
              color = TailwindColorVariant.BLUE;
              break;
            case BiddingEntity.OTHER:
              color = TailwindColorVariant.BLUE;
              break;
            case BiddingEntity.BRANDS_PLACEMENT_TOP:
              color = TailwindColorVariant.BLUE;
              break;
            default:
              color = TailwindColorVariant.GREEN;
          }

          const value = params.value ? t(`optimizer_page.bidding_entity.${params.value}`) : '';

          return {
            textColor: color,
            textLabel: value,
          };
        },
      },
      {
        colId: ColumnId.TARGETING,
        headerName: 'Targeting',
        field: 'targeting',
        cellClass: greyCellClass,
        tooltipField: 'targeting',
        width: 130,
      },
      {
        colId: ColumnId.MATCH,
        headerName: 'Match Type',
        field: 'match',
        cellClass: greyCellClass,
        hide: true,
        width: 130,
        type: 'matchLongColumnType',
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Ad Group',
        field: 'adGroup',
        cellClass: greyCellClass,
        hide: true,
        width: 130,
      },
      {
        colId: ColumnId.GROUP_TACOS,
        headerName: 'Target ACOS',
        width: 130,
        field: 'groupTacos',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        valueFormatter: (params: ValueFormatterParams<PreviewDataRow>) => formatWholePercent(params.value),
        cellRendererSelector: (params: ICellRendererParams<PreviewDataRow>) => {
          if (params.data?.isOptimizingDisabled) {
            return {
              component: () => (
                <>
                  <Tooltip title={MODIFYING_NOT_SUPPORTED_TOOLTIP}>
                    <span>–</span>
                  </Tooltip>
                </>
              ),
            };
          }
          return { component: undefined }; // undefined means default renderer
        },
      },
      {
        colId: ColumnId.GROUP_PRESET,
        headerName: 'Prioritization',
        width: 130,
        field: 'groupPreset',
        filter: 'agNumberColumnFilter',
        cellRendererSelector: (params: ICellRendererParams<PreviewDataRow>) => {
          if (params.data?.isOptimizingDisabled) {
            return {
              component: () => (
                <>
                  <Tooltip title={MODIFYING_NOT_SUPPORTED_TOOLTIP}>
                    <span>–</span>
                  </Tooltip>
                </>
              ),
            };
          }
          return { component: undefined }; // undefined means default renderer
        },
        cellClass: (params) => {
          if (params.value == OptimizationPreset.NOT_SET) {
            return 'text-gray-400';
          } else {
            return '';
          }
        },
        valueFormatter: (params) => t(`optimizer_page.optimization_presets.${params.value}`),
      },
      {
        colId: ColumnId.OLD_VALUE,
        headerName: 'Current Value',
        field: 'oldValue',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 135,
        valueFormatter: formatBasedOnBiddingEntity,
        cellRendererSelector: (params: ICellRendererParams<PreviewDataRow>) => {
          if (params.data?.isOptimizingDisabled) {
            return {
              component: () => (
                <>
                  <Tooltip title={MODIFYING_NOT_SUPPORTED_TOOLTIP}>
                    <span>–</span>
                  </Tooltip>
                </>
              ),
            };
          }
          return { component: undefined }; // undefined means default renderer
        },
      },
      {
        colId: ColumnId.NEW_VALUE,
        headerName: 'New Value',
        field: 'newValue',
        editable: (params) => !params.data?.isOptimizingDisabled,
        type: 'numericColumn',
        width: 135,
        cellRendererSelector: (params: ICellRendererParams<PreviewDataRow>) => {
          if (params.data?.isOptimizingDisabled) {
            return {
              component: () => (
                <>
                  <Tooltip title={MODIFYING_NOT_SUPPORTED_TOOLTIP}>
                    <span>–</span>
                  </Tooltip>
                </>
              ),
            };
          }
          return { component: EditableCellRenderer };
        },
        cellClass: 'font-semibold',
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>): IEditableCellRendererParams => {
          return {
            isEditable: !params.data?.isOptimizingDisabled,
            hideTooltip: !params.data?.isOptimizingDisabled,
            isChanged: params.data?.newValue !== params.data?.originalNewValue,
          };
        },
        valueFormatter: (params: ValueFormatterParams<PreviewDataRow>) => {
          const biddingEntity = params.data?.biddingEntity;
          if (!biddingEntity) return params.value.toString();

          return isPercentEntity(biddingEntity) ? formatWholePercent(params.value / 100) : formatCurrency(params.value);
        },
        valueGetter: (params: ValueGetterParams<PreviewDataRow>) => {
          const biddingEntity = params.data?.biddingEntity;
          if (!biddingEntity) return params.data?.newValue;

          if (isPercentEntity(biddingEntity)) {
            // Under the hood the value is in fraction, but for edit we want to show %
            return params.data && isNumber(params.data.newValue) ? round(params.data.newValue * 100) : 0;
          }
          return params.data?.newValue;
        },
        // Parse the edited percentage back to a fraction
        valueParser: (params) => {
          // Not the same as params.data.newValue, this newValue refers to new value after editing
          if (params.newValue == '') {
            return 0;
          }

          try {
            const newValue = isPercentEntity(params.data?.biddingEntity)
              ? round(parseFloat(params.newValue) / 100, 2)
              : round(parseFloat(params.newValue), 2);

            if (isNaN(newValue)) {
              toast.error('Invalid new value entered: ' + params.newValue);
              return params.oldValue;
            }

            const warnings = new Set<string>();
            const clampedValue = isPercentEntity(params.data?.biddingEntity)
              ? getNewPlacementValue_byCurrentProfileMarketplaceLimits(newValue, params.data?.campaignAdType, warnings)
              : getNewBidValue_byCurrentProfileMarketplaceLimits(
                  newValue,
                  params.data?.campaignAdType,
                  params.data?.campaignIsVideo,
                  warnings,
                );

            if (warnings.size > 0) {
              toastWarnWithSetMessages(warnings);
              return clampedValue;
            }

            return newValue;
          } catch (e: unknown) {
            toast.error(getErrorMessage(e));
          }

          // Revert to old when invalid
          return params.oldValue;
        },
        filter: 'agNumberColumnFilter',
        tooltipValueGetter: disabledEditingTooltip,
      },
      {
        colId: ColumnId.DELTA,
        headerName: 'Delta',
        field: 'delta',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 105,
        cellRendererSelector: (params: ICellRendererParams<PreviewDataRow>) => {
          if (params.data?.isOptimizingDisabled) {
            return {
              component: () => (
                <>
                  <Tooltip title={MODIFYING_NOT_SUPPORTED_TOOLTIP}>
                    <span>–</span>
                  </Tooltip>
                </>
              ),
            };
          }
          return { component: undefined }; // undefined means default renderer
        },
        valueFormatter: (params: { value: number }) => {
          return formatPercentWithArrow(params.value);
        },

        cellClass: (params: CellClassParams<PreviewDataRow, number>) => {
          if (isNil(params.value)) return '';
          else if (params.value == 0) return 'text-right';

          return `${params.value >= 0 ? SentimentColors.GREEN : SentimentColors.RED} text-right`;
        },
      },
      {
        colId: ColumnId.REASONS,
        headerName: 'Reasons',
        width: 150,
        field: 'reasons',
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>): IChipArrayCellRendererParams => {
          return {
            maxChipsToShow: 2,
            chipArrayChips: params.data?.reasons
              ? params.data.reasons.map((reason: OptimizationReason) => {
                  let color: AdLabsColorVariant;
                  switch (reason) {
                    case OptimizationReason.LOW_VISIBILITY:
                    case OptimizationReason.LOW_ACOS:
                      color = TailwindColorVariant.GREEN;
                      break;
                    case OptimizationReason.USER_BID_CEILING:
                    case OptimizationReason.LOWEST_POSSIBLE_BID:
                    case OptimizationReason.CAMPAIGN_PERFORMANCE:
                    case OptimizationReason.PROFILE_PERFORMANCE:
                      color = TailwindColorVariant.VIOLET;
                      break;
                    case OptimizationReason.HIGH_ACOS:
                    case OptimizationReason.HIGH_SPEND:
                      color = TailwindColorVariant.ORANGE;
                      break;
                    case OptimizationReason.KEYWORD_BID_CEILING:
                    case OptimizationReason.CAMPAIGN_GROUP_BID_CEILING:
                    case OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE:
                      color = TailwindColorVariant.BLUE;
                      break;
                    case OptimizationReason.AD_GROUP_BID_CEILING:
                    case OptimizationReason.MAX_ONE_TIME_CHANGE:
                      color = TailwindColorVariant.PINK;
                      break;
                    case OptimizationReason.SMALLEST_POSSIBLE_INCREASE:
                    case OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT:
                      color = TailwindColorVariant.LIME;
                      break;
                    case OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT:
                    case OptimizationReason.MAX_BID_DECREASE:
                      color = TailwindColorVariant.SKY;
                      break;
                    default:
                      color = TailwindColorVariant.SLATE;
                  }
                  return {
                    value: t(`optimizer_page.reason.${reason}`),
                    color,
                    tooltip: 'Click to learn more about Bid Change Reasons (new tab)',
                    link: 'https://help.adlabs.app/en/articles/16-adlabs-optimization-reasons-explained',
                  };
                })
              : [],
          };
        },
        valueGetter: (params) => (params.data ? params.data.reasons : null),
      },
      {
        colId: ColumnId.LAST_OPTIMIZED,
        headerName: 'Last Optimized',
        field: 'lastOptimizedAt',
        width: 130,
        filter: 'agDateColumnFilter',
        tooltipValueGetter: (params) => (params.value ? formatDateStringTimeNoSeconds(params.value) : 'Never'),
        valueFormatter: (params) => (params.value ? formatDateStringTimeNoSeconds(params.value) : 'Never'),
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
      },
    ];

    if (isAdminModeActive) {
      colDefsToReturn.unshift({
        colId: ColumnId.DEBUG,
        headerName: 'Debug',
        cellRenderer: ButtonPopoverCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          return {
            content: params.data
              ? `========== AD GROUP DATA ==========\n${JSON.stringify(adGroupToDebugDataMap[params.data.adGroup], null, 2)}` +
                `\n\n\n========== CAMPAIGN DATA ==========\n${JSON.stringify(campaignNameToDebugDataMap[params.data.campaignName], null, 2)}`
              : null,
          };
        },
        width: 100,
      });
    }

    applyStateToDefinitions(colDefsToReturn);
    return colDefsToReturn;
  }, [isAdminModeActive, optimizationResults.previewData, metricColumnAggregates]);

  // DEBUG DATA
  const adGroupToDebugDataMap = optimizationResults.adGroupDebugData.reduce(
    (map, dataRow) => {
      map[dataRow.adGroup] = dataRow;
      return map;
    },
    {} as { [key: string]: AdGroupDebugRow },
  );

  const campaignNameToDebugDataMap = optimizationResults.campaignDebugData.reduce(
    (map, dataRow) => {
      map[dataRow.campaignName] = dataRow;
      return map;
    },
    {} as { [key: string]: CampaignDebugRow },
  );

  // Pass selected fields values to parent for checked rows
  const handleRowSelection = useCallback((event: SelectionChangedEvent<PreviewDataRow>) => {
    updateOptimizationPreviewSelection(event.api);
  }, []);

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<PreviewDataRow>) => {
    updateOptimizationPreviewSelection(event.api);
  }, []);

  const updateOptimizationPreviewSelection = (api: GridApi<PreviewDataRow>) => {
    // Update the states
    setOptimizationPreviewSelection(
      api.getSelectedRows().map((selectedOptimization) => {
        return {
          id: selectedOptimization.id,
          ad_type: selectedOptimization.campaignAdType,
          bidding_entity: selectedOptimization.biddingEntity,
          match_type: selectedOptimization.match,
          new_value: selectedOptimization.newValue,
          old_value: selectedOptimization.oldValue,
          reasons: selectedOptimization.reasons,
        };
      }),
    );
  };

  const applyExternalFilters = (filters: AlFilterModel[]) => {
    if (gridApi) {
      gridApi.setGridOption('isExternalFilterPresent', () => true);
      gridApi.setGridOption('doesExternalFilterPass', (node: IRowNode<PreviewDataRow>) => {
        if (!node || !node.data || !filters || filters.length === 0) {
          return true;
        }

        return !filters.some((filter) => {
          if (!node.data) {
            return true;
          }
          return !filter.doesFilterPass(node.data);
        });
      });
      gridApi.onFilterChanged();
      setVisibleRowCount(gridApi.getDisplayedRowCount());
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    gridContextRef.current.comparisonUnit = comparisonUnit;
    gridApiRef.current.refreshCells({ columns: getMetricColumns(), force: true });
  }, [comparisonUnit]);

  useEffect(() => {
    setOptimizationPreviewSelection([]);
    applyExternalFilters(externalFilters);

    if (gridApi) {
      setMetricColumnAggregates(getColumnAggregates(gridApi));
    }
  }, [externalFilters]);

  const onGridReady = (params: GridReadyEvent<PreviewDataRow>) => {
    setGridApi(params.api);
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
    applyExternalFilters(externalFilters);
    setVisibleRowCount(params.api.getDisplayedRowCount());
    setColumnStateGridApi(params.api);

    if (!metricColumnAggregates) {
      setMetricColumnAggregates(getColumnAggregates(params.api));
    }

    if (onInternalGridReady) {
      onInternalGridReady(params.api);
    }
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const onCellClicked = useCallback((params: CellClickedEvent<PreviewDataRow>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<PreviewDataRow> = useMemo(() => {
    const defaultGridContext: ExpandedGridContext = {
      comparisonUnit,
      metricColumnAggregates,
      isComparisonDataMissing: optimizationResults.isComparisonDataMissing,
    };

    return {
      ...DEFAULT_GRID_OPTIONS,
      context: defaultGridContext,
      getRowId: (params) => params.data.id.toString() + params.data.biddingEntity,
      isRowSelectable: (rowNode) => !rowNode.data?.isOptimizingDisabled,
      maintainColumnOrder: true,
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      onCellValueChanged: onCellValueChanged,

      // Column state management
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,

      columnTypes: { metricFieldWithChangePercentage, matchLongColumnType },
    };
  }, [handleRowSelection]);

  function GetBiddingEntityByExpression(entityType?: BiddingEntity, expression?: string): string {
    if (expression?.startsWith('audience=') || expression?.startsWith('views=') || expression?.startsWith('purchases=')) {
      return 'AUDIENCE';
    }
    return entityType ?? BiddingEntity.KEYWORD;
  }

  return (
    <>
      {isAdminModeActive ? (
        <Accordion className="mb-2 rounded-lg">
          <AccordionSummary className="pl-8">General debug data</AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-row pl-8">
              <div>
                <strong>Campaign group</strong>
                <br />
                <pre className="text-xs">{JSON.stringify(optimizationResults.campaignGroupDebugData, null, 2)}</pre>
              </div>
              <div>
                <strong>Profile</strong>
                <br />
                <pre className="text-xs">{JSON.stringify(optimizationResults.profileDebugData, null, 2)}</pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <div style={{ height: `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` }}>
        <AlGrid
          gridOptions={customGridOptions}
          colDefs={columnDefs}
          rowData={optimizationResults.previewData}
          onGridReadyCallback={onGridReady}
          fitToResizeEnabled={false}
          addExtraBottomPadding={true}
        />
      </div>
    </>
  );
};

export default OptimizationTable;
