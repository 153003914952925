import { isEmpty } from 'lodash-es';
import * as Sentry from '@sentry/react';

export enum CurrencyCode {
  BRL = 'BRL', // Brazil
  CAD = 'CAD', // Canada
  MXN = 'MXN', // Mexico
  USD = 'USD', // United States
  AED = 'AED', // United Arab Emirates
  EUR = 'EUR', // Belgium, Germany, Spain, France, Italy, Netherlands, Poland, Sweden, Turkey, United Kingdom
  EGP = 'EGP', // Egypt
  INR = 'INR', // India
  PLN = 'PLN', // Poland
  SAR = 'SAR', // Saudi Arabia
  SEK = 'SEK', // Sweden
  TRY = 'TRY', // Turkey
  GBP = 'GBP', // United Kingdom
  AUD = 'AUD', // Australia
  JPY = 'JPY', // Japan
  SGD = 'SGD', // Singapore
}

export function getCurrencyCode(code: string): CurrencyCode {
  switch (code.toUpperCase()) {
    case 'BRL':
      return CurrencyCode.BRL;
    case 'CAD':
      return CurrencyCode.CAD;
    case 'MXN':
      return CurrencyCode.MXN;
    case 'USD':
      return CurrencyCode.USD;
    case 'AED':
      return CurrencyCode.AED;
    case 'EUR':
      return CurrencyCode.EUR;
    case 'EGP':
      return CurrencyCode.EGP;
    case 'INR':
      return CurrencyCode.INR;
    case 'PLN':
      return CurrencyCode.PLN;
    case 'SAR':
      return CurrencyCode.SAR;
    case 'SEK':
      return CurrencyCode.SEK;
    case 'TRY':
      return CurrencyCode.TRY;
    case 'GBP':
      return CurrencyCode.GBP;
    case 'AUD':
      return CurrencyCode.AUD;
    case 'JPY':
      return CurrencyCode.JPY;
    case 'SGD':
      return CurrencyCode.SGD;
    default:
      console.log(`Invalid currency code: ${isEmpty(code) ? '<empty>' : code}`);
      Sentry.captureMessage(`Invalid currency code: ${isEmpty(code) ? '<empty>' : code}`);
      return CurrencyCode.USD;
  }
}
