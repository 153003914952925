import { SortByUnit } from '@/components/buttons/SortByToggleButton';
import { useMemo } from 'react';
import { ComparisonUnit } from '../types';
import { MetricData } from '@/modules/targeting/models/TargetsModel';

export const useComparator = (
  contextSortByUnit: SortByUnit,
  contextComparisonUnit: ComparisonUnit,
  {
    metricsDataComparator,
    metricsDataDeltaComparator,
    metricsDataDeltaPercentageComparator,
  }: {
    metricsDataComparator: (valueA: MetricData, valueB: MetricData) => number;
    metricsDataDeltaComparator: (valueA: MetricData, valueB: MetricData) => number;
    metricsDataDeltaPercentageComparator: (valueA: MetricData, valueB: MetricData) => number;
  },
) => {
  // Memoize the comparator selection logic to optimize performance
  const comparator = useMemo(() => {
    // If SortByUnit is Count, use the metricsDataComparator
    if (contextSortByUnit === SortByUnit.Count) {
      return metricsDataComparator;
    }

    // If the comparison unit is percent, use the delta percentage comparator
    if (contextComparisonUnit === 'percent') {
      return metricsDataDeltaPercentageComparator;
    }

    // Otherwise, default to the delta comparator
    return metricsDataDeltaComparator;
  }, [contextSortByUnit, contextComparisonUnit, metricsDataComparator, metricsDataDeltaComparator, metricsDataDeltaPercentageComparator]);

  return comparator;
};
