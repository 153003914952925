import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { TableWidgetConfiguration } from './TableWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import { TableRowsRounded } from '@mui/icons-material';

export const TABLE_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<TableWidgetConfiguration> = {
  title: 'Table',
  description: 'Shows a Table with statistics.',
  icon: <TableRowsRounded />,
  iconColor: 'blue',
  id: v4(),
  type: DashboardWidgetType.TableWidget,
  configuration: new TableWidgetConfiguration(),
  layout: {
    h: 4,
    w: 8,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 42,
    maxW: 42,
    minH: 2,
    minW: 2,
  },
};
