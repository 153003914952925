import { SelectChangeEvent } from '@mui/material';
import { FunctionComponent } from 'react';
import { MetricField } from '../metrics/models/CommonMetricsModel';
import AlSelect from './AlSelect';

interface MetricSelectProps {
  value: MetricField | '';
  onChange: (value: MetricField | '') => void;
  helperText?: string;
}

const MetricSelect: FunctionComponent<MetricSelectProps> = ({ value, onChange, helperText }) => {
  const handleChange = (event: SelectChangeEvent<MetricField | ''>) => {
    onChange(event.target.value as MetricField | '');
  };

  const options: { label: string; value: MetricField }[] = [
    { label: 'Impressions', value: MetricField.IMPRESSIONS },
    { label: 'Clicks', value: MetricField.CLICKS },
    { label: 'Orders', value: MetricField.ORDERS },
    { label: 'CTR', value: MetricField.CTR },
    { label: 'CVR', value: MetricField.CVR },
    { label: 'CPC', value: MetricField.CPC },
    { label: 'Spend', value: MetricField.SPEND },
    { label: 'Sales', value: MetricField.SALES },
    { label: 'ACOS', value: MetricField.ACOS },
    { label: 'ROAS', value: MetricField.ROAS },
    { label: 'RPC', value: MetricField.RPC },
    { label: 'CPA', value: MetricField.CPA },
    { label: 'AOV', value: MetricField.AOV },
    { label: 'CPM', value: MetricField.CPM },
  ];

  return (
    <AlSelect
      label="Select Metric"
      value={value}
      options={options}
      onChange={handleChange}
      renderOption={(v) => v.label}
      valueExtractor={(v) => v.value}
      helperText={helperText}
    />
  );
};

export default MetricSelect;
