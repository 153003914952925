export const DATA_MANAGEMENT_TRANSLATIONS_ENGLISH = {
  importer: {
    upload_a_file: 'Upload a File',
    upload: {
      file_details: 'File Details',
      files_you_can_imported: 'Select a XLSX file to be imported.',
      file_size: 'Size',
      select_file: 'Select file',
      select_xlsx_file: 'Select XLSX file',
      change_file: 'Change File',
      row_count: 'Rows (inc header rows)',
    },
    import: {
      import: 'Import',
    },
    campaign_mapping_import: {
      title: 'Campaign Mappings',
      description:
        'Download the template, fill it in and upload campaign mappings in bulk. After uploading, you can review and edit the mappings before importing.',
    },
  },
};
