import { FunctionComponent, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { IconButton } from '@mui/material';
import { SettingsRounded } from '@mui/icons-material';

const DashboardWidgetConfigurationButton: FunctionComponent = () => {
  const { isConfiguring, setIsConfiguring, widgetId } = useContext(DashboardWidgetContext);
  const { setEditingWidgetId, editingWidgetId, isEditingDashboard } = useDashboardContextValue((context) => ({
    setEditingWidgetId: context.setEditingWidgetId,
    editingWidgetId: context.editingWidgetId,
    isEditingDashboard: context.isEditingDashboard,
  }));

  return (
    isEditingDashboard && (
      <div
        className={`absolute right-1 top-1 z-50 transition-opacity   ${isEditingDashboard ? 'opacity-100' : 'opacity-0'}`}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.stopPropagation();
          e.bubbles = false;

          // Don't allow edits when dashboard is not in edit mode
          if (!isEditingDashboard) {
            return;
          }

          // If clicked and it's the cogwheel of a different widget
          // Open the drawer for this widget
          if (editingWidgetId !== widgetId || !isConfiguring) {
            setIsConfiguring(true);
            setEditingWidgetId(widgetId ?? '');
          } else {
            setIsConfiguring(false);
            setEditingWidgetId('');
          }
        }}
      >
        <IconButton size="small" color="primary">
          <SettingsRounded></SettingsRounded>
        </IconButton>
      </div>
    )
  );
};

export default DashboardWidgetConfigurationButton;
