import Sparkline from '@/components/chart/charts/Sparkline';
import { expandPreviousDayFromMetricDTO } from '@/components/metrics/api/metrics-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Timezone } from '@/modules/users/types/Timezone';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';

export interface IAlSparklineCellRendererParams {
  color?: 'green' | 'amber';
  valueFormatter?: (value: number) => string;
}

interface ISparklineCellRendererProps extends ICellRendererParams, IAlSparklineCellRendererParams {}

const SparklineCellRenderer: FunctionComponent<ISparklineCellRendererProps> = ({ value, color, valueFormatter }) => {
  const { activeProfile } = useActiveTeamContext();

  if (!value) return null;

  if (value.length === 0) return null;

  // if array max value is 0, return null
  if (Math.max(...value) === 0) return null;
  // Optionally we might want to return just a flat line:
  // return (
  //   <div className="h-full w-full flex">
  //     <div className="flex-1 border-b border-b-gray-400 mb-0.5"></div>
  //   </div>
  // );

  const dataPoints = expandPreviousDayFromMetricDTO(
    value,
    activeProfile ? activeProfile.timeZone.toString() : Timezone.AmericaLosAngeles.toString(),
  );

  return <Sparkline data={dataPoints} color={color} valueFormatter={valueFormatter} />;
};

export default SparklineCellRenderer;
