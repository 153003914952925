import { FunctionComponent } from 'react';

export interface IImageWithTitleCellRendererProps {
  title?: string;
  imageUrl?: string;
}

const ImageWithTitleCellRenderer: FunctionComponent<IImageWithTitleCellRendererProps> = ({ title = '', imageUrl }) => {
  return (
    <div className="flex items-center space-x-3 h-full">
      {imageUrl && <img src={imageUrl} alt={title} className="object-cover rounded-md" />}
      <p className="line-clamp-2 text-wrap leading-normal font-medium">{title}</p>
    </div>
  );
};

export default ImageWithTitleCellRenderer;
