import UnitToggleButton from '@/components/buttons/UnitToggleButton';
import { DATE_FORMAT } from '@/components/filter-builder/FiltersConfig';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { AlFilterModel, getDefaultDateRange } from '@/components/filter-builder/models/AlFilterModel';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ChangePercentageCellRenderer from '@/components/grid/cells/ChangePercentageCellRenderer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import { ComparisonUnit } from '@/components/grid/types';
import { getSentimentByMetric } from '@/components/metrics/MetricsConfig';
import { CommonMetrics, MetricField } from '@/components/metrics/models/CommonMetricsModel';
import useFormatting, { FormattingParams } from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { sleep } from '@/lib/api/api-utils';
import { profileService } from '@/modules/profiles/api/profile.service';
import { profilesStatsTableDefaultColumnState } from '@/modules/profiles/configuration/profiles-stats-table.default-column-state';
import { ProfileWithMetricsModel } from '@/modules/profiles/types/ProfileWithMetricsModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { SentimentDirection } from '@/types/colors.enum';
import { InfoOutlined } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { ColDef, GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DateRangePickerButton from '../../application/components/date-range-picker/DateRangePickerButton';

interface ProfilesStatsGridContext {
  comparisonUnit: ComparisonUnit;
}

const ProfilesStatsTable = () => {
  const navigate = useNavigate();
  const { getLongFormatterForMetricField, formatWithThousandsSeparator } = useFormatting();
  const { user } = useUserContext();
  const { setActiveTeam } = useActiveTeamContext();

  const gridApiRef = useRef<GridApi<ProfileWithMetricsModel>>();
  const gridContextRef = useRef<ProfilesStatsGridContext>();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.PROFILES_STATS_TABLE_COLUMN_STATE,
    profilesStatsTableDefaultColumnState(),
  );

  // FILTERS
  const { activeTeam } = useActiveTeamContext();

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    return activeTeam?.hasProPlan ? filtersService.loadDateFilters() : getDefaultDateRange();
  });

  const { dates, comparisonDates, onSetDates } = useFilters({ filters, setFilters });

  useEffect(() => {
    if (!activeTeam?.hasProPlan) {
      return;
    }
    filtersService.saveDateFilters(filters);
  }, [filters]);

  const PROFILE_STATS_QUERY_KEY = [
    'profile-stats',
    dates.map((d) => d.format(DATE_FORMAT)),
    comparisonDates.map((d) => d.format(DATE_FORMAT)),
    user?.teams.map((t) => t.name), // If team name is changed we need to refresh
    user?.teams.map((t) => t.profiles.map((p) => p.id)).flat(), // If a profile is added or removed we need to refresh
  ];

  const { data: rowData, isFetching } = useQuery({
    queryKey: PROFILE_STATS_QUERY_KEY,
    queryFn: async () => {
      const result = await profileService.getUserProfilesWithMetrics({
        userId: user?.id as number,
        dates: {
          startDate: dates[0].format(DATE_FORMAT),
          endDate: dates[1].format(DATE_FORMAT),
        },
        compareDates: {
          startDate: comparisonDates[0].format(DATE_FORMAT),
          endDate: comparisonDates[1].format(DATE_FORMAT),
        },
      });

      if (result.isSuccess) {
        return result.payload.filter((p) => p.isActive);
      } else {
        toast.error('Error loading profile stats');
      }
    },
  });

  async function onProfileNameClicked(profileWithMetrics: ProfileWithMetricsModel) {
    try {
      if (!profileWithMetrics.id) {
        return;
      }
      setActiveTeam({ teamId: profileWithMetrics.teamId, profileId: profileWithMetrics.id });
      await sleep(50); // wait for 50 ms before navigate
      navigate(Routes.OPTIMIZER);
    } catch (error) {
      console.log(error);
      toast.error('Unable to navigate to profile. Please try again later.');
    }
  }

  const getCellRendererParamsThousandsSeparatorSynced =
    (field: keyof CommonMetrics) => (params: ICellRendererParams<ProfileWithMetricsModel>) => ({
      currentValueFormatter: formatWithThousandsSeparator,
      sentimentDirection: SentimentDirection.SYNCED,
      value: params.data ? params.data.metrics[field] : null,
      comparisonUnit: params.context?.comparisonUnit,
    });

  const columnDefs: ColDef<ProfileWithMetricsModel>[] = useMemo(() => {
    const colDefs: ColDef<ProfileWithMetricsModel>[] = [
      {
        colId: ColumnId.TEAM_NAME,
        headerName: 'Team',
        field: 'teamName',
        minWidth: 150,
      },
      {
        colId: ColumnId.PROFILE_NAME,
        headerName: 'Profile',
        minWidth: 150,
        cellRenderer: LinkCallbackCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<ProfileWithMetricsModel>,
        ): ILinkCallbackCellRendererParams<ProfileWithMetricsModel> => {
          return {
            buttonText: params.data?.nameWithMarket || '',
            tooltip: 'Start optimizing this Profile',
            callback: onProfileNameClicked,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.nameWithMarket : null),
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'metrics.impressions',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 125,
        width: 125,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('impressions'),
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.impressions[0] : null),
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'metrics.clicks',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('clicks'),
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.clicks[0] : null),
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'metrics.orders',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('orders'),
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.orders[0] : null),
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'metrics.ctr',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 100,
        width: 100,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.CTR;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.ctr,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.ctr[0] : null),
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'metrics.cvr',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 100,
        width: 100,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.CVR;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.cvr,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.cvr[0] : null),
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'metrics.cpc',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 100,
        width: 100,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.CPC;
          const formattingParams: FormattingParams = { customCurrencyCode: params.data?.currencyCode };
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value, formattingParams),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.cpc,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.cpc[0] : null),
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'metrics.spend',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 125,
        width: 125,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.SPEND;
          const formattingParams: FormattingParams = { customCurrencyCode: params.data?.currencyCode };
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value, formattingParams),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.spend,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.spend[0] : null),
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'metrics.sales',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 125,
        width: 125,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.SALES;
          const formattingParams: FormattingParams = { customCurrencyCode: params.data?.currencyCode };
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value, formattingParams),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.sales,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.sales[0] : null),
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'metrics.acos',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.ACOS;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.acos,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.acos[0] : null),
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'metrics.roas',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('roas'),
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.roas[0] : null),
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'metrics.rpc',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.RPC;
          const formattingParams: FormattingParams = { customCurrencyCode: params.data?.currencyCode };
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value, formattingParams),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.rpc,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.rpc[0] : null),
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'metrics.cpa',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.CPA;
          const formattingParams: FormattingParams = { customCurrencyCode: params.data?.currencyCode };
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value, formattingParams),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.cpa,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.cpa[0] : null),
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'metrics.aov',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.AOV;
          const formattingParams: FormattingParams = { customCurrencyCode: params.data?.currencyCode };
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value, formattingParams),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.aov,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.aov[0] : null),
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'metrics.cpm',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        minWidth: 105,
        width: 105,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel>) => {
          const metricField = MetricField.CPM;
          const formattingParams: FormattingParams = { customCurrencyCode: params.data?.currencyCode };
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value, formattingParams),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.metrics.cpm,
            comparisonUnit: params.context?.comparisonUnit,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.metrics.cpm[0] : null),
      },
    ];
    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  const [comparisonUnit, setComparisonUnit] = useState<ComparisonUnit>('percent'); // Default value and tracks button state
  const handleComparisonUnitChange = (event: React.MouseEvent<HTMLElement>, newAlignment: ComparisonUnit) => {
    setComparisonUnit(newAlignment);

    if (!gridContextRef.current || !gridApiRef.current) {
      return;
    }
    gridContextRef.current.comparisonUnit = newAlignment;
    gridApiRef.current.refreshCells({ force: true });
  };

  const customGridOptions: GridOptions<ProfileWithMetricsModel> = {
    ...DEFAULT_GRID_OPTIONS,
    getRowId: (params) => params.data.teamId.toString() + '-' + params.data.id.toString(),
    context: {
      comparisonUnit: comparisonUnit,
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
      headerComponent: DefaultHeaderRenderer,
    },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
  };

  const onGridReady = (params: GridReadyEvent<ProfileWithMetricsModel>) => {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <div className="flex flex-col gap-y-3 flex-1 flex-grow h-full">
      <div className="flex flex-row items-end justify-end ">
        <div className="flex flex-grow items-center gap-2">
          <InfoOutlined /> Profile totals include all campaigns (incl. archived)
        </div>

        <DateRangePickerButton dates={dates} comparisonDates={comparisonDates} onSetDates={onSetDates} />
        <UnitToggleButton handleComparisonUnitChange={handleComparisonUnitChange} comparisonUnit={comparisonUnit} />
      </div>

      <AlGrid
        colDefs={columnDefs}
        rowData={rowData}
        gridOptions={customGridOptions}
        isLoading={isFetching}
        onGridReadyCallback={onGridReady}
      />
    </div>
  );
};

export default ProfilesStatsTable;
