import { DATE_FORMAT } from '@/components/filter-builder/FiltersConfig';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';

interface DateRangeDisplayProps {
  filtersUsed: AlFilterModel[];
  hideComparison?: boolean;
  comparisonRangeWarning?: string | null;
}

const DateRangeDisplay: FunctionComponent<DateRangeDisplayProps> = ({
  filtersUsed: filters,
  hideComparison = false,
  comparisonRangeWarning,
}) => {
  const dateRange = filters.find((filterItem) => filterItem.key === FilterKey.DATE)?.conditions;
  const comparisonDateRange = filters.find((filterItem) => filterItem.key === FilterKey.COMPARE_DATE)?.conditions;

  return (
    <div className="flex">
      <div className="flex flex-col">
        {dateRange && dateRange.length > 0 ? (
          <div className="font-bold whitespace-nowrap">
            {dayjs(dateRange[0].values[0], DATE_FORMAT).format('D MMM')}
            {dayjs(dateRange[0].values[0], DATE_FORMAT).isSame(dayjs(dateRange[1].values[0], DATE_FORMAT), 'year')
              ? ''
              : ', ' + dayjs(dateRange[0].values[0], DATE_FORMAT).format('YYYY')}
            <span className="px-1">-</span>
            {dayjs(dateRange[1].values[0], DATE_FORMAT).format('D MMM, YYYY')}
          </div>
        ) : (
          <div className="flex">
            <div className="text-sm">-</div>
          </div>
        )}
        {!hideComparison && comparisonDateRange && comparisonDateRange.length > 0 && (
          <div className="flex items-center text-xs opacity-70">
            {comparisonRangeWarning && (
              <Tooltip title={comparisonRangeWarning} placement="top">
                <WarningIcon
                  sx={{
                    marginRight: '4px',
                    width: 12,
                    height: 12,
                    color: 'darkorange',
                  }}
                />
              </Tooltip>
            )}
            <div className="">{dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).format('D MMM')}</div>
            <div>
              {dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).isSame(dayjs(comparisonDateRange[1].values[0], DATE_FORMAT), 'year')
                ? ''
                : ', ' + dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).format('YYYY')}
            </div>
            <div className=" px-1">-</div>
            <div className="">{dayjs(comparisonDateRange[1].values[0], DATE_FORMAT).format('D MMM, YYYY')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangeDisplay;
