import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { CampaignAdType, CampaignGroupDTO } from '../campaign-contracts';
import { CampaignGroupMetricsModel } from './CampaignGroupMetricsModel';

export class CampaignGroupModel {
  public id: number;
  public adType: CampaignAdType;
  public name: string;
  public tacos?: number;
  public preset: OptimizationPreset;
  public bidCeiling?: number;
  public totalCampaigns: number;

  constructor(args: CampaignGroupArguments) {
    this.id = args.id;
    this.adType = args.adType;
    this.name = args.name;
    this.tacos = args.tacos;
    this.preset = args.preset;
    this.bidCeiling = args.bidCeiling;
    this.totalCampaigns = args.totalCampaigns;
  }

  public static fromResponseArray(dtos: CampaignGroupDTO[]): CampaignGroupModel[] {
    return dtos.map(
      (dto) =>
        new CampaignGroupModel({
          id: dto.id,
          adType: dto.ad_type,
          name: dto.name,
          tacos: dto.tacos,
          preset: dto.preset ?? OptimizationPreset.NOT_SET,
          bidCeiling: dto.bid_ceiling,
          totalCampaigns: dto.total_campaigns,
        }),
    );
  }

  public static fromCampaignGroupMetricsModel(metricsModel: CampaignGroupMetricsModel): CampaignGroupModel {
    return new CampaignGroupModel({
      id: metricsModel.id,
      adType: metricsModel.adType,
      name: metricsModel.name,
      tacos: metricsModel.tacos,
      preset: metricsModel.preset,
      bidCeiling: metricsModel.bidCeiling,
      totalCampaigns: metricsModel.totalCampaigns,
    });
  }
}

export interface CampaignGroupArguments {
  id: number;
  adType: CampaignAdType;
  name: string;
  tacos?: number;
  preset: OptimizationPreset;
  bidCeiling?: number;
  totalCampaigns: number;
}

export const emptyUnAssignedCampaignGroup = new CampaignGroupModel({
  id: 0,
  adType: CampaignAdType.PRODUCTS,
  name: 'Unassigned',
  tacos: 0,
  preset: OptimizationPreset.NOT_SET,
  bidCeiling: 0,
  totalCampaigns: 0,
});
