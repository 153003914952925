import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FilterKey } from '../types/FilterKey';
import { FunctionComponent } from 'react';

export enum BetweenOperatorType {
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  BETWEEN = 'BETWEEN',
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
}

interface BetweenOperatorSelectProps {
  label: string;
  filterKey: FilterKey;
  defaultValue?: BetweenOperatorType;
  handleChange: (event: SelectChangeEvent) => void;
  isDisabled?: boolean;
}

export const BetweenOperatorSelect: FunctionComponent<BetweenOperatorSelectProps> = ({
  label,
  filterKey,
  defaultValue,
  handleChange,
  isDisabled = false,
}) => {
  const OPERATOR_OPTIONS = [
    { value: BetweenOperatorType.LESS_THAN, label: 'Less than' },
    { value: BetweenOperatorType.LESS_THAN_OR_EQUAL, label: 'Less than or equal' },
    { value: BetweenOperatorType.EQUAL, label: 'Equal to' },
    { value: BetweenOperatorType.BETWEEN, label: 'Between' },
    { value: BetweenOperatorType.GREATER_THAN, label: 'Greater than' },
    { value: BetweenOperatorType.GREATER_THAN_OR_EQUAL, label: 'Greater than or equal' },
  ];

  return (
    <FormControl className={'w-48'}>
      <InputLabel id={'logical-operator-label-' + filterKey}>{label}</InputLabel>
      <Select
        labelId={'logical-operator-label-' + filterKey}
        label={label}
        id={'logical-operator-' + filterKey}
        disabled={isDisabled}
        defaultValue={OPERATOR_OPTIONS.find((option) => option.value === defaultValue)?.value ?? BetweenOperatorType.BETWEEN}
        onChange={handleChange}
      >
        {OPERATOR_OPTIONS.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
