import { RouteObject } from 'react-router-dom';
import ProductsPage from '../pages/ProductsPage';

export const PRODUCTS_ROUTES: RouteObject[] = [
  {
    path: 'products',
    children: [
      {
        index: true,
        element: <ProductsPage />,
      },
    ],
  },
];
