import { FilterBuilderDialog } from '@/components/filter-builder/FilterBuilderDialog';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { AlFilterModel, TargetingFilterModel, createNegativeTargetingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button, Card, Divider } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { useNegativeTargetingContext } from '../contexts/NegativeTargetsContext';
import { FilterChipRow } from '@/components/filter-builder/FilterChipRow';

const MAX_FILTER_CHIPS = 6;

const NegativeTargetingFilterBar: FunctionComponent = () => {
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false); // state to control the popover
  const { filters, setFilters, setFilterValue } = useNegativeTargetingContext();

  const onFilterChipClicked = () => {
    setIsFilterDialogOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  const NEGATIVE_TARGETS_FILTERS = createNegativeTargetingFilters();

  function onFiltersChanged(newFilters: AlFilterModel[]) {
    setFilters(newFilters);
  }

  return (
    <Card className="flex flex-row items-center gap-x-4 p-2 pb-3 rounded-none rounded-tr-xl rounded-tl-xl -mb-1 ">
      <SearchBox
        filterKey={FilterKey.TARGETING}
        newFilterModel={() => new TargetingFilterModel()}
        placeholder="Search Neg. Target"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />
      <div className="flex flex-row">
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={onFilterChipClicked}
          startIcon={<FilterListRoundedIcon />}
          ref={filtersButtonRef}
          className="mr-2 flex-shrink-0"
        >
          Adjust Filters
        </Button>

        <FilterBuilderDialog
          buttonRef={filtersButtonRef}
          setIsOpen={setIsFilterDialogOpen}
          isOpen={isFilterDialogOpen}
          availableFilters={NEGATIVE_TARGETS_FILTERS}
          defaultFilterKey={FilterKey.CAMPAIGN_NAME}
          appliedFilters={filters}
          onFiltersChanged={onFiltersChanged}
        />

        <FilterChipRow
          filters={filters}
          maxFilterChips={MAX_FILTER_CHIPS}
          onFilterDeleteButtonClicked={onFilterDeleteButtonClicked}
          onFilterChipClicked={onFilterChipClicked}
        />
      </div>

      <Divider className="my-4 " orientation="vertical" flexItem />
    </Card>
  );
};

export default NegativeTargetingFilterBar;
