import { TimelineDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MetricField } from '../../../../../components/metrics/models/CommonMetricsModel';

export class TimelineModel {
  public xAxisData: string[];
  public yAxisData: {
    key: MetricField;
    values: number[];
  }[];

  constructor(args: TimelineCreateArguments) {
    this.xAxisData = args.xAxisData;
    this.yAxisData = args.yAxisData;
  }

  public static fromResponse(response: TimelineDTO): TimelineModel {
    return new TimelineModel({
      xAxisData: response.x_axis,
      yAxisData: Object.keys(response.y_axis).map((key) => {
        return {
          key: key as MetricField,
          values: response.y_axis[key as MetricField],
        };
      }),
    });
  }

  public static fromResponseArray(responses: TimelineDTO[]): TimelineModel[] {
    return responses.map((response) => TimelineModel.fromResponse(response));
  }
}

interface TimelineCreateArguments {
  xAxisData: string[];
  yAxisData: {
    key: MetricField;
    values: number[];
  }[];
}
