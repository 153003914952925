import React, { useEffect, useState } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';

interface PopoverLikePopperProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const PopoverLikePopper: React.FunctionComponent<PopoverLikePopperProps> = ({ open, anchorEl, onClose, children, style }) => {
  // State to control the delayed activation of ClickAwayListener
  const [active, setActive] = useState(false);

  useEffect(() => {
    // If open, delay the activation of ClickAwayListener, otherwise the opening click is registred as "click away" and the popover is closed immediately after opening
    if (open) {
      const timeoutId = setTimeout(() => setActive(true), 200);
      return () => clearTimeout(timeoutId);
    }
    setActive(false);
  }, [open]);

  const handleClickAway = () => {
    if (!active) return; // Ignore click away if not active
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 60 }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center bottom' }}>
            <Paper elevation={8} style={{ maxWidth: '500px', overflowY: 'auto', maxHeight: 'calc(100vh - 120px)', ...style }}>
              {children}
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default PopoverLikePopper;
