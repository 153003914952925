import { ApiResponse } from '@/lib/api/api-response';
import { apiTeamClient } from '@/lib/api/base-client';

class SellerCentralService {
  public basePath = 'sellercentral';

  async createReport(profileId: string): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`profiles/${profileId}/${this.basePath}/reports/create`, {});
  }
}

export const sellerCentralService = new SellerCentralService();
