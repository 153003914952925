import { MetricAggregates } from '@/components/metrics/models/MetricsModel';
import { GridApi } from 'ag-grid-enterprise';
import { useEffect } from 'react';
import { ExpandedGridContext } from '../types';

interface UseMetricColumnAggregatesProps {
  gridApiRef: React.MutableRefObject<GridApi | undefined>;
  gridContextRef: React.MutableRefObject<ExpandedGridContext | undefined>;
  metricColumnAggregates: MetricAggregates | undefined;
}

// General hook to manage metric column aggregations
const useMetricColumnAggregates = ({ gridApiRef, gridContextRef, metricColumnAggregates }: UseMetricColumnAggregatesProps) => {
  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || !metricColumnAggregates) return;

    gridContextRef.current.metricColumnAggregates = metricColumnAggregates;

    gridApiRef.current.refreshHeader();
  }, [metricColumnAggregates]);

  const onGridReadyForMetricColumnAggregates = () => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    Object.assign(gridContextRef.current, {
      metricColumnAggregates: metricColumnAggregates,
    });

    // IMPORTANT: cell refreshing needs to be done in table onGridReady to avoid multiple refreshes
  };
  return { onGridReadyForMetricColumnAggregates };
};

export default useMetricColumnAggregates;
