import i18n from '@/lib/i18n/i18nConfig';
import { AccountTreeRounded } from '@mui/icons-material';
import { purple } from '@mui/material/colors';
import { ImportType } from '../../api/importer.contracts';
import { ImportDefinition } from '../../types/import-definition';
import { CampaignMappingImportModal } from './CampaignMappingImportModal';

export class CampaignMappingImport implements ImportDefinition {
  title = i18n.t('importer.campaign_mapping_import.title');
  description = i18n.t('importer.campaign_mapping_import.description');
  icon = (<AccountTreeRounded sx={{ fontSize: 40 }} />);
  iconColor = purple[500];
  type = ImportType.CAMPAIGN_MAPPING;
  templateFileUrl = '/importers/campaign-mapping/campaign_map_bulk_upload_template_v1.xlsx';
  component = CampaignMappingImportModal;
}
