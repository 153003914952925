import TextLink from '@/components/buttons/TextLink';
import { VideoUrl } from '@/config/urls';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { ProfileSelectorDialog } from '@/modules/teams/components/ProfileSelectorDialog';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext, userService } from '@/modules/users';
import { HookEvent } from '@/modules/users/api/users/users.service';
import { Routes } from '@/router/router-paths';
import Launch from '@mui/icons-material/Launch';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Card, CardContent, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { debounce, isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const BUTTON_WIDTH = '180px';

export const GettingStartedPage: FunctionComponent = () => {
  const { activeTeam } = useActiveTeamContext();
  const { user, refetchUser } = useUserContext();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Amazon redirects back to this page including code and state GET params
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const errorDescription = searchParams.get('error_description');

  const { mutate: authorizeUser, isPending: isLoadingAuthorizeUser } = useMutation({
    mutationFn: ({ teamId, userId, code, state }: { teamId: number; userId: number; code: string; state: string }) =>
      userService.authorizeUser(teamId, userId, { code, state, is_registration: true }),

    onSuccess: async (res) => {
      if (res.isSuccess) {
        toast.success(`Amazon authorization successful`);
        userService.sendNewUserDataToHook({
          event: HookEvent.CONNECT_AMAZON,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          name: user?.name ?? '' + (user?.surname ? ' ' + user?.surname : ''),
          email: user?.email ?? '',
        });
        await refetchUser();
        navigate(Routes.GETTING_STARTED, { replace: true });
      } else if (!activeTeam?.amazonAccount?.isAuthorized) {
        setIsError(true);
        setErrorMessage(`Amazon authorization failed: ${res.message}`);
      }
    },
  });

  const debouncedAuthorizeUser = debounce(authorizeUser, 250);

  // If GET params are present, authorize user
  useEffect(() => {
    if (!isNil(code) && !isEmpty(code) && !isNil(state) && !isEmpty(state) && activeTeam && user) {
      debouncedAuthorizeUser({ teamId: activeTeam.id, userId: user.id, code, state });
    }
  }, [code, state, activeTeam, user]);

  useEffect(() => {
    if (!isEmpty(errorDescription)) {
      setIsError(true);
      setErrorMessage(errorDescription + ' Please try again!');
    }
  }, []);

  const [isGetAuthUrlLoading, setIsGetAuthUrlLoading] = useState(false);
  async function onConnectAmazonClicked() {
    setIsGetAuthUrlLoading(true);
    const authUrl = await getAuthUrl();
    setIsGetAuthUrlLoading(false);
    if (isEmpty(authUrl)) {
      setIsError(true);
      setErrorMessage('Something went wrong. Auth url is empty. Please try again later.');
    } else {
      window.location.replace(authUrl); // user will not be able to use browser's back button
    }
  }

  async function getAuthUrl(): Promise<string> {
    if (user?.id && activeTeam?.id) {
      const res = await userService.getAmazonAuthorizationUrl(user?.id, activeTeam?.id, true);

      if (res.isSuccess) {
        return res.payload;
      } else {
        setIsError(true);
        setErrorMessage('Unable to get auth url. Please try again later.');
      }
    } else {
      setIsError(true);
      setErrorMessage('User or active team not set.');
    }

    return '';
  }

  function onManageProfilesClicked() {
    if (!activeTeam?.amazonAccount?.isAuthorized) {
      onConnectAmazonClicked();
    } else {
      setOpenProfileSelectorDialog(true);
    }
  }

  const [openProfileSelectorDialog, setOpenProfileSelectorDialog] = useState(false);
  const handleClose = () => {
    setOpenProfileSelectorDialog(false);
  };

  return (
    <PageLayout>
      <PageLayoutTopBar
        header="Getting Started"
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="flex justify-center mt-6">
          <div className="w-full max-w-4xl">
            {isError && (
              <Alert severity="error" className="mt-4 mb-4">
                {errorMessage}
              </Alert>
            )}
            <Card>
              <CardContent>
                <Typography variant="h5" className="mb-4">
                  Welcome to AdLabs
                </Typography>
                <Alert
                  severity={activeTeam?.amazonAccount?.isAuthorized ? 'success' : 'info'}
                  icon={false}
                  action={
                    activeTeam?.amazonAccount?.isAuthorized ? null : (
                      <LoadingButton
                        className="min-w-32"
                        variant="contained"
                        onClick={onConnectAmazonClicked}
                        loading={isGetAuthUrlLoading || isLoadingAuthorizeUser}
                        style={{ width: BUTTON_WIDTH }}
                      >
                        Connect Amazon
                      </LoadingButton>
                    )
                  }
                >
                  <AlertTitle>
                    {'Step 1 - ' + (activeTeam?.amazonAccount?.isAuthorized ? 'Amazon Ads Connected' : 'Connect with Amazon Ads')}
                  </AlertTitle>

                  <Typography>
                    Connect to select Profiles on AdLabs.{' '}
                    <a
                      href="https://help.adlabs.app/en/articles/20-why-do-you-need-to-connect-amazon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Why do I have to connect Amazon?
                    </a>
                  </Typography>
                </Alert>

                {/* <div className="mt-8 mb-8">
              <Typography variant="h3">How to Make Your First Niche Dive</Typography>
            </div> */}

                <Alert
                  className="mt-4"
                  severity={activeTeam && activeTeam.profiles.length > 0 ? 'success' : 'info'}
                  icon={false}
                  action={
                    <LoadingButton className="min-w-32" variant="contained" onClick={onManageProfilesClicked} style={{ width: BUTTON_WIDTH }}>
                      Manage Profiles
                    </LoadingButton>
                  }
                >
                  <AlertTitle>{'Step 2 - Manage Profiles'}</AlertTitle>
                  <Typography>
                    {' '}
                    {/* Adjust the max width as needed */}
                    It takes our system ~30 minutes to sync your data for the profile(s) you connect. You can later add/remove profiles from
                    the Manage Profiles section.
                  </Typography>
                </Alert>

                <Alert
                  className="mt-4"
                  severity={activeTeam && activeTeam?.preferredUpdateTime ? 'success' : 'info'}
                  icon={false}
                  action={<PreferredTimePicker onSuccess={() => refetchUser()} />}
                >
                  <AlertTitle>
                    {'Step 3 - ' + (activeTeam && activeTeam?.preferredUpdateTime ? 'Daily Sync Time Set' : 'Set Daily Sync Time')}
                  </AlertTitle>
                  <Typography>
                    {' '}
                    {/* Adjust the max width as needed */}
                    Set your data to update ~30min before your day starts to ensure fresh data. Daily Sync time applies per team. You can
                    later change this time in the Manage Profiles section.
                  </Typography>
                </Alert>
              </CardContent>
            </Card>
            <div className="flex flex-col gap-4 mt-4">
              <Card>
                <CardContent>
                  <Typography variant="h6">Watch the Beginner’s Tutorial video</Typography>
                  <Typography variant="body1">
                    In this short video, we walk you through <b>the most important things</b> to know about using the AdLabs Bid Optimizer.
                  </Typography>
                  <div className="flex justify-center mt-4">
                    <iframe
                      width="100%"
                      height="450px"
                      src={VideoUrl.PAYWALL_DEFAULT}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                    ></iframe>
                  </div>
                  <Typography variant="body1">
                    <b>Sections:</b>
                    <br />
                    00:00 - Introduction to Ad Labs Bid Optimizer
                    <br />
                    00:24 - Choosing the Right Date Range
                    <br />
                    01:40 - Selecting Campaigns and Optimizing Bids
                    <br />
                    01:53 - Understanding Prioritization Options
                    <br />
                    03:50 - Previewing and Adjusting Optimizations
                    <br />
                    04:42 - Quality Assurance and Manual Tweaks
                    <br />
                    06:19 - Applying Changes and Reverting if Necessary
                  </Typography>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography variant="h6">Additional Resources</Typography>
                  <div className="flex flex-row gap-6 mb-2 mt-2">
                    <div>
                      <TextLink
                        onClick={() => window.open('https://help.adlabs.app/en/collections/1-general-help-documentation', '_blank')}
                        className="cursor-pointer"
                      >
                        <Typography variant="body1" className="font-bold">
                          📚 General Help Docs
                        </Typography>
                        <Launch sx={{ ml: 0.5, fontSize: 'small' }} />
                      </TextLink>
                      <Typography variant="body1">FAQs and Quick Tips</Typography>
                    </div>
                    <div>
                      <TextLink
                        onClick={() => window.open('https://help.adlabs.app/en/collections/2-bid-optimizer-best-practices', '_blank')}
                        className="cursor-pointer"
                      >
                        <Typography variant="body1" className="font-bold">
                          💡 Bid Optimizer Best Practices
                        </Typography>
                        <Launch sx={{ ml: 0.5, fontSize: 'small' }} />
                      </TextLink>
                      <Typography variant="body1">Helpful tips to point you in the right direction</Typography>
                    </div>
                    <div>
                      <TextLink
                        onClick={() =>
                          window.open('https://help.adlabs.app/en/collections/3-the-adlabs-white-box-bidding-algorithm', '_blank')
                        }
                        className="cursor-pointer"
                      >
                        <Typography variant="body1" className="font-bold">
                          🧮 AdLabs &quot;White Box&quot; Bidding Algorithm
                        </Typography>
                        <Launch sx={{ ml: 0.5, fontSize: 'small' }} />
                      </TextLink>
                      <Typography variant="body1">See the math behind the magic</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography variant="h6">Want to get more out of AdLabs? Watched the Advanced Tutorial!</Typography>
                  <Typography variant="body1">
                    For those that really want to control and fine-tune their optimizations, we discuss the ways in which you can further
                    manipulate the Bid Optimizer to achieve your desired outcome.
                  </Typography>
                  <div className="flex justify-center mt-4">
                    <iframe
                      width="100%"
                      height="450px"
                      src={VideoUrl.BID_OPTIMIZER_ADVANCED}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                    ></iframe>
                  </div>
                  <Typography variant="body1">
                    <b>Sections:</b>
                    <br />
                    00:41 - Creating and Managing Optimization Groups
                    <br />
                    03:00 - Optimizing Bids and Understanding Optimization Group Settings
                    <br />
                    06:35 - Advanced Controls and Targets for Optimization
                    <br />
                    06:57 - Optimizing High ACoS and High Spend Non-Converting Keywords
                    <br />
                    08:59 - Increasing Bids on Low Visibility Keywords
                    <br />
                    12:41 - Understanding SmartBid Ceilings
                    <br />
                    21:55 - Frequency of Optimization and Final Tips
                    <br />
                    30:53 - Conclusion and Further Support
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

        <ProfileSelectorDialog open={openProfileSelectorDialog} onClose={handleClose} />
      </PageLayoutBody>
    </PageLayout>
  );
};
