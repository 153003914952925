import { toast } from 'react-toastify';

export function getErrorMessage(e: unknown): string {
  if (e instanceof Error) {
    return e.message;
  } else if (typeof e === 'string') {
    return e;
  }
  return 'An unexpected error occurred';
}

/**
 * Checks if a string is a valid Amazon ASIN, ignoring case.
 * This regex covers ASINs that start with 'B' followed by 9 alphanumeric characters
 * or those that are 9 digits followed by either 'X' or another digit (like ISBNs).
 * Has to start with asin= and have the asin value in quotes.
 *
 * @param asin - The string to validate.
 * @returns true if the string is a valid ASIN, false otherwise.
 */
export function isValidASIN(asin: string): boolean {
  const parts = asin.split('asin=');
  if (parts.length !== 2) return false;

  // Check if first and last chars are "
  if (!(parts[1].length > 2 && parts[1][0] === '"' && parts[1][parts[1].length - 1] === '"')) return false;

  // Remove the first and last chars
  asin = parts[1].slice(1, -1);

  const regex = /^(B[\dA-Z]{9}|\d{9}(X|\d))$/i; // Added the 'i' flag for case insensitivity
  return regex.test(asin);
}

export const EXAMPLE_VALID_ASIN = 'asin="B000000000"';

export function standardizeASIN(asin: string): string {
  const cleanedCode = asin.trim().replaceAll('"', '').replaceAll('asin=', '').toUpperCase();
  return `asin="${cleanedCode}"`;
}

export const truncateName = (name: string): string => {
  return name.length > 35 ? name.substring(0, 15) + ' ... ' + name.substring(name.length - 15, name.length) : name;
};

const DEFAULT_MAX_STRING_LENGTH = 30;
export const truncateString = (s: string, tagLengthLimit = DEFAULT_MAX_STRING_LENGTH): string => {
  return s.length > tagLengthLimit ? s.substring(0, 4) + '...' + s.substring(s.length - 4, s.length) : s;
};

export function getSeparatedValuesFromText(text: string, currentTags: string[]): string[] {
  // Split the pasted text based on newline or tab character
  // \t represents a tab character.
  // \n represents a newline character in Unix/Linux systems.
  // \r represents a carriage return character, used in old Mac systems and part of the Windows newline sequence (\r\n).
  // + quantifier, if the pasted text contains consecutive newline or tab characters, they will be treated as a single delimiter
  // If we want to split on each occurrence of a newline or tab character (even if they are consecutive), we should split(/[\t\n\r]/)
  const pastedTags = text.split(/[\t\n\r]+/).filter((tag) => {
    const trimmedTag = tag.trim();
    return trimmedTag !== '';
  });

  const allTags = currentTags.map((tag) => tag.toLowerCase());
  const newTags = pastedTags.filter((tag) => !allTags.includes(tag.toLowerCase()));
  const duplicatedTags = pastedTags.filter((tag) => allTags.includes(tag.toLowerCase()));

  if (duplicatedTags?.length > 0) {
    if (duplicatedTags.length == 1) {
      toast.error(duplicatedTags[0] + ' is already in the list');
    } else {
      toast.error('These existing values not added: ' + duplicatedTags.join(', '));
    }
  }
  return newTags;
}

export function isNonNegativeNumber(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value) && value >= 0;
}

export function isPositiveNumber(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value) && value > 0;
}

export const downloadObjectArrayAsCsv = (rowData: object[], fileName: string, headers?: string[]) => {
  // Create CSV header from keys of the first object, assuming all objects have the same structure
  let csvHeader = Object.keys(rowData[0]).join(',') + '\n';
  if (headers && headers.length > 0) {
    csvHeader = headers.join(',') + '\n';
  }

  // Create CSV rows from rowData
  const csvRows = rowData
    .map((row) =>
      Object.values(row)
        .map(
          (field) => `"${field.toString().replace(/"/g, '""')}"`, // Handle fields that might contain quotes
        )
        .join(','),
    )
    .join('\n');

  const csvString = csvHeader + csvRows;

  // Create a Blob from the CSV String
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a link and trigger the download
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = `${fileName}.csv`;
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function sanitizeFilename(input: string): string {
  // TODO: Fix Unnecessary escape character issue
  const illegalRe = /[\/\?<>\\:\*\|"]/g; // Regular expression to match illegal characters in Windows and Unix/Linux
  const controlRe = /[\x00-\x1f\x80-\x9f]/g; // Control characters
  const reservedRe = /^\.+$|[\x00-\x1f\x80-\x9f]|^\.+|\.+$|[*?<>|]/g; // Additional problematic characters
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i; // Windows reserved names

  const sanitized = input
    .replace(illegalRe, '') // Remove illegal characters
    .replace(controlRe, '') // Remove control characters
    .replace(reservedRe, '') // Remove reserved characters problematic for Windows
    .replace(/\s/g, '_') // Replace spaces with underscores, or you can use .replace(/\s+/g, '') to remove spaces
    .replace(windowsReservedRe, ''); // Remove Windows reserved names

  return sanitized;
}

export function roundToPrecision(precisionNumber: number, numberToRound: number) {
  // Determine the number of decimal places in precisionNumber
  const decimalPlaces = (precisionNumber.toString().split('.')[1] || '').length;

  // Round the numberToRound to the specified number of decimal places
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(numberToRound * factor) / factor;
}

export function isValidPositiveNumberOrEmptyString(value: string): boolean {
  return /^(\d+(\.\d*)?|\.\d*|)?$/.test(value);
}

export function cleanAsinsReturnErrorMessage(asins: string[]): { validAsins: string[]; errorMessage: string | null } {
  const validAsins: string[] = [];
  const invalidAsins: string[] = [];

  for (const asin of asins) {
    const cleanedAsin = standardizeASIN(asin);
    if (isValidASIN(cleanedAsin)) {
      validAsins.push(cleanedAsin);
    } else {
      invalidAsins.push(asin);
    }
  }

  let errorMessage: string | null = null;

  if (invalidAsins.length > 0) {
    const INVALID_ASIN_SHOW_COUNT = 5;
    const firstX = invalidAsins.slice(0, INVALID_ASIN_SHOW_COUNT);
    const remainingCount = invalidAsins.length - firstX.length;

    errorMessage = `ASIN${firstX.length > 1 ? 's are' : ' is'} invalid: ${firstX.join(', ')}${remainingCount > 0 ? `, with ${remainingCount} others` : ''}.`;
  }

  return { validAsins, errorMessage };
}
