import { FunctionComponent, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react';

interface LayoutContext {
  onSourceScroll: (scrollPosition: number) => void;
  scrollDownTargetDivRef: React.MutableRefObject<HTMLDivElement | null>;
  isDisplayingBanner: boolean;
  setIsDisplayingBanner: (isDisplayingBanner: boolean) => void;
}

export const LayoutContext = createContext<LayoutContext>({
  onSourceScroll: () => null,
  scrollDownTargetDivRef: { current: null },
  isDisplayingBanner: false,
  setIsDisplayingBanner: () => null,
});

export const LayoutProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isDisplayingBanner, setIsDisplayingBanner] = useState(false);
  const lastScrollTopRef = useRef(0);
  const scrollDownTargetDivRef = useRef<HTMLDivElement>(null);
  const requestAnimationFrameRef = useRef<number>();

  const onSourceScroll = (scrollPosition: number) => {
    if (!scrollDownTargetDivRef.current) return;
    const diff = scrollPosition - lastScrollTopRef.current;
    // Only scroll down (diff positive)
    if (diff > 0) {
      // Frame wrapper is more performant - updates with browser natural render instead of separately
      requestAnimationFrameRef.current = requestAnimationFrame(() => {
        if (!scrollDownTargetDivRef.current) return;
        scrollDownTargetDivRef.current.scrollBy({
          top: diff,
          behavior: 'instant', // 'smooth' jumps back and is not _smooth_
        });
      });
    }
    lastScrollTopRef.current = scrollPosition;
  };

  useEffect(() => {
    return () => {
      // Cleanup
      if (requestAnimationFrameRef.current) cancelAnimationFrame(requestAnimationFrameRef.current);
    };
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        onSourceScroll,
        scrollDownTargetDivRef: scrollDownTargetDivRef,
        isDisplayingBanner,
        setIsDisplayingBanner,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = (): LayoutContext => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};
