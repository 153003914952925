import DropdownButton, { DropdownButtonItem } from '@/components/buttons/DropdownButton';
import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { useTheming } from '@/config/theme/useTheming';
import { ColorModeContext } from '@/modules/application';
import { DataGroupsPopover } from '@/modules/data-groups/components/DataGroupsPopover';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { ThemeProvider } from '@emotion/react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import { isNil } from 'lodash-es';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { CampaignGroupsPopover } from './campaign-groups/campaign-groups-popover/CampaignGroupsPopover';

interface GroupsDropdownButtonProps {
  selectedCampaigns: SelectedCampaignDTO[];
  setSelectedCampaigns: React.Dispatch<React.SetStateAction<SelectedCampaignDTO[]>>;
}

const GroupsDropdownButton: FunctionComponent<GroupsDropdownButtonProps> = ({ selectedCampaigns, setSelectedCampaigns }) => {
  const { activeTeam } = useActiveTeamContext();

  // Paywall
  const [paywallMessage, setPaywallMessage] = useState('');
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
    setPaywallMessage('');
  };

  // Optimization groups
  const [isOptimizationGroupsPopoverOpen, setIsOptimizationGroupsPopoverOpen] = useState(false);
  const onOptimizationGroupsClicked = () => {
    setIsOptimizationGroupsPopoverOpen((previousValue) => !previousValue);
  };

  // Data groups
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDataGroupsPopoverOpen, setIsDataGroupsPopoverOpen] = useState(false);
  const onDataGroupsClicked = () => {
    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canAssignDataGroups) {
      setPaywallMessage(`Upgrade to Pro to assign data groups`);
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDataGroupsPopoverOpen(true);
  };

  const items: DropdownButtonItem[] = [
    {
      label: 'Optimization Groups',
      onClick: onOptimizationGroupsClicked,
      startIcon: <AutoAwesomeMotionIcon fontSize="small" />,
    },
    {
      label: 'Data Groups',
      onClick: onDataGroupsClicked,
      startIcon: <AccountTreeIcon fontSize="small" />,
    },
  ];

  // Theming, because it's inside OppositeModeOverlayBar, the theme needs to be opposite again
  const { mode } = useContext(ColorModeContext);
  const { colorMode } = useTheming();
  return (
    <>
      <DropdownButton
        ref={dropdownButtonRef}
        size="small"
        items={items}
        startIcon={<AutoAwesomeMotionIcon />}
        variant={isDataGroupsPopoverOpen || isOptimizationGroupsPopoverOpen ? 'contained' : 'outlined'}
      >
        Groups
      </DropdownButton>
      <ColorModeContext.Provider
        value={{ toggleColorMode: colorMode.toggleColorMode, mode: mode === 'light' ? 'dark' : 'light', isDarkMode: mode === 'light' }}
      >
        <ThemeProvider theme={mode === 'light' ? DARK_THEME : BASE_THEME}>
          <CampaignGroupsPopover
            buttonRef={dropdownButtonRef}
            isOpen={isOptimizationGroupsPopoverOpen}
            setIsOpen={setIsOptimizationGroupsPopoverOpen}
            selectedCampaigns={selectedCampaigns}
            setSelectedCampaigns={setSelectedCampaigns}
          />

          <DataGroupsPopover
            buttonRef={dropdownButtonRef}
            isOpen={isDataGroupsPopoverOpen}
            setIsOpen={setIsDataGroupsPopoverOpen}
            selectedItems={selectedCampaigns}
            dataGroupType={DataGroupType.CAMPAIGN}
          />

          <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal} returnURLPath={Routes.OPTIMIZER}>
            {paywallMessage}
          </PaywallModal>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default GroupsDropdownButton;
