import { useTranslation } from '@/lib/i18n/useTranslate';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { createGetAllGroupsQueryKey, dataGroupsService } from '../api/data-groups-service';
import DataGroupsActions from '../components/DataGroupsActions';
import DataGroupsTable from '../components/DataGroupsTable';

const DataGroupsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const { activeTeam, activeProfile } = useActiveTeamContext();

  const {
    data: rowData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: createGetAllGroupsQueryKey(activeTeam?.id),
    queryFn: async () => {
      const result = await dataGroupsService.getAllGroups();

      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error(`Error loading groups ${result.message}`);
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('data_groups_page.header')}
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <ErrorBoundary>
          <DataGroupsActions rowData={rowData} refetch={refetch} />
          <div className="flex flex-grow">
            <DataGroupsTable rowData={rowData} isLoading={isLoading} refetch={refetch} />
          </div>
        </ErrorBoundary>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default DataGroupsPage;
