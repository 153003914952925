import { FunctionComponent, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import AlGrid from '../grid/AlGrid';
import { isNil } from 'lodash-es';
import CloseIcon from '@mui/icons-material/Close';

interface ViewChartDataModalProps {
  open: boolean;
  onClose: () => void;
  rowData: Record<string, unknown>[]; // The data to be displayed in the grid
  onDownloadAsCsv: () => void;
}

export const ViewChartDataModal: FunctionComponent<ViewChartDataModalProps> = ({ open, onClose, rowData, onDownloadAsCsv }) => {
  // Generate colDefs based on the data
  const colDefs = useMemo(() => {
    if (isNil(rowData) || rowData.length === 0) {
      return [];
    }
    return Object.keys(rowData[0]).map((key) => ({
      field: key,
      headerName: key,
    }));
  }, [rowData]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <div className="flex flex-row justify-between">
          <div>Data Table</div>

          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className="h-[75svh] hv flex  w-full flex-auto">
        <div className="flex flex-grow flex-shrink-0 flex-auto w-full h-full">
          <AlGrid colDefs={colDefs} rowData={rowData} isLoading={false} fitToResizeEnabled={false} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDownloadAsCsv} color="primary" variant="outlined" className="min-w-32">
          Download as CSV
        </Button>
        <Button onClick={onClose} color="primary" className="min-w-32">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
