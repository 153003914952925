import CollapsibleCopyableDetails from '@/components/feedback/CollapsibleCopyableDetails';
import AddMetricButton from '@/components/metrics/AddMetricButton';
import { getUpdatedSelectionColors } from '@/components/metrics/MetricsConfig';
import { MetricsCustomizationModal } from '@/components/metrics/MetricsCustomizationModal';
import { metricsService } from '@/components/metrics/api/metrics-service';
import { MetricField } from '@/components/metrics/models/CommonMetricsModel';
import { createAdvertisedProductsMetricsQueryKey } from '@/components/metrics/models/MetricsModel';
import { useTranslation } from '@/lib';
import MetricsList from '@/modules/dashboards/components/forms/MetricsList';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Alert, AlertTitle, Card, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { useAdvertisedProductsContext } from '../contexts/AdvertisedProductsContext';

// TODO: refactor: move to common component, keep only getting stuff from context and query
const AdvertisedProductsMetrics: FunctionComponent = () => {
  const { filters, visibleMetrics, setVisibleMetrics, selectedMetrics, setSelectedMetrics, selectedMetricColors, setSelectedMetricColors } =
    useAdvertisedProductsContext();

  const { activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();

  const {
    data: metricValues,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: createAdvertisedProductsMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await metricsService.getAdvertisedProductsMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading metric values\n' + JSON.stringify(result));
      }
    },

    retry: 1,
    enabled: !isEmpty(filters),
  });

  function onSingleSelectionChanged(changedMetric: MetricField) {
    const newSelectedMetrics = selectedMetrics.includes(changedMetric)
      ? selectedMetrics.filter((metric) => metric !== changedMetric)
      : [...selectedMetrics, changedMetric];

    setSelectedMetrics(newSelectedMetrics);

    const updatedColors = getUpdatedSelectionColors(selectedMetricColors, newSelectedMetrics);
    setSelectedMetricColors(updatedColors);
  }

  const [isCustomizationModalOpen, setIsCustomizationModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsCustomizationModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCustomizationModalOpen(false);
  };

  const onApplyVisibilityChanges = (visibleMetrics: MetricField[]) => {
    setVisibleMetrics(visibleMetrics);
    // Deselect hidden metrics
    setSelectedMetrics(selectedMetrics.filter((metric) => visibleMetrics.includes(metric)));
  };

  return (
    <div className="flex flex-wrap gap-2">
      {isError && (
        <Card className="flex-grow rounded-xl py-0">
          <Alert severity="error">
            <AlertTitle>Oops!</AlertTitle>
            <div className="flex w-full flex-col">
              <Typography variant="body1">{t('messages.errors.generic')}</Typography>

              <CollapsibleCopyableDetails headerText={'Details'} message={error instanceof Error ? error.message : ''} />
            </div>
          </Alert>
        </Card>
      )}
      {isLoading && (
        <div className="flex animate-fadeInBg gap-2">
          {Array(5)
            .fill(1)
            .map((_, i) => (
              <Skeleton key={i} variant="rectangular" width={138} height={90} style={{ borderRadius: '12px' }} />
            ))}
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <MetricsList
            metricValues={metricValues}
            selectedMetrics={selectedMetrics}
            selectedMetricColors={selectedMetricColors}
            visibleMetrics={visibleMetrics}
            onSingleSelectionChanged={onSingleSelectionChanged}
          />
          <AddMetricButton onClick={handleOpenModal} />
        </>
      )}
      <MetricsCustomizationModal
        isOpen={isCustomizationModalOpen}
        onClose={handleCloseModal}
        visibleMetrics={visibleMetrics}
        onApplyVisibilityChanges={onApplyVisibilityChanges}
      />
    </div>
  );
};

export default AdvertisedProductsMetrics;
