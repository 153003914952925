import { FunctionComponent, PropsWithChildren } from 'react';

interface ActionBarProps extends PropsWithChildren {
  location?: 'top' | 'bottom';
}

export const ActionBar: FunctionComponent<ActionBarProps> = ({ children, location = 'bottom' }) => {
  return (
    <div
      className={`fixed left-0 right-0 z-10 mx-auto flex min-w-min max-w-4xl justify-center gap-x-2 ${
        location === 'bottom' ? 'bottom-0' : 'top-0'
      } `}
    >
      <div className={`flex gap-x-4 bg-slate-300 px-12 py-2 dark:bg-slate-700 ${location === 'bottom' ? 'rounded-t-xl' : 'rounded-b-xl'}`}>
        {children}
      </div>
    </div>
  );
};
