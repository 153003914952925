import { getMetricConfigByColId } from '@/components/metrics/MetricsConfig';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { getColorForText } from '@/types/colors.enum';
import { ICellRendererParams, IHeaderParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import ChangePercentageCellRenderer from '../cells/ChangePercentageCellRenderer';
import ChipArrayCellRenderer, { ChipArrayChip, IChipArrayCellRendererParams } from '../cells/ChipArrayCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '../cells/TextCellRenderer';
import { ColumnId } from '../columns/columns.enum';
import ChangePercentageHeaderRenderer, { IChangePercentageHeaderRendererProps } from '../headers/ChangePercentageHeaderRenderer';
import { StringToCount } from '../helpers';
import useColDefsFunctions from './useColDefsFunctions';

const useColumnTypes = () => {
  const { getLongFormatterForMetricField, formatPercent } = useFormatting();
  const { metricsDataComparator, getMetricFieldChangePercentageCellRendererParams } = useColDefsFunctions();
  const { t } = useTranslation();

  const getChangePercentageHeaderRendererParams = (params: IHeaderParams): IChangePercentageHeaderRendererProps => {
    const colId = params.column.getColId() as ColumnId;
    const metricField = getMetricConfigByColId(colId)?.key;

    let current: number | undefined;
    if (metricField !== undefined) {
      current = params.context?.metricColumnAggregates?.[metricField]?.current;
    }

    const formattedAggValue =
      metricField !== undefined && current !== undefined
        ? getLongFormatterForMetricField(metricField)(current)
        : (current?.toString() ?? '0');

    return {
      ...params,
      currentValue: formattedAggValue,
    };
  };

  const metricFieldWithChangePercentage = {
    autoHeight: true,
    width: 125,
    headerComponent: ChangePercentageHeaderRenderer,
    headerComponentParams: getChangePercentageHeaderRendererParams,
    comparator: metricsDataComparator,
    aggFunc: 'metricsDataAggFunc',
    cellRenderer: ChangePercentageCellRenderer,
    cellRendererParams: getMetricFieldChangePercentageCellRendererParams,
    valueFormatter: (params: ValueFormatterParams) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        // Convert to string to avoid 0 values to be discarded and whole array returned on export
        return params.value[0].toString();
      }
      return '';
    },
  };

  const optGroupNameColumnType = {
    headerName: 'Opt Group',
    width: 125,
    enableRowGroup: true,
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      const groupId = params.data?.groupId;
      const contextCampaignGroupIdToCampaignGroupMap = params.context?.campaignGroupIdToCampaignGroupMap;
      const group = groupId && contextCampaignGroupIdToCampaignGroupMap ? contextCampaignGroupIdToCampaignGroupMap[groupId] : null;

      const tooltip = group ? (
        <>
          Target ACOS: {!isNil(group?.tacos) ? formatPercent(group.tacos) : 'Not Set'}
          <br />
          Prioritization: {t(`optimizer_page.optimization_presets.${group.preset}`)}
          <br />
          Campaigns In Group: {group.totalCampaigns}
        </>
      ) : null;

      return {
        textLabel: params.value,
        tooltip: tooltip,
        noneLabel: '(Unassigned)',
      };
    },
  };

  const dataGroupColumnType = {
    width: 100,
    hide: true,
    enableRowGroup: true,
    cellRendererSelector: (params: ICellRendererParams) => {
      if (params.value) {
        return { component: ChipArrayCellRenderer };
      }
      return { component: undefined }; // undefined means default renderer
    },
    cellRendererParams: (params: ICellRendererParams): IChipArrayCellRendererParams => {
      return {
        chipArrayChips: [{ color: getColorForText(params.value), value: params.value }],
      };
    },
  };

  const checkboxColumnType = {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    resizable: false,
    width: 50,
    minWidth: 30,
    maxWidth: 50,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    lockVisible: true,
    headerClass: 'checkbox-column-header',
    cellClass: 'checkbox-column-cell',
  };

  const matchLongColumnType = {
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: ChipArrayCellRenderer,
    cellRendererParams: (params: ICellRendererParams): IChipArrayCellRendererParams => {
      const isAutoGroupColumn = params.colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;
      if (params.value == '' && !isAutoGroupColumn) return { chipArrayChips: [] };

      if (params.node.group && !isAutoGroupColumn) {
        // Expecting stringToCountAggFunc output
        const matchToCountRecord = params.value as StringToCount;

        const chipArrayChips: ChipArrayChip[] = [];
        for (const [match, count] of Object.entries(matchToCountRecord).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))) {
          chipArrayChips.push({
            value: `${t(`optimizer_page.match_type_long.${match}`)}: ${count}`,
            color: getColorForText(match ?? 'matchType'),
          });
        }

        return { chipArrayChips };
      }
      return {
        chipArrayChips: [
          {
            value: t(`optimizer_page.match_type_long.${params.value}`),
            color: getColorForText(params.value ?? 'matchType'),
          },
        ],
      };
    },
  };

  return { metricFieldWithChangePercentage, optGroupNameColumnType, checkboxColumnType, dataGroupColumnType, matchLongColumnType };
};

export default useColumnTypes;
