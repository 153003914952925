import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { QueryClient } from '@tanstack/react-query';
import { PlacementModel } from '../models/PlacementsModel';
import { BidAdjustmentsWithTimelineDTO, PlacementsWithTimeline } from './placements-contracts';
import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';

// Don't expose this and only use creators
const _PLACEMENTS_WITH_TIMELINE_QUERY_KEY = 'placements-with-timeline';

// Main key to use
export function createPlacementsWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const placementsWithTimelineQueryKey_withoutFilters = createPlacementsWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...placementsWithTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

export function invalidateAll_placementsWithTimelineQueryKeys(queryClient: QueryClient) {
  const key = [_PLACEMENTS_WITH_TIMELINE_QUERY_KEY];
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

function createPlacementsWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_PLACEMENTS_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

export function invalidateProfile_placementsWithTimelineQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createPlacementsWithTimelineQueryKey_withoutFilters(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

export class PlacementsService {
  public basePath = 'campaigns';

  async getPlacementsWithTimeline(filters?: AlFilterModel[]): Promise<ApiResponse<PlacementsWithTimeline>> {
    try {
      const filterData = filters?.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<BidAdjustmentsWithTimelineDTO>(
        `${this.basePath}/bid-adjustments-with-timeline`,
        requestData,
      );

      return ApiResponse.responseWithPayload<PlacementsWithTimeline>(
        {
          placements: PlacementModel.fromResponseArray(applicationResponse.payload.bid_adjustments),
          timeline: TimelineModel.fromResponse(applicationResponse.payload.timeline),
          isComparisonDataMissing: applicationResponse.payload.comparison_missing,
        },
        200,
      );
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const placementsService = new PlacementsService();
