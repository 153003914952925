import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import LoadingBlock from '@/components/feedback/LoadingBlock';

const DashboardWidgetContent: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { isLoading } = useContext(DashboardWidgetContext);

  if (isLoading) {
    return <LoadingBlock />;
  }

  return <div className="relative flex  h-full w-full flex-col ">{children}</div>;
};

export default DashboardWidgetContent;
