import AlSelect from '@/components/form/AlSelect';
import { SelectChangeEvent } from '@mui/material';
import { FunctionComponent } from 'react';

export enum EntityType {
  KEYWORD = 'KEYWORD',
  CAMPAIGN = 'CAMPAIGN',
  PROFILE = 'PROFILE',
}

interface EntityTypeSelectProps {
  value: EntityType | '';
  onChange: (value: EntityType | '') => void;
  helperText?: string;
}

const EntityTypeSelect: FunctionComponent<EntityTypeSelectProps> = ({ value, onChange, helperText }) => {
  const handleChange = (event: SelectChangeEvent<EntityType | ''>) => {
    onChange(event.target.value as EntityType | '');
  };

  const options: { label: string; value: EntityType }[] = [
    { label: 'Keyword', value: EntityType.KEYWORD },
    { label: 'Campaign', value: EntityType.CAMPAIGN },
    { label: 'Profile', value: EntityType.PROFILE },
  ];

  return (
    <AlSelect
      label="Entity Type"
      value={value}
      options={options}
      onChange={handleChange}
      renderOption={(v) => v.label}
      valueExtractor={(v) => v.value}
      helperText={helperText}
    />
  );
};

export default EntityTypeSelect;
