import { BiddingMethod } from '@/modules/campaign-mapping/api/campaign-mapping-contracts';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { ActionEntityType, ActionType, FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { CreateNegativesEntityType, NegativeMatchType } from '@/modules/negative-targets/api/negative-targets-contracts';
import { ProductAvailability } from '@/modules/products/api/products-contracts';
import { ReportingStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';
import { SyncStatusReason } from '@/modules/profiles/types/ProfileStatus';
import { NegativeTypeShort } from '@/modules/search-terms/api/search-terms-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { ReducerType } from '../../../components/metrics/MetricsConfig';
import {
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignAdType,
  CostType,
  CreativeType,
  MultiAdGroupsEnabledType,
  TargetingType,
} from '../api/campaign/campaign-contracts';
import { OptimizationPreset } from '../components/optimization/OptimizerConfig';
import { MatchType, OptimizationReason } from '../components/optimization/api/optimization-contracts';
import { BiddingEntity } from '../components/optimization/models/OptimizationModel';

export const optimizerTranslationsEnglish = {
  optimizer_page: {
    header: 'Bid Optimizer',
    sub_header: 'Select campaigns to preview targeting bid suggestions',
    optimization_presets: {
      [OptimizationPreset.NOT_SET]: 'Not Set',
      [OptimizationPreset.BALANCED]: 'Balanced',
      [OptimizationPreset.INCREASE_SALES]: 'Increase Sales',
      [OptimizationPreset.REDUCE_ACOS]: 'Reduce ACOS',
    },
    labels: {
      optimization_preset: 'Prioritization',
      [ReducerType.AVG]: 'Average',
      [ReducerType.SUM]: 'Sum',
    },
    bidding_entity: {
      [BiddingEntity.KEYWORD]: 'Keyword',
      [BiddingEntity.PRODUCT_TARGET]: 'Product Target',
      ['AUDIENCE']: 'Audience', // Product targets where targeting begins with audience=, views=, purchases=
      [BiddingEntity.PLACEMENT_PRODUCT_PAGE]: 'Product Pages',
      [BiddingEntity.PLACEMENT_REST_OF_SEARCH]: 'Rest of Search',
      [BiddingEntity.PLACEMENT_TOP]: 'Top of Search',
      [BiddingEntity.HOME]: 'Home',
      [BiddingEntity.DETAIL_PAGE]: 'Product Pages',
      [BiddingEntity.OTHER]: 'Rest of Search',
      [BiddingEntity.BRANDS_PLACEMENT_TOP]: 'Top of Search',
    },
    ad_type: {
      [CampaignAdType.PRODUCTS]: 'SP',
      [CampaignAdType.BRANDS]: 'SB',
      [CampaignAdType.DISPLAY]: 'SD',
    },
    ad_type_long: {
      [CampaignAdType.PRODUCTS]: 'Sponsored Products',
      [CampaignAdType.BRANDS]: 'Sponsored Brands',
      [CampaignAdType.DISPLAY]: 'Sponsored Display',
    },
    creative_type: {
      [CreativeType.PRODUCT_COLLECTION]: 'Product Collection',
      [CreativeType.STORE_SPOTLIGHT]: 'Store Spotlight',
      [CreativeType.VIDEO]: 'Video',
      [CreativeType.BRAND_VIDEO]: 'Brand Video',
      [CreativeType.NONE]: '',
    },
    targeting_type: {
      [TargetingType.TARGETING]: 'Targeting',
      [TargetingType.AUDIENCES]: 'Audiences',
      [TargetingType.MANUAL]: 'Manual',
      [TargetingType.AUTO]: 'Auto',
      [TargetingType.CONTEXTUAL]: 'Contextual',
      [TargetingType.NONE]: '',
    },
    bid_optimization: {
      [AmazonBrandsBidOptimization.AUTO]: 'Auto',
      [AmazonBrandsBidOptimization.MANUAL]: 'Manual',
      [AmazonBrandsBidOptimization.NONE]: '',
    },
    multi_ad_groups_enabled: {
      [MultiAdGroupsEnabledType.TRUE]: 'Multi Ad Group',
      [MultiAdGroupsEnabledType.FALSE]: 'Legacy',
      [MultiAdGroupsEnabledType.NONE]: '',
    },
    reason: {
      [OptimizationReason.LOW_VISIBILITY]: 'Low Visibility',
      [OptimizationReason.HIGH_ACOS]: 'High ACOS',
      [OptimizationReason.HIGH_SPEND]: 'High Spend',
      [OptimizationReason.LOW_ACOS]: 'Low ACOS',
      [OptimizationReason.USER_BID_CEILING]: 'User Bid Ceiling',
      [OptimizationReason.KEYWORD_BID_CEILING]: 'Keyword Bid Ceiling',
      [OptimizationReason.AD_GROUP_BID_CEILING]: 'Ad Group Bid Ceiling',
      [OptimizationReason.CAMPAIGN_BID_CEILING]: 'Campaign Bid Ceiling',
      [OptimizationReason.CAMPAIGN_GROUP_BID_CEILING]: 'Campaign Group Bid Ceiling',
      [OptimizationReason.PROFILE_BID_CEILING]: 'Profile Bid Ceiling',
      [OptimizationReason.LOWEST_POSSIBLE_BID]: 'Lowest Possible Bid',
      [OptimizationReason.SMALLEST_POSSIBLE_INCREASE]: 'Smallest Possible Increase',
      [OptimizationReason.CAMPAIGN_PERFORMANCE]: 'Campaign Performance',
      [OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE]: 'Campaign Group Performance',
      [OptimizationReason.PROFILE_PERFORMANCE]: 'Profile Performance',
      [OptimizationReason.MAX_ONE_TIME_CHANGE]: 'Max One Time Increase',
      [OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT]: 'Lowest Possible Adjustment',
      [OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT]: 'Highest Possible Adjustment',
      [OptimizationReason.MAX_BID_DECREASE]: 'Max Bid Decrease',
    },
    bidStrategy: {
      [BidStrategyType.LEGACY_FOR_SALES]: 'Dynamic Bidding (Down Only)',
      [BidStrategyType.AUTO_FOR_SALES]: 'Dynamic Bidding (Up & Down)',
      [BidStrategyType.MANUAL]: 'Fixed Bids',
      [BidStrategyType.RULE_BASED]: 'Rule Based bidding',
      [BidStrategyType.NONE]: '',
    },
    data_group_type: {
      [DataGroupType.CAMPAIGN]: 'Campaign',
      [DataGroupType.TARGET]: 'Targeting',
      [DataGroupType.SEARCHTERM]: 'Search Term',
      [DataGroupType.PRODUCT]: 'Product',
    },
    negative_match_type: {
      [NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT]: 'Campaign Negative Exact',
      [NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE]: 'Campaign Negative Phrase',
      [NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET]: 'Campaign Negative Product Target',
      [NegativeMatchType.AD_GROUP_NEGATIVE_EXACT]: 'Negative Exact',
      [NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE]: 'Negative Phrase',
      [NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET]: 'Negative Product Target',
    },
    match_type_long: {
      [MatchType.EXACT]: 'Exact',
      [MatchType.BROAD]: 'Broad',
      [MatchType.PHRASE]: 'Phrase',
      [MatchType.INDIVIDUAL]: 'Indiv. PT',
      [MatchType.EXPANDED]: 'Exp. PT',
      [MatchType.AUTO]: 'Auto',
      [MatchType.NONE]: 'None',
      [MatchType.THEME]: 'Theme',
      [MatchType.CATEGORY]: 'Category',
    },
    match_type_short: {
      [MatchType.EXACT]: 'E',
      [MatchType.BROAD]: 'B',
      [MatchType.PHRASE]: 'P',
      [MatchType.INDIVIDUAL]: 'PT',
      [MatchType.EXPANDED]: 'EXP',
      [MatchType.AUTO]: 'A',
      [MatchType.NONE]: '-',
      [MatchType.THEME]: 'T',
      [MatchType.CATEGORY]: 'C',
    },
  },

  team_profiles_page: {
    header: 'Profiles',
    sub_header: 'Manage all your profiles',
    labels: {
      [StatusType.PENDING]: 'Pending Amazon',
      [StatusType.ONGOING]: 'Processing',
      [StatusType.DOWNLOADED]: 'Processing',
      [StatusType.PROCESSED]: 'Ready',
      [StatusType.FAILED]: 'Failed',
      [ReportingStatusType.LOADING]: 'Updating',
      [ReportingStatusType.NEVER]: 'Warming Up',
      [ReportingStatusType.OUTDATED]: 'Outdated',
      [ReportingStatusType.ERROR]: 'Status Error',
      [ReportingStatusType.COMPLETED]: 'Completed',
      [ReportingStatusType.WAITING_RESPONSE]: 'Checking',
    },
    descriptions: {
      [StatusType.PENDING]: 'Pending Amazon to generate the data report',
      [StatusType.ONGOING]: 'Processing data from the report',
      [StatusType.DOWNLOADED]: 'Processing data from the report',
      [StatusType.PROCESSED]: 'Data is updated within the last 24 hours',
      [StatusType.FAILED]: 'Data update failed. Try again or contact support',
      [ReportingStatusType.LOADING]: 'Updating data, please wait',
      [ReportingStatusType.NEVER]: 'We are making a home for your data, please wait',
      [ReportingStatusType.OUTDATED]: 'Data is older than 24 hours, we recommend updating it',
      [ReportingStatusType.ERROR]: 'Error getting the current status of the data',
      [ReportingStatusType.COMPLETED]: 'Data is updated within the last 24 hour',
      [ReportingStatusType.WAITING_RESPONSE]: 'Checking status, please wait',
    },
  },

  profile_history_page: {
    header: 'Time machine',
    sub_header: 'History of all changes',
    log_preview_table_header: {
      i: 'Job ID',
      ad: 'Ad Type',
      et: 'Entity Type',
      cn: 'Campaign Name',
      agn: 'Ad Group Name',
      t: 'Target',
      ov: 'Old Value',
      nv: 'New Value',
      r: 'Reasons',
      s: 'Status',
      e: 'Error',
      cr: 'Created At',
    },
  },

  campaign_groups_page: {
    header: 'Optimization Groups',
    sub_header: 'Optimization Groups',
  },

  profiles_stats_page: {
    header: 'Profiles Stats',
    sub_header: 'Profiles Stats',
  },

  targeting_page: {
    header: 'Targeting',
    sub_header: 'Targeting',
    bidding_entity: {
      [TargetEntityType.KEYWORD]: 'KW',
      [TargetEntityType.PRODUCT_TARGET]: 'PT',
      ['AUDIENCE']: 'AD', // Product targets where targeting begins with audience=, views=, purchases=
    },
  },

  data_groups_page: {
    header: 'Data Groups',
    sub_header: 'Data Groups',
  },

  placements_page: {
    header: 'Placements',
    sub_header: 'Placements',
  },

  negative_keywords_page: {
    header: 'Negative Targeting',
    sub_header: 'Negative Targeting',
  },

  search_terms_page: {
    header: 'Search Terms',
    sub_header: 'Search Terms',
    bidding_method: {
      [BiddingMethod.ADLABS]: 'AdLabs',
      [BiddingMethod.CPC_MINUS]: 'CPC - X',
      [BiddingMethod.CPC_PLUS]: 'CPC + X',
      [BiddingMethod.CPC_TIMES]: 'CPC * X',
      [BiddingMethod.CUSTOM]: 'Custom Bid',
    },
    bidding_method_before_value: {
      [BiddingMethod.ADLABS]: 'AdLabs',
      [BiddingMethod.CPC_MINUS]: 'CPC - ',
      [BiddingMethod.CPC_PLUS]: 'CPC + ',
      [BiddingMethod.CPC_TIMES]: 'CPC * ',
      [BiddingMethod.CUSTOM]: 'Custom ',
    },
    create_negatives_entity_type: {
      [CreateNegativesEntityType.SP_NEG_KEYWORD]: 'SP - Neg. Keyword',
      [CreateNegativesEntityType.SP_NEG_CAMPAIGN_TARGET]: 'SP - Campaign Neg. Target',
      [CreateNegativesEntityType.SP_NEG_TARGET]: 'SP - Neg. Target',
      [CreateNegativesEntityType.SP_NEG_CAMPAIGN_KEYWORD]: 'SP - Campaign Neg. Keyword',
      [CreateNegativesEntityType.SB_NEG_KEYWORD]: 'SB - Neg. Keyword',
      [CreateNegativesEntityType.SB_NEG_TARGET]: 'SB - Neg. Target',
      [CreateNegativesEntityType.SD_NEG_TARGET]: 'SD - Neg. Target',
      ['SP_KEYWORD']: 'SP - Keyword',
      ['SB_KEYWORD']: 'SB - Keyword',
      ['SP_TARGET']: 'SP - Target',
      ['SB_TARGET']: 'SB - Target',
    },
    negative_type_short: {
      [NegativeTypeShort.CAMPAIGN_NEG_EXACT]: 'Campaign Negative Exact',
      [NegativeTypeShort.CAMPAIGN_NEG_PHRASE]: 'Campaign Negative Phrase',
      [NegativeTypeShort.CAMPAIGN_NEG_PRODUCT]: 'Campaign Negative Product Target',
      [NegativeTypeShort.AD_GROUP_NEG_EXACT]: 'Ad Group Negative Exact',
      [NegativeTypeShort.AD_GROUP_NEG_PHRASE]: 'Ad Group Negative Phrase',
      [NegativeTypeShort.AD_GROUP_NEG_PRODUCT]: 'Ad Group Negative Product Target',
    },
  },

  campaign_mapping_page: {
    header: 'Campaign Mapping',
    sub_header: 'Campaign Mapping',
  },

  logs_page: {
    header: 'Logs',
    sub_header: 'Logs',
    flow_type: {
      [FlowType.NEGATIVE_TARGETING]: 'Negative Target',
      [FlowType.HARVESTING]: 'Harvesting',
      [FlowType.CAMPAIGN_OPTIMIZER]: 'Campaign',
      [FlowType.TARGETING]: 'Targeting',
      [FlowType.PLACEMENT]: 'Placement',
      [FlowType.SEARCH_TERMS]: 'Search Terms',
      [FlowType.CAMPAIGN_MAPPING]: 'Campaign Mapping',
      [FlowType.OPTIMIZATION_GROUPS]: 'Optimization Groups',
      [FlowType.DATA_GROUPS]: 'Data Groups',
    },
    log_action_type: {
      [ActionType.CREATE]: 'Create',
      [ActionType.UPDATE]: 'Update',
      [ActionType.DELETE]: 'Delete',
    },
    action_entity_type: {
      [ActionEntityType.NEGATIVE]: 'Negative',
      [ActionEntityType.TARGET]: 'Target',
      [ActionEntityType.CAMPAIGN]: 'Campaign',
      [ActionEntityType.AD_GROUP]: 'Ad Group',
      [ActionEntityType.CAMPAIGN_MAP]: 'Campaign Mapping',
      [ActionEntityType.OPTIMIZATION_GROUP]: 'Optimization Group',
      [ActionEntityType.DATA_GROUP]: 'Data Group',
    },
  },

  products_page: {
    header: 'Products',
    sub_header: 'Products',
    product_availability: {
      [ProductAvailability.IN_STOCK]: 'In Stock',
      [ProductAvailability.IN_STOCK_SCARCE]: 'Low Inventory',
      [ProductAvailability.OUT_OF_STOCK]: 'Out of Stock',
      [ProductAvailability.PREORDER]: 'Preorder',
      [ProductAvailability.LEADTIME]: 'Lead time',
      [ProductAvailability.AVAILABLE_DATE]: 'Available Date',
      [ProductAvailability.ERROR]: 'Error',
    },
    product_availability_description: {
      [ProductAvailability.IN_STOCK]: 'The item is in stock.',
      [ProductAvailability.IN_STOCK_SCARCE]: 'The item is in stock, but stock levels are limited.',
      [ProductAvailability.OUT_OF_STOCK]: 'The item is currently out of stock.',
      [ProductAvailability.PREORDER]: 'The item is not yet available, but can be pre-ordered.',
      [ProductAvailability.LEADTIME]: 'The item is only available after some amount of lead time.',
      [ProductAvailability.AVAILABLE_DATE]: 'The item is not available, but will be available on a future date.',
      [ProductAvailability.ERROR]: 'Something went wrong',
    },
  },

  advertised_products_page: {
    header: 'Advertised Products',
    sub_header: 'AdvertisedProducts',
  },

  messages: {
    errors: {
      enter_valid_tacos: 'Enter a valid TACOS value',
      generic: 'Something went wrong on our end. If the problem persists, make sure to contact us!',
    },
  },

  labels: {
    [SyncStatusReason.LOADING]: 'Syncing is already in progress',
    [SyncStatusReason.PLAN_RESTRICTION]: 'Upgrade to sync more than once per 24h',
    [SyncStatusReason.TOO_EARLY]: 'You can update data once per hour',
    [SyncStatusReason.WAITING_RESPONSE]: 'Checking current status, please wait...',
  },

  cost_type: {
    [CostType.CPC]: 'CPC',
    [CostType.VCPM]: 'VCPM',
    [CostType.NONE]: '',
  },
};
