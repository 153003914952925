import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { TeamMemberModel } from '../types/TeamMemberModel';
import DeleteIcon from '@mui/icons-material/Delete';
import { TeamModel } from '../types/TeamModel';
import { useMutation } from '@tanstack/react-query';
import { teamService } from '../api/teams.service';
import { toast } from 'react-toastify';
import { isNil } from 'lodash-es';
import { useUserContext } from '@/modules/users';
interface MemberListProps {
  team: TeamModel;
  members: TeamMemberModel[];
  isLoading?: boolean;
}

const MemberList: FunctionComponent<MemberListProps> = ({ team, members: initialMembers, isLoading = false }) => {
  const { refetchUser } = useUserContext();
  const [isConfirmRemoveMemberDialogOpen, setIsConfirmRemoveMemberDialogOpen] = useState(false);
  const [memberToBeRemoved, setMemberToBeRemoved] = useState<TeamMemberModel>();
  const [members, setMembers] = useState(initialMembers); // allows to remove member from the list on delete

  function onRemoveTeamMemberClicked(member: TeamMemberModel) {
    setMemberToBeRemoved(member);
    setIsConfirmRemoveMemberDialogOpen(true);
  }
  function handleCancelRemoveTeamMember() {
    setMemberToBeRemoved(undefined);
    setIsConfirmRemoveMemberDialogOpen(false);
  }

  function handleConfirmRemoveTeamMember() {
    setIsConfirmRemoveMemberDialogOpen(false);
    if (isNil(memberToBeRemoved)) {
      toast.error('User not found');
      return;
    }
    removeUsersFromTeam({ teamId: team.id, userIds: [memberToBeRemoved.user.id] });
  }

  const { mutate: removeUsersFromTeam, isPending: isLoadingRemoveUsersFromTeam } = useMutation({
    mutationFn: ({ teamId, userIds }: { teamId: number; userIds: number[] }) => teamService.removeUsersFromTeam(teamId, userIds),

    onSuccess: (response) => {
      if (response.isSuccess) {
        refetchUser();
        setMembers(members.filter((member) => member.user.id !== memberToBeRemoved?.user.id));
        toast.success(`Member removed from team successfully`);
      } else {
        toast.error(`Removing member from team failed: ${response.message}`);
      }
    },
  });

  return (
    <>
      <TableContainer className="w-full flex-1 rounded-lg border bg-white  shadow-md">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading
              ? Array(6)
                  .fill(1)
                  .map((v, i) => (
                    <TableRow key={'cell-row-skeleton-' + i}>
                      {Array(4)
                        .fill(1)
                        .map((v, i) => (
                          <TableCell key={'cell-skeleton-' + i} component="th" scope="row">
                            <Skeleton variant="rectangular" width={'full'} animation="wave" />
                          </TableCell>
                        ))}
                    </TableRow>
                  ))
              : members.map((member) => (
                  <TableRow key={member.user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{member.user.name}</TableCell>
                    <TableCell align="left">{member.user.email}</TableCell>
                    <TableCell align="left">{member.role}</TableCell>
                    <TableCell align="left">
                      {isLoadingRemoveUsersFromTeam && <CircularProgress color="inherit" size={16} />}
                      {!isLoadingRemoveUsersFromTeam && (
                        <IconButton aria-label="delete" size="large" onClick={() => onRemoveTeamMemberClicked(member)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog maxWidth="xs" open={isConfirmRemoveMemberDialogOpen}>
        <DialogTitle>Remove team Member</DialogTitle>
        <DialogContent>{`Are you sure you want to remove ${memberToBeRemoved?.user.name} from ${team?.name}?`}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelRemoveTeamMember} variant="text">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemoveTeamMember}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MemberList;
