import { FunctionComponent, useState } from 'react';
import { planService } from '../api/plan.service';
import { Routes } from '@/router/router-paths';
import { toast } from 'react-toastify';

const PaymentPastDueBanner: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onResolveButtonClicked = () => {
    redirectToBillingPortal();
  };

  async function redirectToBillingPortal() {
    try {
      setIsLoading(true);

      const billingPortalLinkResponse = await planService.getBillingLinkForTeam(Routes.BILLING);
      if (billingPortalLinkResponse.isSuccess) {
        // Navigate to billing portal
        window.location.href = billingPortalLinkResponse.payload.session_url;
      } else {
        toast.error(`Something went wrong. Please try again later. ${billingPortalLinkResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 w-full flex items-center justify-between py-1 bg-orange-400 text-gray-900">
      <div className="pl-2 flex items-center justify-center text-center w-full font-bold">
        <div>There was an issue with your payment. To regain access to Pro features, please update your payment information.</div>
        <button
          className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
          onClick={onResolveButtonClicked}
          disabled={isLoading}
        >
          Resolve
        </button>
      </div>
    </div>
  );
};

export default PaymentPastDueBanner;
