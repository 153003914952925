import { ColumnId } from '@/components/grid/columns/columns.enum';
import { isNonNegativeNumber, isPositiveNumber, roundToPrecision } from '@/modules/application/utils';
import {
  CampaignMappingImportRow,
  FieldNames,
  emptyCampaignMappingImportRow,
} from '@/modules/data-management/importers/campaign-mapping/CampaignMappingImportRow';
import { CampaignAdType, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { isBoolean, isEmpty, isNil, isNumber } from 'lodash-es';
import { BiddingMethod } from '../api/campaign-mapping-contracts';
import { CampaignToCampaignDataWithCampaignMappingAdGroupDataType } from './CampaignMappingModel';

export class CampaignMappingModelWithValidation {
  // Names are null when user inputs empty
  public id: string;
  private _sourceCampaignId: string | null;
  private _sourceCampaignName: string | null;
  private _sourceCampaignAdType: string | CampaignAdType | null;
  private _destinationCampaignId: string | null;
  private _destinationCampaignName: string | null;
  private _destinationCampaignAdType: string | CampaignAdType | null;
  private _sourceAdGroupId: string | null;
  private _sourceAdGroupName: string | null;
  private _sourceAdGroupEntityType: string | TargetEntityType | null;
  private _destinationAdGroupId: string | null;
  private _destinationAdGroupName: string | null;
  private _destinationAdGroupEntityType: string | TargetEntityType | null;
  private _destinationCampaignIsVideo: boolean;
  private _biddingMethod: string | BiddingMethod;
  private _biddingMethodValue: string | number | null; // null when choosing AdLabs method
  private _bidFloor: string | null | number;
  private _bidCeiling: string | null | number;
  private _exactMatch: string | boolean;
  private _broadMatch: string | boolean;
  private _phraseMatch: string | boolean;
  private _individualProductTarget: string | boolean;
  private _expandedProductTarget: string | boolean;
  private _campaignNegativeExact: string | boolean;
  private _campaignNegativePhrase: string | boolean;
  private _campaignNegativeProductTarget: string | boolean;
  private _adGroupNegativeExact: string | boolean;
  private _adGroupNegativePhrase: string | boolean;
  private _adGroupNegativeProductTarget: string | boolean;
  private _actions = null; // Placeholder for error and warning keys

  private _campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;
  private _campaignNameToCampaignIdsMap: Record<string, string[]>;
  private readonly getNewBidValue_byCurrentProfileMarketplaceLimits: (
    oldValue: number,
    adType: CampaignAdType,
    campaignIsVideo: boolean,
    warnings: Set<string>,
  ) => number;

  private _existingMappingKeys: Set<string>;

  public validationErrors: Partial<Record<keyof CampaignMappingModelWithValidation, string>> = {};
  public validationWarnings: Partial<Record<keyof CampaignMappingModelWithValidation, string>> = {};

  public isCellDisabledMap: Partial<Record<ColumnId, boolean>> = {};

  constructor({
    rowId,
    importRow,
    campaignNameToCampaignIdsMap,
    campaignToAdGroupsMap,
    getNewBidValue_byCurrentProfileMarketplaceLimits,
    existingMappingKeys,
  }: CampaignMappingModelWithValidationArguments) {
    this.getNewBidValue_byCurrentProfileMarketplaceLimits = getNewBidValue_byCurrentProfileMarketplaceLimits;
    this._existingMappingKeys = existingMappingKeys;

    this._campaignToAdGroupsMap = campaignToAdGroupsMap;
    this._campaignNameToCampaignIdsMap = campaignNameToCampaignIdsMap;

    const inputSourceCampaignName = isEmpty(importRow[FieldNames.SourceCampaign]) ? null : importRow[FieldNames.SourceCampaign].trim();
    const sourceCampaignId = campaignNameToCampaignIdsMap[inputSourceCampaignName ?? '']?.[0] ?? null;
    const sourceCampaign = campaignToAdGroupsMap[sourceCampaignId ?? ''] ?? null;
    const sourceCampaignName = inputSourceCampaignName;
    const sourceCampaignAdType = sourceCampaign?.campaignAdType;

    const inputSourceAdGroupName = isEmpty(importRow[FieldNames.SourceAdGroup]) ? null : importRow[FieldNames.SourceAdGroup].trim();
    const sourceAdGroup = sourceCampaign?.adGroups?.find((ag) => ag.name === inputSourceAdGroupName);
    const sourceAdGroupId = sourceAdGroup?.id ?? null;
    const sourceAdGroupName = inputSourceAdGroupName;
    const sourceAdGroupEntityType = sourceAdGroup?.entityType ?? null;

    const inputDestinationCampaignName = isEmpty(importRow[FieldNames.DestinationCampaign])
      ? null
      : importRow[FieldNames.DestinationCampaign].trim();
    const destinationCampaignId = campaignNameToCampaignIdsMap[inputDestinationCampaignName ?? '']?.[0] ?? null;
    const destinationCampaign = campaignToAdGroupsMap[destinationCampaignId ?? ''] ?? null;
    const destinationCampaignName = inputDestinationCampaignName;
    const destinationCampaignAdType = destinationCampaign?.campaignAdType ?? null;
    const destinationCampaignIsVideo = destinationCampaign?.isVideo;

    const inputDestinationAdGroupName = isEmpty(importRow[FieldNames.DestinationAdGroup])
      ? null
      : importRow[FieldNames.DestinationAdGroup].trim();
    const destinationAdGroup = destinationCampaign?.adGroups?.find((ag) => ag.name === inputDestinationAdGroupName);
    const destinationAdGroupId = destinationAdGroup?.id ?? null;
    const destinationAdGroupName = inputDestinationAdGroupName;
    const destinationAdGroupEntityType = destinationAdGroup?.entityType ?? null;

    this.id = rowId;
    this._sourceCampaignId = sourceCampaignId;
    this._sourceCampaignName = sourceCampaignName;
    this._sourceCampaignAdType = sourceCampaignAdType;
    this._destinationCampaignId = destinationCampaignId;
    this._destinationCampaignName = destinationCampaignName;
    this._destinationCampaignAdType = destinationCampaignAdType;
    this._sourceAdGroupId = sourceAdGroupId;
    this._sourceAdGroupName = sourceAdGroupName;
    this._sourceAdGroupEntityType = sourceAdGroupEntityType;
    this._destinationAdGroupId = destinationAdGroupId;
    this._destinationAdGroupName = destinationAdGroupName;
    this._destinationAdGroupEntityType = destinationAdGroupEntityType;
    this._destinationCampaignIsVideo = destinationCampaignIsVideo;

    // Try to parse fields, if failed show inputs as is and let validation show errors
    this._biddingMethod = applyFunctionReturnOriginalOnNull<BiddingMethod>(
      importRow[FieldNames.StartingBidMethod],
      tryBidMethodCellValueToBidMethod,
    );

    // When empty, set to null
    const biddingMethodValue = importRow[FieldNames.StartingBidMethodValue];
    if (!isNumber(biddingMethodValue) && isEmpty(biddingMethodValue)) {
      this._biddingMethodValue = null;
    } else {
      const parsedValue = tryParseInputFloat(biddingMethodValue);
      this._biddingMethodValue = parsedValue ? parsedValue : biddingMethodValue;
    }
    // When empty, set to null
    const bidFloorValue = importRow[FieldNames.StartingBidBidFloorOptional];
    if (!isNumber(bidFloorValue) && isEmpty(bidFloorValue)) {
      this._bidFloor = null;
    } else {
      const parsedValue = tryParseInputFloat(bidFloorValue);
      this._bidFloor = parsedValue ? parsedValue : bidFloorValue;
    }

    const bidCeilingValue = importRow[FieldNames.StartingBidBidCeilingOptional];
    if (!isNumber(bidCeilingValue) && isEmpty(bidCeilingValue)) {
      this._bidCeiling = null;
    } else {
      const parsedValue = tryParseInputFloat(bidCeilingValue);
      this._bidCeiling = parsedValue ? parsedValue : bidCeilingValue;
    }

    this._exactMatch = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.Exact], tryParsingBoolean);
    this._broadMatch = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.Broad], tryParsingBoolean);
    this._phraseMatch = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.Phrase], tryParsingBoolean);
    this._individualProductTarget = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.IndivPT], tryParsingBoolean);
    this._expandedProductTarget = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.ExpandedPT], tryParsingBoolean);

    this._adGroupNegativeExact = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.NegExact], tryParsingBoolean);
    this._adGroupNegativePhrase = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.NegPhrase], tryParsingBoolean);
    this._adGroupNegativeProductTarget = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.NegPT], tryParsingBoolean);
    this._campaignNegativeExact = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.CampNegExact], tryParsingBoolean);
    this._campaignNegativePhrase = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.CampNegPhrase], tryParsingBoolean);
    this._campaignNegativeProductTarget = applyFunctionReturnOriginalOnNull<boolean>(importRow[FieldNames.CampNegPT], tryParsingBoolean);

    this.validate();
  }

  public get campaignToAdGroupsMap(): CampaignToCampaignDataWithCampaignMappingAdGroupDataType {
    return this._campaignToAdGroupsMap;
  }

  public get campaignNameToCampaignIdsMap(): Record<string, string[]> {
    return this._campaignNameToCampaignIdsMap;
  }

  public get existingMappingKeys(): Set<string> {
    return this._existingMappingKeys;
  }

  public set campaignToAdGroupsMap(value: CampaignToCampaignDataWithCampaignMappingAdGroupDataType) {
    this._campaignToAdGroupsMap = value;
  }

  public set campaignNameToCampaignIdsMap(value: Record<string, string[]>) {
    this._campaignNameToCampaignIdsMap = value;
  }

  public set existingMappingKeys(value: Set<string>) {
    this._existingMappingKeys = value;
  }

  public get sourceCampaignId(): string | null {
    return this._sourceCampaignId;
  }

  // Cannot override only setters, need also to override getters
  public get sourceCampaignName(): string | null {
    // Include id if duplicate name
    if (this._campaignNameToCampaignIdsMap[this._sourceCampaignName ?? '']?.length > 1) {
      return CampaignMappingModelWithValidation.joinIDAndName(this.sourceCampaignId ?? '', this._sourceCampaignName ?? '');
    }
    return this._sourceCampaignName;
  }

  public get sourceCampaignAdType(): string | CampaignAdType | null {
    return this._sourceCampaignAdType;
  }

  public get destinationCampaignId(): string | null {
    return this._destinationCampaignId;
  }

  public get destinationCampaignName(): string | null {
    // Include id if duplicate name
    if (this._campaignNameToCampaignIdsMap[this._destinationCampaignName ?? '']?.length > 1) {
      return CampaignMappingModelWithValidation.joinIDAndName(this.destinationCampaignId ?? '', this._destinationCampaignName ?? '');
    }
    return this._destinationCampaignName;
  }

  public get destinationCampaignAdType(): string | CampaignAdType | null {
    return this._destinationCampaignAdType;
  }

  public get destinationCampaignIsVideo(): boolean {
    return this._destinationCampaignIsVideo;
  }

  public get sourceAdGroupId(): string | null {
    return this._sourceAdGroupId;
  }

  public get sourceAdGroupName(): string | null {
    const sourceCampaign = this._campaignToAdGroupsMap[this.sourceCampaignId ?? ''];
    const adGroups = sourceCampaign?.adGroups?.filter((ag) => ag.name === this._sourceAdGroupName);
    // If name duplicate
    if (adGroups?.length > 1) {
      return CampaignMappingModelWithValidation.joinIDAndName(this._sourceAdGroupId ?? '', this._sourceAdGroupName ?? '');
    }
    return this._sourceAdGroupName;
  }

  public get sourceAdGroupEntityType(): string | TargetEntityType | null {
    return this._sourceAdGroupEntityType;
  }

  public get destinationAdGroupId(): string | null {
    return this._destinationAdGroupId;
  }

  public get destinationAdGroupName(): string | null {
    const destinationCampaign = this._campaignToAdGroupsMap[this.destinationCampaignId ?? ''];
    const adGroups = destinationCampaign?.adGroups?.filter((ag) => ag.name === this._destinationAdGroupName);
    // If name duplicate
    if (adGroups?.length > 1) {
      return CampaignMappingModelWithValidation.joinIDAndName(this._destinationAdGroupId ?? '', this._destinationAdGroupName ?? '');
    }
    return this._destinationAdGroupName;
  }

  public get destinationAdGroupEntityType(): string | TargetEntityType | null {
    return this._destinationAdGroupEntityType;
  }

  public get biddingMethod(): string | BiddingMethod {
    return this._biddingMethod;
  }

  public get biddingMethodValue(): string | number | null {
    return this._biddingMethodValue;
  }

  public get bidFloor(): string | null | number {
    return this._bidFloor;
  }

  public get bidCeiling(): string | null | number {
    return this._bidCeiling;
  }

  public get exactMatch(): boolean {
    // Strings are reported by validation
    if (isBoolean(this._exactMatch)) return this._exactMatch;
    return false;
  }

  public get broadMatch(): boolean {
    if (isBoolean(this._broadMatch)) return this._broadMatch;
    return false;
  }

  public get phraseMatch(): boolean {
    if (isBoolean(this._phraseMatch)) return this._phraseMatch;
    return false;
  }

  public get individualProductTarget(): boolean {
    if (isBoolean(this._individualProductTarget)) return this._individualProductTarget;
    return false;
  }

  public get expandedProductTarget(): boolean {
    if (isBoolean(this._expandedProductTarget)) return this._expandedProductTarget;
    return false;
  }

  public get campaignNegativeExact(): boolean {
    if (isBoolean(this._campaignNegativeExact)) return this._campaignNegativeExact;
    return false;
  }

  public get campaignNegativePhrase(): boolean {
    if (isBoolean(this._campaignNegativePhrase)) return this._campaignNegativePhrase;
    return false;
  }

  public get campaignNegativeProductTarget(): boolean {
    if (isBoolean(this._campaignNegativeProductTarget)) return this._campaignNegativeProductTarget;
    return false;
  }

  public get adGroupNegativeExact(): boolean {
    if (isBoolean(this._adGroupNegativeExact)) return this._adGroupNegativeExact;
    return false;
  }

  public get adGroupNegativePhrase(): boolean {
    if (isBoolean(this._adGroupNegativePhrase)) return this._adGroupNegativePhrase;
    return false;
  }

  public get adGroupNegativeProductTarget(): boolean {
    if (isBoolean(this._adGroupNegativeProductTarget)) return this._adGroupNegativeProductTarget;
    return false;
  }

  public set sourceCampaignId(value: string | null) {
    this._sourceCampaignId = value;

    const sourceCampaign = this._campaignToAdGroupsMap[value ?? ''];

    this._sourceCampaignName = sourceCampaign?.name ?? null;
    this._sourceCampaignAdType = sourceCampaign?.campaignAdType ?? null;

    this._sourceAdGroupId = null;
    this._sourceAdGroupName = null;
    this._sourceAdGroupEntityType = null;

    this.validate();
  }

  public set destinationCampaignId(value: string | null) {
    this._destinationCampaignId = value;

    const destinationCampaign = this._campaignToAdGroupsMap[value ?? ''];

    this._destinationCampaignName = destinationCampaign?.name ?? null;
    this._destinationCampaignAdType = destinationCampaign?.campaignAdType ?? null;
    this._destinationCampaignIsVideo = destinationCampaign?.isVideo;

    this._destinationAdGroupId = null;
    this._destinationAdGroupName = null;
    this._destinationAdGroupEntityType = null;

    this.validate();
  }

  public set sourceAdGroupId(value: string | null) {
    this._sourceAdGroupId = value;

    const sourceCampaign = this._campaignToAdGroupsMap[this.sourceCampaignId ?? ''];
    const sourceAdGroup = sourceCampaign?.adGroups?.find((ag) => ag.id === value);

    this._sourceAdGroupName = sourceAdGroup?.name ?? null;
    this._sourceAdGroupEntityType = sourceAdGroup?.entityType ?? null;

    this.validate();
  }

  public set destinationAdGroupId(value: string | null) {
    this._destinationAdGroupId = value;

    const destinationCampaign = this._campaignToAdGroupsMap[this.destinationCampaignId ?? ''];
    const destinationAdGroup = destinationCampaign?.adGroups?.find((ag) => ag.id === value);

    this._destinationAdGroupName = destinationAdGroup?.name ?? null;
    this._destinationAdGroupEntityType = destinationAdGroup?.entityType ?? null;

    this.validate();
  }

  public set biddingMethod(value: BiddingMethod) {
    this._biddingMethod = value;
    this.validate();
  }

  public set biddingMethodValue(value: number | string | null) {
    this._biddingMethodValue = value;
    this.validate();
  }

  public set bidFloor(value: number | null) {
    this._bidFloor = value;
    this.validate();
  }

  public set bidCeiling(value: number | null) {
    this._bidCeiling = value;
    this.validate();
  }

  public set exactMatch(value: boolean) {
    this._exactMatch = value;

    this.validate();
  }

  public set broadMatch(value: boolean) {
    this._broadMatch = value;

    this.validate();
  }

  public set phraseMatch(value: boolean) {
    this._phraseMatch = value;

    this.validate();
  }

  public set individualProductTarget(value: boolean) {
    this._individualProductTarget = value;

    this.validate();
  }

  public set expandedProductTarget(value: boolean) {
    this._expandedProductTarget = value;

    this.validate();
  }

  public set campaignNegativeExact(value: boolean) {
    this._campaignNegativeExact = value;

    this.validate();
  }

  public set campaignNegativePhrase(value: boolean) {
    this._campaignNegativePhrase = value;

    this.validate();
  }

  public set campaignNegativeProductTarget(value: boolean) {
    this._campaignNegativeProductTarget = value;

    this.validate();
  }

  public set adGroupNegativeExact(value: boolean) {
    this._adGroupNegativeExact = value;

    this.validate();
  }

  public set adGroupNegativePhrase(value: boolean) {
    this._adGroupNegativePhrase = value;

    this.validate();
  }

  public set adGroupNegativeProductTarget(value: boolean) {
    this._adGroupNegativeProductTarget = value;

    this.validate();
  }

  // Placeholder
  public get actions() {
    return this._actions;
  }

  public getNegatives(): CampaignMappingNegatives {
    return {
      campaignNegativeExact: this.campaignNegativeExact,
      campaignNegativePhrase: this.campaignNegativePhrase,
      campaignNegativeProductTarget: this.campaignNegativeProductTarget,
      adGroupNegativeExact: this.adGroupNegativeExact,
      adGroupNegativePhrase: this.adGroupNegativePhrase,
      adGroupNegativeProductTarget: this.adGroupNegativeProductTarget,
    };
  }

  public applyNegativesIfAny(negatives: CampaignMappingNegatives[]) {
    this.campaignNegativeExact = negatives.some((n) => n.campaignNegativeExact);
    this.campaignNegativePhrase = negatives.some((n) => n.campaignNegativePhrase);
    this.campaignNegativeProductTarget = negatives.some((n) => n.campaignNegativeProductTarget);
    this.adGroupNegativeExact = negatives.some((n) => n.adGroupNegativeExact);
    this.adGroupNegativePhrase = negatives.some((n) => n.adGroupNegativePhrase);
    this.adGroupNegativeProductTarget = negatives.some((n) => n.adGroupNegativeProductTarget);
  }

  public validate() {
    this.validationErrors = {};
    this.validationWarnings = {};
    this.isCellDisabledMap = {};

    // sourceCampaignId
    // sourceCampaignName
    // sourceCampaignAdType - set with name, null when name can't be matched with existing
    if (!this.sourceCampaignId) {
      // TODO: fuzzy match name if incorrect and "... did you mean ..."
      this.validationErrors.sourceCampaignId = 'Unable to match given source campaign name with existing campaign';
    } else if (isEmpty(this.sourceCampaignName) || isNil(this.sourceCampaignName)) {
      this.validationErrors.sourceCampaignName = 'Campaign name cannot be empty';
    } else if (this._campaignNameToCampaignIdsMap[this._sourceCampaignName ?? '']?.length > 1) {
      this.validationWarnings.sourceCampaignId = 'Multiple campaigns exist with given name';
    }

    // destinationCampaignId
    // destinationCampaignName
    // destinationCampaignAdType
    if (!this.destinationCampaignId) {
      this.validationErrors.destinationCampaignId = 'Unable to match given destination campaign name with existing campaign';
    } else if (isEmpty(this.destinationCampaignName) || isNil(this.destinationCampaignName)) {
      this.validationErrors.destinationCampaignName = 'Campaign name cannot be empty';
    } else if (this._campaignNameToCampaignIdsMap[this._destinationCampaignName ?? '']?.length > 1) {
      this.validationWarnings.destinationCampaignId = 'Multiple campaigns exist with given name';
    }

    // sourceAdGroupId
    // sourceAdGroupName
    // sourceAdGroupEntityType
    const isSourceAdGroupNameDuplicate =
      this._campaignToAdGroupsMap[this.sourceCampaignId ?? '']?.adGroups?.filter((ag) => ag.id === this.sourceAdGroupId).length > 1;
    if (!this.sourceAdGroupId) {
      this.validationErrors.sourceAdGroupId = 'Unable to match given source ad group name with existing ad group in this campaign';
    } else if (isEmpty(this.sourceAdGroupName) || isNil(this.sourceAdGroupName)) {
      this.validationErrors.sourceAdGroupName = 'Ad Group name cannot be empty';
    } else if (isSourceAdGroupNameDuplicate) {
      this.validationWarnings.sourceAdGroupId = 'Multiple ad groups exist with given name';
    }

    // destinationAdGroupId
    // destinationAdGroupName
    // destinationAdGroupEntityType
    const isDestinationAdGroupNameDuplicate =
      this._campaignToAdGroupsMap[this.destinationCampaignId ?? '']?.adGroups?.filter((ag) => ag.id === this.destinationAdGroupId).length > 1;
    const isSourceCampaignTypeManual = this._campaignToAdGroupsMap[this.sourceCampaignId ?? '']?.targetingType === TargetingType.MANUAL;
    if (!this.destinationAdGroupId) {
      this.validationErrors.destinationAdGroupId = 'Unable to match given destination ad group name with existing ad group in this campaign';
    } else if (isEmpty(this.destinationAdGroupName) || isNil(this.destinationAdGroupName)) {
      this.validationErrors.destinationAdGroupName = 'Ad Group name cannot be empty';
      // RIGHT NOW AMAZON SUPPORTS AGAIN DIFFERENT TYPES
      // } else if (
      //   isSourceCampaignTypeManual &&
      //   !isNil(this.sourceAdGroupEntityType) &&
      //   this.sourceAdGroupEntityType != this.destinationAdGroupEntityType
      // ) {
      //   this.validationErrors.destinationAdGroupId = 'For MANUAL source campaigns, source and destination ad groups must be the same type';
    } else if (isDestinationAdGroupNameDuplicate) {
      this.validationWarnings.destinationAdGroupId = 'Multiple ad groups exist with given name';
    }

    // biddingMethod
    if (!this.biddingMethod || isEmpty(this.biddingMethod)) {
      this.validationErrors.biddingMethod = `Bidding Method can't be empty. Valid values are: ${MAIN_VALID_BIDDING_METHOD_INPUTS}`;
    } else if (!Object.values(BiddingMethod).includes(this.biddingMethod as BiddingMethod)) {
      this.validationErrors.biddingMethod = `Invalid bidding method ${this.biddingMethod}. Valid values are: ${MAIN_VALID_BIDDING_METHOD_INPUTS}`;
    }

    // biddingMethodValue
    const clampedBiddingMethodValue =
      !isNil(this.biddingMethodValue) && isNonNegativeNumber(this.biddingMethodValue) && !isNil(this.destinationCampaignAdType)
        ? this.getNewBidValue_byCurrentProfileMarketplaceLimits(
            Number(this.biddingMethodValue),
            this.destinationCampaignAdType as CampaignAdType,
            this.destinationCampaignIsVideo,
            new Set<string>(),
          )
        : null;

    if (this.biddingMethod === BiddingMethod.ADLABS && !isNil(this.biddingMethodValue)) {
      this.validationErrors.biddingMethodValue = '"AdLabs" bidding method cannot have a value';
    } else if (
      this.biddingMethod !== BiddingMethod.ADLABS &&
      !isNumber(this.biddingMethodValue) &&
      (isEmpty(this.biddingMethodValue) || isNil(this.biddingMethodValue))
    ) {
      this.validationErrors.biddingMethodValue = 'Bidding Method value cannot be empty when Bidding Method is not "AdLabs"';
    } else if (this.biddingMethod !== BiddingMethod.ADLABS && !isPositiveNumber(this.biddingMethodValue)) {
      this.validationErrors.biddingMethodValue = 'Invalid bidding method value (only positive numbers allowed)';
    } else if (
      [BiddingMethod.CPC_MINUS, BiddingMethod.CPC_PLUS, BiddingMethod.CUSTOM].includes(this.biddingMethod as BiddingMethod) &&
      this.biddingMethodValue &&
      isPositiveNumber(this.biddingMethodValue) &&
      !isNil(clampedBiddingMethodValue) &&
      clampedBiddingMethodValue != Number(this.biddingMethodValue)
    ) {
      this.validationErrors.biddingMethodValue = `Value is not within current marketplace bid limits. Suggested value: ${clampedBiddingMethodValue}`;
    }

    // bidFloor
    const clampedFloor =
      !isNil(this.bidFloor) && isNonNegativeNumber(this.bidFloor) && !isNil(this.destinationCampaignAdType)
        ? this.getNewBidValue_byCurrentProfileMarketplaceLimits(
            Number(this.bidFloor),
            this.destinationCampaignAdType as CampaignAdType,
            this.destinationCampaignIsVideo,
            new Set<string>(),
          )
        : null;

    if (!isNil(this.bidFloor) && !isNonNegativeNumber(this.bidFloor)) {
      this.validationErrors.bidFloor = `Invalid bid floor (only non-negative numbers allowed)`;
    } else if (
      !isNil(this.bidFloor) &&
      isNonNegativeNumber(this.bidFloor) &&
      !isNil(clampedFloor) &&
      clampedFloor != roundToPrecision(clampedFloor, Number(this.bidFloor))
    ) {
      this.validationErrors.bidFloor = `Bid floor is not within current marketplace bid limits. Suggested value: ${clampedFloor}`;
    }

    // bidCeiling
    const clampedCeiling =
      !isNil(this.bidCeiling) && isNonNegativeNumber(this.bidCeiling) && !isNil(this.destinationCampaignAdType)
        ? this.getNewBidValue_byCurrentProfileMarketplaceLimits(
            Number(this.bidCeiling),
            this.destinationCampaignAdType as CampaignAdType,
            this.destinationCampaignIsVideo,
            new Set<string>(),
          )
        : null;

    if (!isNil(this.bidCeiling) && !isNonNegativeNumber(this.bidCeiling)) {
      this.validationErrors.bidCeiling = `Invalid bid ceiling (only non-negative numbers allowed)`;
    } else if (
      !isNil(this.bidCeiling) &&
      isNonNegativeNumber(this.bidCeiling) &&
      !isNil(clampedCeiling) &&
      clampedCeiling != roundToPrecision(clampedCeiling, Number(this.bidCeiling))
    ) {
      this.validationErrors.bidCeiling = `Bid ceiling is not within current marketplace bid limits. Suggested value: ${clampedCeiling}`;
    }

    const matchNotSelectedMsg = 'At least one match type needs to be selected';
    // exactMatch
    // broadMatch
    // phraseMatch
    // individualProductTarget
    // expandedProductTarget
    // Depending on destinationAdGroupEntityType at least 1 in the group needs to be selected
    if (this.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
      if (!isBoolean(this._exactMatch) && !VALID_TRUE_VALUES.includes(this._exactMatch)) {
        this.validationErrors.exactMatch = `Invalid Exact Match value "${this._exactMatch}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (!isBoolean(this._broadMatch) && !VALID_TRUE_VALUES.includes(this._broadMatch)) {
        this.validationErrors.broadMatch = `Invalid Broad Match value "${this._broadMatch}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (!isBoolean(this._phraseMatch) && !VALID_TRUE_VALUES.includes(this._phraseMatch)) {
        this.validationErrors.phraseMatch = `Invalid Phrase Match value "${this._phraseMatch}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (!this.exactMatch && !this.broadMatch && !this.phraseMatch) {
        this.validationErrors.exactMatch = matchNotSelectedMsg;
        this.validationErrors.broadMatch = matchNotSelectedMsg;
        this.validationErrors.phraseMatch = matchNotSelectedMsg;
      }

      if (this.individualProductTarget) {
        this.validationErrors.individualProductTarget = 'Individual PT is not supported for keywords';
      }

      if (this.expandedProductTarget) {
        this.validationErrors.expandedProductTarget = 'Expanded PT is not supported for keywords';
      }
    } else if (this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
      if (!isBoolean(this._individualProductTarget) && !VALID_TRUE_VALUES.includes(this._individualProductTarget)) {
        this.validationErrors.individualProductTarget = `Invalid Individual Product Target value "${this._individualProductTarget}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (!isBoolean(this._expandedProductTarget) && !VALID_TRUE_VALUES.includes(this._expandedProductTarget)) {
        this.validationErrors.expandedProductTarget = `Invalid Expanded Product Target value "${this._expandedProductTarget}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (!this.individualProductTarget && !this.expandedProductTarget) {
        this.validationErrors.individualProductTarget = matchNotSelectedMsg;
        this.validationErrors.expandedProductTarget = matchNotSelectedMsg;
      }

      if (this.exactMatch) {
        this.validationErrors.exactMatch = 'Exact match is not supported for product targets';
      }

      if (this.broadMatch) {
        this.validationErrors.broadMatch = 'Broad match is not supported for product targets';
      }

      if (this.phraseMatch) {
        this.validationErrors.phraseMatch = 'Phrase match is not supported for product targets';
      }
    }

    // campaignNegativeExact
    // campaignNegativePhrase
    // campaignNegativeProductTarget
    if (this.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
      if (!isBoolean(this._campaignNegativeExact) && !VALID_TRUE_VALUES.includes(this._campaignNegativeExact)) {
        this.validationErrors.campaignNegativeExact = `Invalid Campaign Negative Exact match value "${this._campaignNegativeExact}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (!isBoolean(this._campaignNegativePhrase) && !VALID_TRUE_VALUES.includes(this._campaignNegativePhrase)) {
        this.validationErrors.campaignNegativePhrase = `Invalid Campaign Negative Phrase match value "${this._campaignNegativePhrase}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (this.campaignNegativeProductTarget) {
        this.validationWarnings.campaignNegativeProductTarget = 'Campaign Negative Product Target is not supported for keywords';
      }
    } else if (this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
      const sourceCampaign = this._campaignToAdGroupsMap[this.sourceCampaignId ?? ''];

      // Also implemented in campaign mapping model
      if (this._campaignNegativeProductTarget && isSourceCampaignTypeManual && sourceCampaign?.campaignAdType != CampaignAdType.PRODUCTS) {
        this.validationErrors.campaignNegativeProductTarget = `Only AUTO SP campaigns can have campaign-level negative targets`;
      }

      if (!isBoolean(this._campaignNegativeProductTarget) && !VALID_TRUE_VALUES.includes(this._campaignNegativeProductTarget)) {
        this.validationErrors.campaignNegativeProductTarget = `Invalid Campaign Negative Product Target value "${this._campaignNegativeProductTarget}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (this.campaignNegativeExact) {
        this.validationWarnings.campaignNegativeExact = 'Campaign Negative Exact is not supported for product targets';
      }

      if (this.campaignNegativePhrase) {
        this.validationWarnings.campaignNegativePhrase = 'Campaign Negative Phrase is not supported for product targets';
      }
    }

    // Also implemented in campaign mapping model
    if (this.sourceCampaignAdType === CampaignAdType.BRANDS) {
      if (this.campaignNegativeProductTarget) {
        this.validationErrors.campaignNegativeProductTarget =
          'Campaign Negative Product Target is not supported when source campaign is of type BRANDS';
      }

      if (this.campaignNegativeExact) {
        this.validationErrors.campaignNegativeExact = 'Campaign Negative Exact is not supported when source campaign is of type BRANDS';
      }

      if (this.campaignNegativePhrase) {
        this.validationErrors.campaignNegativePhrase = 'Campaign Negative Phrase is not supported when source campaign is of type BRANDS';
      }
    }

    if (isSourceCampaignTypeManual) {
      if (
        this.sourceAdGroupEntityType === TargetEntityType.KEYWORD &&
        this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET
      ) {
        const msg = 'Cannot have any negatives in non-AUTO target ad group when source is keyword and target product target';
        if (this._campaignNegativeExact) {
          this.validationErrors.campaignNegativeExact = msg;
        }

        if (this._campaignNegativePhrase) {
          this.validationErrors.campaignNegativePhrase = msg;
        }

        if (this._campaignNegativeProductTarget) {
          this.validationErrors.campaignNegativeProductTarget = msg;
        }

        if (!this._campaignNegativeExact && !this._campaignNegativePhrase && !this._campaignNegativeProductTarget) {
          // Also implemented in campaign mapping model
          this.isCellDisabledMap[ColumnId.NEGATIVE_CAMPAIGN] = true;
        }
      }
    }

    // adGroupNegativeExact
    // adGroupNegativePhrase
    // adGroupNegativeProductTarget
    if (this.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
      if (!isBoolean(this._adGroupNegativeExact) && !VALID_TRUE_VALUES.includes(this._adGroupNegativeExact)) {
        this.validationErrors.adGroupNegativeExact = `Invalid Ad Group Exact Match value "${this._adGroupNegativeExact}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (!isBoolean(this._adGroupNegativePhrase) && !VALID_TRUE_VALUES.includes(this._adGroupNegativePhrase)) {
        this.validationErrors.adGroupNegativePhrase = `Invalid Ad Group Phrase Match value "${this._adGroupNegativePhrase}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (this.adGroupNegativeProductTarget) {
        this.validationWarnings.adGroupNegativeProductTarget = 'Ad Group Negative Product Target is not supported for keywords';
      }
    } else if (this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
      if (!isBoolean(this._adGroupNegativeProductTarget) && !VALID_TRUE_VALUES.includes(this._adGroupNegativeProductTarget)) {
        this.validationErrors.adGroupNegativeProductTarget = `Invalid Ad Group Product Target value "${this._adGroupNegativeProductTarget}", valid values are: ${VALID_TRUE_VALUES.join(', ')}`;
      }

      if (this.adGroupNegativeExact) {
        this.validationWarnings.adGroupNegativeExact = 'Ad Group Negative Exact is not supported for product targets';
      }

      if (this.adGroupNegativePhrase) {
        this.validationWarnings.adGroupNegativePhrase = 'Ad Group Negative Phrase is not supported for product targets';
      }
    }

    if (isSourceCampaignTypeManual) {
      if (
        this.sourceAdGroupEntityType === TargetEntityType.KEYWORD &&
        this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET
      ) {
        const msg = 'Cannot have negative keywords in non-AUTO target ad group';
        if (this._adGroupNegativeExact) {
          this.validationErrors.adGroupNegativeExact = msg;
        }

        if (this._adGroupNegativePhrase) {
          this.validationErrors.adGroupNegativePhrase = msg;
        }

        if (!this._adGroupNegativeExact && !this.adGroupNegativePhrase) {
          // Also implemented in campaign mapping model
          this.isCellDisabledMap[ColumnId.NEGATIVE_AD_GROUP] = true;
        }
      } else if (
        this.sourceAdGroupEntityType === TargetEntityType.PRODUCT_TARGET &&
        this.destinationAdGroupEntityType === TargetEntityType.KEYWORD
      ) {
        if (this._adGroupNegativeProductTarget) {
          this.validationErrors.adGroupNegativeProductTarget = 'Cannot have negative product targets in non-AUTO target ad group';
        }

        if (!this._adGroupNegativeProductTarget) {
          this.isCellDisabledMap[ColumnId.NEGATIVE_AD_GROUP] = true;
        }
      }
    }

    // TODO: when the duplicate matter settles, can remove existingMappingKeys?
    // Check if such a mapping already exists
    // const mappingKey = createMappingSpecificKey({
    //   sourceCampaignId: this.sourceCampaignId ?? '',
    //   sourceAdGroupId: this.sourceAdGroupId ?? '',
    //   destinationCampaignId: this.destinationCampaignId ?? '',
    //   destinationAdGroupId: this.destinationAdGroupId ?? '',
    // });

    // if (this.existingMappingKeys.has(mappingKey)) {
    //   this.validationErrors.actions = 'This mapping already exists previously (source + destination is not unique).';
    // }
  }

  public static joinIDAndName(id: string, name: string) {
    return `${id} | ${name}`;
  }

  clone() {
    const clone = new CampaignMappingModelWithValidation({
      rowId: this.id,
      importRow: emptyCampaignMappingImportRow,
      campaignNameToCampaignIdsMap: this._campaignNameToCampaignIdsMap,
      campaignToAdGroupsMap: this._campaignToAdGroupsMap,
      getNewBidValue_byCurrentProfileMarketplaceLimits: this.getNewBidValue_byCurrentProfileMarketplaceLimits,
      existingMappingKeys: this._existingMappingKeys,
    });

    // Not a complete list of fields because e.g. id also sets name and type
    clone.sourceCampaignId = this._sourceCampaignId;
    clone.destinationCampaignId = this._destinationCampaignId;
    clone.sourceAdGroupId = this._sourceAdGroupId;
    clone.destinationAdGroupId = this._destinationAdGroupId;
    clone.biddingMethod = this._biddingMethod as BiddingMethod;
    clone.biddingMethodValue = this._biddingMethodValue;
    clone.bidFloor = tryParseInputFloat(this._bidFloor);
    clone.bidCeiling = tryParseInputFloat(this.bidCeiling);
    clone.exactMatch = this.exactMatch;
    clone.broadMatch = this.broadMatch;
    clone.phraseMatch = this.phraseMatch;
    clone.individualProductTarget = this.individualProductTarget;
    clone.expandedProductTarget = this.expandedProductTarget;
    clone.campaignNegativeExact = this.campaignNegativeExact;
    clone.campaignNegativePhrase = this.campaignNegativePhrase;
    clone.campaignNegativeProductTarget = this.campaignNegativeProductTarget;
    clone.adGroupNegativeExact = this.adGroupNegativeExact;
    clone.adGroupNegativePhrase = this.adGroupNegativePhrase;
    clone.adGroupNegativeProductTarget = this.adGroupNegativeProductTarget;

    return clone;
  }
}

interface CampaignMappingModelWithValidationArguments {
  rowId: string;
  importRow: CampaignMappingImportRow;
  campaignNameToCampaignIdsMap: Record<string, string[]>;
  campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;
  getNewBidValue_byCurrentProfileMarketplaceLimits: (
    oldValue: number,
    adType: CampaignAdType,
    campaignIsVideo: boolean,
    warnings: Set<string>,
  ) => number;
  existingMappingKeys: Set<string>;
}

// Make sure all these are lowercased
const VALID_BIDDING_METHOD_INPUTS_ADLABS = [BiddingMethod.ADLABS, 'adlabs', 'ad labs'];
const VALID_BIDDING_METHOD_INPUTS_CPC_PLUS = [BiddingMethod.CPC_PLUS, 'cpc_plus', 'cpc+', 'cpc plus', 'cpc +', 'cpcplus', 'cpc+', 'cpc + x'];
const VALID_BIDDING_METHOD_INPUTS_CPC_MINUS = [BiddingMethod.CPC_MINUS, 'cpc_minus', 'cpc-', 'cpc minus', 'cpc -', 'cpc - x'];
const VALID_BIDDING_METHOD_INPUTS_CPC_TIMES = [BiddingMethod.CPC_TIMES, 'cpc_times', 'cpc*', 'cpc times', 'cpc *', 'cpc * x'];
const VALID_BIDDING_METHOD_INPUTS_CUSTOM = [BiddingMethod.CUSTOM, 'custom', 'custom bid', 'custom bid x', 'custom bid (x)'];
const MAIN_VALID_BIDDING_METHOD_INPUTS = [
  BiddingMethod.ADLABS,
  BiddingMethod.CPC_PLUS,
  BiddingMethod.CPC_MINUS,
  BiddingMethod.CPC_TIMES,
  BiddingMethod.CUSTOM,
];
function tryBidMethodCellValueToBidMethod(value: string | null): BiddingMethod | null {
  if (!value) {
    return null;
  }
  const lowercaseValue = value.toLowerCase();

  if (VALID_BIDDING_METHOD_INPUTS_ADLABS.includes(lowercaseValue)) {
    return BiddingMethod.ADLABS;
  } else if (VALID_BIDDING_METHOD_INPUTS_CPC_PLUS.includes(lowercaseValue)) {
    return BiddingMethod.CPC_PLUS;
  } else if (VALID_BIDDING_METHOD_INPUTS_CPC_MINUS.includes(lowercaseValue)) {
    return BiddingMethod.CPC_MINUS;
  } else if (VALID_BIDDING_METHOD_INPUTS_CPC_TIMES.includes(lowercaseValue)) {
    return BiddingMethod.CPC_TIMES;
  } else if (VALID_BIDDING_METHOD_INPUTS_CUSTOM.includes(lowercaseValue)) {
    return BiddingMethod.CUSTOM;
  }

  return null; // Return null if no matching bidding method is found
}

function tryParseInputFloat(value: string | number | null): number | null {
  if (value == null) {
    return null;
  }

  if (typeof value === 'number') {
    return value;
  }
  const floatValue = parseFloat(value);
  return isNaN(floatValue) ? null : floatValue;
}

const VALID_TRUE_VALUES = ['x'];
function tryParsingBoolean(value: string): boolean | null {
  if (isEmpty(value)) {
    // Empty input == false
    return false;
  }

  const lowercaseValue = value.toLowerCase();
  if (VALID_TRUE_VALUES.includes(lowercaseValue)) return true;

  return null; // invalid input
}

function applyFunctionReturnOriginalOnNull<T>(value: string, fn: (value: string) => T | string | null): T | string {
  const fnValue = fn(value);
  return isNil(fnValue) ? value : fnValue;
}

export interface CampaignMappingNegatives {
  campaignNegativeExact: boolean;
  campaignNegativePhrase: boolean;
  campaignNegativeProductTarget: boolean;
  adGroupNegativeExact: boolean;
  adGroupNegativePhrase: boolean;
  adGroupNegativeProductTarget: boolean;
}
