import { FunctionComponent } from 'react';
import { AlFilterModel } from './models/AlFilterModel';
import { FiltersMoreButton } from './FiltersMoreButton';
import { FilterChip } from './FilterChip';

const DEFAULT_MAX_FILTER_CHIPS = 3;

interface FilterChipRowProps {
  filters: AlFilterModel[];
  onFilterDeleteButtonClicked: (filter: AlFilterModel) => void;
  onFilterChipClicked: () => void;
  maxFilterChips?: number;
}

export const FilterChipRow: FunctionComponent<FilterChipRowProps> = ({
  filters,
  onFilterDeleteButtonClicked,
  onFilterChipClicked,
  maxFilterChips = DEFAULT_MAX_FILTER_CHIPS,
}) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      {filters
        .filter((f) => f.isFilterBuilderFilter)
        .map((filter, index) => {
          return (
            <FilterChip
              key={filter.key + index}
              filter={filter}
              handleClick={onFilterChipClicked}
              handleDelete={onFilterDeleteButtonClicked}
            />
          );
        })
        .slice(0, maxFilterChips)}

      <FiltersMoreButton
        filterChipsCount={filters.filter((f) => f.isFilterBuilderFilter).length}
        maxFilterChips={maxFilterChips}
        onFilterChipClicked={onFilterChipClicked}
      />
    </div>
  );
};
