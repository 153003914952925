import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function generateCampaignGroupDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false, pinned: false },
    { colId: ColumnId.GROUP_NAME, hide: false, pinned: false },
    { colId: ColumnId.ACTIONS, hide: false, pinned: false },
    { colId: ColumnId.GROUP_TACOS, hide: false, pinned: false },
    { colId: ColumnId.GROUP_PRESET, hide: false, pinned: false },
    //{ colId: ColumnId.GROUP_BID_CEILING, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_GROUP_COUNT, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
  ];
}
