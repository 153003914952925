import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignGroupsFilters, getUpdatedFiltersValue } from '@/components/filter-builder/models/AlFilterModel';
import { ComparisonUnit } from '@/components/grid/types';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { GridApi } from 'ag-grid-enterprise';
import { cloneDeep, isEqual } from 'lodash-es';
import { FunctionComponent, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react';

export const CAMPAIGN_GROUPS_CONTEXT_KEY = 'campaign-groups-context';

interface CampaignGroupsContext {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  setFilterValue: (filter: AlFilterModel) => void;
  setFilterValues: (filters: AlFilterModel[]) => void;
  selectedCampaignGroups: CampaignGroupModel[];
  setSelectedCampaignGroups: React.Dispatch<React.SetStateAction<CampaignGroupModel[]>>;
  totalCampaignGroupsCount: number;
  comparisonUnit: ComparisonUnit;
  setComparisonUnit: React.Dispatch<React.SetStateAction<ComparisonUnit>>;
  gridApiRef: React.MutableRefObject<GridApi<CampaignGroupModel> | undefined>;
}

export const CampaignGroupsContext = createContext<CampaignGroupsContext>({
  filters: [],
  setFilters: () => {},
  setFilterValue: () => {},
  setFilterValues: () => {},
  selectedCampaignGroups: [],
  setSelectedCampaignGroups: () => {},
  totalCampaignGroupsCount: 0,
  comparisonUnit: 'nominal',
  setComparisonUnit: () => {},
  gridApiRef: { current: undefined },
});

interface CampaignGroupsProviderProps extends PropsWithChildren {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  selectedCampaignGroups: CampaignGroupModel[];
  setSelectedCampaignGroups: React.Dispatch<React.SetStateAction<CampaignGroupModel[]>>;
  totalCampaignGroupsCount: number;
}

export const CampaignGroupsProvider: FunctionComponent<CampaignGroupsProviderProps> = ({
  filters,
  setFilters,
  selectedCampaignGroups,
  setSelectedCampaignGroups,
  totalCampaignGroupsCount,
  children,
}) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();

  const [comparisonUnit, setComparisonUnit] = useState<ComparisonUnit>('percent');
  const [isMounted, setIsMounted] = useState(false);

  const setFilterValue = (filter: AlFilterModel) => {
    const newFilters = getUpdatedFiltersValue(filters, filter);
    setFilterValues(newFilters);
  };

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(CAMPAIGN_GROUPS_CONTEXT_KEY, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(
        CAMPAIGN_GROUPS_CONTEXT_KEY,
        activeProfile?.id,
        getDefaultCampaignGroupsFilters(),
      );

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // GRID
  const gridApiRef = useRef<GridApi<CampaignGroupModel>>();

  return (
    <CampaignGroupsContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
        selectedCampaignGroups,
        setSelectedCampaignGroups,
        totalCampaignGroupsCount,
        comparisonUnit,
        setComparisonUnit,
        gridApiRef,
      }}
    >
      {children}
    </CampaignGroupsContext.Provider>
  );
};

export const useCampaignGroupsContext = (): CampaignGroupsContext => {
  const context = useContext(CampaignGroupsContext);
  if (!context) {
    throw new Error('useCampaignGroupsContext must be used within a CampaignGroupsProvider');
  }
  return context;
};
