import { Environment } from '@/config/Environment';
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_FIRST_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
} from '@/modules/registration/pages/RegistrationStepCreateAccountPage';
import { useUserContext, userService } from '@/modules/users';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { teamService } from '../api/teams.service';
import { TeamModel } from '../types/TeamModel';
import { sleep } from '@/lib/api/api-utils';

const MAX_EMAIL_LENGTH = 100;
const MAX_PASSWORD_LENGTH = 250;
const MIN_PASSWORD_LENGTH = 6;
interface AddTeamMemberDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  team: TeamModel;
  onSuccess: () => void;
  onError: () => void;
}

export const AddTeamMemberDialog: FunctionComponent<AddTeamMemberDialogProps> = ({ isOpen, setIsOpen, team, onSuccess, onError }) => {
  const { refetchUser } = useUserContext();

  const [isExistingEmail, setIsExistingEmail] = useState(false);
  const [isUserAlreadyInTeam, setIsUserAlreadyInTeam] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null); // null - not checked yet, true - valid, false - invalid
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  async function onClose() {
    setIsOpen(false);

    await sleep(200);
    setEmail('');
    setFirstName('');
    setSurname('');
    setPassword('');
    setIsEmailValid(null);
    setIsUserAlreadyInTeam(false);
    setIsExistingEmail(false);
    setIsLoading(false);
  }

  function isValidEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  async function checkIfEmailIsValidAndExists(email: string) {
    if (isValidEmail(email)) {
      setIsEmailValid(true);

      const isUserAlreadyInTeam = team.members.some((member) => member.user.email === email);
      setIsUserAlreadyInTeam(isUserAlreadyInTeam);

      if (!isUserAlreadyInTeam) {
        setIsLoading(true);
        const result = await userService.checkIfEmailExists(email);
        if (result.isSuccess) {
          setIsExistingEmail(result.payload.exists);
        } else {
          toast.error(result.message);
          setIsExistingEmail(false);
        }

        setIsLoading(false);
      }
    } else {
      setIsEmailValid(false);
      setIsExistingEmail(false);
    }
  }

  const timeoutID = useRef<NodeJS.Timeout | undefined>();
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);

    if (timeoutID) {
      clearTimeout(timeoutID.current);
    }

    timeoutID.current = setTimeout(() => checkIfEmailIsValidAndExists(email), 500);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  function isInputValid(): boolean {
    if (email.length === 0) {
      toast.error('Email cannot be empty');
      return false;
    } else if (email.length > MAX_EMAIL_LENGTH) {
      toast.error(`Email cannot exceed ${MAX_EMAIL_LENGTH} characters`);
      return false;
    }

    if (!isExistingEmail && !isUserAlreadyInTeam) {
      if (firstName.length === 0) {
        toast.error('First name cannot be empty');
        return false;
      } else if (firstName.length > MAX_FIRST_NAME_LENGTH) {
        toast.error(`First name cannot exceed ${MAX_FIRST_NAME_LENGTH} characters`);
        return false;
      } else if (firstName.length < MIN_FIRST_NAME_LENGTH) {
        toast.error(`First name must be at least ${MIN_FIRST_NAME_LENGTH} characters`);
        return false;
      }

      if (surname.length === 0) {
        toast.error('Last name cannot be empty');
        return false;
      } else if (surname.length > MAX_SURNAME_LENGTH) {
        toast.error(`Last name cannot exceed ${MAX_SURNAME_LENGTH} characters`);
        return false;
      } else if (surname.length < MIN_SURNAME_LENGTH) {
        toast.error(`Last name must be at least ${MIN_SURNAME_LENGTH} characters`);
        return false;
      }

      if (password.length === 0) {
        toast.error('Password cannot be empty');
        return false;
      } else if (password.length < MIN_PASSWORD_LENGTH) {
        toast.error(`Password must be at least ${MIN_PASSWORD_LENGTH} characters`);
        return false;
      } else if (password.length > MAX_PASSWORD_LENGTH) {
        toast.error(`Password cannot exceed ${MAX_EMAIL_LENGTH} characters`);
        return false;
      }
    }

    return true;
  }

  function handleSubmit() {
    if (isInputValid()) {
      if (isExistingEmail || isUserAlreadyInTeam) {
        addUserToTeam({ teamId: team.id, email });
      } else {
        addNewUserToTeam({ teamId: team.id, name: firstName, surname, email, password });
      }
    }
  }

  const { mutate: addUserToTeam, isPending: isLoadingAddUserToTeam } = useMutation({
    mutationFn: ({ teamId, email }: { teamId: number; email: string }) => teamService.addUserToTeam(teamId, email),
    onMutate: () => {
      if (Environment.isDev()) {
        console.log('Adding member to a team');
      }
    },
    onSuccess: (response) => {
      if (response.isSuccess) {
        setEmail('');
        refetchUser();
        onClose();
        onSuccess();
        toast.success(`Member added to a team successfully`);
      } else {
        onError();
        toast.error(`Adding member to a team failed: ${response.message}`);
      }
    },
  });

  const { mutate: addNewUserToTeam, isPending: isLoadingCreateUserAndAddToTeam } = useMutation({
    mutationFn: ({
      teamId,
      name,
      surname,
      email,
      password,
    }: {
      teamId: number;
      name: string;
      surname: string;
      email: string;
      password: string;
    }) =>
      userService.createUserAndAddToTeam(teamId, {
        name,
        surname,
        email,
        password,
      }),
    onMutate: () => {
      if (Environment.isDev()) {
        console.log('Adding member to a team');
      }
    },
    onSuccess: (response) => {
      if (response.isSuccess) {
        setEmail('');
        refetchUser();
        onClose();
        onSuccess();
        toast.success(`Member added to a team successfully`);
      } else {
        onError();
        toast.error(`Adding member to a team failed: ${response.message}`);
      }
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>
        <div className="flex flex-row justify-between">
          <div>Add a new member to team: {team.name}</div>

          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box className="flex flex-col gap-2" onSubmit={handleSubmit}>
          <TextField
            placeholder="Login email"
            disabled={isLoadingCreateUserAndAddToTeam || isLoadingAddUserToTeam}
            margin="normal"
            size="small"
            id="email"
            label="Email"
            type="email"
            value={email}
            inputProps={{
              maxLength: MAX_EMAIL_LENGTH,
            }}
            onChange={handleEmailChange}
            error={isEmailValid == false}
            helperText={isEmailValid == false && 'Please enter a valid email address'}
            InputProps={{
              endAdornment: isLoading ? <CircularProgress size={14} /> : undefined,
            }}
          />

          {isEmailValid && isUserAlreadyInTeam && <Alert severity="success">User already in team</Alert>}

          {!isLoading && isEmailValid && !isUserAlreadyInTeam && isExistingEmail && <Alert severity="success">Existing user found</Alert>}

          {!isLoading && isEmailValid && !isUserAlreadyInTeam && !isExistingEmail && (
            <>
              <Alert severity="info">Existing user not found, insert user details to finalize new user creation</Alert>

              <TextField
                id="name"
                label="First name"
                placeholder="First name"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                error={firstName.length > MAX_FIRST_NAME_LENGTH}
                helperText={
                  firstName.length > MAX_FIRST_NAME_LENGTH && `First name cannot be longer than ${MAX_FIRST_NAME_LENGTH} characters`
                }
              />
              <TextField
                id="surname"
                label="Last name"
                placeholder="Last name"
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
                error={surname.length > MAX_SURNAME_LENGTH}
                helperText={surname.length > MAX_SURNAME_LENGTH && `Last name cannot be longer than ${MAX_SURNAME_LENGTH} characters`}
              />

              <TextField
                disabled={isLoadingCreateUserAndAddToTeam || isLoadingAddUserToTeam}
                margin="normal"
                size="small"
                id="password"
                label="Password"
                type="password"
                value={password}
                inputProps={{
                  maxLength: MAX_PASSWORD_LENGTH,
                  minLength: MIN_PASSWORD_LENGTH,
                }}
                onChange={handlePasswordChange}
              />
            </>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          size="small"
          type="submit"
          loading={isLoadingCreateUserAndAddToTeam}
          disabled={!isEmailValid}
          className="-mt-4"
          onClick={handleSubmit}
        >
          Add member
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
