import { ProfileOption } from '@/modules/profiles/types/ProfileOption';
import { teamService } from '@/modules/teams';
import { userService, useUserContext } from '@/modules/users';
import { ProfileType } from '@/modules/users/types/ProfileType';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { isEmpty, isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import ProfilesMultiSelect from '@/modules/profiles/components/ProfilesMultiSelect';
import { toast } from 'react-toastify';
import { SELLER_CENTRAL_COUNTRY_CODE_LOCAL_STORAGE_KEY } from '@/modules/users/api/users/users.contracts';

export interface VendorCentralAuthorizationDialogProps {
  open: boolean;
  onClose: () => void;
}

export function VendorCentralAuthorizationDialog({ onClose, open }: VendorCentralAuthorizationDialogProps) {
  const { user, refetchUser } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const [profiles, setProfiles] = useState<ProfileOption[]>([]);
  const [availableMarkets, setAvailableMarkets] = useState<string[]>([]);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    // As getProfiles() may set some of the profiles not active. One option is to refresh profiles on close
    if (profiles.some((p) => p.isNotAuthorized)) {
      refetchUser();
    }
    onClose();
  };

  function setError(message: string) {
    setIsError(true);
    setErrorMessage(message);
  }

  function resetError() {
    setIsError(false);
    setErrorMessage('');
  }

  useEffect(() => {
    if (activeTeam && open) {
      if (!isNil(activeTeam) && !isNil(activeTeam.amazonAccount) && activeTeam.amazonAccount.isAuthorized) {
        setAvailableMarkets([]);
        loadProfiles();
      }
    }
  }, [activeTeam, open]);

  async function loadProfiles() {
    try {
      resetError();
      setIsLoadingProfiles(true);
      const res = await teamService.getProfiles();

      if (res.isSuccess) {
        if (!isNil(res.payload) && res.payload.length > 0) {
          const profiles = res.payload
            .map((profile) => {
              return {
                id: profile.id,
                name: profile.name,
                market: profile.countryCode,
                type: profile.type,
                isSelected: false,
                isNotAuthorized: profile.isNotAuthorized,
              } as ProfileOption;
            })
            .filter((p) => p.type != ProfileType.AGENCY)
            .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()) || b.market.localeCompare(a.market));

          if (profiles.length > 0) {
            setProfiles(profiles);
            setAvailableMarkets([...new Set(profiles.map((p) => p.market))]); // We need unique values
          } else {
            setError('No profiles found');
          }
        } else {
          setError('No profiles found.');
        }
      } else {
        setError('Something went wrong. ' + res.message);
      }
    } catch (error) {
      console.error(error);
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoadingProfiles(false);
    }
  }

  const [isGetAuthUrlLoading, setIsGetAuthUrlLoading] = useState(false);

  async function onAuthorizeClicked() {
    resetError();

    if (isNil(activeTeam)) {
      setError('Active team not found');
      return;
    }

    const selectedProfile = profiles.find((p) => p.isSelected);

    if (!selectedProfile) {
      setError('No profile selected');
      return;
    }

    try {
      setIsGetAuthUrlLoading(true);
      const authUrl = await getSellerCentralAuthUrl();

      if (isEmpty(authUrl)) {
        setIsError(true);
        setErrorMessage('Something went wrong. Auth url is empty. Please try again later.');
      } else {
        localStorage.setItem(SELLER_CENTRAL_COUNTRY_CODE_LOCAL_STORAGE_KEY, market);
        window.location.replace(authUrl); // user will not be able to use browser's back button
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsGetAuthUrlLoading(false);
    }
  }

  async function getSellerCentralAuthUrl(): Promise<string> {
    const selectedProfile = profiles.find((p) => p.isSelected);
    if (user?.id && selectedProfile) {
      // TODO
      const res = await userService.getSellerCentralAuthorizationUrl('');
      if (res.isSuccess) {
        return res.payload;
      } else {
        toast.error('Unable to get auth url. Please try again later.');
      }
    } else {
      toast.error('User or active team not set.');
    }

    return '';
  }

  function deselectAllProfiles() {
    const newProfiles = profiles.map((p) => {
      p.isSelected = false;
      return p;
    });

    setProfiles(newProfiles);
  }

  function onProfileSelectionChange(profileId: string, checked: boolean) {
    // Allow selecting only one profile
    const newProfiles = profiles.map((p) => {
      if (p.id === profileId) {
        p.isSelected = checked;
      } else {
        p.isSelected = false;
      }
      return p;
    });
    setProfiles(newProfiles);
  }

  const [market, setMarket] = useState<string>('');
  useEffect(() => {
    if (availableMarkets.length > 0) {
      setMarket(availableMarkets[0]);
    }
  }, [availableMarkets]);

  const handleChange = (event: SelectChangeEvent) => {
    const newRegion = event.target.value as string;
    setMarket((prevRegion) => {
      if (prevRegion != newRegion) {
        deselectAllProfiles();
      }
      return newRegion;
    });
  };

  const [filteredProfiles, setFilteredProfiles] = useState<ProfileOption[]>([]);
  useEffect(() => {
    let filteredProfiles: ProfileOption[] = [];
    if (activeTeam && !isEmpty(market) && profiles && profiles.length > 0) {
      const existingProfileIds = new Set(activeTeam.profiles.map((p) => p.id));
      filteredProfiles = profiles.filter((p) => p.market == market && !p.isNotAuthorized && existingProfileIds.has(p.id));
    }
    setFilteredProfiles(filteredProfiles);
  }, [profiles, activeTeam, market]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <DialogTitle>Select Profile</DialogTitle>

        <DialogContent dividers>
          {isError && (
            <Alert className="mb-4" severity="error">
              {errorMessage}
            </Alert>
          )}

          <div className="flex flex-col justify-stretch gap-y-3">
            {availableMarkets.length > 0 && (
              <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 300 }}>
                <InputLabel id="demo-simple-select-helper-label">Market</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={market}
                  label="Region"
                  onChange={handleChange}
                >
                  <MenuItem value={''}>
                    <em>None</em>
                  </MenuItem>
                  {availableMarkets.map((c) => (
                    <MenuItem key={c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>
                {isEmpty(market) && <FormHelperText className="text-xs text-red-500">Please select market</FormHelperText>}
              </FormControl>
            )}

            <ProfilesMultiSelect
              profiles={filteredProfiles}
              onProfileSelectionChange={onProfileSelectionChange}
              isLoadingProfiles={isLoadingProfiles}
              isLoading={isLoadingProfiles}
            />
          </div>
        </DialogContent>

        <DialogActions className="mb-2 px-4">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            className="w-52"
            variant="contained"
            color="primary"
            onClick={onAuthorizeClicked}
            loading={isGetAuthUrlLoading}
            disabled={isLoadingProfiles || isGetAuthUrlLoading}
          >
            Authorize
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
