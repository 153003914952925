import { Drawer, IconButton, Portal } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { Close } from '@mui/icons-material';
import { DashboardWidgetConfigurationDrawerDeleteButton } from './DashboardWidgetConfigurationDrawerDeleteButton';

interface DashboardWidgetConfigurationDrawerProps extends PropsWithChildren {
  widgetName: string;
}

const DashboardWidgetConfigurationDrawer: FunctionComponent<DashboardWidgetConfigurationDrawerProps> = ({ children, widgetName }) => {
  const { isConfiguring, widgetId } = useContext(DashboardWidgetContext);

  const editingWidgetId = useDashboardContextValue((context) => context.editingWidgetId);
  const setEditingWidgetId = useDashboardContextValue((context) => context.setEditingWidgetId);

  const isConfiguringThisWidget = isConfiguring && widgetId === editingWidgetId;

  return (
    <Portal>
      <Drawer anchor={'right'} open={isConfiguringThisWidget} hideBackdrop variant="persistent">
        <div className="flex h-full w-96 flex-col  justify-between px-6 py-4 overflow-x-visible">
          <div className="flex flex-col flex-1 h-full">
            <div className="div flex items-center justify-between">
              <div className="text-lg font-medium ">Edit &apos;{widgetName}&apos; Widget</div>
              <IconButton edge="start" color="inherit" onClick={() => setEditingWidgetId('')} aria-label="close">
                <Close />
              </IconButton>
            </div>
            <div className="mb-6 mt-2">Edit the configuration of this widget below.</div>
            <div className="flex flex-col gap-y-2  flex-1 overflow-y-auto overflow-x-visible mb-2 ">
              {isConfiguringThisWidget && children}
            </div>
            <div className="w-full pt-2">
              <DashboardWidgetConfigurationDrawerDeleteButton />
            </div>
          </div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default DashboardWidgetConfigurationDrawer;
