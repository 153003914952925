import { MainMenu } from '../components/MainMenu';
import { Outlet } from 'react-router';
import { MAIN_MENU_ITEMS } from '../configuration/main-menu.configuration';
import { FunctionComponent, useEffect } from 'react';
import TrialEndingBanner from '@/modules/plans/components/TrialEndingBanner';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import PaymentPastDueBanner from '@/modules/plans/components/PaymentPastDueBanner';

const DAYS_BEFORE_TRIAL_ENDS_TO_DISPLAY_BANNER = 14;

export const ApplicationLayout: FunctionComponent = () => {
  const { setIsDisplayingBanner, isDisplayingBanner } = useLayoutContext();
  const { activeTeam } = useActiveTeamContext();

  const daysBeforeTrialEnds = activeTeam?.daysBeforeTrialEnd;
  const isPastDue = activeTeam?.isPastDue;

  useEffect(() => {
    if (activeTeam?.isPastDue) {
      setIsDisplayingBanner(true);
    } else if (
      activeTeam &&
      activeTeam.isTrialing &&
      activeTeam?.daysBeforeTrialEnd <= DAYS_BEFORE_TRIAL_ENDS_TO_DISPLAY_BANNER &&
      !activeTeam.hasDefaultPaymentMethod
    ) {
      setIsDisplayingBanner(true);
    } else {
      setIsDisplayingBanner(false);
    }
  }, [daysBeforeTrialEnds, activeTeam, setIsDisplayingBanner, isPastDue]);

  return (
    <div className="w-full">
      <div className="fixed right-0 left-0 top-0 z-50">
        {isDisplayingBanner &&
          (isPastDue ? <PaymentPastDueBanner /> : daysBeforeTrialEnds && <TrialEndingBanner daysBeforeTrialEnds={daysBeforeTrialEnds} />)}
      </div>
      <div
        className={`flex w-full bg-gray-100   dark:bg-gray-900
       ${isDisplayingBanner ? 'pt-7' : ''}
      `}
      >
        <MainMenu mainMenuItemGroups={MAIN_MENU_ITEMS} />

        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
