import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import TeamsTable from '@/modules/teams/components/TeamsTable';
import { FunctionComponent } from 'react';

export const TeamSettingsPage: FunctionComponent = () => {
  return (
    <PageLayout>
      <PageLayoutTopBar
        header="Manage your Teams"
        actions={
          <div className="flex flex-row gap-2">
            <TeamSelect />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="mt-6 flex flex-grow flex-1 flex-col">
          <TeamsTable />
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};
