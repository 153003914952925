/* eslint-disable react/prop-types */
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { UserSettingKey } from '@/modules/users';
import { CellClickedEvent, CellValueChangedEvent, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { ColDef, GridApi, GridOptions } from 'ag-grid-enterprise';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { CreateNegativeModel } from '../../models/CreateNegativeModel';
import { generateCreateNegativesPreviewTableColumnState } from './create-negatives-preview-table.default-column-state';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 60;

interface CreateNegativesPreviewTableProps {
  negativeKeywords: CreateNegativeModel[];
  setSelection: (selection: CreateNegativeModel[]) => void;
  onInternalGridReady?: (gridApi: GridApi<CreateNegativeModel>) => void;
  onCreateNegativesPreviewTableGridReady: (api: GridApi<CreateNegativeModel>) => void;
}

const CreateNegativesPreviewTable: FunctionComponent<CreateNegativesPreviewTableProps> = ({
  negativeKeywords,
  setSelection,
  onInternalGridReady,
  onCreateNegativesPreviewTableGridReady,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.ADD_NEGATIVE_KEYWORDS_PREVIEW_TABLE_COLUMN_STATE,
    generateCreateNegativesPreviewTableColumnState(),
  );
  const { t } = useTranslation();
  const { checkboxColumnType } = useColumnTypes();

  const gridApiRef = useRef<GridApi<CreateNegativeModel>>();

  const { getCampaignAdTypeCellRendererParams } = useColDefsFunctions();

  const columnDefs: ColDef<CreateNegativeModel>[] = useMemo(() => {
    const colDefsToReturn: ColDef<CreateNegativeModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.SEARCH_TERM,
        headerName: 'Search Term',
        field: 'searchTerm',
        hide: true,
      },
      {
        colId: ColumnId.NEGATIVE_MATCH_TYPE,
        headerName: 'Neg. Match Type',
        field: 'negativeMatchType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`optimizer_page.negative_match_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Ad Group',
        field: 'adGroupName',
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        width: 100,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'campaignName',
        resizable: true,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        width: 150,
        pinned: 'left',
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Campaign Ad Type',
        field: 'campaignAdType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
    ];

    applyStateToDefinitions(colDefsToReturn);
    return colDefsToReturn;
  }, [negativeKeywords]);

  // Pass selected fields values to parent for checked rows
  const handleRowSelection = useCallback((event: SelectionChangedEvent<CreateNegativeModel>) => {
    updateSelection(event.api);
  }, []);

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<CreateNegativeModel>) => {
    updateSelection(event.api);
  }, []);

  const updateSelection = (api: GridApi<CreateNegativeModel>) => {
    // Update the states
    setSelection(
      api.getSelectedRows().map((selectedForAdding) => {
        return selectedForAdding;
      }),
    );
  };

  const onGridReady = (params: GridReadyEvent<CreateNegativeModel>) => {
    gridApiRef.current = params.api;
    setColumnStateGridApi(params.api);
    onCreateNegativesPreviewTableGridReady(params.api);
    if (onInternalGridReady) {
      onInternalGridReady(params.api);
    }
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const onCellClicked = useCallback((params: CellClickedEvent<CreateNegativeModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  // TODO: is defaultColDef needed?
  const customGridOptions: GridOptions<CreateNegativeModel> = useMemo(() => {
    return {
      ...DEFAULT_GRID_OPTIONS,
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 40,
        headerComponent: DefaultHeaderRenderer,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      },
      getRowId: (params) => params.data.id.toString() + params.data.negativeMatchType,
      rowGroupPanelShow: 'always', // The place you can drag headers for row grouping
      maintainColumnOrder: true,
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      onCellValueChanged: onCellValueChanged,

      // Column state management
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,

      columnTypes: { checkboxColumnType },
    };
  }, [handleRowSelection]);

  return (
    <div style={{ height: `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` }}>
      <AlGrid
        gridOptions={customGridOptions}
        colDefs={columnDefs}
        rowData={negativeKeywords}
        onGridReadyCallback={onGridReady}
        fitToResizeEnabled={false}
        addExtraBottomPadding={true}
      />
    </div>
  );
};

export default CreateNegativesPreviewTable;
