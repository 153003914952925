export enum ColumnId {
  ID = 'id',
  CHECKBOX = 'checkbox',
  BIDDING_ENTITY = 'biddingEntity',
  CAMPAIGN_ID = 'campaignId',
  CAMPAIGN_NAME = 'campaignName',
  CAMPAIGN_NAME_DESTINATION = 'campaignNameDestination',
  CAMPAIGN_STATE = 'campaignState',
  TARGETING = 'targeting',
  MATCH = 'match',
  AD_GROUP = 'adGroup',
  AD_GROUP_DESTINATION = 'adGroupDestination',
  CAMPAIGN_AD_TYPE = 'campaignAdType',
  CAMPAIGN_AD_TYPE_DESTINATION = 'campaignAdTypeDestination',
  CREATIVE_TYPE = 'CreativeType',
  COST_TYPE = 'costType',
  BID_OPTIMIZATION = 'bidOptimization',
  OLD_VALUE = 'oldValue',
  NEW_VALUE = 'newValue',
  DELTA = 'delta',
  REASONS = 'reasons',
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  SAME_SKU_ORDERS = 'sso',
  SAME_SKU_SALES = 'sss',
  ACOS = 'acos',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
  NAME = 'name',
  STATE = 'state',
  SALES_PREVIOUS_DAYS = 'sales.previousDays',
  SPEND_PREVIOUS_DAYS = 'spend.previousDays',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TARGETING_TYPE = 'targetingType',
  BUDGET_TYPE = 'budgetType',
  BUDGET_AMOUNT = 'budgetAmount',
  BID_STRATEGY = 'bidStrategy',
  MULTI_AD_GROUPS_ENABLED = 'multiAdGroupsEnabled',
  SERVING_STATUS = 'servingStatus',
  TAGS = 'tags',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  REPORTED_AT = 'reportedAt',
  PROFILE_NAME = 'profileName',
  BILLING_STATUS = 'billingStatus',
  MARKET = 'market',
  CURRENCY = 'currency',
  TIMEZONE = 'timezone',
  TYPE = 'type',
  ONBOARD_DATE = 'onboardDate',
  TEAM_ACCESS = 'teamAccess',
  DEBUG = 'debug',
  REPORT_INFO = 'reportInfo',
  DATA_STATUS = 'dataStatus',
  PROCESS_LAST = 'processLast',
  MANUAL_UPDATE = 'manualUpdate',
  LAST_OPTIMIZED = 'lastOptimized',
  REMOVE_PROFILE = 'removeProfile',
  JOB_ID = 'jobId',
  REVERTED_JOB_ID = 'revertedJobId',
  VERSION = 'version',
  APPLIED_ENTITY_COUNT = 'appliedEntityCount',
  SUCCESS_ENTITY_COUNT = 'successEntityCount',
  FAILED_ENTITY_COUNT = 'failedEntityCount',
  TACOS = 'tacos',
  SENTIMENT = 'sentiment',
  BID_CEILING = 'bidCeiling',
  REVERT_JOB = 'revertJob',
  RESTART_INITIAL_REPORTS = 'restartInitialReports',
  SELLER_CENTRAL_REPORTS = 'sellerCentralReports',
  GROUP_ID = 'groupId',
  GROUP_NAME = 'groupName',
  GROUP_DESCRIPTION = 'groupDescription',
  GROUP_TACOS = 'groupTacos',
  GROUP_PRESET = 'groupPreset',
  GROUP_BID_CEILING = 'groupBidCeiling',
  CAMPAIGN_GROUP_COUNT = 'campaignGroupCount',
  CAMPAIGN_GROUP_DELETE = 'campaignGroupDelete',
  ACTIONS = 'actions',
  ACTION = 'action',
  TEAM_NAME = 'teamName',
  TEAM_PLAN = 'teamPlan',
  TEAM_ROLE = 'teamRole',
  TEAM_DELETE = 'teamDelete',
  TEAM_MEMBERS_COUNT = 'teamMembersCount',
  TEAM_IS_AUTHORIZED = 'teamIsAuthorized',
  TEAM_PROFILES_COUNT = 'teamProfilesCount',
  TEAM_OWNER = 'teamOwner',
  TEAM_MEMBERS = 'teamMembers',
  TEAM_PREFERRED_UPDATE_TIME = 'teamPreferredUpdateTime',
  BID = 'bid',
  ENTITY_TYPE = 'entityType',
  FLOW_TYPE = 'flowType',
  ACTION_ENTITY_TYPE = 'actionEntityType',
  ENTITY_TYPE_DESTINATION = 'entityTypeDestination',
  ENTITY_ID = 'entityId',
  DATA_ITEMS = 'dataItems',
  PORTFOLIO_NAME = 'portfolioName',
  PLACEMENT_TYPE = 'placementType',
  PLACEMENT_PERCENTAGE = 'placementPercentage',
  SEARCH_TERM = 'searchTerm',
  NEGATIVE_MATCH_TYPE = 'negativeMatchType',
  WORD_COUNT = 'wordCount',
  NEGATIVE_CAMPAIGN = 'negativeCampaign',
  NEGATIVE_AD_GROUP = 'negativeAdGroup',
  BID_METHOD = 'bidMethod',
  BID_METHOD_VALUE = 'bidMethodValue',
  AD_SPEND = 'adSpend',
  ESTIMATED_COST = 'estimatedCost',
  BID_FLOOR = 'bidFloor',
  USERNAME = 'username',
  HARVESTED = 'harvested',
  NEGATIVES = 'negatives',
  SOURCE_GROUP = 'SOURCE_GROUP',
  DESTINATION_GROUP = 'DESTINATION_GROUP',
  STARTING_BID_GROUP = 'STARTING_BID_GROUP',
  MATCH_TYPE_GROUP = 'MATCH_TYPE_GROUP',
  TITLE = 'TITLE',
  AVAILABILITY = 'AVAILABILITY',
  BASIS_PRICE = 'BASIS_PRICE',
  PRICE_TO_PAY = 'PRICE_TO_PAY',
  BEST_SELLER_RANK = 'BEST_SELLER_RANK',
  ASIN = 'ASIN',
  SKU = 'SKU',
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  SELLER_CENTRAL_AUTHORIZED = 'SellerCentralAuthorized',
}
