import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { MetricField } from '../metrics/models/CommonMetricsModel';
import { TimeUnit } from './types';

// weekStart: 0 (Sunday) to 6 (Saturday)
function getWeekStartDate(dateString: string, weekStart: number = 0): string {
  let date;
  try {
    date = new Date(dateString);
  } catch (error) {
    console.log(error);
    return '';
  }

  // Adjust day according to the weekStart
  const dayNum = (date.getDay() + 7 - weekStart) % 7;

  // Subtract the dayNum from the date to get the week start date
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() - dayNum);

  // Format the week start date as Month Day (e.g., Aug 14)
  return startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
}

// Get month name for aggregation
function getMonthName(dateString: string): string {
  // Use Date.parse to handle different date formats more flexibly
  const timestamp = Date.parse(dateString);

  // If the timestamp is NaN, it means the date string was invalid
  if (isNaN(timestamp)) {
    console.log('Invalid date string:', dateString);
    return 'Invalid Date';
  }

  const date = new Date(timestamp);

  // Return month name (e.g., Aug)
  return date.toLocaleDateString('en-US', { month: 'short' });
}

export function aggregateByPeriod(data: TimelineModel, aggregateBy: TimeUnit): TimelineModel {
  const initialData = new Map<string, { [key in MetricField]?: number }>();
  const periodDayCount = new Map<string, number>();

  // Initialize data for weekly or monthly aggregation
  data.xAxisData.forEach((date) => {
    const periodKey = aggregateBy === TimeUnit.WEEK ? getWeekStartDate(date) : getMonthName(date);

    if (!initialData.has(periodKey)) {
      initialData.set(periodKey, {});
      periodDayCount.set(periodKey, 0);
    }

    periodDayCount.set(periodKey, (periodDayCount.get(periodKey) || 0) + 1);
  });

  // Sum up direct metrics weekly or monthly
  data.yAxisData.forEach((metric) => {
    metric.values.forEach((value, index) => {
      const periodKey = aggregateBy === TimeUnit.WEEK ? getWeekStartDate(data.xAxisData[index]) : getMonthName(data.xAxisData[index]);
      const metrics = initialData.get(periodKey);
      if (metrics) {
        const metricKey = metric.key;
        metrics[metricKey] = (metrics[metricKey] || 0) + value;
      }
    });
  });

  // Check if the period is partial
  const isPeriodPartial = (periodKey: string): boolean => {
    const dayCount = periodDayCount.get(periodKey);
    return dayCount !== undefined && dayCount < (aggregateBy === TimeUnit.WEEK ? 7 : new Date().getDate());
  };

  // Create a new data with updated keys (add Partial where needed)
  const aggregatedData = new Map<string, { [key in MetricField]?: number }>();
  initialData.forEach((metrics, periodKey) => {
    const newKey = `${periodKey}${isPeriodPartial(periodKey) ? ' (Partial)' : ''}`;
    aggregatedData.set(newKey, metrics);
  });

  // Calculate metrics like CTR, ACOS, ROAS, etc.
  aggregatedData.forEach((metrics) => {
    // Calculating Click-Through Rate (CTR)
    if (metrics[MetricField.CLICKS] !== undefined && metrics[MetricField.IMPRESSIONS] !== undefined) {
      metrics[MetricField.CTR] = metrics[MetricField.CLICKS] / metrics[MetricField.IMPRESSIONS];
    }

    // Calculating Advertising Cost of Sales (ACOS)
    if (metrics[MetricField.SPEND] !== undefined && metrics[MetricField.SALES]) {
      metrics[MetricField.ACOS] = metrics[MetricField.SPEND] / metrics[MetricField.SALES];
    }

    // Calculating Return on Advertising Spend (ROAS)
    if (metrics[MetricField.SALES] !== undefined && metrics[MetricField.SPEND]) {
      metrics[MetricField.ROAS] = metrics[MetricField.SALES] / metrics[MetricField.SPEND];
    }

    // Calculating Revenue Per Click (RPC)
    if (metrics[MetricField.SALES] !== undefined && metrics[MetricField.CLICKS]) {
      metrics[MetricField.RPC] = metrics[MetricField.SALES] / metrics[MetricField.CLICKS];
    }

    // Calculating Cost Per Acquisition (CPA)
    if (metrics[MetricField.SPEND] !== undefined && metrics[MetricField.ORDERS]) {
      metrics[MetricField.CPA] = metrics[MetricField.SPEND] / metrics[MetricField.ORDERS];
    }

    // Calculating Average Order Value (AOV)
    if (metrics[MetricField.SALES] !== undefined && metrics[MetricField.ORDERS]) {
      metrics[MetricField.AOV] = metrics[MetricField.SALES] / metrics[MetricField.ORDERS];
    }

    // Calculating Cost Per Click (CPC)
    if (metrics[MetricField.SPEND] !== undefined && metrics[MetricField.CLICKS]) {
      metrics[MetricField.CPC] = metrics[MetricField.SPEND] / metrics[MetricField.CLICKS];
    }

    // Calculating Cost Per Mille (CPM)
    if (metrics[MetricField.SPEND] !== undefined && metrics[MetricField.IMPRESSIONS]) {
      metrics[MetricField.CPM] = (metrics[MetricField.SPEND] / metrics[MetricField.IMPRESSIONS]) * 1000;
    }

    // Calculating Conversion Rate (CVR)
    if (metrics[MetricField.ORDERS] !== undefined && metrics[MetricField.CLICKS]) {
      metrics[MetricField.CVR] = metrics[MetricField.ORDERS] / metrics[MetricField.CLICKS];
    }
  });

  return fromAggregatedData(aggregatedData);
}

function fromAggregatedData(aggregatedData: Map<string, { [key in MetricField]?: number }>): TimelineModel {
  const xAxisData: string[] = [];
  const yAxisData: {
    key: MetricField;
    values: number[];
  }[] = [];

  // Assuming aggregatedData is not empty, get the first entry's metrics
  const firstPeriodMetrics = aggregatedData.size > 0 ? Array.from(aggregatedData.values())[0] : {};

  // Initialize yAxisData with empty arrays for each metric in the order they appear in aggregatedData
  Object.keys(firstPeriodMetrics).forEach((metricKey) => {
    yAxisData.push({
      key: metricKey as MetricField,
      values: [],
    });
  });

  // Populate xAxisData and accumulate period values in yAxisData
  aggregatedData.forEach((metrics, periodKey) => {
    xAxisData.push(`${periodKey}`);

    yAxisData.forEach((metricData) => {
      const metricValue = metrics[metricData.key as keyof typeof metrics] ?? 0;
      metricData.values.push(metricValue);
    });
  });

  return new TimelineModel({
    xAxisData: xAxisData,
    yAxisData: yAxisData,
  });
}
