import { FilterAlt } from '@mui/icons-material';
import React from 'react';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';

interface DashboardWidgetFilterIndicatorProps {
  visible: boolean;
}

const DashboardWidgetFilterIndicator: React.FC<DashboardWidgetFilterIndicatorProps> = ({ visible }) => {
  const { isEditingDashboard } = useDashboardContextValue((context) => ({
    isEditingDashboard: context.isEditingDashboard,
  }));

  if (isEditingDashboard) {
    return null;
  }

  return <div className="pt-2 pr-2 opacity-45 text-md">{visible && <FilterAlt fontSize="inherit" />}</div>;
};

export default DashboardWidgetFilterIndicator;
