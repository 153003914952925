import VideoModal from '@/components/modals/video-modal/VideoModal';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultPlacementsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { VideoUrl } from '@/config/urls';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywall } from '@/modules/plans/hooks/usePaywall';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users/contexts/UserContext';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { createPlacementsWithTimelineQueryKey, placementsService } from '../api/placements-service';
import PlacementsMetrics from '../components/PlacementMetrics';
import PlacementsFilterBar from '../components/PlacementsFilterBar';
import PlacementsSelectionActionsBar from '../components/PlacementsSelectionActionsBar';
import PlacementsTimelineChart from '../components/PlacementsTimelineChart';
import PlacementsTable from '../components/placements-table/PlacementsTable';
import { PLACEMENTS_CONTEXT_KEY, PlacementsProvider } from '../contexts/PlacementsContext';
import { SelectedPlacement } from '../models/PlacementsModel';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { UserSettingKey } from '@/modules/users';

let isPaywallDisplayedBefore = false;

const PlacementsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { paywallModalElement, showPaywallWithDelay } = usePaywall({
    message: 'Upgrade to Pro to Analyze Campaign Placement Performance',
    videoUrl: VideoUrl.PLACEMENTS,
  });
  const { refetchUser } = useUserContext();

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(PLACEMENTS_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultPlacementsFilters());
  });

  const [selectedPlacements, setSelectedPlacements] = useState<SelectedPlacement[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const PlacementsTableGridApi = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedPlacements([]);
    if (PlacementsTableGridApi.current && !PlacementsTableGridApi.current.isDestroyed()) {
      PlacementsTableGridApi.current.deselectAll();
    }
  }

  const placementsWithTimelineQueryKey = createPlacementsWithTimelineQueryKey(activeProfile?.id, filters);
  const {
    data: placementsWithTimeline,
    isLoading: isPlacementsRowDataLoading,
    isError: isPlacementsLoadingError,
    error: placementsLoadingError,
  } = useQuery({
    queryKey: placementsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await placementsService.getPlacementsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading Placements ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  function onPlacementsTableGridReady(params: GridReadyEvent) {
    PlacementsTableGridApi.current = params.api;
  }

  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
      return;
    }

    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUsePlacementsPage) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(UserSettingKey.PLACEMENTS_TUTORIAL_DISPLAY_COUNT);

  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={
            <div className="flex flex-row items-center gap-4">
              {t('placements_page.header')}
              <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                How to Use Placements
              </Button>
            </div>
          }
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
              <TeamSelect />
              <ProfileSyncSelectButton setFilters={setFilters} />
            </div>
          }
        ></PageLayoutTopBar>

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <PlacementsProvider filters={filters} setFilters={setFilters}>
              <div className="mt-2">
                <AlErrorBoundary>
                  <PlacementsMetrics />
                </AlErrorBoundary>
              </div>

              <div className="my-2">
                <AlErrorBoundary>
                  <PlacementsTimelineChart />
                </AlErrorBoundary>
              </div>

              <AlErrorBoundary>
                <PlacementsFilterBar withTimeline={placementsWithTimeline} />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <PlacementsTable
                  withTimeline={placementsWithTimeline}
                  isLoading={isPlacementsRowDataLoading}
                  selectedPlacements={selectedPlacements}
                  setSelectedPlacements={setSelectedPlacements}
                  PlacementsLoadingErrorMessage={placementsLoadingError instanceof Error ? placementsLoadingError.message : ''}
                  isPlacementsLoadingError={isPlacementsLoadingError}
                  onGridReadyCallback={onPlacementsTableGridReady}
                  noTopBorderRadius={true}
                />
              </AlErrorBoundary>
            </PlacementsProvider>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <PlacementsSelectionActionsBar
          selectedPlacements={selectedPlacements}
          totalPlacementsCount={placementsWithTimeline?.placements ? placementsWithTimeline.placements.length : 0}
          onDiscardClicked={clearSelections}
        />
      </AlErrorBoundary>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.PLACEMENTS}
        title={'How to Use the Placements Tab'}
      />
      {paywallModalElement}
    </>
  );
};

export default PlacementsPage;
