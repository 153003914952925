import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import RGL, { Layout, WidthProvider, ItemCallback } from 'react-grid-layout';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { IDashboardWidget } from '../../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';
import DashboardWidget from '../dashboard-widget/DashboardWidget';
import { v4 } from 'uuid';
import { WIDGET_WIDTH_IN_PX, WIDGET_HEIGHT_IN_PX, DASHBOARD_COLUMN_COUNT, DASHBOARD_ROW_COUNT } from '../../configuration/configuration';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';

const ReactGridLayout = WidthProvider(RGL);

interface DashboardProps extends RGL.ReactGridLayoutProps, RGL.WidthProviderProps {}

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const {
    widgets,
    updateWidgetLayout,
    widgetPlaceholderHeight,
    widgetPlaceholderWidth,
    isEditingDashboard,
    addWidget,
    comparisonDates,
    dates,
    dashboardVersion,
  } = useDashboardContextValue((context) => ({
    widgets: context.widgets,
    updateWidgetLayout: context.updateWidgetLayout,
    widgetPlaceholderHeight: context.widgetPlaceholderHeight,
    widgetPlaceholderWidth: context.widgetPlaceholderWidth,
    isEditingDashboard: context.isEditingDashboard,
    setDragginWidgetId: context.setDragginWidgetId,
    addWidget: context.addWidget,
    dates: context.dates,
    comparisonDates: context.comparisonDates,
    dashboardVersion: context.dashboardVersion,
  }));

  const { refetchForWidgetWithId, refetchDashboardData } = useDashboardQueries();

  const widgetElements = useMemo(() => {
    return widgets.map((x) => {
      return <DashboardWidget key={x.id} widgetid={x.id} data-grid={x.layout} />;
    });
  }, [widgets]);

  const [dragClass, setDragClass] = useState('');
  const onLayoutChange = useCallback<ItemCallback>(
    (_, oldItem, newItem) => {
      console.log('onLayoutChange', oldItem, newItem, widgets);

      const widgetToUpdateLayout = widgets.find((widget) => widget.layout.i === oldItem.i || widget.id === oldItem.i);
      if (!widgetToUpdateLayout) {
        console.error('Widget not found');
        return;
      }
      updateWidgetLayout(widgetToUpdateLayout.id, newItem, true);
    },
    [widgets],
  );

  const onDrop = useCallback(
    async (_: Layout[], item: Layout, e: DragEvent) => {
      const raw = e.dataTransfer?.getData('droppableWidget');

      if (!raw) {
        return;
      }

      const droppableWidget = JSON.parse(raw) as IDashboardWidget<IDashboardWidgetBaseConfiguration>;

      // const newWidgetArr = [...widgets];

      droppableWidget.layout.x = item.x;
      droppableWidget.layout.y = item.y;
      droppableWidget.layout.isDraggable = undefined;
      droppableWidget.id = v4();
      droppableWidget.layout.i = v4();
      // newWidgetArr.push(droppableWidget);

      console.log({ addWidget });

      await addWidget(droppableWidget, true);
      refetchForWidgetWithId(droppableWidget.id);
    },
    [addWidget, refetchForWidgetWithId, widgets],
  );

  // Memoize styles
  const gridStyle = useMemo(
    () => ({
      minWidth: DASHBOARD_COLUMN_COUNT * WIDGET_WIDTH_IN_PX,
      minHeight: DASHBOARD_ROW_COUNT * WIDGET_HEIGHT_IN_PX,
      width: DASHBOARD_COLUMN_COUNT * WIDGET_WIDTH_IN_PX,
      height: DASHBOARD_ROW_COUNT * WIDGET_HEIGHT_IN_PX,
    }),
    [],
  );

  useEffect(() => {
    if (dashboardVersion) {
      refetchDashboardData();
    }
  }, [dashboardVersion]);

  return (
    <div
      className={`${
        isEditingDashboard
          ? `rounded-lg border-2 border-dashed border-gray-300  transition-all 
            bg-gray-200 bg-[linear-gradient(to_right,#80808025_1px,transparent_1px),linear-gradient(to_bottom,#80808025_1px,transparent_1px)] bg-[size:159.625px_100px]
            bg-[5px_5px]`
          : ''
      }`}
      style={gridStyle}
    >
      <ReactGridLayout
        {...props}
        className={`dashboard-grid  ${dragClass} `}
        cols={DASHBOARD_COLUMN_COUNT}
        rowHeight={WIDGET_HEIGHT_IN_PX}
        maxRows={DASHBOARD_ROW_COUNT}
        compactType={null}
        width={DASHBOARD_COLUMN_COUNT * WIDGET_WIDTH_IN_PX}
        useCSSTransforms={true}
        style={gridStyle}
        measureBeforeMount={false}
        preventCollision={true}
        onDrop={onDrop}
        // Override draggable handle because toggling draggable on either the grid or the grid items, does not work
        draggableHandle=".draggable"
        onDrag={(layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent) => {
          if (event.movementX > 0 && dragClass !== 'dragging-right') {
            setDragClass('dragging-right');
          } else if (event.movementX < 0 && dragClass !== 'dragging-left') {
            setDragClass('dragging-left');
          }
        }}
        onDropDragOver={() => {
          return { h: widgetPlaceholderHeight, w: widgetPlaceholderWidth };
        }}
        onDragStop={onLayoutChange}
        onResizeStop={onLayoutChange}
        isDroppable
      >
        {widgetElements}
      </ReactGridLayout>
    </div>
  );
};

export default Dashboard;
