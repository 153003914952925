import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { useFullscreenToggle } from '@/hooks/useFullscreenToggle';
import { ActionBar, PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import DateRangePickerButton from '@/modules/application/components/date-range-picker/DateRangePickerButton';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { ArrowDropDown, Close } from '@mui/icons-material';
import { Button, ButtonGroup, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { dashboardService } from '../api/dashboard/dashboard.service';
import Dashboard from '../components/dashboard/Dashboard';
import RenameDashboardDialog from '../components/dashboard/RenameDasbhoardDialog';
import DashboardWidgetCatalog from '../components/widget-catalog/DashboardWidgetCatalog';
import { WIDGET_CATALOG } from '../components/widgets/widget-catalog';
import { DashboardContext, useDashboard } from '../contexts/DashboardContextProvider';
import EditableText from '@/components/form/EditableText';

export const DashboardPage: FunctionComponent = () => {
  const { dashboardId } = useParams();

  const [isDashboardFullscreen, toggleIsDashboardFullscreen] = useFullscreenToggle();

  const {
    setPlaceholderSize,
    widgetPlaceholderHeight,
    widgetPlaceholderWidth,
    editingWidgetId,
    setEditingWidgetId,
    isEditingDashboard,
    setIsEditingDashboard,
    widgets,
    setWidgets,
    dashboard,
    isLoadingDashboard,
    removeWidget,
    updateWidgetConfiguration,
    dates,
    setDates,
    comparisonDates,
    setComparisonDates,
    dashboardDateFilters,
    setDragginWidgetId,
    dragginWidgetId,
    addWidget,
    saveDashboard,
    setWidgetIdsRequestingData,
    widgetIdsRequestingData,
    updateWidgetLayout,
    setDashboardVersion,
    dashboardVersion,
  } = useDashboard(dashboardId);

  const dashboardContextValue = useMemo(
    () => ({
      setPlaceholderSize,
      widgetPlaceholderHeight,
      widgetPlaceholderWidth,
      editingWidgetId,
      setEditingWidgetId,
      isEditingDashboard,
      setIsEditingDashboard,
      widgets,
      setWidgets,
      dashboard,
      isLoadingDashboard,
      removeWidget,
      updateWidgetConfiguration,
      dates,
      setDates,
      comparisonDates,
      setComparisonDates,
      dashboardDateFilters,
      setDragginWidgetId,
      dragginWidgetId,
      addWidget,
      saveDashboard,
      setWidgetIdsRequestingData,
      widgetIdsRequestingData,
      updateWidgetLayout,
      setDashboardVersion,
      dashboardVersion,
    }),
    [
      setPlaceholderSize,
      widgetPlaceholderHeight,
      widgetPlaceholderWidth,
      editingWidgetId,
      setEditingWidgetId,
      isEditingDashboard,
      setIsEditingDashboard,
      widgets,
      setWidgets,
      dashboard,
      isLoadingDashboard,
      removeWidget,
      updateWidgetConfiguration,
      dates,
      setDates,
      comparisonDates,
      setComparisonDates,
      dashboardDateFilters,
      setDragginWidgetId,
      dragginWidgetId,
    ],
  );

  const navigate = useNavigate();

  const { ModalComponent, handleOpenModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete this dashboard?',
    headerText: 'Delete Dashboard',
    onDelete: deleteDashboard,
  });

  function onDeleteDashboardClicked() {
    handleOpenModal();
  }

  async function deleteDashboard() {
    if (!dashboardId) return;

    const result = await dashboardService.delete(+dashboardId);
    if (result.isSuccess) {
      toast.success('Dashboard deleted');
      navigate('/dashboards', { replace: true });
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onRenameDashboardClicked() {
    setIsRenameDashboardDialogOpen(true);
  }

  const [isRenameDashboardDialogOpen, setIsRenameDashboardDialogOpen] = useState(false);

  function onDashboardRenamed() {
    setIsRenameDashboardDialogOpen(false);
  }

  async function onSetDates(dates: Dayjs[], comparisonDates: Dayjs[]) {
    setDates(dates);
    setComparisonDates(comparisonDates);
    if (dashboardId) {
      if (dashboard) {
        console.log('updating dashboard', dashboard);
        dashboard.dto.filter_start_date_offset_in_days = dates[0].diff(dayjs(), 'day');
        dashboard.dto.filter_end_date_offset_in_days = dates[1].diff(dayjs(), 'day');
        dashboard.dto.filter_compare_start_date_offset_in_days = comparisonDates[0].diff(dayjs(), 'day');
        dashboard.dto.filter_compare_end_date_offset_in_days = comparisonDates[1].diff(dayjs(), 'day');
      }

      await dashboardService.update(+dashboardId, {
        filter_start_date_offset_in_days: dates[0].diff(dayjs(), 'day'),
        filter_end_date_offset_in_days: dates[1].diff(dayjs(), 'day'),
        filter_compare_start_date_offset_in_days: comparisonDates[0].diff(dayjs(), 'day'),
        filter_compare_end_date_offset_in_days: comparisonDates[1].diff(dayjs(), 'day'),
      });

      setDashboardVersion(v4());
    }
  }

  useEffect(() => {
    if (isLoadingDashboard) return;
    if (!dashboard) return;

    if (isNil(dashboard.dto.filter_start_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_end_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_compare_start_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_compare_end_date_offset_in_days)) return;

    setDates([
      dayjs().add(dashboard.dto.filter_start_date_offset_in_days, 'day'),
      dayjs().add(dashboard.dto.filter_end_date_offset_in_days, 'day'),
    ]);
    setComparisonDates([
      dayjs().add(dashboard.dto.filter_compare_start_date_offset_in_days, 'day'),
      dayjs().add(dashboard.dto.filter_compare_end_date_offset_in_days, 'day'),
    ]);
  }, [isLoadingDashboard]);

  if (dashboardId && !dashboard) {
    return <LoadingBlock />;
  }

  async function onDashboardNameChanged(newDashboardName: string) {
    if (dashboard) {
      const renamedDashboard = await dashboardService.update(dashboard.dto.id, { name: newDashboardName });
      if (renamedDashboard.isSuccess) {
        dashboard.dto.name = renamedDashboard.payload.name;
        toast.success('Dashboard renamed');
      }
    }
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <PageLayout showFullscreen={isDashboardFullscreen}>
        {isDashboardFullscreen ? (
          <div className="flex w-full items-center justify-between px-4">
            <div>
              {dashboard ? (
                <div className="text-2xl font-semibold">{dashboard.dto.name}</div>
              ) : (
                <div className="text-2xl font-semibold">Dashboard</div>
              )}
            </div>
            {/* TODO: show customer logo? */}
            {/* <div className="mt-2 flex flex-col items-center">
                  {isWhiteLabled && logoForCurrentModeUrl ? (
                    <>
                      <img
                        style={{
                          fill: 'currentColor',
                        }}
                        alt="logo"
                        src={logoForCurrentModeUrl.toString()}
                      />
                      <div className="text-gray mt-2 font-medium tracking-wide">Powered by Posito.io</div>
                    </>
                  ) : (
                    <div className="text-gray mt-2 font-medium tracking-wide">Powered by Posito.io</div>
                  )}
                </div> */}
            <div>
              <IconButton
                onClick={() => {
                  toggleIsDashboardFullscreen();
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
        ) : (
          ''
        )}
        <PageLayoutTopBar
          header={dashboard ? <EditableText onTextChange={onDashboardNameChanged} text={dashboard.dto.name} /> : 'Dashboard'}
          noBg
          navigateBackPathname="/dashboards"
          actions={
            <>
              {/* <Button
                  variant="outlined"
                  onClick={() => {
                    toggleIsDashboardFullscreen();
                  }}
                  startIcon={<Fullscreen />}
                >
                  Show Fullscreen
                </Button> */}
              {!dashboard || isLoadingDashboard ? (
                <Skeleton className="rounded-xl" variant="rectangular" animation="wave" width={200} height={35} />
              ) : (
                <DateRangePickerButton dates={dates} comparisonDates={comparisonDates} onSetDates={onSetDates} disablePaywall />
              )}
              <ButtonGroup variant="contained" disableElevation>
                <Button
                  onClick={() => {
                    setIsEditingDashboard(!isEditingDashboard);
                  }}
                >
                  Edit Widgets
                </Button>

                <Button onClick={handleClick}>
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    onRenameDashboardClicked();
                    handleClose();
                  }}
                >
                  Rename Dashboard
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDeleteDashboardClicked();
                    handleClose();
                  }}
                >
                  Delete Dashboard
                </MenuItem>
              </Menu>

              <DashboardWidgetCatalog isOpen={isEditingDashboard} widgets={WIDGET_CATALOG}></DashboardWidgetCatalog>
            </>
          }
        />

        <PageLayoutBody>
          {isLoadingDashboard ? (
            <LoadingBlock />
          ) : !dashboard ? (
            <div className="mt-4">
              <ErrorLoadingDataAlert />
            </div>
          ) : (
            <>
              <div
                className={` ${
                  isEditingDashboard ? 'right-96 p-2' : 'right-0'
                } absolute bottom-0 left-0 top-0 h-full overflow-auto  scrollbar scrollbar-track-gray-200 scrollbar-thumb-gray-300 dark:scrollbar-track-gray-700 dark:scrollbar-thumb-gray-950`}
              >
                <div className="pb-4 ">{widgets && <Dashboard></Dashboard>}</div>
              </div>
              <div className={'w-96'}></div>
              {isEditingDashboard && (
                <ActionBar location="top">
                  <Button onClick={() => setIsEditingDashboard(false)}>Save Dashboard</Button>
                </ActionBar>
              )}
            </>
          )}
        </PageLayoutBody>
      </PageLayout>

      {dashboard !== undefined && isRenameDashboardDialogOpen && (
        <RenameDashboardDialog
          isOpen={isRenameDashboardDialogOpen}
          onClose={() => setIsRenameDashboardDialogOpen(false)}
          onDashboardRenamed={onDashboardRenamed}
          dashboard={dashboard}
        />
      )}
      {ModalComponent}
    </DashboardContext.Provider>
  );
};
