import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel, OperatorType } from '../models/AlFilterModel';
import { AlMultiSelectOptionModel } from '../models/AlMultiSelectOptionModel';

interface FilterSelectProps {
  filter: AlFilterModel;
  label: string;
}

export const FilterSelect: FunctionComponent<FilterSelectProps> = ({ filter, label }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const [options, setOptions] = useState<AlMultiSelectOptionModel[]>([]);

  useEffect(() => {
    filter.getOptions().then((options) => {
      setOptions(options);
    });
  }, []);

  const selectedValue = useMemo(() => {
    const selectedOptionId = filter.conditions ? filter.conditions[0].values[0] : null;
    return options.find((o) => o.id == selectedOptionId);
  }, [options]);

  function handleChange(event: React.SyntheticEvent<Element, Event>, newValue: AlMultiSelectOptionModel | null) {
    setFilter(newValue?.id);
  }

  function setFilter(selectOptionId: string | number | undefined) {
    filter.conditions = [
      {
        values: selectOptionId ? [selectOptionId as string] : [],
        operator: OperatorType.EQUAL,
      },
    ];

    setFilterValue(filter);
  }

  return (
    <div className="flex flex-row gap-2" style={{ maxWidth: '440px' }}>
      {options.length > 0 && (
        <Autocomplete
          id={'select-' + filter.key}
          style={{ width: 500 }}
          value={selectedValue}
          getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
          onChange={handleChange}
          options={options}
          renderInput={(params) => <TextField {...params} label={label} placeholder={label} InputLabelProps={{ shrink: true }} />}
        />
      )}
    </div>
  );
};
