import { isValidASIN, standardizeASIN } from '@/modules/application/utils';
import { CampaignAdType, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { isEmpty, isNil } from 'lodash-es';
import { CreateNegativesParams, SelectedSearchTerm } from '../../search-terms/models/SearchTermModel';
import { CreateNegativeDTO, NegativeMatchType } from '../api/negative-targets-contracts';

export class CreateNegativeModel {
  private dto: SelectedSearchTerm;
  public negativeMatchType: NegativeMatchType;
  public entityType: TargetEntityType;

  constructor(dto: SelectedSearchTerm, negativeMatchType: NegativeMatchType, entityType: TargetEntityType) {
    this.dto = dto;
    this.negativeMatchType = negativeMatchType;
    this.entityType = entityType;
  }

  public get id(): string {
    return this.dto.id;
  }

  public get searchTerm(): string {
    return this.dto.searchTerm;
  }

  public get campaignName(): string {
    return this.dto.campaignName;
  }

  public get campaignAdType(): CampaignAdType {
    return this.dto.campaignAdType;
  }

  public get targeting(): string {
    return this.dto.targeting;
  }

  public get campaignId(): string {
    return this.dto.campaignId;
  }

  public get adGroupId(): string {
    return this.isCampaign ? '' : this.dto.adGroupId;
  }

  public get adGroupName(): string {
    return this.isCampaign ? '' : this.dto.adGroupName;
  }

  public get isCampaign(): boolean {
    return [
      NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT,
      NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE,
      NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET,
    ].includes(this.negativeMatchType);
  }

  public get isProductTarget(): boolean {
    return [NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET, NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET].includes(
      this.negativeMatchType,
    );
  }

  public static fromSelectedSearchTermArrayAndParams(
    selectedSearchTerms: SelectedSearchTerm[],
    params: CreateNegativesParams,
  ): CreateNegativeModel[] {
    const models: CreateNegativeModel[] = [];

    for (const searchTerm of selectedSearchTerms) {
      models.push(...CreateNegativeModel.fromSelectedSearchTermAndParams(searchTerm, params));
    }

    return models;
  }

  public static getEntityTypeByTargetingAndSearchTerm(searchTerm: string): TargetEntityType {
    searchTerm = standardizeASIN(searchTerm);
    if (isValidASIN(searchTerm)) {
      return TargetEntityType.PRODUCT_TARGET;
    }
    return TargetEntityType.KEYWORD;
  }

  public static fromSelectedSearchTermAndParams(
    selectedSearchTerm: SelectedSearchTerm,
    params: CreateNegativesParams,
  ): CreateNegativeModel[] {
    const models: CreateNegativeModel[] = [];

    const entityType = selectedSearchTerm.entityType;

    // Standardize asin
    if (entityType == TargetEntityType.PRODUCT_TARGET) {
      selectedSearchTerm.searchTerm = standardizeASIN(selectedSearchTerm.searchTerm);
    }

    const isValidAdGroupId = !isEmpty(selectedSearchTerm.adGroupId) && !isNil(selectedSearchTerm.adGroupId);

    if (
      params.campaignNegativeExact &&
      entityType == TargetEntityType.KEYWORD &&
      selectedSearchTerm.campaignAdType == CampaignAdType.PRODUCTS
    ) {
      models.push(new CreateNegativeModel(selectedSearchTerm, NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT, entityType));
    }

    if (
      params.campaignNegativePhrase &&
      entityType == TargetEntityType.KEYWORD &&
      selectedSearchTerm.campaignAdType == CampaignAdType.PRODUCTS
    ) {
      models.push(new CreateNegativeModel(selectedSearchTerm, NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE, entityType));
    }

    if (
      params.campaignNegativeProductTarget &&
      entityType == TargetEntityType.PRODUCT_TARGET &&
      selectedSearchTerm.targetingType == TargetingType.AUTO &&
      selectedSearchTerm.campaignAdType == CampaignAdType.PRODUCTS
    ) {
      models.push(new CreateNegativeModel(selectedSearchTerm, NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET, entityType));
    }

    if (isValidAdGroupId && params.adGroupNegativeExact && entityType == TargetEntityType.KEYWORD) {
      models.push(new CreateNegativeModel(selectedSearchTerm, NegativeMatchType.AD_GROUP_NEGATIVE_EXACT, entityType));
    }

    if (isValidAdGroupId && params.adGroupNegativePhrase && entityType == TargetEntityType.KEYWORD) {
      models.push(new CreateNegativeModel(selectedSearchTerm, NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE, entityType));
    }

    if (isValidAdGroupId && params.adGroupNegativeProductTarget && entityType == TargetEntityType.PRODUCT_TARGET) {
      models.push(new CreateNegativeModel(selectedSearchTerm, NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET, entityType));
    }

    return models;
  }

  public toDTO(): CreateNegativeDTO {
    return {
      campaign_id: this.campaignId,
      ad_group_id: this.adGroupId,
      expression: this.isProductTarget ? this.searchTerm : undefined,
      keyword: this.isProductTarget ? undefined : this.searchTerm,
      negative_match: this.negativeMatchType,
      ad_type: this.campaignAdType,
    };
  }
}
