import { MetricField } from '@/components/metrics/models/CommonMetricsModel';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { v4 } from 'uuid';
import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';

export class MetricWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'ACOS';
  metricField = MetricField.ACOS;
  filters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
