import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultAdvertisedProductsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywall } from '@/modules/plans/hooks/usePaywall';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AdvertisedProductsFilterBar from '../components/AdvertisedProductsFilterBar';
import AdvertisedProductsMetrics from '../components/AdvertisedProductsMetrics';
import AdvertisedProductsTimelineChart from '../components/AdvertisedProductsTimelineChart';
import AdvertisedProductsTable from '../components/advertised-products-table/AdvertisedProductsTable';
import { ADVERTISED_PRODUCT_CONTEXT_KEY, AdvertisedProductsProvider } from '../contexts/AdvertisedProductsContext';
import { SelectedAdvertisedProduct } from '../models/AdvertisedProductModel';
import { createAdvertisedProductsWithTimelineQueryKey, productsService } from '@/modules/products/api/products-service';

let isPaywallDisplayedBefore = false;

const AdvertisedProductsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const { paywallModalElement, showPaywallWithDelay } = usePaywall({ message: 'Upgrade to Pro to Access the AdvertisedProducts Tool Suite' });

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(ADVERTISED_PRODUCT_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultAdvertisedProductsFilters());
  });

  const [selectedAdvertisedProducts, setSelectedAdvertisedProducts] = useState<SelectedAdvertisedProduct[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const advertisedProductsTableGridApiRef = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedAdvertisedProducts([]);
    if (advertisedProductsTableGridApiRef.current && !advertisedProductsTableGridApiRef.current.isDestroyed()) {
      advertisedProductsTableGridApiRef.current.deselectAll();
    }
  }

  const advertisedProductsWithTimelineQueryKey = createAdvertisedProductsWithTimelineQueryKey(activeProfile?.id, filters);
  const {
    data: advertisedProductsWithTimeline,
    isLoading: isAdvertisedProductsRowDataLoading,
    isError: isAdvertisedProductsLoadingError,
    error: advertisedProductsLoadingError,
  } = useQuery({
    queryKey: advertisedProductsWithTimelineQueryKey,

    queryFn: async () => {
      const result = await productsService.getAdvertisedProductsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading advertisedProducts ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  function onAdvertisedProductsTableGridReady(params: GridReadyEvent) {
    advertisedProductsTableGridApiRef.current = params.api;
  }

  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
    }

    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseAdvertisedProductsPage) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // // TUTORIAL MODAL
  // const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  // const onTutorialModalClose = () => {
  //   setIsTutorialModalOpen(false);
  // };

  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={t('advertised_products_page.header')}
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
              <TeamSelect />
              <ProfileSyncSelectButton setFilters={setFilters} />
            </div>
          }
        />

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <AdvertisedProductsProvider filters={filters} setFilters={setFilters}>
              <div className="mt-2">
                <AlErrorBoundary>
                  <AdvertisedProductsMetrics />
                </AlErrorBoundary>
              </div>

              <div className="my-2">
                <AlErrorBoundary>
                  <AdvertisedProductsTimelineChart />
                </AlErrorBoundary>
              </div>

              <AlErrorBoundary>
                <AdvertisedProductsFilterBar withTimeline={advertisedProductsWithTimeline} />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <AdvertisedProductsTable
                  withTimeline={advertisedProductsWithTimeline}
                  isLoading={isAdvertisedProductsRowDataLoading}
                  selectedAdvertisedProducts={selectedAdvertisedProducts}
                  setSelectedAdvertisedProducts={setSelectedAdvertisedProducts}
                  advertisedProductsLoadingErrorMessage={
                    advertisedProductsLoadingError instanceof Error ? advertisedProductsLoadingError.message : ''
                  }
                  isAdvertisedProductsLoadingError={isAdvertisedProductsLoadingError}
                  onGridReadyCallback={onAdvertisedProductsTableGridReady}
                  noTopBorderRadius={true}
                />
              </AlErrorBoundary>
            </AdvertisedProductsProvider>
          </PageLayoutBody>
        )}
      </PageLayout>
      {/* <AlErrorBoundary>
        <AdvertisedProductsSelectionActionsBar
          selectedAdvertisedProducts={selectedAdvertisedProducts}
          setSelectedAdvertisedProducts={setSelectedAdvertisedProducts}
          totalAdvertisedProductsCount={advertisedProductsWithTimeline?.advertisedProducts ? advertisedProductsWithTimeline.advertisedProducts.length : 0}
          onDiscardClicked={clearSelections}
          advertisedProductsTableGridApiRef={advertisedProductsTableGridApiRef}
        />
      </AlErrorBoundary> */}

      {/* <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={''}
        title={'How to Use the Search Terms Tab'}
      /> */}

      {paywallModalElement}
    </>
  );
};

export default AdvertisedProductsPage;
