import { VideoUrl } from '@/config/urls';
import { Routes } from '@/router/router-paths';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import TocIcon from '@mui/icons-material/Toc';
import { MainMenuItemGroup } from '../types/MenuItem';

export const MAIN_MENU_ITEMS: MainMenuItemGroup[] = [
  {
    label: 'Getting Started',
    hideHeader: true,
    children: [
      {
        activeRouteIds: [Routes.GETTING_STARTED],
        label: 'Getting Started',
        url: Routes.GETTING_STARTED,
        icon: <LightbulbIcon />,
      },
    ],
  },
  {
    label: 'Insights',
    icon: <StackedBarChartIcon />,
    children: [
      {
        activeRouteIds: [Routes.PROFILES_STATS],
        label: 'Profiles Stats',
        url: Routes.PROFILES_STATS,
        icon: <StackedBarChartIcon />,
      },
      {
        activeRouteIds: [Routes.DASHBOARDS + '*'],
        label: 'Dashboards',
        url: Routes.DASHBOARDS,
        icon: <DashboardRoundedIcon />,
        isDevOnly: true,
      },
      {
        activeRouteIds: [Routes.PRODUCTS],
        label: 'Products Overview',
        url: Routes.PRODUCTS,
        icon: <ShoppingCartIcon />,
        isBeta: true,
        isDevOnly: true,
        paywallMessage: 'Upgrade to Pro to Access Products Overview',
      },
    ],
  },
  {
    label: 'Optimize',
    icon: <DisplaySettingsIcon />,
    children: [
      {
        activeRouteIds: [Routes.OPTIMIZER],
        label: 'Campaign Optimizer',
        url: Routes.OPTIMIZER,
        icon: <DisplaySettingsIcon />,
      },
      {
        activeRouteIds: [Routes.CAMPAIGN_GROUPS],
        label: 'Optimization Groups',
        url: Routes.CAMPAIGN_GROUPS,
        icon: <AutoAwesomeMotionIcon />,
      },
      {
        activeRouteIds: [Routes.HISTORY],
        label: 'Time Machine',
        url: Routes.HISTORY,
        icon: <HistoryRoundedIcon />,
      },
    ],
  },
  {
    label: 'Search Terms & Targeting',
    icon: <ChecklistRoundedIcon />,
    children: [
      {
        activeRouteIds: [Routes.SEARCH_TERMS],
        label: 'Search Terms',
        url: Routes.SEARCH_TERMS,
        icon: <ManageSearchIcon />,
      },
      {
        activeRouteIds: [Routes.TARGETING],
        label: 'Targeting',
        url: Routes.TARGETING,
        icon: <ModeStandbyIcon />,
        paywallMessage: 'Upgrade to Pro to View & Edit All Targets',
        paywallVideoUrl: VideoUrl.TARGETING,
      },
      {
        activeRouteIds: [Routes.NEGATIVE_TARGETING],
        label: 'Negative Targeting',
        url: Routes.NEGATIVE_TARGETING,
        icon: <PlaylistRemoveIcon />,
        isBeta: true,
      },
      {
        activeRouteIds: [Routes.CAMPAIGN_MAPPING],
        label: 'KW Harvesting Map',
        url: Routes.CAMPAIGN_MAPPING,
        icon: <SyncAltRoundedIcon />,
        isBeta: true,
        paywallMessage: 'Upgrade to Pro to Access Campaign Mapping',
      },
    ],
  },
  {
    label: 'Analyze',
    icon: <ManageSearchIcon />,
    children: [
      {
        activeRouteIds: [Routes.ADVERTISED_PRODUCTS],
        label: 'Advertised Products',
        url: Routes.ADVERTISED_PRODUCTS,
        icon: <ShoppingCartIcon />,
        isBeta: true,
        isDevOnly: true,
        paywallMessage: 'Upgrade to Pro to Access Advertised Products',
      },
      {
        activeRouteIds: [Routes.PLACEMENTS],
        label: 'Placements',
        url: Routes.PLACEMENTS,
        icon: <ManageSearchIcon />,
        paywallMessage: 'Upgrade to Pro to Analyze Campaign Placement Performance',
        paywallVideoUrl: VideoUrl.PLACEMENTS,
      },

      {
        activeRouteIds: [Routes.DATA_GROUPS],
        label: 'Data Groups',
        url: Routes.DATA_GROUPS,
        icon: <AccountTreeIcon />,
      },
      {
        activeRouteIds: [Routes.LOGS],
        label: 'Logs',
        url: Routes.LOGS,
        icon: <TocIcon />,
        isBeta: true,
      },
    ],
  },
];
