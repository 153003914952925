import { FunctionComponent, useState } from 'react';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';
import { LoadingButton } from '@mui/lab';
import { useUserContext, userService } from '@/modules/users';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash-es';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@/router/router-paths';

export const MIN_AVG_SPEND_LENGTH = 1;

const RegistrationConnectAmazonAccountPage: FunctionComponent = () => {
  const { teamId: registrationTeamID, userId: registrationUserID } = useRegistrationContext();
  const { user } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const userId = user ? user.id : registrationUserID;
  const teamId = activeTeam ? activeTeam.id : registrationTeamID;

  async function getAuthUrl(): Promise<string> {
    if (userId && teamId) {
      const res = await userService.getAmazonAuthorizationUrl(userId, teamId, true);

      if (res.isSuccess) {
        return res.payload;
      } else {
        toast.error('Unable to get auth url. Please try again later.');
      }
    } else {
      toast.error('User or active team not set.');
    }

    return '';
  }

  const [isGetAuthUrlLoading, setIsGetAuthUrlLoading] = useState(false);
  async function onConnectAmazonClicked() {
    setIsGetAuthUrlLoading(true);
    const authUrl = await getAuthUrl();
    setIsGetAuthUrlLoading(false);
    if (isEmpty(authUrl)) {
      toast.error('Something went wrong. Auth url is empty. Please try again later.');
    } else {
      window.location.replace(authUrl); // user will not be able to use browser's back button
    }
  }

  const navigate = useNavigate();
  
  function onContinueClicked() {
    try {
      navigate(Routes.GETTING_STARTED);
      return;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center pt-10">
      <RegistrationWizardPageTitle>We can't wait to onboard you to AdLabs!</RegistrationWizardPageTitle>
      <div className="mt-4 text-md max-w-xl text-center text-xl text-slate-600">While not required, we've observed that users who utilized their own profiles during the onboarding call have been able to extract significantly more value from AdLabs.</div>
      <div className="mt-4 items-center">
            <img src="/src/assets/connect_amazon.jpg" className="w-96" alt="Connect" />
          </div>
        <LoadingButton size="large" className="mt-4 min-w-32" variant="contained" onClick={onConnectAmazonClicked} loading={isGetAuthUrlLoading}>
          Connect Amazon Account and take me to AdLabs 
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          size="large"
          color="primary"
          className="mt-4 min-w-96"
          onClick={onContinueClicked}
          
        >
        I don't care, just take me to AdLabs
        </LoadingButton>
      </div>
    </RegistrationWizardPage>
  );
};

export default RegistrationConnectAmazonAccountPage;
