import { ProfileDTO } from '@/modules/profiles/api/profile.contracts';
import { AmazonAccountDTO } from '@/modules/users/api/users/users.contracts';

export enum RoleKey {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export interface AddProfilesToTeamDTO {
  profile_ids: string[];
}

export interface RemoveProfilesFromTeamDTO {
  profile_ids: string[];
}

export interface AddUserToTeamDTO {
  email: string;
}
export interface RemoveUsersFromTeamDTO {
  user_ids: number[];
}

export interface CreateTeamDTO {
  name: string;
}

export interface UpdateTeamDTO {
  name?: string;
  preferred_update_time?: string;
}

export interface TeamMemberDTO {
  user: {
    id: number;
    name: string;
    email: string;
  };
  role: RoleKey;
}

export interface TeamDTO {
  id: number;
  name: string;
  amazon_account: AmazonAccountDTO;
  preferred_update_time: string;
  preferred_last_done: string;
  profiles: ProfileDTO[];
  members: TeamMemberDTO[];

  // Billing related fields
  stripe_subscription_status: string;
  stripe_subscription_cancel_at_period_end: boolean;
  stripe_subscription_current_period_end: string;
  stripe_subscription_trial_end: string | null;
  stripe_subscription_default_payment_method: string | null;
  is_on_legacy_profiles_based_pro_plan: boolean;
  adlabs_plan: string;
  adlabs_plan_price_lookup_key: string;
  current_valid_plan: string;
  is_customer: boolean;

  // Team stats
  spend: number;
  estimated_cost: number;
}
