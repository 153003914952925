import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { filterFactory } from '@/components/filter-builder/models/FilterFactory';
import CampaignFilterListEditor from '@/modules/campaigns/components/CampaignFilterListEditor';
import TargetingFilterListEditor from '@/modules/campaigns/components/TargetingFilterListEditor';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useDashboardQueries } from '@/modules/dashboards/hooks/useDashboardQueries';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupBySelect, { TableWidgetGroupByOptionSelectOption } from '../../forms/GroupBySelect';
import ColumnVisibilitySelect from './ColumnVisibilitySelect';
import { ColumnConfiguration, TableWidgetConfiguration } from './TableWidgetConfiguration';

interface TableWidgetConfigurationFormProps {
  configuration: TableWidgetConfiguration;
  id: string;
}

const TableWidgetConfigurationForm: FunctionComponent<TableWidgetConfigurationFormProps> = ({ configuration, id }) => {
  // const [title, setTitle] = useState(configuration.title);
  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);
  const { refetchForWidgetWithId } = useDashboardQueries();

  // // Debounce update function
  // const debouncedUpdateConfiguration = debounce((title) => {
  //   updateWidgetConfiguration<TableWidgetConfiguration>(id, {
  //     title: title,
  //   });
  // }, 1500);

  // useEffect(() => {
  //   // Update global state when title changes
  //   debouncedUpdateConfiguration(title);
  //   // Cleanup on component unmount
  //   return () => debouncedUpdateConfiguration.flush();
  // }, [title]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateWidgetConfiguration<TableWidgetConfiguration>(id, {
      title: e.currentTarget.value,
    });
  };

  const handleEntityTypeChange = async (entityType: '' | EntityType) => {
    if (entityType === '') return;
    await updateWidgetConfiguration<TableWidgetConfiguration>(
      id,
      {
        entityType: entityType,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleCampaignGroupByChange = async (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    await updateWidgetConfiguration<TableWidgetConfiguration>(
      id,
      {
        campaignGroupByValue: groupByOption.value,
        campaignGroupByCategory: groupByOption.category,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleTargetsGroupByChange = async (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    await updateWidgetConfiguration<TableWidgetConfiguration>(
      id,
      {
        targetsGroupByValue: groupByOption.value,
        targetsGroupByCategory: groupByOption.category,
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const handleMetricColumnsChange = async (metricColumnSettings: ColumnConfiguration[]) => {
    await updateWidgetConfiguration<TableWidgetConfiguration>(id, {
      metricColumns: metricColumnSettings,
    });
  };

  const onCampaignFiltersChanged = async (newFilters: AlFilterModel[]) => {
    await updateWidgetConfiguration<TableWidgetConfiguration>(
      id,
      {
        campaignFilters: newFilters.map((filter) => filter.toDTO()),
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  const onTargetingFiltersChanged = async (newFilters: AlFilterModel[]) => {
    await updateWidgetConfiguration<TableWidgetConfiguration>(
      id,
      {
        targetsFilters: newFilters.map((filter) => filter.toDTO()),
      },
      true,
    );

    refetchForWidgetWithId(id);
  };

  return (
    <>
      <TextField label="Name" value={configuration.title} onChange={handleTitleChange} />

      <EntityTypeSelect
        value={configuration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
      />

      {configuration.entityType === EntityType.CAMPAIGN && (
        <GroupBySelect
          value={configuration.campaignGroupByValue}
          onChange={handleCampaignGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {configuration.entityType === EntityType.KEYWORD && (
        <GroupBySelect value={configuration.targetsGroupByValue} onChange={handleTargetsGroupByChange} dataGroupType={DataGroupType.TARGET} />
      )}
      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption"> Select the metrics to display</Typography>
        <ColumnVisibilitySelect value={configuration.metricColumns ?? []} onChange={handleMetricColumnsChange} />
      </div>
      {/* {configuration.entityType === TableWidgetEntityType.CAMPAIGN && configuration.campaignGroupBy === TableWidgetGroupByOption.AD_TYPE && (
        <AlSelect
          label="Ad Type"
          value={configuration.campaignAdType}
          options={adTypeSelectOptions}
          renderOption={(v) => v.label}
          valueExtractor={(v) => v.value}
          helperText={'Select an Ad Type to filter on.'}
          onChange={(adType) => {
            if (adType.target.value === '') return;
            updateWidget<TableWidgetConfiguration>(id, {
              configuration: {
                title: configuration.title,
                entityType: configuration.entityType,
                campaignGroupBy: configuration.campaignGroupBy,
                targetsGroupBy: configuration.targetsGroupBy,
                campaignAdType: adType.target.value as AdType,
              },
            });
          }}
        /> 
      )}*/}

      {(configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE) && (
        <>
          <div className="mt-4">
            <Typography variant="body1">Filters</Typography>
          </div>

          <CampaignFilterListEditor
            teamProfiles={dashboardProfiles ?? []}
            filters={
              configuration.campaignFilters
                ? configuration.campaignFilters.map((filter) =>
                    filterFactory.createFilterModelFromDTO(filter, {
                      teamProfiles: dashboardProfiles,
                    }),
                  )
                : []
            }
            onFiltersChange={onCampaignFiltersChanged}
          />
        </>
      )}

      {configuration.entityType === EntityType.KEYWORD && (
        <>
          <div className="mt-4">
            <Typography variant="body1">Filters</Typography>
          </div>

          <TargetingFilterListEditor
            teamProfiles={dashboardProfiles ?? []}
            filters={
              configuration.targetsFilters
                ? configuration.targetsFilters.map((filter) =>
                    filterFactory.createFilterModelFromDTO(filter, {
                      teamProfiles: dashboardProfiles,
                    }),
                  )
                : []
            }
            onFiltersChange={onTargetingFiltersChanged}
          />
        </>
      )}
    </>
  );
};

export default TableWidgetConfigurationForm;
