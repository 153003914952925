import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { OptimizerJobDTO } from '@/modules/profiles/api/profile.contracts';
import { OptimizerJobModel } from '@/modules/profiles/types/OptimizerJobModel';
import { AlFilterModel, ComparisonDateFilterModel, DateFilterModel } from '../../../../../components/filter-builder/models/AlFilterModel';
import { OptimizationApplyData, OptimizationParams } from '../OptimizerConfig';
import { OptimizationModel } from '../models/OptimizationModel';
import {
  EnabledKeywordGroups,
  OptimizationApplyDTO,
  OptimizationRequestDTO,
  OptimizationResponse,
  OptimizationUpdates,
  OptimizedBiddingEntityDTO,
  RevertJobDTO,
} from './optimization-contracts';

export class OptimizationService {
  public basePath = 'optimizer';
  private OPTIMIZATION_QUERY_KEY_BASE = 'optimization';

  async getOptimizedResults(
    selectedCampaigns: string[],
    filters: AlFilterModel[],
    optimizationParams: OptimizationParams,
  ): Promise<ApiResponse<OptimizationModel>> {
    try {
      const datesFilter = filters.find((f) => f instanceof DateFilterModel);
      const compareDatesFilter = filters.find((f) => f instanceof ComparisonDateFilterModel);

      if (!datesFilter || !compareDatesFilter) {
        return ApiResponse.UnknownErrorResponse();
      }

      const keywordGroupMapping: Record<string, EnabledKeywordGroups> = {
        highAcos: EnabledKeywordGroups.HIGH_ACOS,
        highSpend: EnabledKeywordGroups.HIGH_SPEND_NO_SALES,
        lowAcos: EnabledKeywordGroups.LOW_ACOS,
        lowVisibility: EnabledKeywordGroups.LOW_VISIBILITY,
      };

      const enabledKeywordGroups: EnabledKeywordGroups[] = Object.entries(keywordGroupMapping)
        .filter(([key]) => optimizationParams[key as keyof typeof optimizationParams])
        .map(([, value]) => value);

      const requestData: OptimizationRequestDTO = {
        dates: {
          start_date: (datesFilter.conditions ? datesFilter.conditions[0].values[0] : null) as string,
          end_date: (datesFilter.conditions ? datesFilter.conditions[1].values[0] : null) as string,
        },
        compare_dates: {
          start_date: (compareDatesFilter.conditions ? compareDatesFilter.conditions[0].values[0] : null) as string,
          end_date: (compareDatesFilter.conditions ? compareDatesFilter.conditions[1].values[0] : null) as string,
        },
        campaign_ids: selectedCampaigns,
        tacos: optimizationParams.tacos / 100,
        preset: optimizationParams.selectedPreset,
        advanced: {
          bid_ceiling: Number(optimizationParams.bidCeiling),
          smart_bid_ceiling_disabled: optimizationParams.smartBidCeilingDisabled,
          enabled_keyword_groups: enabledKeywordGroups,
          exclude_no_impressions: !optimizationParams.showZeroImpressions,
          override_group_settings: !optimizationParams.useGroupSettings,
          skip_placement_optimization: !optimizationParams.usePlacementOptimization,
          no_limit_placement_change: !optimizationParams.limitPlacementChange,
        },
      };

      const applicationResponse = await apiProfileClient.post<OptimizationResponse>(`${this.basePath}/preview`, requestData);
      return applicationResponse.processPayload(OptimizationModel.fromResponse);
    } catch (error) {
      console.log(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async applyOptimization(
    jobId: number,
    optimizationPreviewSelection: OptimizationApplyData[],
  ): Promise<ApiResponse<OptimizedBiddingEntityDTO[]>> {
    const convertDataToRequest = (dataArray: OptimizationApplyData[]): OptimizationUpdates[] => {
      return dataArray.map((dataItem) => {
        const { id, ad_type, bidding_entity, match_type, new_value, old_value, reasons } = dataItem;

        const requestItem: OptimizationUpdates = {
          id,
          ad_type,
          bidding_entity,
          match_type,
          new_value,
          old_value,
          reasons,
        };

        return requestItem;
      });
    };

    const updates: OptimizationUpdates[] = convertDataToRequest(optimizationPreviewSelection);

    const requestData: OptimizationApplyDTO = {
      job_id: jobId,
      updates,
    };

    try {
      const applicationResponse = await apiProfileClient.post<OptimizedBiddingEntityDTO[]>(`${this.basePath}/apply`, requestData);
      return applicationResponse;
    } catch (error) {
      console.log(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async revertOptimizerJob(jobID: number): Promise<ApiResponse<OptimizedBiddingEntityDTO[]>> {
    const revertJobDTO: RevertJobDTO = {
      job_id: jobID,
    };

    return await apiProfileClient.post<OptimizedBiddingEntityDTO[]>(`/optimizer/revert`, revertJobDTO);
  }

  async getOptimizerJobs(): Promise<ApiResponse<OptimizerJobModel[]>> {
    const applicationResponse = await apiProfileClient.get<OptimizerJobDTO[]>(`/optimizer/jobs`);

    return applicationResponse.processPayload((payload) => {
      return payload.map(OptimizerJobModel.fromDTO);
    });
  }

  createGetLogsQueryKey(activeProfileId: string | undefined, jobId: number | undefined) {
    return [this.OPTIMIZATION_QUERY_KEY_BASE, activeProfileId, jobId];
  }
  async getLogs(jobId: number): Promise<ApiResponse<object[]>> {
    const applicationResponse = await apiProfileClient.get<object[]>(`${this.basePath}/logs/${jobId}`);
    return applicationResponse;
  }
}

export const optimizationService = new OptimizationService();
