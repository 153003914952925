import { DASHBOARDS_TRANSLATIONS_ENGLISH } from '@/modules/dashboards/translations/en';
import { DATA_MANAGEMENT_TRANSLATIONS_ENGLISH } from '@/modules/data-management/translations/en';
import { optimizerTranslationsEnglish } from '@/modules/optimizer/translations/en';
import { ResourceLanguage } from 'i18next';

export const enTranslations: ResourceLanguage = {
  translation: {
    campaign_data_group: 'Campaign Data Group',
    product_data_group: 'Product Data Group',
    target_data_group: 'Target Data Group',
    searchterm_data_group: 'Search Term Data Group',
    ...optimizerTranslationsEnglish,
    ...DASHBOARDS_TRANSLATIONS_ENGLISH,
    ...DATA_MANAGEMENT_TRANSLATIONS_ENGLISH,
  },
};
