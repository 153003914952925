import { ToggleButton, ToggleButtonGroup, Tooltip, styled, toggleButtonClasses, toggleButtonGroupClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { blue, red, slate } from 'tailwindcss/colors';

export interface MultipleChoiceCellRendererOption<T extends Record<string, any>> {
  id: keyof T;
  label: string;
  selected: boolean;
  tooltip?: string;
  isError?: boolean;
}

export interface MultipleChoiceUpdatedValue<T extends Record<string, any>> {
  id: keyof T;
  selected: boolean;
}

export interface IMultipleChoiceCellRendererParams<T extends Record<string, any>> {
  options: MultipleChoiceCellRendererOption<T>[];
  updateValues: (values: MultipleChoiceUpdatedValue<T>[]) => void;
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '35px', // Set width to your desired square size
  height: '35px', // Set height equal to width to form a square
  border: 0,
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0.5),
  backgroundColor: slate[300],
  '&:hover': {
    backgroundColor: blue[200],
  },
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: blue[300], // Background color for selected state
    color: blue[800], // Text color for selected state
    '&:hover': {
      backgroundColor: blue[400],
    },
  },
}));

const ErrorStyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '35px', // Set width to your desired square size
  height: '35px', // Set height equal to width to form a square
  border: 0,
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0.5),
  backgroundColor: red[100],
  '&:hover': {
    backgroundColor: red[200],
  },
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: red[400], // Background color for selected state
    color: red[900], // Text color for selected state
    '&:hover': {
      backgroundColor: red[500],
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: 'none',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(4),
    border: 0,
    width: '35px', // Set width to your desired square size
    height: '35px', // Set height equal to width to form a square
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
}));

const MultipleChoiceCellRenderer = <T extends Record<string, any>>({ options, updateValues }: IMultipleChoiceCellRendererParams<T>) => {
  const [localOptions, setLocalOptions] = useState<MultipleChoiceCellRendererOption<T>[]>(options);

  const handleValueChange = (event: React.MouseEvent<HTMLElement>, newValues: string[]) => {
    const updatedValues: MultipleChoiceUpdatedValue<T>[] = options.map((option) => {
      return {
        id: option.id,
        selected: newValues.includes(option.id as string),
      };
    });

    updateValues(updatedValues);
  };

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  return (
    <div className="h-full flex items-center justify-center">
      <StyledToggleButtonGroup
        value={localOptions.filter((option) => option.selected === true).map((option) => option.id)}
        onChange={handleValueChange}
        aria-label="text formatting"
        size="large"
        className=""
      >
        {options.map((option) => (
          <Tooltip title={option.tooltip} key={option.id.toString()}>
            <span>
              {option.isError ? (
                <ErrorStyledToggleButton value={option.id} aria-label={option.label}>
                  {option.label}
                </ErrorStyledToggleButton>
              ) : (
                <StyledToggleButton value={option.id} aria-label={option.label}>
                  {option.label}
                </StyledToggleButton>
              )}
            </span>
          </Tooltip>
        ))}
      </StyledToggleButtonGroup>
    </div>
  );
};

export default MultipleChoiceCellRenderer;
