import { FunctionComponent, useState } from 'react';
import { Radio, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { MetricField } from './models/CommonMetricsModel';
import { METRICS } from './MetricsConfig';

interface MetricsRadioListProps {
  value: MetricField;
  onChange: (selectedMetric: MetricField) => void;
}

const MetricsRadioList: FunctionComponent<MetricsRadioListProps> = ({ value, onChange }) => {
  const [showHelperText, setShowHelperText] = useState(false);

  const handleRadioChange = (metric: MetricField) => {
    setShowHelperText(false);
    onChange(metric);
  };

  return (
    <FormGroup>
      <div className="grid grid-cols-2">
        {Object.values(METRICS).map((metric) => (
          <FormControlLabel
            key={metric.key}
            control={<Radio checked={value === metric.key} onChange={() => handleRadioChange(metric.key)} />}
            label={metric.title}
          />
        ))}
      </div>
      {showHelperText && <FormHelperText error>Error message if needed</FormHelperText>}
    </FormGroup>
  );
};

export default MetricsRadioList;
