import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImportType } from '../../api/importer.contracts';
import { FileUploader } from '../../components/FileUploader';
import { useSheets } from '../../hooks/useSheets';
import { CAMPAIGN_MAPPING_IMPORT_ROW_KEYS } from './CampaignMappingImportRow';

export const CampaignMappingImportPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const { getFirstSheetData } = useSheets();

  function onFileChanged(file: File | null) {
    setFile(file);
  }

  async function onPreviewCampaignMappingsClicked() {
    if (!file) return;

    const parsedData = await getFirstSheetData(file, CAMPAIGN_MAPPING_IMPORT_ROW_KEYS);
    navigate(`/configuration/data-management/import/${ImportType.CAMPAIGN_MAPPING}/preview`, {
      state: { uploadedFile: file, uploadedFileParsed: parsedData },
    });
  }

  return (
    <PageLayout>
      <PageLayoutTopBar
        header="Campaign Mapping Import"
        actions={
          <div className="flex flex-row gap-2">
            <TeamSelect />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="flex w-full flex-1 flex-col items-center justify-center ">
          <FileUploader file={file} onFileChanged={onFileChanged} />
          <div className="mt-12">{file && <Button onClick={onPreviewCampaignMappingsClicked}>Preview Campaign Mappings</Button>}</div>
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};
