import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { useTheming } from '@/config/theme/useTheming';
import { ColorModeContext } from '@/modules/application';
import { Box, Paper, Slide, ThemeProvider } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useContext, useRef } from 'react';

interface OppositeModeOverlayBarProps extends PropsWithChildren {
  visible?: boolean;
  marginBottom?: number | string;
}

const OppositeModeOverlayBar: FunctionComponent<OppositeModeOverlayBarProps> = ({ children, visible = true, marginBottom = '28px' }) => {
  // Global mode
  const { mode } = useContext(ColorModeContext);

  const { colorMode } = useTheming();

  const containerDivRef = useRef<HTMLDivElement>(null);

  return (
    <ColorModeContext.Provider
      value={{ toggleColorMode: colorMode.toggleColorMode, mode: mode === 'light' ? 'dark' : 'light', isDarkMode: mode === 'light' }}
    >
      <ThemeProvider theme={mode === 'light' ? DARK_THEME : BASE_THEME}>
        <Slide direction="up" in={visible} mountOnEnter unmountOnExit>
          <Box className={`fixed flex justify-center w-full z-3 pointer-events-none pr-[64px] z-[50]`} sx={{ bottom: marginBottom }}>
            <Paper
              className="rounded-xl mx-auto p-2 flex justify-between items-center z-3 pointer-events-auto"
              ref={containerDivRef}
              elevation={3}
            >
              {children}
            </Paper>
          </Box>
        </Slide>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default OppositeModeOverlayBar;
