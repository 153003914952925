import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import useFormatting from '@/hooks/useFormatting';
import BulkEditCampaignGroupSelectionPopover from '@/modules/optimizer/components/campaign-groups/BulkEditCampaignGroupSelectionPopover';
import { useCampaignGroupsContext } from '@/modules/optimizer/contexts/CampaignGroupsContext';
import { invalidateProfile_placementsWithTimelineQueryKeys } from '@/modules/placements/api/placements-service';
import { invalidateProfile_searchTermsWithTimelineQueryKeys } from '@/modules/search-terms/api/search-terms-service';
import { invalidateProfile_targetingWithTimelineQueryKeys } from '@/modules/targeting/api/targets-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Delete } from '@mui/icons-material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { QueryObserverResult, RefetchOptions, useQueryClient } from '@tanstack/react-query';
import { FunctionComponent, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import OppositeModeOverlayBar from '../../../../components/OppositeModeOverlayBar';
import { CampaignGroupsWithMetrics } from '../../api/campaign/campaign-contracts';
import { campaignService, invalidateProfile_campaignGroupsQueryKey } from '../../api/campaign/campaign-service';

interface CampaignGroupsSelectionActionsBarProps {
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<CampaignGroupsWithMetrics | undefined, Error>>;
}

const CampaignGroupsSelectionActionsBar: FunctionComponent<CampaignGroupsSelectionActionsBarProps> = ({ refetch }) => {
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  const { selectedCampaignGroups, setSelectedCampaignGroups, totalCampaignGroupsCount, gridApiRef } = useCampaignGroupsContext();

  const onDeleteSelectionClicked = () => {
    openDeleteConfirmationModal();
  };

  const onDeselectAll = () => {
    setSelectedCampaignGroups([]);
    gridApiRef.current?.deselectAll();
  };

  const onSelectAll = () => {
    gridApiRef.current?.selectAll();
  };

  // BULK EDIT
  const handleEditSelectionPopoverApplySucceeded = () => {
    toast.success('Optimization group(s) updated successfully');
    invalidateProfile_campaignGroupsQueryKey(queryClient, activeProfile?.id);

    onDeselectAll();
  };

  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isBulkEditPopoverOpen, setIsBulkEditPopoverOpen] = useState(false);

  // DELETE GROUP
  const onDeleteConfirmed = async () => {
    const response = await campaignService.deleteGroups(selectedCampaignGroups.map((group) => group.id));

    if (response.isSuccess === false) {
      toast.error(response.message);
      return;
    }

    refetch();

    toast.success(`Campaign group deleted successfully`);

    // Delete local data used in campaign table so when they're needed, they're refetched again
    invalidateProfile_campaignGroupsQueryKey(queryClient, activeProfile?.id);

    invalidateProfile_placementsWithTimelineQueryKeys(queryClient, activeProfile?.id);

    invalidateProfile_searchTermsWithTimelineQueryKeys(queryClient, activeProfile?.id);

    invalidateProfile_targetingWithTimelineQueryKeys(queryClient, activeProfile?.id);
  };

  const deleteConfirmationHeaderText = `Delete ${selectedCampaignGroups.length} ${selectedCampaignGroups.length === 1 ? 'group' : 'groups'}?`;
  const deleteConfirmationQuestion =
    selectedCampaignGroups.length === 1
      ? 'Campaigns in the selected Group will have their Group unassigned.'
      : 'Campaigns in the selected Groups will have their Group unassigned.';

  const { ModalComponent: DeleteConfirmationModal, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    questionText: deleteConfirmationQuestion,
    headerText: deleteConfirmationHeaderText,
    onDelete: onDeleteConfirmed,
  });

  return (
    <>
      <OppositeModeOverlayBar visible={selectedCampaignGroups.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          {/* TODO: move deselect section into own component everywhere */}
          <Tooltip title="Deselect all">
            <div onClick={onDeselectAll} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedCampaignGroups.length)} of {formatWithThousandsSeparator(totalCampaignGroupsCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            <Button
              ref={bulkEditButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={() => setIsBulkEditPopoverOpen(true)}
              variant={isBulkEditPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AssignmentOutlinedIcon />}
            >
              Bulk Actions
            </Button>

            <Button onClick={onDeleteSelectionClicked} variant="outlined" color="error" startIcon={<Delete />}>
              Delete Group{selectedCampaignGroups.length > 1 ? 's' : ''}
            </Button>

            <Divider className="my-2" orientation="vertical" flexItem />

            <Button
              className="whitespace-nowrap"
              onClick={selectedCampaignGroups.length == totalCampaignGroupsCount ? onDeselectAll : onSelectAll}
              variant="text"
            >
              {selectedCampaignGroups.length == totalCampaignGroupsCount ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <BulkEditCampaignGroupSelectionPopover
        buttonRef={bulkEditButtonRef}
        selectedItems={selectedCampaignGroups}
        isOpen={isBulkEditPopoverOpen}
        onClose={() => setIsBulkEditPopoverOpen(false)}
        onApplySucceeded={handleEditSelectionPopoverApplySucceeded}
      />
      {DeleteConfirmationModal}
    </>
  );
};

export default CampaignGroupsSelectionActionsBar;
