import { Edit } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';

export interface IEditableCellRendererParams {
  isEditable: boolean;
  tooltip?: string;
  hideTooltip?: boolean;
  isChanged?: boolean; // highlight the pencil icon to indicate the initial value has changed
  extraTextClass?: string;
  extraContainerClass?: string;
}

interface IEditableCellInternalRendererParams extends IEditableCellRendererParams, ICellRendererParams {}

const EditableCellRenderer: FunctionComponent<IEditableCellInternalRendererParams> = ({
  value,
  valueFormatted,
  isEditable = true,
  tooltip = 'Edit',
  hideTooltip,
  isChanged = false,
  api,
  node,
  column,
  extraTextClass = '',
  extraContainerClass = '',
}) => {
  const handleEditIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent the grid from processing the click
    const colKey = column?.getId();

    if (isEditable && api && !isNil(node.rowIndex) && colKey) {
      api.startEditingCell({
        rowIndex: node.rowIndex,
        colKey,
      });
    }
  };

  return (
    <Tooltip title={hideTooltip ? null : tooltip}>
      <div className={`flex relative w-full ${isEditable ? 'cursor-text group' : ''} ${extraContainerClass}`}>
        <div className={`flex-1 ${isChanged ? 'text-primary-600' : ''} ${extraTextClass}`}>{valueFormatted ?? value}</div>
        {isEditable && (
          <div
            className={`ml-1 mt-0.5 cursor-pointer ${isChanged ? 'opacity-100' : 'opacity-20 group-hover:opacity-100 transition-opacity'}`}
            onClick={handleEditIconClick}
          >
            <Edit fontSize="inherit" />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default EditableCellRenderer;
