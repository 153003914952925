import { Dialog, DialogTitle, IconButton, DialogContent, Box, TextField, DialogActions } from '@mui/material';
import { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { MAX_TEAM_NAME_LENGTH } from '../types/TeamModel';
import { Environment } from '@/config/Environment';
import { teamService } from '../api/teams.service';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useUserContext } from '@/modules/users';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@/router/router-paths';

interface CreateNewTeamDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  onError: () => void;
}

export const CreateNewTeamDialog: FunctionComponent<CreateNewTeamDialogProps> = ({ isOpen, setIsOpen, onSuccess, onError }) => {
  const navigate = useNavigate();
  const { user, refetchUser } = useUserContext();
  const { setActiveTeam } = useActiveTeamContext();

  const [teamName, setTeamName] = useState<string>('');
  function onClose() {
    setIsOpen(false);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTeamName(event.target.value);
  };

  function handleSubmit() {
    if (teamName.length > MAX_TEAM_NAME_LENGTH) {
      toast.error(`Team name must be less than ${MAX_TEAM_NAME_LENGTH} characters`);
    } else if (teamName.length === 0) {
      toast.error('Team name cannot be empty');
    } else {
      createTeam(teamName);
    }
  }

  const { mutate: createTeam, isPending: isLoading } = useMutation({
    mutationFn: (newTeamName: string) => teamService.create(user?.id ?? 0, newTeamName),
    onMutate: () => {
      if (Environment.isDev()) {
        console.log('Creating new team');
      }
    },
    onSuccess: (response) => {
      if (response.isSuccess) {
        setTeamName('');
        onClose();
        onSuccess();
        toast.success(`Team created successfully`);
        refetchUser().then((res) => {
          // To avoid "Active team was not set. Team not found" error caused by the fact that the new team is not yet in user teams (race condition).
          // We need to pass user teams to set active teams as well.
          setActiveTeam({ teamId: response.payload, userTeams: res.data?.teams });
          navigate(Routes.PROFILES);
        });
      } else {
        onError();
        toast.error(`Team creation failed: ${response.message}`);
      }
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>
        <div className="flex flex-row justify-between">
          <div>Create New Team</div>

          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <Box className="flex flex-col gap-2" onSubmit={handleSubmit}>
          <TextField
            disabled={isLoading}
            margin="normal"
            size="small"
            id="active-team-name"
            label="Team Name"
            value={teamName}
            onChange={handleChange}
            inputProps={{
              maxLength: MAX_TEAM_NAME_LENGTH,
            }}
            helperText={`Maximum ${MAX_TEAM_NAME_LENGTH} characters`}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant="contained" size="small" type="submit" loading={isLoading} className="-mt-4" onClick={handleSubmit}>
          Create team
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
