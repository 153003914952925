import { SettingValue } from '@/modules/settings/api/settings/setting.contracts';
import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import { UserSettingValue } from '@/modules/users/api/user-settings/user-setting.contracts';
import { debounce } from 'lodash-es';
import { useState } from 'react';

export function useUserSetting<T = SettingValue>(userSettingKey: UserSettingKey, initialValue?: T) {
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();

  const [settingState, setSettingState] = useState<T | undefined>(() => {
    return getUserSettingValueByKey<T>(userSettingKey) ?? initialValue;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  function handleSettingStateChange(newValue: T) {
    setSettingState(newValue);
    debouncedSaveSettingState(newValue);
  }

  const debouncedSaveSettingState = debounce((newValue: T) => {
    saveSettingState(newValue);
  }, 500);

  async function saveSettingState(newValue: T) {
    setIsLoading(true);

    const success = await upsertUserSetting(userSettingKey, newValue as UserSettingValue);
    setIsError(!success);
    setIsLoading(false);
  }

  return { settingState, isLoading, isError, handleSettingStateChange };
}
