import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { optimizationService } from '../components/optimization/api/optimization-service';
import { useTranslation } from '@/lib/i18n/useTranslate';

interface useOptimizationLogFetchProps {
  jobId?: number | undefined;
  isAutoFetchEnabled?: boolean;
}

const useOptimizationLogFetch = ({ jobId, isAutoFetchEnabled = true }: useOptimizationLogFetchProps) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();
  const queryKey = useMemo(() => optimizationService.createGetLogsQueryKey(activeProfile?.id, jobId), [activeProfile?.id, jobId]);

  const queryClient = useQueryClient();

  // Common query function for both single fetch and continuous fetch
  const fetchData = useCallback(async (jobId: number) => {
    const result = await optimizationService.getLogs(jobId);
    if (result.isSuccess) {
      return result.payload;
    } else {
      toast.error(`Error loading logs ${result.message}`);
    }
  }, []);

  // Single fetch (downloading)
  const fetchLogOnDemand = useCallback(
    async (jobId: number | undefined): Promise<object[] | undefined> => {
      if (isNil(jobId)) {
        toast.error('Action ID is undefined');
        console.error('jobId is undefined');
        return [];
      }
      const queryKey = optimizationService.createGetLogsQueryKey(activeProfile?.id, jobId);

      // Check if data is in the cache
      let data: object[] | undefined = queryClient.getQueryData(queryKey);

      if (!data) {
        // If not in cache, fetch it and update the cache
        try {
          data = await queryClient.fetchQuery({ queryKey: queryKey, queryFn: () => fetchData(jobId) });
        } catch (error) {
          toast.error(`Error fetching logs: ${error}`);
        }
      }

      return data;
    },
    [activeProfile?.id, fetchData, queryClient],
  );

  function getHeaderNames(data: object[] | undefined): string[] {
    const headers: string[] = [];

    if (isNil(data)) {
      return headers;
    }

    const firstItem = data[0]; // Get the first item of the array
    if (isNil(firstItem)) {
      return headers;
    }

    const headerTranslationKey = 'profile_history_page.log_preview_table_header';
    Object.keys(firstItem).forEach((key) => {
      let headerName = t(headerTranslationKey + '.' + key);
      if (headerName.includes(headerTranslationKey)) {
        headerName = (key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, ' '); // fallback
      }

      headers.push(headerName);
    });

    return headers;
  }

  // Continuous fetch (table)
  const {
    data: rowData,
    isLoading: isLogLoading,
    isError: isLogLoadingError,
    error: logLoadingError,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      if (isNil(jobId)) {
        return undefined;
      }
      return await fetchData(jobId);
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile) && !isNil(jobId) && isAutoFetchEnabled,
  });

  return { rowData, isLogLoading, isLogLoadingError, logLoadingError, fetchLogOnDemand, getHeaderNames };
};

export default useOptimizationLogFetch;
