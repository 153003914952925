import { FunctionComponent, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { RegistrationProvider } from '../contexts/registration/RegistrationContext';
import { useRegistration } from '../contexts/registration/useRegistration';
import { AdLabsLogo } from '@/modules/application';
import RegistrationWizardSteps from '../components/RegistrationWizardSteps';

export const RegistrationLayout: FunctionComponent = () => {
  const registration = useRegistration();

  useEffect(() => {
    registration.loadStateFromStorage();
  }, []);

  return (
    <RegistrationProvider registration={registration}>
      <div className="flex h-full min-h-screen w-full">
        <div className="flex min-h-screen w-1/3 justify-end bg-slate-800 px-10">
          <div className="mt-10 flex w-96 flex-col">
            <div className="mb-16">
              <AdLabsLogo forceMode="dark" />
            </div>
            <div>
              <RegistrationWizardSteps />
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </RegistrationProvider>
  );
};
