import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { ProfileWithMetricsModel } from '../types/ProfileWithMetricsModel';
import { StartEndDate } from '../types/StartEndDate';
import {
  AdProductType,
  CreateReportsResponse,
  ProfileReportsDTO,
  ProfileReportsStatusDTO,
  ProfilesMetricsWithTeamDTO,
  ProfilesWithMetricsRequestDTO,
  UpdateReportType,
} from './profile.contracts';

export const QUERY_STATUS_REPORT_KEY = 'query_status_report';

class ProfileService {
  public basePath = 'profiles';

  async getReports(activeTeamId: number, profileId: string): Promise<ApiResponse<ProfileReportsDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<ProfileReportsDTO>(`teams/${activeTeamId}/profiles/${profileId}/reports`, {
      limit: 15,
    });
  }

  async getReportsStatus(activeTeamId: number, profileId: string): Promise<ApiResponse<ProfileReportsStatusDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<ProfileReportsStatusDTO>(`teams/${activeTeamId}/profiles/${profileId}/reports/status`);
  }

  async createProductsReports(profileId: string): Promise<CreateReportsResponse> {
    const [
      createTargetingReportResponse,
      createPlacementReportResponse,
      createSearchTermsReportResponse,
      createAdvertisedProductReportResponse,
    ] = await Promise.all([
      this.createReport(profileId, UpdateReportType.TARGETING, AdProductType.PRODUCTS),
      this.createReport(profileId, UpdateReportType.PLACEMENT, AdProductType.PRODUCTS),
      this.createReport(profileId, UpdateReportType.SEARCH_TERM, AdProductType.PRODUCTS),
      this.createReport(profileId, UpdateReportType.ADVERTISED_PRODUCT, AdProductType.PRODUCTS),
    ]);

    return {
      createTargetingReportResponse,
      createPlacementReportResponse,
      createSearchTermsReportResponse,
      createAdvertisedProductReportResponse,
    };
  }

  async createBrandsReports(profileId: string): Promise<CreateReportsResponse> {
    const [createTargetingReportResponse, createPlacementReportResponse, createSearchTermsReportResponse] = await Promise.all([
      this.createReport(profileId, UpdateReportType.TARGETING, AdProductType.BRANDS),
      this.createReport(profileId, UpdateReportType.PLACEMENT, AdProductType.BRANDS),
      this.createReport(profileId, UpdateReportType.SEARCH_TERM, AdProductType.BRANDS),
    ]);

    return {
      createTargetingReportResponse,
      createPlacementReportResponse,
      createSearchTermsReportResponse,
    };
  }

  async createDisplayReports(profileId: string): Promise<CreateReportsResponse> {
    const [createTargetingReportResponse, createAdvertisedProductReportResponse] = await Promise.all([
      this.createReport(profileId, UpdateReportType.TARGETING, AdProductType.DISPLAY),
      this.createReport(profileId, UpdateReportType.ADVERTISED_PRODUCT, AdProductType.DISPLAY),
    ]);

    return {
      createTargetingReportResponse,
      createAdvertisedProductReportResponse,
    };
  }

  async createReport(profileId: string, type: UpdateReportType, adProductType: AdProductType): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`${this.basePath}/${profileId}/reports/create?type=${type}&ad_product=${adProductType}`, {});
  }

  async restartInitialReports(profileId: string): Promise<ApiResponse<null>> {
    return await apiClient.post(`admin/reports/profiles/${profileId}/restart-initial-reports`, {});
  }

  async downloadAndProcessReport(profileId: string, reportId: string): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`${this.basePath}/${profileId}/reports/download?reportid=` + reportId, {});
  }

  async getUserProfilesWithMetrics({
    userId,
    dates,
    compareDates,
  }: {
    userId: number;
    dates: StartEndDate;
    compareDates: StartEndDate;
  }): Promise<ApiResponse<ProfileWithMetricsModel[]>> {
    const requestData: ProfilesWithMetricsRequestDTO = {
      dates: {
        start_date: dates.startDate,
        end_date: dates.endDate,
      },
      compare_dates: {
        start_date: compareDates.startDate,
        end_date: compareDates.endDate,
      },
    };

    // Response is an incomplete Profile model (lacking for example timezone)
    const applicationResponse = await apiClient.post<ProfilesMetricsWithTeamDTO[]>(`/users/${userId}/metrics-by-profile`, requestData);
    return applicationResponse.processPayload(ProfileWithMetricsModel.fromUserProfilesWithMetricsResponse);
  }
}

export const profileService = new ProfileService();
