import { FunctionComponent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
  questionText: string;
  headerText: string;
}

export const DeleteConfirmationModal: FunctionComponent<DeleteConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirmDelete,
  questionText,
  headerText,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent className="w-96">
        <Typography style={{ whiteSpace: 'pre-line' }}>{questionText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={onConfirmDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
