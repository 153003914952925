import { FunctionComponent, PropsWithChildren } from 'react';

interface PageLayoutTopBarHeaderProps extends PropsWithChildren {
  noBg?: boolean;
}

const PageLayoutTopBarHeader: FunctionComponent<PageLayoutTopBarHeaderProps> = ({ children, noBg }) => {
  return <div className={`text-lg font-black uppercase  dark:text-gray-300 ${noBg ? '' : 'text-gray-50'} `}>{children}</div>;
};

export default PageLayoutTopBarHeader;
