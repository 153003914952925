import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Autocomplete,
  SelectChangeEvent,
} from '@mui/material';
import { ChangeEvent, FunctionComponent, SyntheticEvent, useCallback, useState } from 'react';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useUserContext } from '@/modules/users';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import AlSelect from '@/components/form/AlSelect';
import { TeamProfile } from '../../types/TeamProfile';
import { DashboardReadDTO } from '../../api/dashboard/dashboard.contracts';
import { debounce, trim } from 'lodash-es';

interface CreateDashboardDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDashboardCreated: (createdDashboard: DashboardReadDTO) => void;
}

const validateDashboardName = debounce((name: string, setError: (error: boolean) => void) => {
  if (trim(name).length < 3) {
    setError(true);
  } else {
    setError(false);
  }
}, 1000);

const CreateDashboardDialog: FunctionComponent<CreateDashboardDialogProps> = ({ isOpen, onClose, onDashboardCreated }) => {
  // const { t } = useTranslation();
  const { user } = useUserContext();
  const [isCreatingDashboard, setIsCreatingDashboard] = useState(false);

  const [dashboardName, setDashboardName] = useState('');
  const [currency, setCurrency] = useState(CurrencyCode.USD);
  const [selectedProfiles, setSelectedProfiles] = useState<TeamProfile[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{ id: number; name: string }[]>([]);
  const [nameHasValidationError, setNameHasValidationError] = useState(false);

  const profileOptions: TeamProfile[] | undefined = user?.teams.flatMap<TeamProfile>((team) =>
    team.profiles.flatMap((p) => ({
      profileId: p.id,
      profileName: p.name,
      teamId: team.id,
      teamName: team.name,
      countryCode: p.countryCode,
      state: p.state,
    })),
  );

  let usersInAllTeams = user?.teams?.flatMap((team) => {
    return team.members
      .filter((member) => member.user.id !== user.id)
      .map((member) => {
        return { id: member.user.id, name: member.user.name };
      });
  });

  // Filter out duplicate users based on their ID
  usersInAllTeams = usersInAllTeams?.filter((user, index, self) => index === self.findIndex((u) => u.id === user.id));

  if (!usersInAllTeams) {
    usersInAllTeams = [];
  }

  const isCreateValid = trim(dashboardName).length >= 3 && selectedProfiles.length > 0;

  const currencyOptions = Object.values(CurrencyCode);

  const onDashboardNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;
      setDashboardName(name);
      validateDashboardName(name, setNameHasValidationError);
    },
    [setDashboardName, setNameHasValidationError],
  );

  function onCurrencyChange(event: SelectChangeEvent<CurrencyCode>) {
    setCurrency(event.target.value as CurrencyCode);
  }

  function onProfileSelectionChanged(event: SyntheticEvent<Element, Event>, value: TeamProfile[]) {
    setSelectedProfiles(value);
  }

  async function onCreateDashboardClicked() {
    setIsCreatingDashboard(true);
    const newDashboard = await dashboardService.create({
      name: dashboardName,
      currency_code: currency,
      profiles: selectedProfiles.map((p) => {
        return {
          profile_id: p.profileId,
          team_id: p.teamId,
        };
      }),
      collaborators: selectedUsers.map((user) => user.id),
    });
    if (newDashboard.isSuccess) {
      toast.success('Dashboard created!');
      onDashboardCreated(newDashboard.payload);
    }
    setIsCreatingDashboard(false);
  }

  function onModalClose() {
    setDashboardName('');
    setCurrency(CurrencyCode.USD);
    setSelectedProfiles([]);
    setSelectedUsers([]);
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onModalClose}>
      <DialogTitle>Create a new Dashboard</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the name of the Dashboard you would like to create, select the displayed currency format, select which profiles you would like
          to include in the dashboard, and add collaborators.
        </DialogContentText>
        <div className="flex flex-col mt-4">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            helperText={'At least 3 characters'}
            error={nameHasValidationError}
            onChange={onDashboardNameChange}
          />
          <div className="mb-5">
            <AlSelect
              label="Currency"
              value={currency}
              options={currencyOptions}
              renderOption={(option) => option}
              valueExtractor={(option) => option}
              disabled={false}
              onChange={onCurrencyChange}
            />
          </div>
          {profileOptions && (
            <Autocomplete
              multiple
              id="tags-standard"
              options={profileOptions}
              groupBy={(option) => option.teamName}
              renderGroup={(params) => (
                <div key={params.key}>
                  <div className="px-4 py-0.5 bg-gray-100 font-bold text-gray-600">{params.group}</div>
                  <div>{params.children}</div>
                </div>
              )}
              getOptionDisabled={(option) => option.state !== 'ACTIVE'}
              getOptionLabel={(option) =>
                option.profileName + ' (' + option.countryCode + ') ' + (option.state === 'ACTIVE' ? '' : ' - Inactive')
              }
              getOptionKey={(option) => `${option.profileId}_${option.teamId}`}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.profileId === value.profileId && option.teamId === value.teamId}
              onChange={onProfileSelectionChanged}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Profiles" placeholder="Select Profiles" InputLabelProps={{ shrink: true }} />
              )}
            />
          )}
          <div className="mt-4">
            <Autocomplete
              id="collaborators-select"
              options={usersInAllTeams}
              multiple
              fullWidth
              value={selectedUsers}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setSelectedUsers(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Collaborator(s)"
                  InputLabelProps={{ shrink: true }}
                  helperText={'Users that can also edit the dashboard (optional)'}
                />
              )}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton loading={isCreatingDashboard} variant="contained" onClick={onCreateDashboardClicked} disabled={!isCreateValid}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDashboardDialog;
