import { createGetAllGroupsQueryKey, dataGroupsService } from '@/modules/data-groups/api/data-groups-service';
import { DataGroupModel } from '@/modules/data-groups/models/DataGroup';
import { DataItemMapElement } from '@/modules/data-groups/models/DataItem';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

export const useDataGroups = (filterByDataGroupTypes: DataGroupType[]) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const {
    data: allDataGroups,
    isLoading: isDataGroupsLoading,
    refetch: refetchDataGroups,
  } = useQuery({
    queryKey: createGetAllGroupsQueryKey(activeTeam?.id),
    queryFn: async () => {
      const result = await dataGroupsService.getAllGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error(`Error loading groups ${result.message}`);
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  function buildDataItemToInfoMap(dataGroups: DataGroupModel[] | undefined): Map<number, DataItemMapElement> {
    const dataItemToInfo = new Map<number, DataItemMapElement>();
    if (!dataGroups) {
      return dataItemToInfo;
    }
    dataGroups.forEach((group) => {
      group.items.forEach((item) => {
        dataItemToInfo.set(item.id, {
          name: item.name,
          groupId: group.id,
        });
      });
    });

    return dataItemToInfo;
  }

  function createGroupIdToItemSetMap(dataGroups: DataGroupModel[] | undefined): Map<number, Set<number>> {
    const groupIdToItemSet = new Map<number, Set<number>>();

    if (!dataGroups) {
      return groupIdToItemSet;
    }

    dataGroups.forEach((group) => {
      const itemSet = groupIdToItemSet.get(group.id) || new Set<number>();

      group.items.forEach((item) => {
        itemSet.add(item.id);
      });

      groupIdToItemSet.set(group.id, itemSet);
    });

    return groupIdToItemSet;
  }

  const { dataGroups, dataGroupTypeToDataGroupMap } = useMemo(() => {
    const filteredGroups: DataGroupModel[] = [];
    const groupTypeMap: Record<DataGroupType, DataGroupModel[]> = {} as Record<DataGroupType, DataGroupModel[]>;

    allDataGroups?.forEach((group) => {
      if (filterByDataGroupTypes.includes(group.type)) {
        filteredGroups.push(group);

        if (!groupTypeMap[group.type]) {
          groupTypeMap[group.type] = [];
        }
        groupTypeMap[group.type].push(group);
      }
    });

    return { dataGroups: filteredGroups, dataGroupTypeToDataGroupMap: groupTypeMap };
  }, [allDataGroups]);

  const dataItemToInfoMap = useMemo(() => buildDataItemToInfoMap(dataGroups), [dataGroups]);
  const groupIdToItemSetMap = useMemo(() => createGroupIdToItemSetMap(dataGroups), [dataGroups]);

  return {
    dataGroups,
    dataGroupTypeToDataGroupMap,
    dataItemToInfoMap,
    groupIdToItemSetMap,
    createGroupIdToItemSetMap,
    refetchDataGroups,
    isDataGroupsLoading,
  };
};
