import { createContext, FC, PropsWithChildren, useState, useMemo } from 'react';

interface DashboardWidgetContextType {
  isConfiguring: boolean;
  setIsConfiguring: (isConfiguring: boolean) => void;
  widgetId?: string;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
}

export const DashboardWidgetContext = createContext<DashboardWidgetContextType>({
  isConfiguring: false,
  setIsConfiguring: () => null,
  widgetId: undefined,
  isLoading: false,
  setIsLoading: () => null,
});

interface WidgetProviderProps extends PropsWithChildren {
  widgetId: string;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
}

export const DashboardWidgetProvider: FC<WidgetProviderProps> = ({ children, widgetId, isLoading, setIsLoading }) => {
  const [isConfiguring, setIsConfiguring] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      isConfiguring,
      setIsConfiguring,
      widgetId,
      isLoading,
      setIsLoading,
    }),
    [isConfiguring, setIsConfiguring, widgetId, isLoading, setIsLoading],
  );

  return <DashboardWidgetContext.Provider value={contextValue}>{children}</DashboardWidgetContext.Provider>;
};

DashboardWidgetProvider.displayName = 'DashboardWidgetProvider'; // Add display name

export default DashboardWidgetProvider;
