import { Navigate, RouteObject } from 'react-router-dom';
import { BillingSettingsPage } from '../pages/BillingSettingsPage';
import { TeamSettingsPage } from '../pages/TeamSettingsPage';
import { UserSettingsPage } from '../pages/UserSettingsPage';

export const SETTING_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'settings',
    children: [
      {
        index: true,
        element: <Navigate to="billing" replace />,
      },
      {
        path: 'billing',
        element: <BillingSettingsPage />,
      },
      {
        path: 'teams',
        element: <TeamSettingsPage />,
      },
      {
        path: 'user',
        element: <UserSettingsPage />,
      },
    ],
  },
];
