import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { useTranslation } from '@/lib';
import { Routes } from '@/router/router-paths';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../application/utils';
import { PaywallModal } from '../plans/components/PaywallModal';
import { ProfileSelect } from '../profiles/components/ProfileSelect';
import { useActiveTeamContext } from '../teams/contexts/ActiveTeamContext';
import { useReportsContext } from '../teams/contexts/ReportsContext';
import { SyncStatusReason } from '../profiles/types/ProfileStatus';

interface ProfileSyncSelectButtonProps {
  setFilters?: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}

const ProfileSyncSelectButton: FunctionComponent<ProfileSyncSelectButtonProps> = ({ setFilters }) => {
  const { t } = useTranslation();
  const { activeProfile } = useActiveTeamContext();
  const { updateProfileData, isActiveProfileReportsBeingProcessed, activeProfileSyncStatus } = useReportsContext();

  const syncButtonTooltip = (() => {
    if (!isNil(activeProfileSyncStatus.reason)) {
      return t(`labels.${activeProfileSyncStatus.reason}`);
    } else if (!isActiveProfileReportsBeingProcessed) {
      return 'Sync profile data with Amazon';
    } else {
      return 'Data processing may take several minutes';
    }
  })();

  async function onUpdateProfileClicked() {
    try {
      if (activeProfile?.id) {
        if (activeProfileSyncStatus.reason == SyncStatusReason.PLAN_RESTRICTION) {
          setIsPaywallModalOpen(true);
          return;
        }

        await updateProfileData(activeProfile.id);

        toast.success('Report generation started successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error(getErrorMessage(error));
    }
  }

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <>
      <div className="flex bg-white bg-opacity-100 text-gray-800 rounded-md">
        <div className="flex-col border-r border-b-blue-600">
          <Tooltip title={syncButtonTooltip}>
            <div>
              <Button
                className="px-0 h-9 bg-transparent rounded-r-none hover:bg-primary-50 text-gray-700"
                onClick={onUpdateProfileClicked}
                disabled={!activeProfileSyncStatus.canClickSync}
              >
                {activeProfileSyncStatus.isLoading ? (
                  <CircularProgress style={{ color: 'blue' }} size={20} />
                ) : (
                  <CachedRoundedIcon style={{ color: activeProfileSyncStatus.canClickSync ? undefined : 'gray' }} />
                )}
              </Button>
            </div>
          </Tooltip>
        </div>

        <ProfileSelect squareLeftSide setFilters={setFilters} />
      </div>

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.BILLING}
        headerText="Upgrade to Pro to update more than once a day!"
      ></PaywallModal>
    </>
  );
};

export default ProfileSyncSelectButton;
