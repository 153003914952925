import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function teamsTableDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.TEAM_NAME, hide: false, pinned: false },
    { colId: ColumnId.TEAM_OWNER, hide: false, pinned: false },
    { colId: ColumnId.TEAM_PLAN, hide: false, pinned: false },
    { colId: ColumnId.AD_SPEND, hide: false, pinned: false },
    { colId: ColumnId.ESTIMATED_COST, hide: false, pinned: false },
    { colId: ColumnId.TEAM_PROFILES_COUNT, hide: false, pinned: false },
    { colId: ColumnId.TEAM_PREFERRED_UPDATE_TIME, hide: false, pinned: false },
    { colId: ColumnId.TEAM_MEMBERS, hide: false, pinned: false },
    { colId: ColumnId.ACTIONS, hide: false, pinned: false },
  ];
}
