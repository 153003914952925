import { Drawer, IconButton, Portal } from '@mui/material';
import { FunctionComponent } from 'react';

import { IDashboardWidgetCatalogItem } from '@/modules/dashboards/types/IDashboardWidgetCatalogItem';
import DashboardWidgetCatalogItem from './DashboardWidgetCatalogItem';
import { Close } from '@mui/icons-material';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';

interface DashboardWidgetCatalogProps {
  widgets: IDashboardWidgetCatalogItem<IDashboardWidgetBaseConfiguration>[];
  isOpen: boolean;
}

const DashboardWidgetCatalog: FunctionComponent<DashboardWidgetCatalogProps> = ({ widgets, isOpen }) => {
  const { setPlaceholderSize, setIsEditingDashboard } = useDashboardContextValue((context) => ({
    setPlaceholderSize: context.setPlaceholderSize,
    setIsEditingDashboard: context.setIsEditingDashboard,
  }));

  return (
    <Portal>
      <Drawer anchor={'right'} open={isOpen} hideBackdrop variant="persistent">
        <div className="flex w-96 flex-col px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="text-lg font-medium ">Add Widgets</div>
            <IconButton edge="start" color="inherit" onClick={() => setIsEditingDashboard(false)} aria-label="close">
              <Close />
            </IconButton>
          </div>
          <div className="mb-6 mt-2">Drag and drop a widget from the list below onto the dashboard to start using it</div>
          <div className="flex flex-col gap-y-4">
            {widgets.map((widget) => (
              <div
                key={widget.id}
                draggable={true}
                // eslint-disable-next-line react/no-unknown-property
                unselectable="on"
                className="select-none"
                onDragStart={(e) => {
                  // this is a hack for firefox
                  // Firefox requires some kind of initialization
                  // which we can do by adding this attribute
                  // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                  e.dataTransfer.setData('text/plain', '');
                  e.dataTransfer.setData('droppableWidget', JSON.stringify(widget));
                  setPlaceholderSize(widget.layout.w, widget.layout.h);
                  return true;
                }}
              >
                <DashboardWidgetCatalogItem widget={widget}></DashboardWidgetCatalogItem>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default DashboardWidgetCatalog;
