import { ADVERTISED_PRODUCT_CONTEXT_KEY } from '@/modules/advertised-products/contexts/AdvertisedProductsContext';
import { OPTIMIZER_CONTEXT_KEY } from '@/modules/optimizer/contexts/OptimizerContext';
import { PLACEMENTS_CONTEXT_KEY } from '@/modules/placements/contexts/PlacementsContext';
import { PRODUCTS_CONTEXT_KEY } from '@/modules/products/contexts/ProductsContext';
import { SEARCH_TERMS_CONTEXT_KEY } from '@/modules/search-terms/contexts/SearchTermsContext';
import { TARGETING_CONTEXT_KEY } from '@/modules/targeting/contexts/TargetingContext';
import { AlFilterModel } from '../../filter-builder/models/AlFilterModel';
import { MetricsDTO } from '../api/metrics-contracts';
import { MetricField } from './CommonMetricsModel';
import { CAMPAIGN_GROUPS_CONTEXT_KEY } from '@/modules/optimizer/contexts/CampaignGroupsContext';

export class MetricsModel {
  public key: MetricField;
  public current: number;
  public previous: number;

  constructor(args: MetricsCreateArguments) {
    this.key = args.key;
    this.current = args.current;
    this.previous = args.previous;
  }

  public static fromDTO(response: MetricsDTO): MetricsModel[] {
    const metrics: MetricsModel[] = [];
    for (const [key, value] of Object.entries(response)) {
      metrics.push(
        new MetricsModel({
          key: key as MetricField,
          current: value ? value[0] : 0,
          previous: value ? value[1] : 0,
        }),
      );
    }
    return metrics;
  }
}

interface MetricsCreateArguments {
  key: MetricField;
  current: number;
  previous: number;
}

export type MetricAggregates = Record<MetricField, { current: number }>;

const METRICS_QUERY_KEY = 'metrics'; // don't expose this and only use key creators
export function createOptimizerMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, OPTIMIZER_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createCampaignGroupsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, CAMPAIGN_GROUPS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createTargetingMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, TARGETING_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createPlacementsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, PLACEMENTS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createSearchTermsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, SEARCH_TERMS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createProductsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, PRODUCTS_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}

export function createAdvertisedProductsMetricsQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  return [METRICS_QUERY_KEY, ADVERTISED_PRODUCT_CONTEXT_KEY, activeProfileId, filters.map((filter) => filter.toQueryKey())];
}
