import { DTO } from '@/types/dto-wrapper';
import { DashboardReadDTO } from '../api/dashboard/dashboard.contracts';
import dayjs, { Dayjs } from 'dayjs';
import { TeamProfile } from './TeamProfile';

// Dashboard Class
export class DashboardModel extends DTO<DashboardReadDTO> {
  constructor(dto: DashboardReadDTO) {
    super(dto);
  }

  get teamProfiles(): TeamProfile[] {
    return (
      this.dto?.profiles.map<TeamProfile>((teamProfile) => {
        return {
          profileId: teamProfile.profile_id.toString(),
          profileName: teamProfile.profile_name,
          teamId: teamProfile.team_id,
          teamName: teamProfile.profile_name,
          countryCode: teamProfile.country_code,
          state: teamProfile.state,
        };
      }) ?? []
    );
  }

  get filterDateRangeStart(): Dayjs {
    const startDate = this.dto.filter_start_date_offset_in_days
      ? dayjs().subtract(this.dto.filter_start_date_offset_in_days, 'day')
      : dayjs();
    return startDate.startOf('day');
  }

  get filterDateRangeEnd(): Dayjs {
    const endDate = this.dto.filter_end_date_offset_in_days ? dayjs().subtract(this.dto.filter_end_date_offset_in_days, 'day') : dayjs();
    return endDate.endOf('day');
  }

  get filterComparisonDateRangeStart(): Dayjs {
    const startDate = this.dto.filter_compare_start_date_offset_in_days
      ? dayjs().subtract(this.dto.filter_compare_start_date_offset_in_days, 'day')
      : dayjs();
    return startDate.startOf('day');
  }

  get filterComparisonDateRangeEnd(): Dayjs {
    const endDate = this.dto.filter_compare_end_date_offset_in_days
      ? dayjs().subtract(this.dto.filter_compare_end_date_offset_in_days, 'day')
      : dayjs();
    return endDate.endOf('day');
  }
}
