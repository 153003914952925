import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Instance } from '@popperjs/core';
import dayjs from 'dayjs';
import { round } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';

export interface SparklineDataPoint {
  date: string;
  value: number;
}

interface SparklineProps {
  data: SparklineDataPoint[];
  color?: 'green' | 'amber';
  valueFormatter?: (value: number) => string;
}

const Sparkline: React.FC<SparklineProps> = ({ data, color = 'green', valueFormatter }) => {
  const maxValue = Math.max(...data.map((p) => p.value));
  const minValue = Math.min(...data.map((p) => p.value));
  const padding = 3; // Padding inside the SVG
  const [size, setSize] = useState({ width: 0, height: 0 });
  const positionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const popperRef = useRef<Instance | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const [tooltipContent, setTooltipContent] = useState('');
  const [open, setOpen] = useState(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const scaleX = (index: number) => padding + (index / (data.length - 1)) * (size.width - 2 * padding);
  const scaleY = useMemo(() => {
    // Check if maxValue equals minValue to avoid division by zero
    if (maxValue === minValue) {
      // Return the middle of the graph when all values are equal
      return () => size.height / 2;
    }
    return (value: number) =>
      padding + (size.height - 2 * padding) - ((value - minValue) / (maxValue - minValue)) * (size.height - 2 * padding);
  }, [size.height, minValue, maxValue]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        resizeObserver.unobserve(boxRef.current);
      }
    };
  }, []);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!boxRef.current) return;

    const boxRect = boxRef.current.getBoundingClientRect();
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current) {
      popperRef.current.update();
    }

    const xPosition = ((event.clientX - boxRect.left) / boxRect.width) * data.length;
    const index = Math.min(data.length - 1, Math.max(0, Math.floor(xPosition)));
    const point = data[index];

    const formattedDate = dayjs(point.date).format('MMM DD');

    setTooltipContent(`${formattedDate}: ${valueFormatter ? valueFormatter(point.value) : round(point.value, 2)}`);
    setHoverIndex(index);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setHoverIndex(null);
  };

  // Create the d attribute for the path and area fill path
  const linePathD = useMemo(() => {
    if (size.width === 0 || size.height === 0) {
      return '';
    }
    return data.map((point, i) => `${i === 0 ? 'M' : 'L'} ${scaleX(i)},${scaleY(point.value)}`).join(' ');
  }, [data, scaleX, scaleY, size.width, size.height]);

  const areaPathD = useMemo(() => {
    if (linePathD === '') {
      return '';
    }
    return `${linePathD} L ${size.width - padding},${size.height - padding} L ${padding},${size.height - padding} Z`;
  }, [linePathD, size.width, size.height]);

  return (
    <div className="w-full h-full">
      <Tooltip
        title={tooltipContent}
        open={open}
        placement="top"
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => new DOMRect(positionRef.current.x, positionRef.current.y, 0, 0),
          },
        }}
      >
        <Box ref={boxRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} sx={{ width: '100%', height: '100%' }}>
          {/* Render the SVG */}
          {size.width === 0 || size.height === 0 ? null : (
            <svg width={size.width} height={size.height}>
              {/* Render the area fill */}
              <path d={areaPathD} className={`${color === 'green' ? 'fill-green-600' : 'fill-amber-600'} opacity-15`} />
              {/* Render the line */}
              <path d={linePathD} fill="none" className={`${color === 'green' ? 'stroke-green-600' : 'stroke-amber-600'}`} strokeWidth="1" />
              {/* Render the circle for the current data point */}
              {hoverIndex !== null && (
                <circle
                  cx={scaleX(hoverIndex)}
                  cy={scaleY(data[hoverIndex].value)}
                  r="3"
                  className={`${color === 'green' ? 'fill-green-600' : 'fill-amber-600'}`}
                />
              )}
            </svg>
          )}
        </Box>
      </Tooltip>
    </div>
  );
};

export default Sparkline;
