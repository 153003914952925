import App from '@/App';
import { Environment } from '@/config/Environment';
import { ADVERTISED_PRODUCT_ROUTES } from '@/modules/advertised-products/routes/advertised-products-routes';
import { ApplicationLayout } from '@/modules/application/layouts/ApplicationLayout';
import { PublicLayout } from '@/modules/application/layouts/PublicLayout';
import { ErrorPage } from '@/modules/application/pages/ErrorPage';
import { GettingStartedPage } from '@/modules/application/pages/GettingStartedPage';
import ImpersonationPage from '@/modules/application/pages/ImpersonatePage';
import { LoginPage } from '@/modules/application/pages/LoginPage';
import ResetPasswordPage from '@/modules/application/pages/ResetPasswordPage';
import { CAMPAIGN_MAPPING_ROUTES } from '@/modules/campaign-mapping/routes/campaign-mapping-routes';
import { CONFIGURATION_ROUTES } from '@/modules/configuration/routes/configuration-routes';
import { DASHBOARDS_ROUTES } from '@/modules/dashboards/routes/dashboard-routes';
import { DATA_GROUPS_ROUTES } from '@/modules/data-groups/routes/data-groups-routes';
import { LOGS_ROUTES } from '@/modules/log-viewing/routes/log-routes';
import { NEGATIVE_TARGETS_ROUTES } from '@/modules/negative-targets/routes/negative-targets-routes';
import { OPTIMIZER_ROUTES } from '@/modules/optimizer/routes/optimizer-routes';
import { PLACEMENTS_ROUTES } from '@/modules/placements/routes/placements-routes';
import { PRODUCTS_ROUTES } from '@/modules/products/routes/products-routes';
import { PROFILES_STATS_ROUTES } from '@/modules/profiles-stats/routes/profiles-stats-routes';
import { REGISTRATION_ROUTES } from '@/modules/registration/routes/registration.routes';
import { SEARCH_TERMS_ROUTES } from '@/modules/search-terms/routes/search-terms-routes';
import { TARGETING_ROUTES } from '@/modules/targeting/routes/targeting-routes';
import { TEAMS_ROUTES } from '@/modules/teams/routes/teams-routes';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { Routes } from './router-paths';

export const PUBLIC_PATHS = [
  Routes.LOGIN,
  Routes.IMPERSONATE,
  Routes.RESET_PASSWORD,
  Routes.REGISTER,
  Routes.REGISTER_CREATE_YOUR_ACCOUNT,
  Routes.REGISTER_ABOUT_YOU,
];

export const isPublicPath = (path: string) => {
  return PUBLIC_PATHS.includes(path as Routes);
};

export const isPrivatePath = (path: string) => {
  return !isPublicPath(path);
};

export const APP_DEFAULT_PATH = Routes.GETTING_STARTED;

const APP_ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <App />,

    children: [
      {
        element: <PublicLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: Routes.LOGIN,
            element: <LoginPage />,
          },
          {
            path: Routes.IMPERSONATE,
            element: <ImpersonationPage />,
          },
          {
            path: Routes.RESET_PASSWORD,
            element: <ResetPasswordPage />,
          },
          ...REGISTRATION_ROUTES,
        ],
      },
      {
        element: <ApplicationLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to="profiles" replace />,
          },
          {
            path: Routes.GETTING_STARTED,
            element: <GettingStartedPage />,
          },
          /**
           * All App Module Routes go below
           */

          ...OPTIMIZER_ROUTES,
          ...CONFIGURATION_ROUTES,
          ...TEAMS_ROUTES,
          ...PROFILES_STATS_ROUTES,
          ...DASHBOARDS_ROUTES,
          ...TARGETING_ROUTES,
          ...DATA_GROUPS_ROUTES,
          ...PLACEMENTS_ROUTES,
          ...NEGATIVE_TARGETS_ROUTES,
          ...SEARCH_TERMS_ROUTES,
          ...CAMPAIGN_MAPPING_ROUTES,
          ...LOGS_ROUTES,
          ...PRODUCTS_ROUTES,
          ...ADVERTISED_PRODUCT_ROUTES,
        ],
      },
      {
        path: '*',
        element: <Navigate to={APP_DEFAULT_PATH} replace />,
      },
    ],
  },
];

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = Environment.isAdLabsProd() ? sentryCreateBrowserRouter(APP_ROUTES) : createBrowserRouter(APP_ROUTES);
