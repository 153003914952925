import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { isNumber } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import Dimension from './Dimension';
import DimensionContainer, { DimensionItem } from './DimensionContainer';

interface DragToReorderProps {
  chosenInputItems: string[];
  allInputItems: string[];
  allDisplayNames: string[];
  onChoiceChange: (newChosenItems: string[]) => void;
}

export enum DragAndDropContainer {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

interface DimensionItems {
  [DragAndDropContainer.VISIBLE]: DimensionItem[];
  [DragAndDropContainer.HIDDEN]: DimensionItem[];
}

const splitDimensionItems = (allDimensionItems: DimensionItem[], chosenInputItems: string[]) => {
  const chosenDims: DimensionItem[] = [];
  const unChosenDims: DimensionItem[] = [];

  chosenInputItems.forEach((chosenInputItem: string) => {
    const correspondingDimensionItem = allDimensionItems.find((dimensionItem) => dimensionItem.inputItem === chosenInputItem);

    if (correspondingDimensionItem) {
      chosenDims.push(correspondingDimensionItem);
    }
  });

  allDimensionItems.forEach((dimensionItem) => {
    if (!chosenInputItems.includes(dimensionItem.inputItem)) {
      unChosenDims.push(dimensionItem);
    }
  });

  return { chosenDims, unChosenDims };
};

const DragToReorder: FunctionComponent<DragToReorderProps> = ({ chosenInputItems, allInputItems, allDisplayNames, onChoiceChange }) => {
  const createDimensionItems = (inputItems: string[]): DimensionItem[] => {
    let nextId = 0;

    return inputItems.map((metric: string) => ({
      id: (nextId++).toString(),
      inputItem: metric,
      displayName: allDisplayNames[nextId - 1],
    }));
  };

  const allDimensionItems: DimensionItem[] = createDimensionItems(allInputItems);

  const { chosenDims, unChosenDims }: { chosenDims: DimensionItem[]; unChosenDims: DimensionItem[] } = splitDimensionItems(
    allDimensionItems,
    chosenInputItems,
  );

  const [dimensionItems, setDimensionItems] = useState<DimensionItems>({
    [DragAndDropContainer.VISIBLE]: chosenDims,
    [DragAndDropContainer.HIDDEN]: unChosenDims,
  });
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(PointerSensor));

  const findContainer = (id: string): keyof DimensionItems | undefined => {
    let containerId: keyof DimensionItems | undefined;
    (Object.keys(dimensionItems) as Array<keyof DimensionItems>).forEach((key) => {
      dimensionItems[key].forEach((item: DimensionItem) => {
        if (item.id === id) {
          containerId = key;
        }
      });
    });

    return containerId;
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const overId = isNumber(over?.id) ? over?.id.toString() : over?.id;
    const activeId = isNumber(active?.id) ? active?.id.toString() : active?.id;
    if (!overId) {
      return;
    }

    const overContainer = overId == DragAndDropContainer.VISIBLE || overId == DragAndDropContainer.HIDDEN ? overId : findContainer(overId);
    const activeContainer = findContainer(activeId);

    if (!overContainer || !activeContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      setDimensionItems((items: DimensionItems) => {
        const activeItems =
          activeContainer === DragAndDropContainer.VISIBLE ? items[DragAndDropContainer.VISIBLE] : items[DragAndDropContainer.HIDDEN];
        const overItems =
          activeContainer === DragAndDropContainer.VISIBLE ? items[DragAndDropContainer.HIDDEN] : items[DragAndDropContainer.VISIBLE];

        const overIndex = overItems.findIndex((overItem: DimensionItem) => overItem.id === overId);
        const activeIndex = activeItems.findIndex((activeItem: DimensionItem) => activeItem.id === active.id);

        let newIndex;
        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over && active.rect.current.translated && active.rect.current.translated.top > over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;
          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...items,
          [activeContainer]: items[activeContainer].filter((item: DimensionItem) => item.id !== active.id),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(newIndex, items[overContainer].length),
          ],
        };
      });
    } else {
      setDimensionItems((items: DimensionItems) => {
        const activeItems = items[activeContainer as keyof DimensionItems];

        const oldIndex = activeItems.findIndex((dim) => dim.id === active.id);
        const newIndex = activeItems.findIndex((dim) => dim.id === over?.id);
        return {
          ...items,
          [activeContainer]: arrayMove(activeItems, oldIndex, newIndex),
        };
      });
    }
  };
  // { active, over }: DragEndEvent
  const handleDragEnd = () => {
    setActiveId(null);
    onChoiceChange(dimensionItems[DragAndDropContainer.VISIBLE].map((item) => item.inputItem));
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    const activeId = isNumber(active?.id) ? active?.id.toString() : active?.id;
    setActiveId(activeId);
  };

  // Label being shown on the item that's being dragged
  const getDimensionLabel = (activeId: string) => {
    return [...dimensionItems[DragAndDropContainer.VISIBLE], ...dimensionItems[DragAndDropContainer.HIDDEN]].filter(
      (dim) => dim.id === activeId,
    )[0].displayName;
  };

  const itemRemovedFromGroup = (id: string) => {
    setDimensionItems((prevItems) => {
      const itemInVisible = prevItems[DragAndDropContainer.VISIBLE].find((item) => item.id === id);

      if (itemInVisible) {
        const newVisible = prevItems[DragAndDropContainer.VISIBLE].filter((item) => item.id !== id);
        const newHidden = [...prevItems[DragAndDropContainer.HIDDEN], itemInVisible];
        onChoiceChange(newVisible.map((item) => item.inputItem));
        return {
          [DragAndDropContainer.VISIBLE]: newVisible,
          [DragAndDropContainer.HIDDEN]: newHidden,
        };
      } else {
        const itemInHidden = prevItems[DragAndDropContainer.HIDDEN].find((item) => item.id === id);
        if (itemInHidden) {
          const newVisible = [...prevItems[DragAndDropContainer.VISIBLE], itemInHidden];
          const newHidden = prevItems[DragAndDropContainer.HIDDEN].filter((item) => item.id !== id);

          onChoiceChange(newVisible.map((item) => item.inputItem));
          return {
            [DragAndDropContainer.VISIBLE]: newVisible,
            [DragAndDropContainer.HIDDEN]: newHidden,
          };
        }
      }
      return prevItems; // Return the previous state if no changes were made
    });
  };

  return (
    <div className="App">
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
      >
        {/* Chosen container */}
        <div>
          <h2>Key Metrics</h2>
          <DimensionContainer
            id={DragAndDropContainer.VISIBLE}
            items={dimensionItems[DragAndDropContainer.VISIBLE]}
            isActive={(activeId && findContainer(activeId)) === DragAndDropContainer.VISIBLE}
            container={DragAndDropContainer.VISIBLE}
            itemRemovedFromGroup={itemRemovedFromGroup}
          />
          <DragOverlay>{activeId ? <Dimension label={getDimensionLabel(activeId)}></Dimension> : null}</DragOverlay>
        </div>
        {/* The rest container */}
        <div>
          <h2>Hidden metrics</h2>
          <DimensionContainer
            id={DragAndDropContainer.HIDDEN}
            items={dimensionItems[DragAndDropContainer.HIDDEN]}
            isActive={(activeId && findContainer(activeId)) === DragAndDropContainer.HIDDEN}
            container={DragAndDropContainer.HIDDEN}
            itemRemovedFromGroup={itemRemovedFromGroup}
          />
        </div>
      </DndContext>
    </div>
  );
};
export default DragToReorder;
