import { DateRange } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayOfYear);

export const mainCalenderDefaultShortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Last 7 days',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.subtract(6, 'day'), yesterday];
    },
  },
  {
    label: 'Last 14 days',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.subtract(13, 'day'), yesterday];
    },
  },
  {
    label: 'Last 30 days',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.subtract(29, 'day'), yesterday];
    },
  },
  {
    label: 'Last 60 days',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.subtract(59, 'day'), yesterday];
    },
  },
  {
    label: 'Last 90 days',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.subtract(89, 'day'), yesterday];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const startOfLastMonth = dayjs().subtract(1, 'month').startOf('month');
      const endOfLastMonth = dayjs().subtract(1, 'month').endOf('month');
      return [startOfLastMonth, endOfLastMonth];
    },
  },
  {
    label: 'Month-to-Date',
    getValue: () => {
      // current month
      const today = dayjs();

      // if is First Day Of Month
      if (today.date() === 1) {
        return [today, today];
      }
      return [today.startOf('month'), today.subtract(1, 'day')];
    },
  },
  {
    label: 'Year-to-Date',
    getValue: () => {
      // current year
      const today = dayjs();

      // if is First Day Of year
      if (today.dayOfYear() === 1) {
        return [today.startOf('year'), today];
      }
      return [today.startOf('year'), today.subtract(1, 'day')];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

export const comparisonCalenderDefaultShortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  //Shortcuts are set dynamically as they need access to main calendar info
  { label: 'Custom', getValue: () => [null, null] },
];
