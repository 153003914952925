import { useState, useEffect } from 'react';
import { UserSettingKey, useUserSettingsContext } from '@/modules/users';

/**
 * Custom hook for managing the tutorial modal state.
 * @param userSettingKey - The key for the user setting, that stores the number of times the video has been shown.
 * @returns An object containing the tutorial modal state and functions to control it.
 */
const useTutorialModal = (userSettingKey: UserSettingKey) => {
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();

  // Function to close the modal and update the user setting
  const onTutorialModalClose = () => {
    setIsTutorialModalOpen(false);
  };

  // Function to open the modal
  const openTutorialModal = () => {
    setIsTutorialModalOpen(true);
  };

  // Automatically open the modal if the setting value is less than 1
  useEffect(() => {
    const currentCount = getUserSettingValueByKey<number>(userSettingKey);

    if (!currentCount || currentCount < 1) {
      openTutorialModal();

      // Increment the count and update the setting
      const newCount = (currentCount || 0) + 1;
      upsertUserSetting(userSettingKey, newCount);
    }
  }, [userSettingKey, getUserSettingValueByKey]);

  return {
    isTutorialModalOpen,
    onTutorialModalClose,
    openTutorialModal,
  };
};

export default useTutorialModal;
