import AlSelect from '@/components/form/AlSelect';
import { LoadingButton } from '@mui/lab';
import { DialogTitle, DialogContent, DialogActions, Button, SelectChangeEvent, Popover, Divider } from '@mui/material';
import { FunctionComponent, RefObject, useMemo, useState } from 'react';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import {
  CampaignAdType,
  AmazonBrandsBidOptimization,
  BidStrategyType,
  EnabledPausedArchivedState,
  CampaignUpdateDTO,
} from '../api/campaign/campaign-contracts';
import { useTranslation } from '@/lib';
import { campaignService, createCampaignsWithTimelineQueryKey } from '../api/campaign/campaign-service';
import { useQueryClient } from '@tanstack/react-query';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useOptimizerContext } from '../contexts/OptimizerContext';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { isNil } from 'lodash-es';
import InfoMessage from '@/components/feedback/InfoMessage';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';

interface CampaignBulkActionsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedCampaigns: SelectedCampaignDTO[];
  isOpen: boolean;
  onClose: () => void;
}

type NoChange = 'NO_CHANGE';
const NO_CHANGE: NoChange = 'NO_CHANGE';

const CampaignBulkEditPopover: FunctionComponent<CampaignBulkActionsPopoverProps> = ({ selectedCampaigns, isOpen, onClose, buttonRef }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();
  const { filters } = useOptimizerContext();
  const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);

  // Post update modal
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [updatedCampaignsResponse, setUpdateCampaignsResponse] = useState<UpdateResponseDTO>();

  const STATE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: EnabledPausedArchivedState.ENABLED, label: 'Enabled' },
    { value: EnabledPausedArchivedState.PAUSED, label: 'Paused' },
  ];

  const BID_STRATEGY_TYPE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: BidStrategyType.LEGACY_FOR_SALES, label: t(`optimizer_page.bidStrategy.${BidStrategyType.LEGACY_FOR_SALES}`) },
    { value: BidStrategyType.AUTO_FOR_SALES, label: t(`optimizer_page.bidStrategy.${BidStrategyType.AUTO_FOR_SALES}`) },
    { value: BidStrategyType.MANUAL, label: t(`optimizer_page.bidStrategy.${BidStrategyType.MANUAL}`) },
    //{ value: BidStrategyType.RULE_BASED, label: t(`optimizer_page.bidStrategy.${BidStrategyType.RULE_BASED}`) },
  ];

  const BID_OPTIMIZATION = [
    { value: NO_CHANGE, label: 'No change' },
    { value: AmazonBrandsBidOptimization.AUTO, label: 'Auto' },
    { value: AmazonBrandsBidOptimization.MANUAL, label: 'Manual' },
  ];

  const [campaignState, setCampaignState] = useState<EnabledPausedArchivedState | NoChange>(NO_CHANGE);
  const [campaignBiddingStrategy, setCampaignBiddingStrategy] = useState<BidStrategyType | NoChange>(NO_CHANGE);
  const [bidOptimization, setBidOptimization] = useState<AmazonBrandsBidOptimization | NoChange>(NO_CHANGE);
  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);

  function hasCampaignEnded(campaign: SelectedCampaignDTO) {
    return !isNil(campaign.endDate) && new Date(campaign.endDate) < new Date();
  }

  async function handleApplyChanges() {
    setIsApplyInProgress(true);

    const filteredSelectedCampaigns = selectedCampaigns.filter(
      (campaign) => campaign.state !== EnabledPausedArchivedState.ARCHIVED && !hasCampaignEnded(campaign),
    );

    const campaignUpdates: CampaignUpdateDTO[] = filteredSelectedCampaigns.map((campaign) => {
      const update: CampaignUpdateDTO = {
        campaign_id: campaign.id,
      };

      if (campaignState !== NO_CHANGE) {
        update.state = campaignState as EnabledPausedArchivedState;
      }

      if (campaignBiddingStrategy !== NO_CHANGE) {
        update.dynamic_bid_strategy = campaignBiddingStrategy as BidStrategyType;
      }

      if (bidOptimization !== NO_CHANGE) {
        update.bid_optimization = bidOptimization as AmazonBrandsBidOptimization;
      }

      return update as CampaignUpdateDTO;
    });

    const response = await campaignService.updateCampaigns(campaignUpdates, FlowType.CAMPAIGN_OPTIMIZER);

    if (response.isSuccess) {
      setUpdateCampaignsResponse({ responseErrorMsg: null, payload: [response?.payload] });
    } else {
      setIsApplyInProgress(false);
      setUpdateCampaignsResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
    }
    setUpdateResponseModalOpen(true);

    // Reset all fields
    setCampaignState(NO_CHANGE);
    setCampaignBiddingStrategy(NO_CHANGE);

    queryClient.invalidateQueries({
      queryKey: campaignsWithTimelineQueryKey,
    });
    onClose();
    setIsApplyInProgress(false);
  }

  function handleCampaignStateChange(event: SelectChangeEvent<EnabledPausedArchivedState | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignState(value);
    } else {
      setCampaignState(value as EnabledPausedArchivedState);
    }
  }

  function onBidStrategyTypeChange(event: SelectChangeEvent<BidStrategyType | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignBiddingStrategy(value);
    } else {
      setCampaignBiddingStrategy(value as BidStrategyType);
    }
  }

  function handleBidOptimizationChange(event: SelectChangeEvent<AmazonBrandsBidOptimization | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setBidOptimization(value);
    } else {
      setBidOptimization(value as AmazonBrandsBidOptimization);
    }
  }

  const brandCampaignsCount = useMemo(
    () => selectedCampaigns.filter((campaign) => campaign.adType === CampaignAdType.BRANDS).length,
    [selectedCampaigns],
  );

  const productCampaignsCount = useMemo(
    () => selectedCampaigns.filter((campaign) => campaign.adType === CampaignAdType.PRODUCTS).length,
    [selectedCampaigns],
  );

  const displayCampaignsCount = useMemo(
    () => selectedCampaigns.filter((campaign) => campaign.adType === CampaignAdType.DISPLAY).length,
    [selectedCampaigns],
  );

  // const endedCampaignsCount = useMemo(() => selectedCampaigns.filter((campaign) => hasCampaignEnded(campaign)).length, [selectedCampaigns]);

  // const archivedCampaignsCount = useMemo(
  //   () => selectedCampaigns.filter((campaign) => campaign.state === EnabledPausedArchivedState.ARCHIVED).length,
  //   [selectedCampaigns],
  // );

  const endedArchivedCampaignsCount = useMemo(
    () => selectedCampaigns.filter((campaign) => campaign.state === EnabledPausedArchivedState.ARCHIVED || hasCampaignEnded(campaign)).length,
    [selectedCampaigns],
  );

  return (
    <>
      <Popover
        id={'optimizer-bulk-edit-popover'}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disablePortal={true} // Allow scrolling bg scrolling when popover is open
        slotProps={{ paper: { style: { width: 300 } } }}
      >
        <DialogTitle id="edit-selection-modal-title">Edit Selected Campaigns</DialogTitle>

        <DialogContent className="space-y-8">
          <span>
            You have selected {selectedCampaigns.length} {selectedCampaigns.length == 1 ? 'Campaign' : 'Campaigns'} to edit.
          </span>

          <AlSelect
            label={'Campaign State'}
            value={campaignState}
            options={STATE_OPTIONS}
            disabled={selectedCampaigns.length == endedArchivedCampaignsCount}
            onChange={handleCampaignStateChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            helperText={
              endedArchivedCampaignsCount > 0
                ? `State change not applied to ${endedArchivedCampaignsCount} archived/ended campaigns`
                : undefined
            }
          />
          <AlSelect
            label={'(SP) Campaign Bidding Strategy'}
            value={campaignBiddingStrategy}
            options={BID_STRATEGY_TYPE_OPTIONS}
            onChange={onBidStrategyTypeChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            helperWarningText={
              campaignBiddingStrategy === BidStrategyType.AUTO_FOR_SALES
                ? 'AdLabs works best with Fixed Bids or Dynamic Down only'
                : undefined
            }
            disabled={productCampaignsCount == 0 || selectedCampaigns.length == endedArchivedCampaignsCount} // bid strategy is only for sponsored products
            helperText={
              productCampaignsCount == 0
                ? 'No sponsored product campaigns currently selected'
                : productCampaignsCount > 0 && brandCampaignsCount + displayCampaignsCount > 0
                  ? `Bid strategy not applied to ${brandCampaignsCount + displayCampaignsCount} brand/display campaigns`
                  : undefined
            }
          />
          <AlSelect
            label={'(SB) Amazon Bid Optimization'}
            value={bidOptimization}
            options={BID_OPTIMIZATION}
            onChange={handleBidOptimizationChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            disabled={brandCampaignsCount == 0 || selectedCampaigns.length == endedArchivedCampaignsCount}
            helperWarningText={
              bidOptimization === AmazonBrandsBidOptimization.AUTO ? 'Cannot modify placements if bid optimization turned on' : undefined
            }
            helperText={
              brandCampaignsCount == 0
                ? 'No brand campaigns currently selected'
                : brandCampaignsCount > 0 && productCampaignsCount + displayCampaignsCount > 0
                  ? `Bid optimization not applied to ${productCampaignsCount + displayCampaignsCount} non-brand campaigns`
                  : undefined
            }
          />
        </DialogContent>

        {endedArchivedCampaignsCount > 0 && (
          <>
            <Divider />
            <div className="flex flex-col mx-5">
              <InfoMessage text={`Change(s) are not applied for ${endedArchivedCampaignsCount} archived/ended campaigns`} />
            </div>
          </>
        )}
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isApplyInProgress}
            onClick={handleApplyChanges}
            disabled={campaignState === NO_CHANGE && campaignBiddingStrategy === NO_CHANGE && bidOptimization === NO_CHANGE}
          >
            Apply Changes
          </LoadingButton>
        </DialogActions>
      </Popover>

      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={updatedCampaignsResponse}
      />
    </>
  );
};

export default CampaignBulkEditPopover;
