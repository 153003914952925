import { RouteObject } from 'react-router-dom';
import CampaignGroupsPage from '../pages/CampaignGroupsPage';
import OptimizationLogsPage from '../pages/OptimizationLogsPage';
import OptimizerPage from '../pages/OptimizerPage';

export const OPTIMIZER_ROUTES: RouteObject[] = [
  {
    path: 'optimizer',
    children: [
      {
        index: true,
        element: <OptimizerPage />,
      },
    ],
  },
  {
    path: 'history',
    children: [
      {
        index: true,
        element: <OptimizationLogsPage />,
      },
    ],
  },
  {
    path: 'campaign-groups',
    children: [
      {
        index: true,
        element: <CampaignGroupsPage />,
      },
    ],
  },
];
