import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { QueryClient } from '@tanstack/react-query';
import { ProductModel } from '../models/ProductModel';
import {
  AdvertisedProductsWithTimeline,
  AdvertisedProductsWithTimelineDTO,
  ProductsWithTimeline,
  ProductsWithTimelineDTO,
} from './products-contracts';
import { AdvertisedProductModel } from '@/modules/advertised-products/models/AdvertisedProductModel';
import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';

// Don't expose this and only use creators
const _PRODUCTS_WITH_TIMELINE_QUERY_KEY = 'products-with-timeline';

// Main key to use
export function createProductsWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const withTimelineQueryKey_withoutFilters = createProductsWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...withTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

export function invalidateAll_productsWithTimelineQueryKeys(queryClient: QueryClient) {
  const key = [_PRODUCTS_WITH_TIMELINE_QUERY_KEY];
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

function createProductsWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_PRODUCTS_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

export function invalidateProfile_productsWithTimelineQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createProductsWithTimelineQueryKey_withoutFilters(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

// Don't expose this and only use creators
const _ADVERTISED_PRODUCT_WITH_TIMELINE_QUERY_KEY = 'advertisedProducts-with-timeline';

// Main key to use
export function createAdvertisedProductsWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const withTimelineQueryKey_withoutFilters = createAdvertisedProductsWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...withTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

export function invalidateAll_advertisedProductsWithTimelineQueryKeys(queryClient: QueryClient) {
  const key = [_ADVERTISED_PRODUCT_WITH_TIMELINE_QUERY_KEY];
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

function createAdvertisedProductsWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_ADVERTISED_PRODUCT_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

export function invalidateProfile_advertisedProductsWithTimelineQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createAdvertisedProductsWithTimelineQueryKey_withoutFilters(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

export class ProductsService {
  public basePath = 'products';

  async getProductsWithTimeline(filters: AlFilterModel[]): Promise<ApiResponse<ProductsWithTimeline>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<ProductsWithTimelineDTO>(`${this.basePath}/with-timeline`, requestData);

      return ApiResponse.responseWithPayload<ProductsWithTimeline>(
        {
          products: ProductModel.fromResponseArray(applicationResponse.payload.products),
          timeline: TimelineModel.fromResponse(applicationResponse.payload.timeline),
          isComparisonDataMissing: applicationResponse.payload.comparison_missing,
        },
        200,
      );
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAdvertisedProductsWithTimeline(filters: AlFilterModel[]): Promise<ApiResponse<AdvertisedProductsWithTimeline>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<AdvertisedProductsWithTimelineDTO>(
        `${this.basePath}/advertised/with-timeline`,
        requestData,
      );

      return ApiResponse.responseWithPayload<AdvertisedProductsWithTimeline>(
        {
          products: AdvertisedProductModel.fromResponseArray(applicationResponse.payload.products),
          timeline: TimelineModel.fromResponse(applicationResponse.payload.timeline),
          isComparisonDataMissing: applicationResponse.payload.comparison_missing,
        },
        200,
      );
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getBrands(): Promise<ApiResponse<string[]>> {
    return await apiProfileClient.get<string[]>(`${this.basePath}/brands`);
  }

  async getCategories(): Promise<ApiResponse<string[]>> {
    return await apiProfileClient.get<string[]>(`${this.basePath}/categories`);
  }
}

export const productsService = new ProductsService();
