import { FunctionComponent } from 'react';
import { IDashboardWidgetCatalogItem } from '../../types/IDashboardWidgetCatalogItem';
import DashboardWidgetCatalogItemImage from './DashboardWidgetCatalogItemImage';

interface DashboardWidgetCatalogItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widget: IDashboardWidgetCatalogItem<any>;
}

const DashboardWidgetCatalogItem: FunctionComponent<DashboardWidgetCatalogItemProps> = ({ widget }) => {
  return (
    <div className="flex cursor-move rounded-lg border-2 border-dashed border-gray-300 bg-gray-200  p-2 transition-all dark:border-gray-700 dark:bg-gray-800">
      <div className="flex h-12 w-12 shrink-0 items-center justify-center">
        <DashboardWidgetCatalogItemImage icon={widget.icon} color={widget.iconColor}></DashboardWidgetCatalogItemImage>
      </div>
      <div className="ml-3 flex flex-col">
        <div className="font-bold">{widget.title}</div>
        <div className="pt-0.5 text-xs text-gray-700 dark:text-gray-400">{widget.description}</div>
      </div>
    </div>
  );
};

export default DashboardWidgetCatalogItem;
