import { Checkbox, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ChangeEvent, FunctionComponent } from 'react';
import { ProfileOption } from '../types/ProfileOption';

interface ProfilesMultiSelectProps {
  profiles: ProfileOption[];
  onProfileSelectionChange: (profileId: string, checked: boolean) => void;
  isLoading: boolean;
  isLoadingProfiles: boolean;
}

const ProfilesMultiSelect: FunctionComponent<ProfilesMultiSelectProps> = ({
  profiles,
  onProfileSelectionChange,
  isLoading,
  isLoadingProfiles,
}) => {
  function onChange(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    const profileId = event.target.value;
    onProfileSelectionChange(profileId, checked);
  }

  return (
    <TableContainer className="w-full flex-1 rounded-lg border bg-white  shadow-md">
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Profile</TableCell>
            <TableCell align="center">Market</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoadingProfiles
            ? Array(6)
                .fill(1)
                .map((v, i) => (
                  <TableRow key={'cell-row-skeleton-' + i}>
                    {Array(4)
                      .fill(1)
                      .map((v, i) => (
                        <TableCell key={'cell-skeleton-' + i} component="th" scope="row">
                          <Skeleton variant="rectangular" width={'full'} animation="wave" />
                        </TableCell>
                      ))}
                  </TableRow>
                ))
            : profiles.map((profile) => (
                <TableRow key={profile.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <div className="flex flex-col">
                      <div className="font-bold">
                        <span className="text-red-600">{profile.isNotAuthorized ? '[NOT AUTHORIZED] ' : ''}</span> {profile.name}
                      </div>
                      <div>{profile.id}</div>
                    </div>
                  </TableCell>
                  <TableCell align="center">{profile.market}</TableCell>
                  <TableCell align="center">{profile.type}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      value={profile.id}
                      checked={profile.isSelected}
                      onChange={onChange}
                      disabled={isLoading || (!profile.isSelected && profile.isNotAuthorized)}
                    />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProfilesMultiSelect;
