import { ComparisonUnit, ExpandedGridContext } from '@/components/grid/types';
import { getMetricColumns } from '@/components/metrics/MetricsConfig';
import { GridApi } from 'ag-grid-enterprise';
import { useEffect } from 'react';

interface UseComparisonUnitColumnDataProps<T> {
  gridApiRef: React.MutableRefObject<GridApi<T> | undefined>;
  gridContextRef: React.MutableRefObject<ExpandedGridContext | undefined>;
  comparisonUnit: ComparisonUnit;
}

const useComparisonUnitColumnData = <T>({ gridApiRef, gridContextRef, comparisonUnit }: UseComparisonUnitColumnDataProps<T>) => {
  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    gridContextRef.current.comparisonUnit = comparisonUnit;
    gridApiRef.current.refreshCells({ columns: getMetricColumns(), force: true });
  }, [comparisonUnit]);

  // Not needed because comparison unit isn't fetched and always exists
  //   const onGridReadyForCampaignGroupIdColumnData = () => {  };
  return { comparisonUnit };
};

export default useComparisonUnitColumnData;
