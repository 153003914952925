import { truncateName } from '@/modules/application/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FilterOptionsState,
  ListItemButton,
  ListSubheader,
  Paper,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash-es';
import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { AlMultiSelectOptionModel } from '../filter-builder/models/AlMultiSelectOptionModel';

const NUM_CHIPS_TO_SHOW_FOCUSED = 5;
const NUM_CHIPS_TO_SHOW_UNFOCUSED = 3;
interface AlMultiSelectProps<T = string> {
  options: AlMultiSelectOptionModel[] | undefined;
  selectedOptionIds: T[];
  setSelectedOptionIds: (ids: T[]) => void;
  isDisabled?: boolean;
  label?: string;
  placeholderText?: string;
}

export const AlMultiSelect = <T = string,>({
  options,
  selectedOptionIds,
  setSelectedOptionIds,
  isDisabled,
  label,
  placeholderText,
}: AlMultiSelectProps<T>): React.JSX.Element => {
  const [inputValue, setInputValue] = useState('');

  const defaultFilterOptions = createFilterOptions<AlMultiSelectOptionModel>();

  const selectedValues = useMemo(() => options?.filter((d) => selectedOptionIds.includes(d.id as T)), [options, selectedOptionIds]);
  const currentlyShownOptions = useRef<AlMultiSelectOptionModel[]>([]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: AlMultiSelectOptionModel[]) => {
    setSelectedOptionIds(value.map((v) => v.id as T));
  };

  const handleSelectAll = () => {
    const state: FilterOptionsState<AlMultiSelectOptionModel> = {
      inputValue,
      getOptionLabel: (option) => option.name,
    };

    if (options) {
      const filteredOptions = defaultFilterOptions(options, state);
      setSelectedOptionIds(filteredOptions.map((v) => v.id as T));
    }
  };

  const handleDeselectAll = () => {
    if (!options) {
      setSelectedOptionIds([]);
      return;
    }
    const currentlyShownOptionIds = new Set(currentlyShownOptions.current.map((v) => v.id as T));
    setSelectedOptionIds(options.filter((option) => !currentlyShownOptionIds.has(option.id as T)).map((v) => v.id as T));
  };

  const handleDelete = (indexToDelete: number) => {
    setSelectedOptionIds(selectedOptionIds.filter((_, index) => index !== indexToDelete));
  };

  function filterOptions(options: AlMultiSelectOptionModel[], state: FilterOptionsState<AlMultiSelectOptionModel>) {
    const filteredOptions = defaultFilterOptions(options, state);
    currentlyShownOptions.current = filteredOptions;
    return filteredOptions;
  }

  // Scrolling
  const scrollPosition = useRef(0);

  // How many chips to show
  // isFocused is managed using useRef which doesn't trigger a re-render when updated.
  const isFocused = useRef(false);

  // Calculate the height of each item based on its content
  const getItemSize = (index: number) => {
    const option = currentlyShownOptions.current[index];
    if (!option) return 24; // Default height

    let baseHeight = 24;

    // text-xs + width of 500 allows for about 70 characters on a single line
    // If the text is longer, increase the height
    if (option.chipLabel) {
      baseHeight += 10; // account for label padding
    }
    if (option.name.length + (option.chipLabel?.length || 0) > 60) {
      baseHeight += 20;
    }

    return baseHeight;
  };

  // ListboxComponent with react-window
  const ListboxComponent = forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, _ref) {
    const itemCount = currentlyShownOptions.current.length; // +1 for the header
    const rowHeight = itemCount > 0 && !isNil(currentlyShownOptions.current?.[0].chipLabel) ? 34 : 24; // assumes that all options either have or have not chips, when this isn't true, the height will be just less
    const height = Math.min(300, rowHeight * itemCount + 17);

    const renderRow = ({ index, style }: ListChildComponentProps) => {
      const option = currentlyShownOptions.current[index];
      const selected = selectedOptionIds.includes(option.id as T);

      return (
        <ListItemButton
          {...props}
          key={option.id}
          style={style}
          className={'px-2 py-1 text-xs'}
          onClick={() => {
            if (selectedValues) {
              setSelectedOptionIds(
                selected
                  ? selectedValues.filter((v) => v.id != option.id).map((v) => v.id as T)
                  : [...selectedValues.map((o) => o.id as T), option.id as T],
              );
            }
          }}
          selected={selected}
        >
          <div className="flex items-center">
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 6 }}
              checked={selected}
            />
            <div className="break-all">{option.name}</div>

            {option.chipLabel && (
              <Chip label={option.chipLabel} className={`font-small ml-2`} variant="outlined" color={option.chipColor} size="small" />
            )}
          </div>
        </ListItemButton>
      );
    };

    const handleScroll = ({ scrollOffset }: { scrollOffset: number }) => {
      scrollPosition.current = scrollOffset;
    };

    return (
      <ul {...props}>
        <Paper className="sticky z-10 top-0 rounded-none" elevation={0}>
          <div className="flex flex-row gap-x-4 pl-2 pt-1 h-11">
            <Button variant="text" onClick={handleSelectAll}>
              {'Select All'}
            </Button>
            <Button variant="text" onClick={handleDeselectAll}>
              {currentlyShownOptions.current.length != options?.length ? 'Deselect All Shown' : 'Deselect All'}
            </Button>
          </div>
          <Divider />
        </Paper>
        <VariableSizeList
          height={height}
          itemCount={itemCount}
          itemSize={getItemSize}
          width="100%"
          initialScrollOffset={scrollPosition.current}
          onScroll={handleScroll}
        >
          {renderRow}
        </VariableSizeList>
      </ul>
    );
  });

  // Handle pasting
  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (!options) {
      toast.warn('No options available');
      return;
    }
    event.preventDefault(); // Prevent the default paste behavior
    const pastedText = event.clipboardData.getData('Text');
    const pastedElements = pastedText
      .split(/[\t\n\r]+/)
      .map((tag) => tag.trim())
      .filter((tag) => tag !== '');
    const uniqueElements = [...new Set(pastedElements)];
    if (uniqueElements.length < pastedElements.length) {
      toast.warn('Pasted values contained duplicates. Each value was added only once');
    }

    // Create a map for faster retrieval
    const optionNameToOption: Record<string, AlMultiSelectOptionModel> = {};
    options.forEach((option) => {
      optionNameToOption[option.name.trim()] = option;
    });

    // Split the input into 2 arrays
    const newSelectedOptionIds: T[] = [];
    const unknownElements: string[] = [];
    for (const element of uniqueElements) {
      if (element in optionNameToOption) {
        newSelectedOptionIds.push(optionNameToOption[element].id as T);
      } else {
        unknownElements.push(element);
      }
    }

    // If there are unknown elements, display a warning
    if (unknownElements.length > 0) {
      const INVALID_COUNT_TO_SHOW = 3;
      const otherCount = unknownElements.length - INVALID_COUNT_TO_SHOW;
      const message =
        'Unknown values and were not added: ' +
        unknownElements.slice(0, INVALID_COUNT_TO_SHOW).join(', ') +
        (unknownElements.length > INVALID_COUNT_TO_SHOW
          ? `, and ${unknownElements.length - INVALID_COUNT_TO_SHOW} other${otherCount > 1 ? 's' : ''}`
          : '');
      toast.warn(message);
    }

    // Known elements are added
    setSelectedOptionIds(newSelectedOptionIds);
  };

  // Virtualized multi select does not support grouping
  const useVirtualizedMultiSelect = useMemo(
    () => !isNil(options) && options.length > 0 && (isNil(options[0].categoryName) || isEmpty(options[0].categoryName)),
    [options],
  );

  return (
    <>
      {options && (
        <Autocomplete
          multiple
          id={'multiselect-' + label}
          style={{ width: 500 }}
          filterOptions={filterOptions}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={selectedValues ? undefined : (placeholderText ?? label)}
              InputLabelProps={{ shrink: true }}
            />
          )}
          groupBy={(option) => option.categoryName}
          renderGroup={(params) => (
            <span key={params.key}>
              <ListSubheader className="mt-2" style={{ lineHeight: '24px' }}>
                {params.group}
              </ListSubheader>
              {params.children}
            </span>
          )}
          renderTags={(value) => {
            const numToShow = isFocused ? NUM_CHIPS_TO_SHOW_FOCUSED : NUM_CHIPS_TO_SHOW_UNFOCUSED;
            return (
              <Box>
                {value.slice(0, numToShow).map((tag, index) => (
                  <Chip
                    key={index}
                    className="m-0.5"
                    size="small"
                    label={truncateName(tag.name)}
                    onDelete={() => handleDelete(index)}
                    disabled={isDisabled}
                  />
                ))}
                {value.length > numToShow && <Chip label={`+${value.length - numToShow} more`} disabled={isDisabled} />}
              </Box>
            );
          }}
          options={options}
          value={selectedValues}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            // newInputValue is empty in event type click
            if (event && event.type === 'change') {
              setInputValue(newInputValue);
            }
          }}
          ListboxComponent={useVirtualizedMultiSelect ? ListboxComponent : undefined}
          renderOption={
            useVirtualizedMultiSelect
              ? undefined
              : (props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}

                    {option.chipLabel && (
                      <Chip label={option.chipLabel} className={`font-medium ml-2`} variant="outlined" color={option.chipColor} />
                    )}
                  </li>
                )
          }
          onFocus={() => (isFocused.current = true)}
          onBlur={() => {
            if (inputValue != '') {
              setInputValue('');
            }

            isFocused.current = false;
          }}
          disabled={isDisabled}
          onPaste={handlePaste}
        />
      )}
    </>
  );
};
