import { RouteObject } from 'react-router-dom';
import ProfilesStatsPage from '../pages/ProfilesStatsPage';

export const PROFILES_STATS_ROUTES: RouteObject[] = [
  {
    path: 'profiles-stats',
    children: [
      {
        index: true,
        element: <ProfilesStatsPage />,
      },
    ],
  },
];
