import { CommonMetrics } from '@/components/metrics/models/CommonMetricsModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { MetricData, MetricDataWithPreviousDays } from '@/modules/targeting/models/TargetsModel';
import { CampaignGroupMetricsDTO } from '../campaign-contracts';
import { CampaignGroupArguments, CampaignGroupModel } from './CampaignGroupModel';

export class CampaignGroupMetricsModel extends CampaignGroupModel {
  public impressions: MetricData;
  public clicks: MetricData;
  public orders: MetricData;
  public ctr: MetricData;
  public cvr: MetricData;
  public cpc: MetricData;
  public spend: MetricDataWithPreviousDays;
  public sales: MetricDataWithPreviousDays;
  public acos: MetricData;
  public roas: MetricData;
  public rpc: MetricData;
  public cpa: MetricData;
  public aov: MetricData;
  public cpm: MetricData;

  constructor(args: CampaignGroupMetricsArguments) {
    super(args);

    this.impressions = args.metrics.impressions;
    this.clicks = args.metrics.clicks;
    this.orders = args.metrics.orders;
    this.ctr = args.metrics.ctr;
    this.cvr = args.metrics.cvr;
    this.cpc = args.metrics.cpc;
    this.spend = args.metrics.spend;
    this.sales = args.metrics.sales;
    this.acos = args.metrics.acos;
    this.roas = args.metrics.roas;
    this.rpc = args.metrics.rpc;
    this.cpa = args.metrics.cpa;
    this.aov = args.metrics.aov;
    this.cpm = args.metrics.cpm;
  }

  public static fromResponseArray(dtos: CampaignGroupMetricsDTO[]): CampaignGroupMetricsModel[] {
    return dtos.map(CampaignGroupMetricsModel.fromCampaignGroupResponse);
  }

  public static fromCampaignGroupResponse(response: CampaignGroupMetricsDTO): CampaignGroupMetricsModel {
    // Assuming CommonMetrics.fromResponse correctly constructs a CommonMetrics instance

    return new CampaignGroupMetricsModel({
      id: response.id,
      adType: response.ad_type,
      name: response.name,
      tacos: response.tacos,
      preset: response.preset ?? OptimizationPreset.NOT_SET,
      bidCeiling: response.bid_ceiling,
      totalCampaigns: response.total_campaigns,
      metrics: CommonMetrics.fromResponse(response.metrics),
    });
  }

  public static fromCampaignGroupModel(model: CampaignGroupModel): CampaignGroupMetricsModel {
    return new CampaignGroupMetricsModel({
      id: model.id,
      adType: model.adType,
      name: model.name,
      tacos: model.tacos,
      preset: model.preset,
      bidCeiling: model.bidCeiling,
      totalCampaigns: model.totalCampaigns,
      metrics: CommonMetrics.withZeroMetrics(),
    });
  }
}

interface CampaignGroupMetricsArguments extends CampaignGroupArguments {
  metrics: CommonMetrics;
}
