import { FilterBuilderDialog } from '@/components/filter-builder/FilterBuilderDialog';
import { FilterChipRow } from '@/components/filter-builder/FilterChipRow';
import { AlFilterModel, createCampaignMappingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { LoadingButton } from '@mui/lab';
import { Button, Card, Divider } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { useCampaignMappingContext } from '../contexts/CampaignMappingContext';
import { CampaignMappingModel } from '../models/CampaignMappingModel';
import DownloadCampaignMappingsButton from './DownloadCampaignMappingsButton';
import DownloadCampaignMappingsTemplateButton from './DownloadCampaignMappingsTemplateButton';
import ImportCampaignMappingsButton from './ImportCampaignMappingsButton';
import OpenNewSingleMappingModalButton from './OpenNewSingleMappingModalButton';

interface CampaignMappingFilterBarProps {
  onlyDownloadButton?: boolean; // true in import view
  onDeleteAllConfirmed?: () => void;
  isDeleteLoading?: boolean;
  rowData?: CampaignMappingModel[];
}

const CampaignMappingFilterBar: FunctionComponent<CampaignMappingFilterBarProps> = ({
  onlyDownloadButton,
  onDeleteAllConfirmed,
  isDeleteLoading,
  rowData,
}) => {
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false); // state to control the popover
  const { filters, setFilters } = useCampaignMappingContext();

  const onFilterChipClicked = () => {
    setIsFilterDialogOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  const availableCampaignMappingFilters = createCampaignMappingFilters();

  function onFiltersChanged(newFilters: AlFilterModel[]) {
    setFilters(newFilters);
  }

  // Delete
  const { ModalComponent, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete all mappings currently shown in this table? This action cannot be undone.',
    headerText: 'Delete All Mappings',
    onDelete:
      onDeleteAllConfirmed ??
      (() => {
        // do nothing if onDeleteAllConfirmed is not defined
      }),
  });

  return (
    <Card className="flex flex-row items-center gap-x-4 p-2 pb-3 rounded-none rounded-tr-xl rounded-tl-xl -mb-1 ">
      {/* <CampaignMappingSearch /> */}
      <div className="flex flex-row gap-x-4">
        <>
          {onlyDownloadButton ? (
            <DownloadCampaignMappingsTemplateButton />
          ) : (
            <>
              <ImportCampaignMappingsButton />
              <OpenNewSingleMappingModalButton />
            </>
          )}
        </>
        <Divider className="my-2" orientation="vertical" flexItem />
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={onFilterChipClicked}
          startIcon={<FilterListRoundedIcon />}
          ref={filtersButtonRef}
          className="mr-2 flex-shrink-0"
        >
          Adjust Filters
        </Button>

        <FilterBuilderDialog
          buttonRef={filtersButtonRef}
          setIsOpen={setIsFilterDialogOpen}
          isOpen={isFilterDialogOpen}
          availableFilters={availableCampaignMappingFilters}
          appliedFilters={filters}
          onFiltersChanged={onFiltersChanged}
          defaultFilterKey={FilterKey.SOURCE_CAMPAIGN_NAME}
        />

        <FilterChipRow
          filters={filters}
          onFilterDeleteButtonClicked={onFilterDeleteButtonClicked}
          onFilterChipClicked={onFilterChipClicked}
        />
      </div>
      <div className="flex flex-grow"></div>
      <div className="flex gap-x-4">
        {onDeleteAllConfirmed && (
          <>
            <LoadingButton
              loading={isDeleteLoading}
              disabled={isDeleteLoading}
              variant="outlined"
              size="small"
              color="error"
              onClick={openDeleteConfirmationModal}
              startIcon={<DeleteForeverIcon />}
            >
              {filters.length > 0 ? 'Delete Shown Mappings' : 'Delete All Mappings'}
            </LoadingButton>
            {ModalComponent}
          </>
        )}

        {rowData && rowData.length > 0 && <DownloadCampaignMappingsButton rowData={rowData} />}
      </div>
    </Card>
  );
};

export default CampaignMappingFilterBar;
