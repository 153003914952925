import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';

export interface IButtonCallbackCellRendererParams<T> {
  prefixText?: string;
  buttonText: string;
  callback: (rowData: T) => void;
  variant?: 'text' | 'outlined' | 'contained';
  isDisabled?: boolean;
  tooltip?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  isLoading?: boolean;
}

export interface IButtonCallbackCellRendererProps extends ICellRendererParams, IButtonCallbackCellRendererParams<unknown> {}

const ButtonCallbackCellRenderer: FunctionComponent<IButtonCallbackCellRendererProps> = ({
  data,
  callback,
  buttonText,
  variant = 'contained',
  isDisabled = false,
  prefixText,
  tooltip,
  color = 'primary',
  isLoading = false,
}) => {
  const handleClick = () => {
    if (data) {
      callback(data);
    }
  };

  return (
    <Tooltip title={tooltip}>
      <div className="flex flex-row h-full items-center gap-2">
        {prefixText}
        <LoadingButton loading={isLoading} variant={variant} onClick={handleClick} disabled={isDisabled} color={color}>
          {buttonText}
        </LoadingButton>
      </div>
    </Tooltip>
  );
};

export default ButtonCallbackCellRenderer;
