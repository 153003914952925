import { FunctionComponent, PropsWithChildren } from 'react';

export const SecondaryMenu: FunctionComponent<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  return (
    <>
      <div className="flex w-52 shrink-0 flex-col border-r   bg-gray-200   dark:border-r-gray-800 dark:bg-gray-850">
        <div className="mt-px flex flex-col  gap-y-4 px-4 pb-4 pt-2.5">{children}</div>
      </div>
    </>
  );
};
