import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { CampaignsGroupedByAdTypeRow } from '@/modules/dashboards/hooks/useCampaignCalculations';
import { ColDef } from 'ag-grid-enterprise';
import { EntityType } from '../../forms/EntityTypeSelect';
import { GroupByCategory, GroupByField } from '../../forms/GroupBySelect';
import { v4 } from 'uuid';
import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';

export interface ColumnConfiguration {
  columnId: string | ColumnId | undefined;
  isVisible: boolean;
  label: string | undefined;
}

export class TableWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'Table';
  entityType = EntityType.PROFILE;
  campaignGroupByCategory = GroupByCategory.PROPERTY;
  campaignGroupByValue: number | GroupByField = GroupByField.AD_TYPE;
  campaignAdType = CampaignAdType.BRANDS;
  targetsGroupByCategory = GroupByCategory.PROPERTY;
  targetsGroupByValue: number | GroupByField = GroupByField.AD_TYPE;
  metricColumns: ColumnConfiguration[] = TABLE_METRIC_COLUMNS.map((column) => ({
    columnId: column.colId,
    isVisible: true,
    label: column.headerName,
  }));

  campaignFilters: FilterDTO[] = [];
  targetsFilters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }

  get hasFilters(): boolean {
    return (
      (this.campaignFilters.length > 0 && (this.entityType === EntityType.CAMPAIGN || this.entityType === EntityType.PROFILE)) ||
      (this.targetsFilters.length > 0 && this.entityType === EntityType.KEYWORD)
    );
  }
}

export const TABLE_METRIC_COLUMNS: ColDef<CampaignsGroupedByAdTypeRow>[] = [
  {
    colId: ColumnId.IMPRESSIONS,
    lockVisible: true,
    headerName: 'Impressions',
    field: 'impressions',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.CLICKS,
    lockVisible: true,
    headerName: 'Clicks',
    field: 'clicks',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.ORDERS,
    lockVisible: true,
    headerName: 'Orders',
    field: 'orders',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.CTR,
    lockVisible: true,
    headerName: 'CTR',
    field: 'ctr',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.CVR,
    lockVisible: true,
    headerName: 'CVR',
    field: 'cvr',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.CPC,
    lockVisible: true,
    headerName: 'CPC',
    field: 'cpc',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.SPEND,
    lockVisible: true,
    headerName: 'Spend',
    field: 'spend',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.SALES,
    lockVisible: true,
    headerName: 'Sales',
    field: 'sales',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.ACOS,
    lockVisible: true,
    headerName: 'ACOS',
    field: 'acos',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.ROAS,
    lockVisible: true,
    headerName: 'ROAS',
    field: 'roas',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.RPC,
    lockVisible: true,
    headerName: 'RPC',
    field: 'rpc',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.CPA,
    lockVisible: true,
    headerName: 'CPA',
    field: 'cpa',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.AOV,
    lockVisible: true,
    headerName: 'AOV',
    field: 'aov',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
  {
    colId: ColumnId.CPM,
    lockVisible: true,
    headerName: 'CPM',
    field: 'cpm',
    type: 'metricFieldWithChangePercentage',
    menuTabs: ['generalMenuTab'],
  },
];
