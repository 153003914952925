import { BusinessRounded } from '@mui/icons-material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainMenuTextButton } from './MainMenuTextButton';
import { Routes } from '@/router/router-paths';

interface ManageProfilesButtonProps {
  isExpanded: boolean;
}
export const ManageProfilesButton: FunctionComponent<ManageProfilesButtonProps> = ({ isExpanded }) => {
  const [activePathnames /*setActivePathnames*/] = useState([Routes.PROFILES]);
  const [isRouteActive, setIsRouteActive] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(activePathnames.some((r) => currentLocation.pathname.startsWith(r)));
    }
  }, [activePathnames, currentLocation.pathname]);

  function onManageProfilesButtonClicked() {
    navigate({ pathname: Routes.PROFILES });
  }

  return (
    <MainMenuTextButton
      icon={<BusinessRounded />}
      isActive={isRouteActive}
      isExpanded={isExpanded}
      label="Manage Profiles"
      onClick={onManageProfilesButtonClicked}
    />
  );
};
