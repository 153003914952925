// useTranslation.ts
import { useState, useEffect } from 'react';
import i18n from './i18nConfig';

export function useTranslation() {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const onLanguageChanged = (lng: string) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', onLanguageChanged);
    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, []);

  return {
    t: (key: string) => i18n.t(key),
    language,
    changeLanguage: (lng: string) => {
      i18n.changeLanguage(lng);
    },
  };
}
