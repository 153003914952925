import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface AlSelectProps<T, V> {
  label?: string;
  value: V | '';
  options: T[];
  onChange: (event: SelectChangeEvent<V>) => void;
  renderOption: (item: T) => React.ReactNode;
  valueExtractor: (item: T) => V;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
  helperWarningText?: string;
}

function AlSelect<T, V>({
  label,
  value,
  options,
  onChange,
  renderOption,
  valueExtractor,
  disabled,
  placeholder,
  helperText,
  helperWarningText,
}: AlSelectProps<T, V>): React.JSX.Element {
  return (
    <FormControl fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <Select value={value || ''} onChange={onChange} label={label} disabled={disabled} placeholder={placeholder}>
        {options.map((option, index) => (
          <MenuItem key={`${valueExtractor(option)}` || `${index}`} value={valueExtractor(option) as string}>
            {renderOption(option)}
          </MenuItem>
        ))}
      </Select>
      {helperWarningText && (
        <FormHelperText>
          <span className="flex flex-row">
            <WarningRoundedIcon style={{ fontSize: '16px', marginRight: '4px' }} />
            {helperWarningText}
          </span>
        </FormHelperText>
      )}
      {!helperWarningText && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default AlSelect;
