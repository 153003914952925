import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { logsService } from '../api/logs-service';

interface useLogFetchProps {
  actionId?: string | undefined;
  isAutoFetchEnabled?: boolean;
}

const useLogFetch = ({ actionId, isAutoFetchEnabled = true }: useLogFetchProps) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const queryKey = useMemo(() => logsService.createGetLogsQueryKey(activeProfile?.id, actionId), [activeProfile?.id, actionId]);

  const queryClient = useQueryClient();

  // Common query function for both single fetch and continuous fetch
  const fetchData = useCallback(
    async (actionId: string) => {
      const result = await logsService.getLogs(actionId);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error(`Error loading logs ${result.message}`);
      }
    },
    [logsService],
  );

  // Single fetch (downloading)
  const fetchLogOnDemand = useCallback(
    async (actionId: string | undefined): Promise<object[] | undefined> => {
      if (isNil(actionId)) {
        toast.error('Action ID is undefined');
        console.error('actionId is undefined');
        return [];
      }
      const queryKey = logsService.createGetLogsQueryKey(activeProfile?.id, actionId);

      // Check if data is in the cache
      let data: object[] | undefined = queryClient.getQueryData(queryKey);

      if (!data) {
        // If not in cache, fetch it and update the cache
        try {
          data = await queryClient.fetchQuery({ queryKey: queryKey, queryFn: () => fetchData(actionId) });
        } catch (error) {
          toast.error(`Error fetching logs: ${error}`);
        }
      }

      return data;
    },
    [activeProfile?.id, fetchData, queryClient],
  );

  // Continuous fetch (table)
  const {
    data: rowData,
    isLoading: isLogLoading,
    isError: isLogLoadingError,
    error: logLoadingError,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      if (isNil(actionId)) {
        return undefined;
      }
      return await fetchData(actionId);
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile) && !isNil(actionId) && isAutoFetchEnabled,
  });

  return { rowData, isLogLoading, isLogLoadingError, logLoadingError, fetchLogOnDemand };
};

export default useLogFetch;
