import { IDashboardWidgetCatalogItem } from '../../types/IDashboardWidgetCatalogItem';
import { BAR_GRAPH_WIDGET_CATALOG_ITEM } from './bar-graph-widget/BarGraphWidgetCatalogItem';
import { LINE_GRAPH_WIDGET_CATALOG_ITEM } from './line-graph-widget/LineGraphWidgetCatalogItem';
import { METRIC_WIDGET_CATALOG_ITEM } from './metric-widget/MetricWidgetCatalogItem';
import { PIE_GRAPH_WIDGET_CATALOG_ITEM } from './pie-graph-widget/PieGraphWidgetCatalogItem';
import { TABLE_WIDGET_CATALOG_ITEM } from './table-widget/TableWidgetCatalogItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WIDGET_CATALOG: IDashboardWidgetCatalogItem<any>[] = [
  LINE_GRAPH_WIDGET_CATALOG_ITEM,
  METRIC_WIDGET_CATALOG_ITEM,
  TABLE_WIDGET_CATALOG_ITEM,
  BAR_GRAPH_WIDGET_CATALOG_ITEM,
  PIE_GRAPH_WIDGET_CATALOG_ITEM,
];
