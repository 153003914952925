import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { ImportType } from '../api/importer.contracts';
import { CampaignMappingImportPage } from '../importers/campaign-mapping/CampaignMappingImportPage';
import { ImporterOptionsPage } from '../pages/configuration/ImporterOptionsPage';

export const DATA_MANAGEMENT_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'data-management',
    children: [
      {
        index: true,
        element: <Navigate to="import" replace />,
      },
      {
        path: 'import',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ImporterOptionsPage />,
          },
          {
            path: `:${ImportType.CAMPAIGN_MAPPING}`,
            element: <CampaignMappingImportPage />,
          },
        ],
      },
    ],
  },
];
