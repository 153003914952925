import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext, useUserSettingsContext, userService, UserSettingKey } from '@/modules/users';
import { APP_DEFAULT_PATH } from '@/router/router';
import { Routes } from '@/router/router-paths';
import { isNumber, isString } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useLogin = () => {
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const { setActiveTeam } = useActiveTeamContext();
  const { loadUserSettings } = useUserSettingsContext();

  /**
   * Log in a user into AdLabs, loading all required data (user, settings, etc.)
   * Also navigates to the correct page
   * @param loggedInFirebaseUserId The UID of the currently logged in user in Firebase
   * @param skipImpersonation If true, impersonation will be skipped even if the user is an admin
   * @returns void
   */
  async function logIn(loggedInFirebaseUserId: string, skipImpersonation = false) {
    // Load user
    const getAdLabsUserResponse = await userService.getUserByFirebaseId(loggedInFirebaseUserId);

    if (getAdLabsUserResponse.isSuccess) {
      const user = getAdLabsUserResponse.payload;

      if (user.isAdmin && !skipImpersonation) {
        navigate(Routes.IMPERSONATE);
        return;
      }

      setUser(user);

      // Load user settings
      const settings = await loadUserSettings(user.id);

      // Check if user has active profile id setting
      const activeTeamId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_TEAM_ID)?.value;
      const activeProfileId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_PROFILE_ID)?.value;

      setActiveTeam({
        teamId: isNumber(activeTeamId) ? activeTeamId : undefined,
        profileId: isString(activeProfileId) ? activeProfileId : undefined,
        userTeams: user.teams,
      });

      navigate(APP_DEFAULT_PATH);
    } else {
      toast.error('Error loading user');
      return;
    }
  }

  return { logIn };
};

export { useLogin };
