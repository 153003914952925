import { CommonMetrics } from '@/components/metrics/models/CommonMetricsModel';
import { ProfileCreateArguments, ProfileModel } from './ProfileModel';
import { ProfilesMetricsWithTeamDTO } from '../api/profile.contracts';

export class ProfileWithMetricsModel extends ProfileModel {
  public teamId: number;
  public teamName: string;
  public metrics: CommonMetrics;

  constructor(args: ProfileMetricsArguments) {
    super(args);
    this.teamId = args.teamId;
    this.teamName = args.teamName;
    this.metrics = args.metrics;
  }

  public static fromUserProfilesWithMetricsResponse(dtos: ProfilesMetricsWithTeamDTO[]): ProfileWithMetricsModel[] {
    return dtos.map(
      (dto) =>
        new ProfileWithMetricsModel({
          ...ProfileModel.fromDTO(dto),
          teamId: dto.team_id,
          teamName: dto.team_name,
          metrics: CommonMetrics.fromResponse(dto.metrics),
        }),
    );
  }
}

interface ProfileMetricsArguments extends ProfileCreateArguments {
  teamId: number;
  teamName: string;
  metrics: CommonMetrics;
}
