import { createTargetingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useDataGroups } from '@/hooks/useDataGroups';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { campaignService, createAdGroupsQueryKey, createCampaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { emptyUnAssignedCampaignGroup } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

const useAvailableTargetingFilters = () => {
  const { dataGroups } = useDataGroups([DataGroupType.CAMPAIGN, DataGroupType.TARGET]);
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const {
    data: adGroups,
    isLoading: isLoadingAdGroups,
    error: errorAdGroups,
  } = useQuery({
    queryKey: createAdGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getAdGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading ad groups');
        return []; // Return empty array to prevent crashes
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const {
    data: campaignGroups,
    isLoading: isLoadingCampaignGroups,
    error: errorCampaignGroups,
  } = useQuery({
    queryKey: createCampaignGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading campaign groups');
        return []; // Return empty array to prevent crashes
      }
    },
    select: (campaignGroups) => {
      if (!campaignGroups.find((group) => group.id === 0)) {
        campaignGroups.unshift(emptyUnAssignedCampaignGroup);
      }
      return campaignGroups;
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const isLoading = isLoadingAdGroups || isLoadingCampaignGroups;
  const error = errorAdGroups || errorCampaignGroups; // You might want to handle errors more precisely in a real application.

  // TODO move this logic to the hook? No need to memo twice?
  const campaignDataGroups = useMemo(
    () => (dataGroups ? dataGroups.filter((group) => group.type === DataGroupType.CAMPAIGN) : []),
    [dataGroups],
  );
  const targetDataGroups = useMemo(() => (dataGroups ? dataGroups.filter((group) => group.type === DataGroupType.TARGET) : []), [dataGroups]);

  const availableTargetingFilters = createTargetingFilters(adGroups || [], campaignGroups || [], campaignDataGroups, targetDataGroups);

  const availableTargetingFilterBuilderFilters = availableTargetingFilters.filter((filter) => filter.isFilterBuilderFilter);

  return {
    availableTargetingFilters,
    availableTargetingFilterBuilderFilters,
    isLoading,
    error,
  };
};

export default useAvailableTargetingFilters;
