import { useTranslation } from '@/lib';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { dataGroupsService, invalidateTeam_getAllGroups } from '../api/data-groups-service';
import { DataGroupModel } from '../models/DataGroup';
import { DataItemModel, NewDataItem } from '../models/DataItem';
import { DataGroupType } from '../models/data-groups-contracts';
import { EditableDataItemsList } from './EditableDataItemsList';

interface CreateDataGroupProps {
  existingGroups: DataGroupModel[];
  onClose: () => void;
  onCreateConfirmed: (dataGroup: DataGroupModel) => void;
  fixedDataGroupType?: DataGroupType;
}

export const CreateDataGroup: FunctionComponent<CreateDataGroupProps> = ({
  existingGroups,
  onClose,
  onCreateConfirmed,
  fixedDataGroupType,
}) => {
  const { t } = useTranslation();

  const [groupType, setGroupType] = useState<DataGroupType | ''>(fixedDataGroupType ?? '');
  const [groupName, setGroupName] = useState('');
  const [nameError, setNameError] = useState('');

  const [items, setItems] = useState<NewDataItem[]>([{ name: '' }]);
  const itemsEmptyError = items.length === 0 || (items.length == 1 && items[0].name === '');

  const queryClient = useQueryClient();
  const { activeTeam } = useActiveTeamContext();

  const [isCreateInProgress, setIsCreateInProgress] = useState(false);
  const checkGroupNameExistsInType = (name: string, dataGroupType: DataGroupType | ''): boolean => {
    return (
      !isNil(existingGroups) &&
      existingGroups.filter((g) => g.type === dataGroupType).some((group) => group.name.toLowerCase() === name.toLowerCase())
    );
  };

  const handleDataGroupTypeChange = (event: SelectChangeEvent<DataGroupType>) => {
    const newDataGroupType = event.target.value as DataGroupType;
    setGroupType(newDataGroupType);
    if (!isEmpty(groupName)) {
      setNameError(checkGroupNameExistsInType(groupName, newDataGroupType) ? 'This group already exists' : '');
    }
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setGroupName(name);
    setNameError(checkGroupNameExistsInType(name, groupType) ? 'This group already exists' : isEmpty(name) ? "Name can't be empty" : '');
  };

  const handleCreate = async () => {
    if (isNil(groupType) || isEmpty(groupType)) {
      toast.error('Please select an ad type');
      return;
    }

    if (isEmpty(groupName)) {
      toast.error('Please enter a group name');
      return;
    }

    if (itemsEmptyError) {
      toast.error('Please enter at least one value');
      return;
    }

    setIsCreateInProgress(true);

    const dataItems = items
      .filter((item) => item.name !== '')
      .map(
        (item) =>
          new DataItemModel({
            id: 0, // not used
            name: item.name,
          }),
      );

    const newGroup = new DataGroupModel({
      id: 0, // not used
      name: groupName,
      type: groupType,
      items: dataItems,
    });

    const res = await dataGroupsService.createGroup(newGroup);
    if (res.isSuccess) {
      invalidateTeam_getAllGroups(queryClient, activeTeam?.id);
      onCreateConfirmed(newGroup); // assumes that createConfirmed handles closing
      setIsCreateInProgress(false);
      toast.success(`Group '${groupName}' created`);
    } else {
      toast.error(`Error creating: '${res.message}'`);
      setIsCreateInProgress(false);
      onClose();
    }
  };

  let createButtonTooltipText = '';
  if (isEmpty(groupType)) {
    createButtonTooltipText = 'Data Group Type not specified';
  } else if (groupName === '') {
    createButtonTooltipText = 'Group Name not specified';
  } else if (!isEmpty(nameError)) {
    createButtonTooltipText = 'Problem with Group Name';
  } else if (itemsEmptyError) {
    createButtonTooltipText = 'Add at least one value';
  }
  return (
    <>
      <DialogTitle>Create New Data Group</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off">
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <FormControl className="min-w-32">
                <InputLabel>Type</InputLabel>
                <Select value={groupType} disabled={!isNil(fixedDataGroupType)} onChange={handleDataGroupTypeChange} label="Type">
                  {Object.keys(DataGroupType).map((groupType) => (
                    <MenuItem key={groupType} value={groupType}>
                      {t(`optimizer_page.data_group_type.${groupType}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                className="min-w-72"
                autoFocus
                error={!isEmpty(nameError)}
                label="Group Name"
                value={groupName}
                onChange={handleGroupNameChange}
                helperText={nameError}
              />
            </div>
            <div className="flex flex-col max-w-52">
              <EditableDataItemsList items={items} setItems={setItems} deleteItemIds={[]} setDeleteItemIds={() => {}} originalItems={[]} />
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>

        <Tooltip placement="top" title={createButtonTooltipText}>
          <span>
            <LoadingButton
              onClick={handleCreate}
              loading={isCreateInProgress}
              loadingPosition="start"
              startIcon={<Check />}
              variant="contained"
              disabled={!isEmpty(nameError) || isEmpty(groupType) || itemsEmptyError}
            >
              Create Data Group
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};
