import { DTO } from '@/types/dto-wrapper';
import { ResponseDebugDataRow, ResponseDebugDataRowWithPlacement } from '../api/optimization-contracts';
import { MetricData } from '@/modules/targeting/models/TargetsModel';
import { PlacementType } from '@/modules/optimizer/api/campaign/campaign-contracts';

export class DebugDataRow extends DTO<ResponseDebugDataRow> {
  constructor(data: ResponseDebugDataRow) {
    super(data);
  }

  public get tcpa(): MetricData {
    return this.dto.tcpa;
  }

  public get tcpc(): MetricData {
    return this.dto.tcpc;
  }

  public get rpcInherited(): string {
    return this.dto.rpc_inherited;
  }

  public get aovInherited(): string {
    return this.dto.aov_inherited;
  }

  public get actcInherited(): string {
    return this.dto.actc_inherited;
  }

  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricData {
    return this.dto.spend;
  }

  public get sales(): MetricData {
    return this.dto.sales;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public static fromResponse(dto: ResponseDebugDataRow): DebugDataRow {
    return new DebugDataRow(dto);
  }

  public static fromResponseArray(responseArray: ResponseDebugDataRow[]): DebugDataRow[] {
    return responseArray.map(DebugDataRow.fromResponse);
  }
}

export class DebugDataRowWithPlacement extends DebugDataRow {
  public placementType: PlacementType;

  constructor(row: ResponseDebugDataRow, placementType: PlacementType) {
    super(row);
    this.placementType = placementType;
  }

  public static fromResponse(dto: ResponseDebugDataRowWithPlacement): DebugDataRowWithPlacement {
    return new DebugDataRowWithPlacement(dto, dto.placement_type);
  }

  public static fromResponseArray(responseArray: ResponseDebugDataRowWithPlacement[]): DebugDataRowWithPlacement[] {
    return responseArray.map(DebugDataRowWithPlacement.fromResponse);
  }
}
