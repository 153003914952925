import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export function initDayjs() {
  dayjs.extend(relativeTime);
  dayjs.extend(weekOfYear);
  dayjs.extend(utc);
  dayjs.extend(timezone);
}
