import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { BUTTON_WIDTH } from '@/modules/application/pages/GettingStartedPage';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useUserContext, userService } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { debounce, isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthorizeAmazonDialog from '../components/AuthorizeAmazonDialog';
import { PreferredTimePicker } from '../components/PreferredTimePicker';
import ProfileTable from '../components/ProfileTable';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { ProfileSelectorDialog } from '../components/ProfileSelectorDialog';
import { SellerOrVendorCentralSelectionDialog } from '../components/SellerOrVendorCentralSelectionDialog';
import { Environment } from '@/config/Environment';
import { sleep } from '@/lib/api/api-utils';
import { SELLER_CENTRAL_COUNTRY_CODE_LOCAL_STORAGE_KEY } from '@/modules/users/api/users/users.contracts';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { planService } from '@/modules/plans/api/plan.service';

const TeamProfilesPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeTeam, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { user, refetchUser } = useUserContext();
  const {
    ModalComponent: pastDueModalComponent,
    handleOpenModal: handleOpenPastDueModal,
    setIsLoading,
  } = useConfirmationModal({
    questionText: 'Your payment is past due. Please update your payment information to avoid service interruption.',
    onConfirm: () => {
      redirectToBillingPortal();
    },
    questionTitle: 'Payment Past Due',
    cancelButtonText: 'Close',
    confirmButtonText: 'Go to Billing Portal',
    closeOnConfirm: false,
  });
  // Amazon redirects back to this page including code and state GET params
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const errorDescription = searchParams.get('error_description');
  const spapiOauthCode = searchParams.get('spapi_oauth_code');
  const sellingPartnerId = searchParams.get('selling_partner_id');

  async function redirectToBillingPortal() {
    try {
      setIsLoading(true);

      const billingPortalLinkResponse = await planService.getBillingLinkForTeam(Routes.BILLING);
      if (billingPortalLinkResponse.isSuccess) {
        // Navigate to billing portal
        window.location.href = billingPortalLinkResponse.payload.session_url;
      } else {
        toast.error(`Something went wrong. Please try again later. ${billingPortalLinkResponse.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
      setIsLoading(false);
    }
  }

  // Auth amazon ads
  const { mutate: authorizeUser } = useMutation({
    mutationFn: ({ teamId, userId, code, state }: { teamId: number; userId: number; code: string; state: string }) =>
      userService.authorizeUser(teamId, userId, { code, state, is_registration: false }),

    onSuccess: async (res) => {
      if (res.isSuccess) {
        toast.success(`Amazon authorization successful`);
        await refetchUser();
        navigate(Routes.PROFILES, { replace: true });
        setOpenProfileSelectorDialog(true);
      } else if (!activeTeam?.amazonAccount?.isAuthorized) {
        toast.error(`Amazon authorization failed: ${res.message}`);
      }
    },
  });

  const debouncedAuthorizeUser = debounce(authorizeUser, 250);

  // If GET params are present, authorize user
  useEffect(() => {
    if (!isNil(code) && !isEmpty(code) && !isNil(state) && !isEmpty(state) && activeTeam && user && user.id != 0) {
      debouncedAuthorizeUser({ teamId: activeTeam.id, userId: user.id, code, state });
    }
  }, [code, state, activeTeam, user]);

  //If error_description is present in GET params, show error
  useEffect(() => {
    if (!isEmpty(errorDescription)) {
      toast.error(errorDescription + ' Please try again!');
      navigate(Routes.PROFILES, { replace: true });
    }

    if (activeTeam?.isPastDue) {
      handleOpenPastDueModal();
    } else if (hasUserOverMaxAllowedFreeProfiles()) {
      setIsPaywallModalOpen(true);
    }
  }, []);

  const [openAuthorizeAmazonDialog, setOpenAuthorizeAmazonDialog] = useState(false);

  function onManageProfilesClicked() {
    if (!activeTeam?.amazonAccount?.isAuthorized) {
      setOpenAuthorizeAmazonDialog(true);
    } else {
      setOpenProfileSelectorDialog(true);
    }
  }

  // Auth seller / vendor central

  const [openAuthorizeSellerVendorCentralDialog, setOpenAuthorizeSellerVendorCentralDialog] = useState(false);

  function onAuthorizeSellerVendorCentral() {
    setOpenAuthorizeSellerVendorCentralDialog(!openAuthorizeSellerVendorCentralDialog);
  }

  // If GET params are present, authorize user
  useEffect(() => {
    if (
      !isNil(spapiOauthCode) &&
      !isEmpty(spapiOauthCode) &&
      !isNil(state) &&
      !isEmpty(state) &&
      !isNil(sellingPartnerId) &&
      !isEmpty(sellingPartnerId) &&
      activeTeam
    ) {
      const sellerCentralMarket = localStorage.getItem(SELLER_CENTRAL_COUNTRY_CODE_LOCAL_STORAGE_KEY);
      if (sellerCentralMarket) {
        const profile = activeTeam.profiles.find(
          (profile) => profile.accountId === sellingPartnerId && profile.countryCode === sellerCentralMarket,
        );

        // get profile from
        if (profile && !isLoadingAuthSellerCentral) {
          debouncedAuthorizeSellerCentral({ teamId: activeTeam.id, profileId: profile.id, code: spapiOauthCode, state });
          localStorage.removeItem(SELLER_CENTRAL_COUNTRY_CODE_LOCAL_STORAGE_KEY);
        } else {
          toast.error(`Unable to find profile for ${sellingPartnerId} for market ${sellerCentralMarket}`);
        }
      }

      navigate(Routes.PROFILES, { replace: true });
    }
  }, [spapiOauthCode, state, sellingPartnerId, activeTeam]);

  const { mutate: authorizeSellerCentral, isPending: isLoadingAuthSellerCentral } = useMutation({
    mutationFn: ({ teamId, profileId, code, state }: { teamId: number; profileId: string; code: string; state: string }) =>
      userService.authorizeSellerCentral(teamId, profileId, { code, state }),

    onSuccess: async (res) => {
      if (res.isSuccess) {
        toast.success(`Amazon seller central authorization successful`);
      } else {
        toast.error(`Amazon seller central authorization failed: ${res.message}`);
      }

      await sleep(300);
      await refetchUser();
    },
  });

  const debouncedAuthorizeSellerCentral = debounce(authorizeSellerCentral, 250);

  // Profile selector
  const [openProfileSelectorDialog, setOpenProfileSelectorDialog] = useState(false);
  const handleClose = () => {
    setOpenProfileSelectorDialog(false);
  };

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={t('team_profiles_page.header')}
          actions={
            <div className="flex flex-row items-center gap-2">
              <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <TeamSelect />
            </div>
          }
        ></PageLayoutTopBar>
        <PageLayoutBody>
          <div className="my-4 flex items-center justify-start">
            <div className="flex items-center gap-4">
              <Button variant="contained" onClick={onManageProfilesClicked} style={{ width: BUTTON_WIDTH }}>
                Manage Profiles
              </Button>
              {Environment.isDev() && (
                <Button variant="contained" onClick={onAuthorizeSellerVendorCentral} style={{ width: BUTTON_WIDTH }}>
                  Authorize Seller Central
                </Button>
              )}
            </div>
          </div>

          <AlErrorBoundary>
            <ProfileTable />
          </AlErrorBoundary>
        </PageLayoutBody>
      </PageLayout>

      <AuthorizeAmazonDialog isOpen={openAuthorizeAmazonDialog} setIsOpen={setOpenAuthorizeAmazonDialog} />

      <ProfileSelectorDialog open={openProfileSelectorDialog} onClose={handleClose} />

      <SellerOrVendorCentralSelectionDialog open={openAuthorizeSellerVendorCentralDialog} onClose={onAuthorizeSellerVendorCentral} />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.PROFILES}
        headerText="Upgrade to Pro to use more than 3 profiles or kindly remove any profiles exceeding this limit"
      ></PaywallModal>
      {pastDueModalComponent}
    </>
  );
};

export default TeamProfilesPage;
