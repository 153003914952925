import { BiddingMethod } from '@/modules/campaign-mapping/api/campaign-mapping-contracts';
import { NegativeMatchType, negativesArrayToNegativeBoolsObj } from '@/modules/negative-targets/api/negative-targets-contracts';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { MetricData, MetricDataWithPreviousDays } from '@/modules/targeting/models/TargetsModel';
import { DTO } from '@/types/dto-wrapper';
import { isNil } from 'lodash-es';
import { ResponseKeywordHarvestingPreviewDataRowDTO } from '../api/keyword-harvesting-contracts';

export class KeywordHarvestingPreviewDataRow extends DTO<ResponseKeywordHarvestingPreviewDataRowDTO> {
  originalBid?: number;

  constructor(data: ResponseKeywordHarvestingPreviewDataRowDTO) {
    super(data);

    this.originalBid = data.b ?? 0;
  }

  public get id(): string {
    return this.dto.s + this.dto.det + this.dto.m + this.dto.dai;
  }

  public get searchTerm(): string {
    return this.dto.s;
  }

  public get match(): MatchType {
    return convertMatchTypeToKnown(this.dto.m);
  }

  public get biddingMethod(): BiddingMethod {
    return this.dto.bdm;
  }

  public get biddingMethodValue(): number {
    return this.dto.bdmv;
  }

  public get bid(): number {
    return this.dto.b;
  }

  public set bid(value: number) {
    this.dto.b = value;
  }

  public get bidFloor(): number {
    return this.dto.bf;
  }

  public get bidCeiling(): number {
    return this.dto.bc;
  }

  public get sourceAdGroupEntityType(): TargetEntityType {
    return this.dto.det;
  }

  public get destinationAdGroupEntityType(): TargetEntityType {
    return this.dto.det;
  }

  public get sourceCampaignId(): string {
    return this.dto.sci;
  }
  public get sourceCampaignName(): string {
    return this.dto.scn;
  }

  public get sourceCampaignAdType(): CampaignAdType {
    return this.dto.sct;
  }

  public get sourceCampaignIsVideo(): boolean {
    return this.dto.sv ?? false;
  }

  public get sourceAdGroupId(): string {
    return this.dto.sai;
  }

  public get sourceAdGroupName(): string {
    return this.dto.sagn;
  }

  public get destinationCampaignId(): string {
    return this.dto.dci;
  }

  public get destinationCampaignName(): string {
    return this.dto.dcn;
  }

  public get destinationCampaignAdType(): CampaignAdType {
    return this.dto.dct;
  }

  public get destinationCampaignIsVideo(): boolean {
    return this.dto.dv ?? false;
  }

  public get destinationAdGroupId(): string {
    return this.dto.dai;
  }

  public get destinationAdGroupName(): string {
    return this.dto.dagn;
  }

  // NEG CAMPAIGN //
  public get campaignNegativeExact(): boolean {
    return this.dto.cne;
  }

  public set campaignNegativeExact(value: boolean) {
    this.dto.cne = value;
  }

  public get campaignNegativePhrase(): boolean {
    return this.dto.cnp;
  }

  public set campaignNegativePhrase(value: boolean) {
    this.dto.cnp = value;
  }

  public get campaignNegativeProductTarget(): boolean {
    return this.dto.cnpt;
  }

  public set campaignNegativeProductTarget(value: boolean) {
    this.dto.cnpt = value;
  }

  // NEG ADGROUP //
  public get adGroupNegativeExact(): boolean {
    return this.dto.agne;
  }

  public set adGroupNegativeExact(value: boolean) {
    this.dto.agne = value;
  }

  public get adGroupNegativePhrase(): boolean {
    return this.dto.agnp;
  }

  public set adGroupNegativePhrase(value: boolean) {
    this.dto.agnp = value;
  }

  public get adGroupNegativeProductTarget(): boolean {
    return this.dto.agnpt;
  }

  public set adGroupNegativeProductTarget(value: boolean) {
    this.dto.agnpt = value;
  }

  // Metric getters
  public get impressions(): MetricData {
    return this.dto.stats.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.stats.clicks;
  }

  public get orders(): MetricData {
    return this.dto.stats.orders;
  }

  public get ctr(): MetricData {
    return this.dto.stats.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.stats.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.stats.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.stats.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.stats.sales;
  }

  public get sso(): MetricData {
    return this.dto.stats.sso;
  }

  public get sss(): MetricData {
    return this.dto.stats.sss;
  }

  public get acos(): MetricData {
    return this.dto.stats.acos;
  }

  public get roas(): MetricData {
    return this.dto.stats.roas;
  }

  public get rpc(): MetricData {
    return this.dto.stats.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.stats.cpa;
  }

  public get aov(): MetricData {
    return this.dto.stats.aov;
  }

  public get cpm(): MetricData {
    return this.dto.stats.cpm;
  }

  public setNegativesFromArray(negatives: NegativeMatchType[]) {
    // This method merges the second object's properties into the first object, updating the corresponding values.
    Object.assign(this.dto, negativesArrayToNegativeBoolsObj(negatives));
  }

  public static fromResponseArray(responseArray: ResponseKeywordHarvestingPreviewDataRowDTO[]): KeywordHarvestingPreviewDataRow[] {
    return isNil(responseArray) ? [] : responseArray.map(this.fromResponse);
  }

  public static fromResponse(dto: ResponseKeywordHarvestingPreviewDataRowDTO): KeywordHarvestingPreviewDataRow {
    return new KeywordHarvestingPreviewDataRow(dto);
  }
}

function convertMatchTypeToKnown(match: string): MatchType {
  switch (match) {
    case 'ASIN_SAME_AS':
      return MatchType.INDIVIDUAL;
    case 'ASIN_EXPANDED_FROM':
      return MatchType.EXPANDED;
  }

  return match as MatchType;
}
