import AlGrid from '@/components/grid/AlGrid';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import ChipArrayCellRenderer, { ChipArrayChip, IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import { useUserContext } from '@/modules/users';
import { Delete, GroupAdd } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { DASHBOARDS_QUERY_KEY } from '../../constants/query-keys';
import { DashboardModel } from '../../types/DashboardModel';
import ManageDashboardCollaboratorsModal from './ManageDashboardCollaboratorsModal';
import { ProfileState } from '@/modules/users/types/ProfileState';

interface DashboardsTableProps {
  dashboards?: DashboardModel[];
  isLoading: boolean;
}

const DashboardsTable: FunctionComponent<DashboardsTableProps> = ({ dashboards, isLoading }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const customGridOptions: GridOptions<DashboardModel> = { reactiveCustomComponents: true };
  const { user } = useUserContext();
  const [isManageCollaboratorsModalOpen, setIsManageCollaboratorsModalOpen] = useState(false);
  const [dashboardIdToManageCollaborators, setDashboardIdToManageCollaborators] = useState<number>();

  function isUserOwnerOfDashboard(userId?: number) {
    return userId === user?.id;
  }

  const [isLoadingDeleteDashboard, setIsLoadingDeleteDashboard] = useState(false);
  async function onDeleteDashboardClicked(dashboardId: number | undefined) {
    if (!dashboardId) return;

    setIsLoadingDeleteDashboard(true);
    const result = await dashboardService.delete(dashboardId);
    if (result.isSuccess) {
      toast.success('Dashboard deleted');

      queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEY],
      });
    }

    setIsLoadingDeleteDashboard(false);
  }

  function onEditCollaboratorsClicked(dashboard: DashboardModel | undefined) {
    if (!dashboard) return;

    setIsManageCollaboratorsModalOpen(true);
    setDashboardIdToManageCollaborators(dashboard.dto.id);
  }

  const columnDefs: ColDef<DashboardModel>[] = [
    {
      field: 'dto.name',
      headerName: 'Name',

      cellRenderer: LinkCallbackCellRenderer,
      cellRendererParams: (params: ICellRendererParams<DashboardModel>): ILinkCallbackCellRendererParams<DashboardModel> => {
        return {
          buttonText: params.data?.dto.name || '',
          tooltip: 'View this Dashboard',
          callback: (db) => navigate(`/dashboards/${db.dto.id}`),
        };
      },
    },
    {
      field: 'dto.widgets',
      headerName: 'Widgets',
      valueGetter: (params: ValueGetterParams<DashboardModel>) => {
        return params.data?.dto.widgets?.length || 0;
      },
    },
    {
      field: 'dto.currency',
      headerName: 'Currency',
    },
    {
      field: 'dto.profiles',
      headerName: 'Profiles',
      cellRenderer: ChipArrayCellRenderer,
      cellRendererParams: (params: ICellRendererParams<DashboardModel>): IChipArrayCellRendererParams => {
        let chipArrayChips: ChipArrayChip[] = [];
        if (params.data?.dto.profiles) {
          chipArrayChips = params.data.dto.profiles.map((profile) => {
            return {
              value: profile.profile_name + ' (' + profile.country_code + ')',
              color: profile.state === ProfileState.ACTIVE ? 'primary' : 'warning',
            };
          });
        }
        return {
          chipArrayChips: chipArrayChips,
          maxChipsToShow: 3,
        };
      },
      flex: 1,
    },
    {
      field: 'dto.collaborators',
      headerName: 'Collaborators',
      cellRenderer: ChipArrayCellRenderer,
      cellRendererParams: (params: ICellRendererParams<DashboardModel>): IChipArrayCellRendererParams => {
        let chipArrayChips: ChipArrayChip[] = [];
        if (params.data?.dto.collaborators) {
          chipArrayChips = params.data.dto.collaborators.map((collaborator) => {
            return {
              value: collaborator.name,
              color: 'secondary',
            };
          });
        }
        return {
          chipArrayChips: chipArrayChips,
          maxChipsToShow: 3,
        };
      },
      flex: 1,
    },
    {
      colId: ColumnId.ACTIONS,
      headerName: 'Actions',
      width: 255,
      pinned: 'right',
      cellRenderer: ButtonGroupCellRenderer,
      cellRendererParams: (params: ICellRendererParams<DashboardModel>): IButtonGroupCellRendererParams => {
        if (!isUserOwnerOfDashboard(params.data?.dto.user_id)) {
          return { buttons: [] };
        }

        return {
          buttons: [
            <RowActionButton
              color="default"
              key="edit"
              text="Collaborators"
              tooltipText={`Edit '${params.data?.dto.name}' Collaborators`}
              onClick={() => onEditCollaboratorsClicked(params.data)}
              icon={<GroupAdd />}
            ></RowActionButton>,

            <RowActionButton
              key="delete"
              text="Delete"
              isLoadingText="Deleting..."
              color="red"
              isDisabled={isLoadingDeleteDashboard}
              tooltipText={`Delete '${params.data?.dto.name}'`}
              isLoading={isLoadingDeleteDashboard}
              onClick={() => onDeleteDashboardClicked(params.data?.dto.id)}
              icon={<Delete />}
            ></RowActionButton>,
          ],
        };
      },
    },
  ];

  const dashboardToManageCollaborators = dashboards?.find((db) => db.dto.id === dashboardIdToManageCollaborators);

  return (
    <>
      <AlGrid rowData={dashboards} isLoading={isLoading} gridOptions={customGridOptions} colDefs={columnDefs} />
      {dashboardToManageCollaborators && (
        <ManageDashboardCollaboratorsModal
          isOpen={isManageCollaboratorsModalOpen}
          dashboard={dashboardToManageCollaborators}
          onClose={() => {
            setIsManageCollaboratorsModalOpen(false);
            setDashboardIdToManageCollaborators(undefined);
          }}
          onCollaboratorsUpdated={() => {
            queryClient.invalidateQueries({
              queryKey: [DASHBOARDS_QUERY_KEY],
            });
          }}
        />
      )}
    </>
  );
};
export default DashboardsTable;
