import useFormatting from '@/hooks/useFormatting';
import { EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MetricDataWithPreviousDays } from '@/modules/targeting/models/TargetsModel';
import { IAggFuncParams } from 'ag-grid-enterprise';
import { NONE_LABEL, StringToCount, collectLeafNodeValues, countEnumValues } from '../helpers';

function useAggregators() {
  const { formatDateStringTimeNoSeconds } = useFormatting();

  function campaignNameAggFunc(params: IAggFuncParams) {
    const count = new Set(collectLeafNodeValues(params)).size;
    return `${count} campaign${count === 1 ? '' : 's'}`;
  }

  function targetsAggFunc(params: IAggFuncParams) {
    const count = new Set(collectLeafNodeValues(params)).size;
    return `${count} target${count === 1 ? '' : 's'}`;
  }

  function adGroupsAggFunc(params: IAggFuncParams) {
    const count = new Set(collectLeafNodeValues(params)).size;
    return `${count} ad group${count === 1 ? '' : 's'}`;
  }

  function portfolioAggFunc(params: IAggFuncParams) {
    const count = new Set(collectLeafNodeValues(params)).size;
    return `${count} portfolio${count === 1 ? '' : 's'}`;
  }

  function groupNameAggFunc(params: IAggFuncParams) {
    const unqValues = new Set(collectLeafNodeValues(params).filter((str) => str !== ''));
    const count = unqValues.size;

    return count === 1 ? '1 opt group' : `${count} opt groups`;
  }

  function enabledPausedArchivedStateAggFunc(params: IAggFuncParams) {
    return countEnumValues(EnabledPausedArchivedState, collectLeafNodeValues(params));
  }

  function booleanAggFunc(params: IAggFuncParams) {
    return collectLeafNodeValues<boolean>(params).reduce(
      (counts, currentValue) => {
        if (currentValue) {
          counts.trueCount += 1;
        } else {
          counts.falseCount += 1;
        }
        return counts;
      },
      { trueCount: 0, falseCount: 0 }, // Initial values
    );
  }

  function metricDataWithPreviousDaysAggFunc(params: IAggFuncParams): number[] {
    if (!params.values) return [];

    return (params.values as MetricDataWithPreviousDays[]).reduce((acc: number[], array) => {
      if (!array) return acc;
      array.forEach((metricValue, index) => {
        if (acc[index]) {
          acc[index] += metricValue;
        } else {
          acc[index] = metricValue;
        }
      });
      return acc;
    }, []);
  }

  function stringCountingAggFunc(params: IAggFuncParams) {
    const counts = collectLeafNodeValues<string>(params).reduce<Record<string, { value: string; count: number }>>((acc, value) => {
      const stringValue = value;

      if (!acc[stringValue]) {
        acc[stringValue] = { value: stringValue, count: 0 };
      }
      acc[stringValue].count += 1;
      return acc;
    }, {});

    return Object.entries(counts)
      .filter(([, valueCount]) => valueCount.count > 0)
      .map(([, valueCount]) => `${valueCount.value == '' ? NONE_LABEL : valueCount.value}: ${valueCount.count}`)
      .join(' | ');
  }

  function lastOptimizedAggFunc(params: IAggFuncParams) {
    const unqValues = new Set(collectLeafNodeValues(params));
    const unqCount = unqValues.size;
    if (unqValues.size === 1) {
      const optTime = unqValues.keys().next().value;
      if (!optTime) return 'Never optimized any';
      return 'All optimized at ' + formatDateStringTimeNoSeconds(optTime);
    }
    return `${unqCount} different optimization times`;
  }

  function stringToCountAggFunc(params: IAggFuncParams): StringToCount {
    const aggregation: StringToCount = {};

    for (const str of collectLeafNodeValues<string>(params)) {
      if (aggregation[str]) {
        aggregation[str] += 1;
      } else {
        aggregation[str] = 1;
      }
    }

    return aggregation;
  }

  return {
    campaignNameAggFunc,
    enabledPausedArchivedStateAggFunc,
    groupNameAggFunc,
    stringCountingAggFunc,
    lastOptimizedAggFunc,
    stringToCountAggFunc,
    metricDataWithPreviousDaysAggFunc,
    targetsAggFunc,
    adGroupsAggFunc,
    portfolioAggFunc,
    booleanAggFunc,
  };
}

export default useAggregators;
