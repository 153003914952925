export enum FieldNames {
  SourceCampaign = 'SourceCampaign',
  SourceAdGroup = 'SourceAdGroup',
  DestinationCampaign = 'DestinationCampaign',
  DestinationAdGroup = 'DestinationAdGroup',
  StartingBidMethod = 'StartingBidMethod',
  StartingBidMethodValue = 'StartingBidMethodValue',
  StartingBidBidFloorOptional = 'StartingBidBidFloorOptional',
  StartingBidBidCeilingOptional = 'StartingBidBidCeilingOptional',
  Exact = 'Exact',
  Phrase = 'Phrase',
  Broad = 'Broad',
  IndivPT = 'IndivPT',
  ExpandedPT = 'ExpandedPT',
  NegExact = 'NegExact',
  NegPhrase = 'NegPhrase',
  NegPT = 'NegPT',
  CampNegExact = 'CampNegExact',
  CampNegPhrase = 'CampNegPhrase',
  CampNegPT = 'CampNegPT',
}

export const CAMPAIGN_MAPPING_IMPORT_ROW_KEYS = [
  FieldNames.SourceCampaign,
  FieldNames.SourceAdGroup,
  FieldNames.DestinationCampaign,
  FieldNames.DestinationAdGroup,
  FieldNames.StartingBidMethod,
  FieldNames.StartingBidMethodValue,
  FieldNames.StartingBidBidFloorOptional,
  FieldNames.StartingBidBidCeilingOptional,
  FieldNames.Exact,
  FieldNames.Phrase,
  FieldNames.Broad,
  FieldNames.IndivPT,
  FieldNames.ExpandedPT,
  FieldNames.CampNegExact,
  FieldNames.CampNegPhrase,
  FieldNames.CampNegPT,
  FieldNames.NegExact,
  FieldNames.NegPhrase,
  FieldNames.NegPT,
];

export interface CampaignMappingImportRow {
  [FieldNames.SourceCampaign]: string;
  [FieldNames.SourceAdGroup]: string;
  [FieldNames.DestinationCampaign]: string;
  [FieldNames.DestinationAdGroup]: string;
  [FieldNames.StartingBidMethod]: string;
  [FieldNames.StartingBidMethodValue]: string;
  [FieldNames.StartingBidBidFloorOptional]: string;
  [FieldNames.StartingBidBidCeilingOptional]: string;
  [FieldNames.Exact]: string;
  [FieldNames.Phrase]: string;
  [FieldNames.Broad]: string;
  [FieldNames.IndivPT]: string;
  [FieldNames.ExpandedPT]: string;
  [FieldNames.NegExact]: string;
  [FieldNames.NegPhrase]: string;
  [FieldNames.NegPT]: string;
  [FieldNames.CampNegExact]: string;
  [FieldNames.CampNegPhrase]: string;
  [FieldNames.CampNegPT]: string;
}

export type CampaignMappingImportRowArray = [
  CampaignMappingImportRow[FieldNames.SourceCampaign],
  CampaignMappingImportRow[FieldNames.SourceAdGroup],
  CampaignMappingImportRow[FieldNames.DestinationCampaign],
  CampaignMappingImportRow[FieldNames.DestinationAdGroup],
  CampaignMappingImportRow[FieldNames.StartingBidMethod],
  CampaignMappingImportRow[FieldNames.StartingBidMethodValue],
  CampaignMappingImportRow[FieldNames.StartingBidBidFloorOptional],
  CampaignMappingImportRow[FieldNames.StartingBidBidCeilingOptional],
  CampaignMappingImportRow[FieldNames.Exact],
  CampaignMappingImportRow[FieldNames.Phrase],
  CampaignMappingImportRow[FieldNames.Broad],
  CampaignMappingImportRow[FieldNames.IndivPT],
  CampaignMappingImportRow[FieldNames.ExpandedPT],
  CampaignMappingImportRow[FieldNames.NegExact],
  CampaignMappingImportRow[FieldNames.NegPhrase],
  CampaignMappingImportRow[FieldNames.NegPT],
  CampaignMappingImportRow[FieldNames.CampNegExact],
  CampaignMappingImportRow[FieldNames.CampNegPhrase],
  CampaignMappingImportRow[FieldNames.CampNegPT],
];

export const emptyCampaignMappingImportRow = {
  [FieldNames.SourceCampaign]: '',
  [FieldNames.SourceAdGroup]: '',
  [FieldNames.DestinationCampaign]: '',
  [FieldNames.DestinationAdGroup]: '',
  [FieldNames.StartingBidMethod]: '',
  [FieldNames.StartingBidMethodValue]: '',
  [FieldNames.StartingBidBidFloorOptional]: '',
  [FieldNames.StartingBidBidCeilingOptional]: '',
  [FieldNames.Exact]: '',
  [FieldNames.Phrase]: '',
  [FieldNames.Broad]: '',
  [FieldNames.IndivPT]: '',
  [FieldNames.ExpandedPT]: '',
  [FieldNames.NegExact]: '',
  [FieldNames.NegPhrase]: '',
  [FieldNames.NegPT]: '',
  [FieldNames.CampNegExact]: '',
  [FieldNames.CampNegPhrase]: '',
  [FieldNames.CampNegPT]: '',
};
