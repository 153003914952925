import { createContext, useState, useContext, FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { UserModel } from '../types/UserModel';
import { QueryObserverResult, RefetchOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { userService } from '../api/users/users.service';
import Gleap from 'gleap';
import { isNil } from 'lodash-es';
import * as Sentry from '@sentry/react';

const UPDATE_USER_REFETCH_INTERVAL = 10 * 60 * 1000;
interface UserContextProps {
  user: UserModel | undefined;
  setUser: (user: UserModel | undefined) => void;
  refetchUser: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<UserModel | undefined, Error>>;
  isLoading: boolean;
  isAdminModeActive: boolean;
  toggleAdminMode: () => void;
  firebaseId: string | undefined;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps extends PropsWithChildren {
  initialUser: UserModel | undefined;
}

export const UserProvider: FunctionComponent<UserProviderProps> = ({ children, initialUser }) => {
  const queryClient = useQueryClient();
  const [isAdminModeActive, setIsAdminModeActive] = useState<boolean>(false);
  const [isAutoUpdateUserEnabled, setIsAutoUpdateUserEnabled] = useState(false);

  const USER_QUERY_KEY = ['user']; // we do not need unique key as one user is logged in at the same time
  const cachedUserData = queryClient.getQueryData<UserModel>(USER_QUERY_KEY);
  const {
    data: user,
    refetch: refetchUser,
    isFetching: isLoading,
  } = useQuery({
    queryKey: USER_QUERY_KEY,
    queryFn: async () => {
      const userId = (cachedUserData?.id ?? initialUser?.id) as number;

      if (userId) {
        const userResponse = await userService.getUserById(userId);
        if (userResponse.isSuccess) {
          return userResponse.payload;
        }
      } else {
        Sentry.captureMessage(`User ID is undefined`, 'info');
      }

      return undefined;
    },
    initialData: initialUser,
    enabled: isAutoUpdateUserEnabled,
    refetchInterval: UPDATE_USER_REFETCH_INTERVAL,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAutoUpdateUserEnabled(true); // Enable the query after the initial delay
    }, UPDATE_USER_REFETCH_INTERVAL);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  function setUser(user: UserModel | undefined) {
    if (!isNil(user)) {
      queryClient.setQueryData<UserModel>(USER_QUERY_KEY, (oldUser) => {
        if (!isNil(user)) {
          return user;
        }
        return oldUser;
      });
    }
  }

  useEffect(() => {
    if (!isNil(user)) {
      setGleapIdentity(user);
    }
  }, [user]);

  async function setGleapIdentity(user: UserModel | undefined) {
    try {
      if (user && user.hash) {
        // Create a map of teams to send to gleap
        const teamsData = user.teams.reduce<{ [key: string]: string }>((acc, team, index) => {
          acc[`team${index + 1}name`] = team.name;
          acc[`team${index + 1}plan`] = team.adlabsPlan;
          return acc;
        }, {});

        // fetch user data from the server
        const userGleapData = await userService.getUserGleapData(user.id);
        const gleapDataObject = userGleapData.isSuccess && userGleapData.httpResponseCode !== 204 ? userGleapData.payload : {};

        Gleap.identify(
          user.firebaseId,
          {
            email: user.email,
            name: user.name,
            customData: {
              userFirebaseId: user.firebaseId,
              ...gleapDataObject,
              ...teamsData,
            },
          },
          user.hash,
        );
      } else {
        Gleap.clearIdentity();
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          user,
        },
      });
    }
  }

  function toggleAdminMode() {
    setIsAdminModeActive(!isAdminModeActive);
  }

  return (
    <UserContext.Provider value={{ user, setUser, refetchUser, isLoading, firebaseId: user?.firebaseId, isAdminModeActive, toggleAdminMode }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext(): UserContextProps {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
}
