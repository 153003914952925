import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiProfileClient } from '@/lib/api/base-client';
import { CreatedEntityDTO } from '@/modules/application/types/UpdatedEntity';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { QueryClient } from '@tanstack/react-query';
import { TargetModel } from '../models/TargetsModel';
import {
  TargetCreateDTO,
  TargetCreatePayloadType,
  TargetUpdateDTO,
  TargetsPerProfileWithTimelineResponse,
  TargetsWithTimeline,
  TargetsWithTimelineDTO,
  UpdateTargetsDTO,
  UpdatedTargetsDTO,
} from './targets-contracts';
import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';

const _TARGETING_WITH_TIMELINE_QUERY_KEY = 'targeting-with-timeline';

// Main key to use
export function createTargetingWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const targetingWithTimelineQueryKey_withoutFilters = createTargetingWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...targetingWithTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

function createTargetingWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_TARGETING_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

export function invalidateProfile_targetingWithTimelineQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createTargetingWithTimelineQueryKey_withoutFilters(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

export function invalidateAll_targetingWithTimelineQueryKeys(queryClient: QueryClient) {
  const key = [_TARGETING_WITH_TIMELINE_QUERY_KEY];
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

export class TargetingService {
  public basePath = 'targets';

  async getTargetsWithTimeline(filters?: AlFilterModel[]): Promise<ApiResponse<TargetsWithTimeline>> {
    try {
      const filterData = filters?.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<TargetsWithTimelineDTO>(`${this.basePath}/with-timeline`, requestData);

      return ApiResponse.responseWithPayload<TargetsWithTimeline>(
        {
          targets: TargetModel.fromResponseArray(applicationResponse.payload.targets),
          timeline: TimelineModel.fromResponse(applicationResponse.payload.timeline),
          isComparisonDataMissing: applicationResponse.payload.comparison_missing,
        },
        200,
      );
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getTargetsWithTimelineForProfiles({
    filters,
    profiles,
  }: {
    filters: AlFilterModel[];
    profiles: {
      teamId: number;
      profileId: number;
    }[];
  }): Promise<ApiResponse<TargetsPerProfileWithTimelineResponse[]>> {
    try {
      console.log('getTargetsWithTimelineForProfiles');
      const filterData = filters?.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const campaignPromises = profiles.map((profile) =>
        apiClient.post<TargetsWithTimelineDTO>(
          `teams/${profile.teamId}/profiles/${profile.profileId}/${this.basePath}/with-timeline`,
          requestData,
        ),
      );
      const campaignResponses = await Promise.all(campaignPromises);

      const campaignsWithTimelineResponse: TargetsPerProfileWithTimelineResponse[] = campaignResponses.map((response, index) => {
        return {
          profileId: profiles[index].profileId.toString(),
          targets: TargetModel.fromResponseArray(response.payload.targets),
          timeline: TimelineModel.fromResponse(response.payload.timeline),
        };
      });

      if (campaignResponses.some((response) => !response.isSuccess)) {
        console.log(campaignsWithTimelineResponse);

        return ApiResponse.UnknownErrorResponse();
      }

      console.log(campaignsWithTimelineResponse);

      return ApiResponse.responseWithPayload(campaignsWithTimelineResponse, 200);
    } catch (error) {
      console.log(error);

      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateTargets(targetUpdates: TargetUpdateDTO[]): Promise<ApiResponse<UpdatedTargetsDTO[]>> {
    try {
      const requestData: UpdateTargetsDTO = {
        updates: targetUpdates,
      };

      return await apiProfileClient.post<UpdatedTargetsDTO[]>(`${this.basePath}/update`, requestData);
    } catch (error) {
      console.log(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async createTargets(targets: TargetCreateDTO[]): Promise<ApiResponse<CreatedEntityDTO[]>> {
    try {
      const requestData: TargetCreatePayloadType = {
        targets: targets,
      };

      return await apiProfileClient.post<CreatedEntityDTO[]>(`${this.basePath}/create`, requestData);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const targetingService = new TargetingService();
