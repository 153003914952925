import { useMemo } from 'react';
import { SelectedPlacement } from '../models/PlacementsModel';
import { EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';

export interface PlacementInfo {
  warnings: string[];
  archivedPlacementCount: number;
}

interface UsePlacementWarningsProps {
  selectedPlacements: SelectedPlacement[];
}

const usePlacementWarnings = ({ selectedPlacements }: UsePlacementWarningsProps) => {
  const preBulkEditInfo: PlacementInfo = useMemo(() => {
    const warningMessages: string[] = [];

    const archivedPlacementCount = selectedPlacements.filter((placement) => placement.state === EnabledPausedArchivedState.ARCHIVED).length;
    if (archivedPlacementCount > 0) {
      warningMessages.push(`Cannot change ${archivedPlacementCount} archived ${archivedPlacementCount > 1 ? 'placements' : 'placement'}`);
    }

    return {
      warnings: warningMessages,
      archivedPlacementCount: archivedPlacementCount,
    };
  }, [selectedPlacements]);

  return { ...preBulkEditInfo };
};

export default usePlacementWarnings;
