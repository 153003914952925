import React from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DashboardCollaboratorList from './DashboardCollaboratorList';
import { DashboardModel } from '../../types/DashboardModel';
import { useUserContext } from '@/modules/users';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';

interface ManageDashboardCollaboratorsModalProps {
  isOpen: boolean;
  onClose: () => void;
  dashboard: DashboardModel;
  onCollaboratorsUpdated: () => void;
}

const ManageDashboardCollaboratorsModal: React.FC<ManageDashboardCollaboratorsModalProps> = ({
  isOpen,
  onClose,
  dashboard,
  onCollaboratorsUpdated,
}) => {
  const { user } = useUserContext();
  const [selectedUsers, setSelectedUsers] = React.useState<{ id: number; name: string }[]>([]);

  let usersInAllTeams = user?.teams?.flatMap((team) => {
    return team.members.map((member) => {
      return { id: member.user.id, name: member.user.name };
    });
  });

  // Filter out duplicate users based on their ID
  // Also filter out the current user from the list of users to add as a collaborator (you can't add yourself as a collaborator)
  usersInAllTeams = usersInAllTeams
    ?.filter((user) => !dashboard.dto.collaborators.some((collaborator) => collaborator.user_id === user.id))
    .filter((user, index, self) => index === self.findIndex((u) => u.id === user.id) && user.id != dashboard.dto.user_id);

  if (!usersInAllTeams) {
    usersInAllTeams = [];
  }

  async function onAddCollaboratorsClicked() {
    console.log('selectedUsers', selectedUsers);
    const result = await Promise.all(
      selectedUsers.map((user) => {
        return dashboardService.addCollaborator(dashboard.dto.id, user.id);
      }),
    );

    if (result.every((r) => r.isSuccess)) {
      toast.success('Collaborators added successfully');
      onCollaboratorsUpdated();
      setSelectedUsers([]);
    } else {
      toast.error('Error adding collaborators');
    }
  }

  async function onCollaboratorRemoved(collaboratorId: number) {
    const result = await dashboardService.removeCollaborator(dashboard.dto.id, collaboratorId);
    if (result.isSuccess) {
      toast.success('Collaborator removed successfully');
      onCollaboratorsUpdated();
    } else {
      toast.error('Error removing collaborator');
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        <div className="flex flex-row">
          Manage Collaborators
          <div className="flex flex-grow"></div>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Manage who else can see and edit the &rdquo;{dashboard.dto.name}&rdquo; dashboard.</DialogContentText>

        <div className="w-full flex gap-x-8 ">
          <div className="w-1/2">
            <Typography variant="body2" className="mt-6 mb-2">
              Add Collaborators
            </Typography>
            {/* Select a user */}
            <Autocomplete
              id="combo-box-demo"
              options={usersInAllTeams}
              multiple
              fullWidth
              value={selectedUsers}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setSelectedUsers(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="User(s)" InputLabelProps={{ shrink: true }} />}
            />
            <Button className="mt-4" onClick={onAddCollaboratorsClicked}>
              {selectedUsers.length > 0 ? 'Add Collaborators' : 'Add Collaborators'}
            </Button>
          </div>
          <div className="w-1/2">
            <Typography variant="body2" className="mt-6 mb-2">
              Current Collaborators
            </Typography>
            {!dashboard.dto.collaborators || dashboard.dto.collaborators.length === 0 ? (
              <div className="text-xs text-gray-600 mt-4 "> No collaborators right now </div>
            ) : (
              <Paper className="max-w-52 mt-4">
                <DashboardCollaboratorList collaborators={dashboard.dto.collaborators} onCollaboratorRemoved={onCollaboratorRemoved} />
              </Paper>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageDashboardCollaboratorsModal;
