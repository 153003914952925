import { FunctionComponent, PropsWithChildren } from 'react';

interface PageLayoutBodyProps extends PropsWithChildren {
  suppressBottomPadding?: boolean;
}

export const PageLayoutBody: FunctionComponent<PageLayoutBodyProps> = ({ children, suppressBottomPadding = false }) => {
  return (
    <div className={`relative flex w-full flex-1 flex-grow flex-col items-stretch px-4 ${suppressBottomPadding ? 'pb-0' : 'pb-4'}`}>
      {children}
    </div>
  );
};
