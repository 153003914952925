import { MetricField } from '@/components/metrics/models/CommonMetricsModel';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { EntityType } from '../../forms/EntityTypeSelect';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { GroupByCategory, GroupByField } from '../../forms/GroupBySelect';
import { v4 } from 'uuid';
import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';

export class BarGraphWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'Bar Graph';
  entityType = EntityType.PROFILE;
  campaignGroupByCategory = GroupByCategory.PROPERTY;
  campaignGroupByValue: number | GroupByField = GroupByField.AD_TYPE;
  campaignAdType = CampaignAdType.BRANDS;
  targetsGroupByCategory = GroupByCategory.PROPERTY;
  targetsGroupByValue: number | GroupByField = GroupByField.AD_TYPE;
  selectedMetrics: MetricField[] = [MetricField.SALES, MetricField.ACOS, MetricField.SPEND, MetricField.ROAS];
  campaignFilters: FilterDTO[] = [];
  targetsFilters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
