import { AlFilterModel, createKeywordHarvestingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { PageLayoutBody } from '@/modules/application';
import DateRangeDisplay from '@/modules/application/components/date-range-picker/DateRangeDisplay';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Button, Dialog } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useRef, useState } from 'react';
import { FilterBuilderDialog } from '../../../../../components/filter-builder/FilterBuilderDialog';
import { FilterChip } from '../../../../../components/filter-builder/FilterChip';
import { KeywordHarvestingModel } from '../models/KeywordHarvestingModel';
import { KeywordHarvestingPreviewDataRow } from '../models/KeywordHarvestingPreviewDataRow';
import KeywordHarvestingPreviewActionsBar from './KeywordHarvestingPreviewActionsBar';
import KeywordHarvestingTable from './keyword-harvesting-table/KeywordHarvestingTable';

interface KeywordHarvestingPreviewModalProps {
  keywordHarvestingData: KeywordHarvestingModel | null;
  isOpen: boolean;
  onClose: () => void;
  onApplyChanges: (harvestingPreviewSelection: KeywordHarvestingPreviewDataRow[]) => void;
  filtersUsed: AlFilterModel[];
}

export const KeywordHarvestingPreviewModal: FunctionComponent<KeywordHarvestingPreviewModalProps> = ({
  keywordHarvestingData,
  isOpen,
  onClose,
  onApplyChanges,

  filtersUsed,
}) => {
  const [optimizationTableApi, setOptimizationTableApi] = useState<GridApi<KeywordHarvestingPreviewDataRow> | null>(null);
  const [harvestingPreviewSelection, setHarvestingPreviewSelection] = useState<KeywordHarvestingPreviewDataRow[]>([]);

  const onApply = () => {
    setIsApplyLoading(true);
    onApplyChanges(harvestingPreviewSelection);
    closeAndClearFilters();
    setHarvestingPreviewSelection([]);
  };

  const closeAndClearFilters = () => {
    onClose();
    setIsApplyLoading(false);
    setFilters([]);
  };

  // FILTERS
  const [filters, setFilters] = useState<AlFilterModel[]>([]);
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false); // state to control the popover
  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [visibleRowCount, setVisibleRowCount] = useState<number>(0);

  const onFiltersButtonClicked = () => {
    setIsFilterPopoverOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  const HARVESTING_FILTERS = createKeywordHarvestingFilters();

  const onFiltersChanged = (filters: AlFilterModel[]) => {
    setFilters(filters);
  };

  function onOptimizationTableGridReady(api: GridApi<KeywordHarvestingPreviewDataRow>) {
    setOptimizationTableApi(api);
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={closeAndClearFilters} fullWidth={true}>
      <PageLayoutBody suppressBottomPadding>
        <div className="flex w-full flex-row justify-between mt-4 mb-2">
          <div className="flex gap-x-4">
            <Button className="flex-shrink-0" onClick={closeAndClearFilters} variant="text" startIcon={<WestRoundedIcon />}>
              Back to Search Terms
            </Button>
            <Button
              variant="outlined"
              onClick={onFiltersButtonClicked}
              startIcon={<FilterListRoundedIcon />}
              ref={filtersButtonRef}
              className="mr-2 flex-shrink-0"
            >
              Adjust Filters
            </Button>

            <FilterBuilderDialog
              buttonRef={filtersButtonRef}
              setIsOpen={setIsFilterPopoverOpen}
              isOpen={isFilterPopoverOpen}
              appliedFilters={filters}
              onFiltersChanged={onFiltersChanged}
              availableFilters={HARVESTING_FILTERS}
              defaultFilterKey={FilterKey.SEARCH_TERM}
            />

            <div className="flex flex-row flex-wrap gap-2">
              {filters
                .filter((f) => f.isFilterBuilderFilter)
                .map((filter, index) => {
                  return (
                    <FilterChip
                      key={filter.key + index}
                      filter={filter}
                      handleClick={onFiltersButtonClicked}
                      handleDelete={onFilterDeleteButtonClicked}
                    />
                  );
                })}
            </div>
          </div>
          <div className="flex text-sm gap-8">
            <DateRangeDisplay filtersUsed={filtersUsed} hideComparison />
          </div>
        </div>

        <KeywordHarvestingTable
          externalFilters={filters}
          harvestingKeywordData={keywordHarvestingData}
          setKeywordHarvestingSelection={setHarvestingPreviewSelection}
          setVisibleRowCount={setVisibleRowCount}
          onInternalGridReady={onOptimizationTableGridReady}
        />

        <KeywordHarvestingPreviewActionsBar
          harvestingPreviewSelection={harvestingPreviewSelection}
          visibleRowCount={visibleRowCount}
          onApply={onApply}
          isApplyLoading={isApplyLoading}
          keywordHarvestingData={keywordHarvestingData}
          keywordHarvestingTableApi={optimizationTableApi}
          setHarvestingPreviewSelection={setHarvestingPreviewSelection}
        />
      </PageLayoutBody>
    </Dialog>
  );
};
