import { FunctionComponent, ReactNode } from 'react';

interface DashboardWidgetTopBarProps {
  children: ReactNode;
}

const DashboardWidgetTopBar: FunctionComponent<DashboardWidgetTopBarProps> = ({ children }) => {
  return <div className="flex justify-between items-center">{children}</div>;
};

export default DashboardWidgetTopBar;
