import { CommonMetricsDTO } from '@/components/metrics/api/metrics-contracts';
import { ApiResponse } from '@/lib/api/api-response';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';

export interface ProfileDTO {
  id: string;
  account_id: string;
  name: string;
  type: string;
  sub_type: string;
  country_code: string;
  currency_code: string;
  daily_budget: number;
  timezone: string;
  marketplace_id: string;
  state: string;
  created_at: string;
  seller_central_auth_at?: string;
}

export enum StatusType {
  PENDING = 'PENDING',
  ONGOING = 'ONGOING',
  DOWNLOADED = 'DOWNLOADED',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export enum ReportingStatusType {
  NEVER = 'NEVER',
  LOADING = 'LOADING',
  COMPLETED = 'COMPLETED',
  OUTDATED = 'OUTDATED',
  ERROR = 'ERROR',
  WAITING_RESPONSE = 'WAITING_RESPONSE', // Frontend only
}

export const REPORT_STATUSES_ORDERED_BY_SEVERITY: ReportingStatusType[] = [
  ReportingStatusType.ERROR,
  ReportingStatusType.LOADING,
  ReportingStatusType.NEVER,
  ReportingStatusType.OUTDATED,
  ReportingStatusType.WAITING_RESPONSE,
];

export enum AdProductType {
  PRODUCTS = 'products',
  BRANDS = 'brands',
  DISPLAY = 'display',
}

export enum UpdateReportType {
  TARGETING = 'targeting',
  PLACEMENT = 'placement',
  SEARCH_TERM = 'searchterm',
  ADVERTISED_PRODUCT = 'advertisedproduct',
}

export interface ProfileReportsStatusDTO {
  products: AdTypeReportStatusDTO;
  brands: AdTypeReportStatusDTO;
  display: AdTypeReportStatusDTO;
}

export interface AdTypeReportStatusDTO {
  targets: ReportingStatusDTO;
  placements?: ReportingStatusDTO;
  products?: ReportingStatusDTO;
  search_terms?: ReportingStatusDTO;
}

export interface ReportingStatusDTO {
  status: ReportingStatusType;
  updated_at?: string;
}

export interface ProfileReportsDTO {
  profile: string; // Profile ID
  placement: ReportInfoDTO[];
  targeting: ReportInfoDTO[];
  search_terms: ReportInfoDTO[];
  products: ReportInfoDTO[];
}

export interface ReportInfoDTO {
  id: string;
  created_at: string;
  updated_at: string;
  ad_product: AdProduct;
  type: ReportType;
  status: StatusType;
}

export enum AdProduct {
  SPONSORED_PRODUCTS = 'SPONSORED_PRODUCTS',
  SPONSORED_DISPLAY = 'SPONSORED_DISPLAY',
  SPONSORED_BRANDS = 'SPONSORED_BRANDS',
}

export enum ReportType {
  SPONSORED_PRODUCTS_PLACEMENTS = 'spCampaigns',
  SPONSORED_BRANDS_PLACEMENTS = 'sbCampaignPlacement',

  SPONSORED_PRODUCTS_TARGETING = 'spTargeting',
  SPONSORED_BRANDS_TARGETING = 'sbTargeting',
  SPONSORED_DISPLAY_TARGETING = 'sdTargeting',

  SPONSORED_PRODUCTS_SEARCH_TERMS = 'spSearchTerm',
  SPONSORED_BRANDS_SEARCH_TERMS = 'sbSearchTerm',

  SPONSORED_PRODUCTS_ADVERTISED_PRODUCTS = 'spAdvertisedProduct',
  SPONSORED_DISPLAY_ADVERTISED_PRODUCTS = 'sdAdvertisedProduct',
}

export interface CreateReportsResponse {
  createTargetingReportResponse: ApiResponse<null>;
  createPlacementReportResponse?: ApiResponse<null>;
  createSearchTermsReportResponse?: ApiResponse<null>;
  createAdvertisedProductReportResponse?: ApiResponse<null>;
}

export interface OptimizerJobDTO {
  id: number;
  reverted_job_id: number;
  optimizer_version: string;
  user_id: number;
  user_name: string;
  team_id: number;
  campaign_count: number;
  preview_campaign_count: number;
  preview_entity_count: number;
  applied_entity_count: number;
  success_entity_count: number;
  failed_entity_count: number;
  start_date: string;
  end_date: string;
  compare_start_date: string;
  compare_end_date: string;
  tacos: number;
  sentiment: OptimizationPreset;
  bid_ceiling: number;
  enabled_keyword_groups: string;
  exclude_no_impressions: false;
  state: string;
  created_at: string;
}

export interface ProfilesWithMetricsRequestDTO {
  dates: DateRangeDTO;
  compare_dates: DateRangeDTO;
}

export interface DateRangeDTO {
  start_date: string;
  end_date: string;
}

export interface ProfilesMetricsWithTeamDTO extends ProfileDTO {
  team_id: number;
  team_name: string;
  metrics: CommonMetricsDTO;
}
