import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash-es';
import { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';
import { AlFilterModel, LogicalOperatorType, OperatorType } from './models/AlFilterModel';

interface SearchBoxProps {
  filterKey: FilterKey;
  newFilterModel: () => AlFilterModel;
  placeholder: string;
  filters: AlFilterModel[]; // Applied filters
  setFilterValue: (filter: AlFilterModel) => void;
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}

export const SearchBox: FunctionComponent<SearchBoxProps> = ({
  filterKey,
  newFilterModel,
  placeholder,
  filters,
  setFilterValue,
  setFilters,
}) => {
  const { activeProfile } = useActiveTeamContext();

  const [inputValue, setInputValue] = useState('');

  // clear input if name filter is removed under adjust filters or via the chip clear button
  useEffect(() => {
    if (!filters.some((f) => f.key === filterKey)) {
      setInputValue('');
    }
  }, [filters]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  // Clear selected options when profile changes
  const [previousProfileId, setPreviousProfileId] = useState<string>('');
  useEffect(() => {
    if (activeProfile?.id && activeProfile?.id !== previousProfileId) {
      setPreviousProfileId(activeProfile.id);
    }
  }, [activeProfile?.id]);

  function handleSubmit(event: KeyboardEvent<HTMLInputElement>) {
    if (event.code == 'Enter') {
      updateFilter(inputValue);
    }
  }

  function handleBlur() {
    const currentFilter = filters.find((f) => f.key === filterKey);
    if (currentFilter && currentFilter.conditions) {
      const previousTerm = currentFilter.conditions[0].values[0] as string;
      if (inputValue !== previousTerm) {
        updateFilter(inputValue);
      }
    } else if (!isEmpty(inputValue)) {
      updateFilter(inputValue);
    }
  }

  function handleClear() {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filterKey));
    setInputValue('');
  }

  function updateFilter(searchTerm: string) {
    if (searchTerm == '') {
      handleClear(); // if empty search term is submitted, remove name filter
    } else {
      const filter = newFilterModel();
      filter.logicalOperator = LogicalOperatorType.OR;
      filter.conditions = [
        {
          operator: OperatorType.LIKE,
          values: [searchTerm],
        },
      ];
      setFilterValue(filter);
    }
  }

  return (
    <div className="flex flex-row gap-2">
      <TextField
        label={null}
        placeholder={placeholder}
        id="outlined-start-adornment"
        sx={{ m: 1, width: '25ch' }}
        onChange={handleChange}
        onKeyDown={handleSubmit}
        onBlur={handleBlur}
        value={inputValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ fontSize: '20px' }} />
            </InputAdornment>
          ),
          endAdornment: inputValue && (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} edge="end">
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
