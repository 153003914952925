import { CampaignToAdGroups } from '@/modules/negative-targets/models/CampaignToAdGroupModel';
import { EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { CampaignService, campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { AdGroupModel } from '@/modules/optimizer/api/campaign/models/AdGroupModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { toast } from 'react-toastify';

const useCampaignToAdGroups = () => {
  const { activeProfile } = useActiveTeamContext();

  const { data: campaignToAdGroupsRecord, isLoading: isCampaignToAdGroupsRecordLoading } = useQuery({
    queryKey: CampaignService.getCampaignsToAdGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getCampaignToAdGroups();
      if (result.isSuccess) {
        // Remove archived campaigns and ad groups
        const campaignToAdGroupsRecord: Record<string, CampaignToAdGroups> = result.payload
          .filter((campaign) => campaign.state !== EnabledPausedArchivedState.ARCHIVED)
          .reduce(
            (acc, campaign) => {
              const adGroupsRecord: Record<string, AdGroupModel> = {};
              Object.entries(campaign.adGroupIdToAdGroup).forEach(([adGroupId, adGroup]) => {
                if (adGroup.state !== EnabledPausedArchivedState.ARCHIVED) {
                  adGroupsRecord[adGroupId] = adGroup;
                }
              });

              campaign.adGroupIdToAdGroup = adGroupsRecord;
              acc[campaign.id] = campaign;
              return acc;
            },
            {} as Record<string, CampaignToAdGroups>,
          );

        return campaignToAdGroupsRecord;
      } else {
        toast.error(`Error loading groups ${result.message}`);
      }
    },
    enabled: !isNil(activeProfile),
  });

  return {
    campaignToAdGroupsRecord,
    isCampaignToAdGroupsRecordLoading,
  };
};

export default useCampaignToAdGroups;
