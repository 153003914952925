import { useDroppable } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import DimensionSortable, { DimensionSortableStyles } from './DimensionSortable';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { DragAndDropContainer } from './DragToReorder';
import AddIcon from '@mui/icons-material/Add';

export interface DimensionItem {
  id: string;
  inputItem: string;
  displayName: string;
}

interface DimensionContainerProps {
  id: string;
  items: DimensionItem[];
  isActive: boolean;
  children?: React.ReactNode;
  container: DragAndDropContainer;
  itemRemovedFromGroup: (id: string) => void;
}

const DimensionContainer: FunctionComponent<DimensionContainerProps> = (props) => {
  const { id, items, isActive, container, itemRemovedFromGroup } = props;
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext id={id} items={items.map((item) => item.id)} strategy={rectSortingStrategy}>
      <div
        ref={setNodeRef}
        className={`flex flex-wrap gap-4 rounded-lg border-2 bg-slate-300 bg-opacity-20 p-4 min-h-[172px] ${
          isActive ? 'border-blue-600 shadow-inner' : 'border-blue-600 border-opacity-10'
        }`}
      >
        {items.length == 0 ? (
          <div className="flex flex-grow items-center justify-center">
            <Typography variant="overline">
              Drag metrics here to {container == DragAndDropContainer.VISIBLE ? 'make them visible' : 'hide them'}
            </Typography>
          </div>
        ) : null}
        {items.map((dim: DimensionItem) => {
          return (
            <div key={dim.id} style={{ position: 'relative' }}>
              {/* Invisible container to listen to clicks, visible dummy is in DimensionSortable */}
              <Tooltip title={container == DragAndDropContainer.VISIBLE ? 'Hide this metric' : 'Show this metric'}>
                <Paper variant="outlined" style={{ ...DimensionSortableStyles, opacity: 0, zIndex: 2 }}>
                  <IconButton onClick={() => itemRemovedFromGroup(dim.id)} size="small" style={{ padding: 4 }}>
                    {/* just a dummy placeholder, not visible */}
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Paper>
              </Tooltip>
              <DimensionSortable id={dim.id} label={dim.displayName} container={container} />
            </div>
          );
        })}
        {props.children}
      </div>
    </SortableContext>
  );
};

export default DimensionContainer;
