import {
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  ManagedGridOptionKey,
  RowDataUpdatedEvent,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { useState } from 'react';

interface useDynamicHeightProps {
  VISIBLE_ABOVE_PX_ON_SCROLL_DOWN: number;
}

const useDynamicHeight = ({ VISIBLE_ABOVE_PX_ON_SCROLL_DOWN }: useDynamicHeightProps) => {
  const [heightCssValue, setHeightCssValue] = useState<string>('260px');

  type CombinedType = FirstDataRenderedEvent | RowGroupOpenedEvent | FilterChangedEvent | SortChangedEvent | RowDataUpdatedEvent;
  const functionNames: ManagedGridOptionKey[] = [
    'onFirstDataRendered',
    'onRowGroupOpened',
    'onSortChanged',
    'onFilterChanged',
    'onColumnRowGroupChanged',
    'onRowDataUpdated',
  ];

  const updateRowCount = (gridApi: GridApi) => {
    const displayedRowCount = gridApi.getDisplayedRowCount();
    const cssValue = getTableHeightCssValue(displayedRowCount);

    setHeightCssValue((prevCssValue) => {
      if (prevCssValue !== cssValue) {
        return cssValue;
      }
      return prevCssValue; // No change if the value is the same - should save a rerender usually
    });
  };

  function getTableHeightCssValue(amountOfRows: number): string {
    const gridTopBarsHeight = 48 * 2; // 2 top bars
    const dynamicHeightBasedOnAmountOfRows = gridTopBarsHeight + 43 * amountOfRows + 24;
    const viewportHeight = window.innerHeight;
    const fullScreenHeight = viewportHeight - VISIBLE_ABOVE_PX_ON_SCROLL_DOWN;

    if (dynamicHeightBasedOnAmountOfRows < fullScreenHeight) {
      return `${dynamicHeightBasedOnAmountOfRows + 64}px`; // same as in .extra-padding css class
    } else {
      return `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)`;
    }
  }

  function onGridReadyCallback(params: GridReadyEvent) {
    for (const functionName of functionNames) {
      const existingFunction = params.api.getGridOption(functionName);
      const newFunction = (event: CombinedType) => {
        if (existingFunction) {
          existingFunction(event);
        }
        updateRowCount(event.api);
      };
      if (newFunction != existingFunction) {
        params.api.setGridOption(functionName, newFunction);
      }
    }
  }

  return { heightCssValue, onGridReadyCallback, updateRowCount };
};

export default useDynamicHeight;
