import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { FunctionComponent } from 'react';
import ProfilesStatsTable from '../components/ProfilesStatsTable';

const ProfilesStatsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <PageLayoutTopBar header={t('profiles_stats_page.header')}></PageLayoutTopBar>
      <PageLayoutBody>
        <div className="mt-4 flex flex-grow flex-1 flex-col">
          <ProfilesStatsTable />
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default ProfilesStatsPage;
