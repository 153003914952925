import InfoMessage from '@/components/feedback/InfoMessage';
import AlSelect from '@/components/form/AlSelect';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import {
  BID_UPDATE_OPTIONS,
  BidUpdateData,
  BidUpdateType,
} from '@/modules/optimizer/components/optimization/OptimizationEditSelectionPopover';
import useBidLimits from '@/modules/optimizer/components/optimization/bid-limits';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Popover, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponent, RefObject, useState } from 'react';
import { toast } from 'react-toastify';
import { TargetUpdateDTO } from '../api/targets-contracts';
import { createTargetingWithTimelineQueryKey, targetingService } from '../api/targets-service';
import { useTargetingContext } from '../contexts/TargetingContext';
import useTargetingWarnings from '../hooks/useTargetingWarnings';
import { SelectedTarget } from '../models/TargetsModel';

interface TargetingBulkActionsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedTargets: SelectedTarget[];
  isOpen: boolean;
  onClose: () => void;
}

type NoChange = 'NO_CHANGE';
const NO_CHANGE: NoChange = 'NO_CHANGE';

const TargetingBulkEditPopover: FunctionComponent<TargetingBulkActionsPopoverProps> = ({ selectedTargets, isOpen, onClose, buttonRef }) => {
  // STATE UPDATE
  const STATE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: EnabledPausedArchivedState.ENABLED, label: 'Enabled' },
    { value: EnabledPausedArchivedState.PAUSED, label: 'Paused' },
  ];

  // Post update modal
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [updatedTargetsResponse, setUpdateTargetResponse] = useState<UpdateResponseDTO>();

  const [targetState, setCampaignState] = useState<EnabledPausedArchivedState | NoChange>(NO_CHANGE);
  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);

  const { activeProfile } = useActiveTeamContext();
  const { getNewBidValue_byCurrentProfileMarketplaceLimits } = useBidLimits();

  function handleTargetStateChange(event: SelectChangeEvent<EnabledPausedArchivedState | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignState(value);
    } else {
      setCampaignState(value as EnabledPausedArchivedState);
    }
  }

  // BID UPDATE
  const [bidUpdateData, setBidUpdateData] = useState<BidUpdateData>({ bidUpdateType: BidUpdateType.NO_CHANGE, newBidValue: 0 });

  const [newBidValue, setNewBidValue] = useState<string>('');
  const onNewBidValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input with decimals
    if (!value || /^\d*\.?\d*$/.test(value)) {
      setNewBidValue(value);
      setBidUpdateData({ ...bidUpdateData, newBidValue: parseFloat(value) });
    }
  };

  const handleBidUpdateTypeChange = (event: SelectChangeEvent<BidUpdateType>) => {
    setBidUpdateData({ ...bidUpdateData, bidUpdateType: event.target.value as BidUpdateType });
  };

  function getNewBidValue_byBidUpdateType(oldValue: number, updateData: BidUpdateData): number {
    switch (updateData.bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
        return updateData.newBidValue;
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
        return oldValue + updateData.newBidValue;
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        return oldValue - updateData.newBidValue;
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
        return oldValue * (1 + updateData.newBidValue / 100);
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
        return oldValue * (1 - updateData.newBidValue / 100);
      default:
        // No need for NO_CHANGE as it does nothing
        return oldValue;
    }
  }

  // PROCESS UPDATE
  const queryClient = useQueryClient();
  const { filters } = useTargetingContext();
  const targetsWithTimelineQueryKey = createTargetingWithTimelineQueryKey(activeProfile?.id, filters);
  async function handleApplyChanges() {
    setIsApplyInProgress(true);

    const warnings = new Set<string>();

    const filteredTargets = selectedTargets.filter((target) => target.state !== EnabledPausedArchivedState.ARCHIVED);

    const targetUpdates: TargetUpdateDTO[] = filteredTargets.reduce((updates, target) => {
      const update: TargetUpdateDTO = {
        id: target.id,
        ad_type: target.adType,
        entity_type: target.entityType,
        match_type: target.matchType,
      };

      let isUpdated = false; // Flag to check if the target has been updated

      if (targetState !== NO_CHANGE) {
        update.state = targetState as EnabledPausedArchivedState;
        isUpdated = true; // Mark as updated
      }

      if (bidUpdateData.bidUpdateType !== BidUpdateType.NO_CHANGE && target.isBidChangeEnabled) {
        let newBid = target.bid;
        newBid = getNewBidValue_byBidUpdateType(newBid, bidUpdateData);

        const newValue = getNewBidValue_byCurrentProfileMarketplaceLimits(newBid, target.adType, target.campaignIsVideo, warnings);
        newBid = newValue;
        update.bid = newBid;
        isUpdated = true; // Mark as updated
      }

      // Only add the update to the array if the target was actually updated
      if (isUpdated) {
        updates.push(update);
      }

      return updates;
    }, [] as TargetUpdateDTO[]); // Initialize with an empty array

    if (warnings.size > 0) {
      const warningList = Array.from(warnings);
      warningList.forEach((w) =>
        toast.warning(w, {
          autoClose: 5000,
        }),
      );
      console.log(warnings);
    }
    const response = await targetingService.updateTargets(targetUpdates);

    if (response.isSuccess) {
      setUpdateTargetResponse({ responseErrorMsg: null, payload: response?.payload });
    } else {
      setIsApplyInProgress(false);
      setUpdateTargetResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
    }
    setUpdateResponseModalOpen(true);

    // Reset all fields
    setCampaignState(NO_CHANGE);

    queryClient.invalidateQueries({
      queryKey: targetsWithTimelineQueryKey,
    });
    onClose();
    setIsApplyInProgress(false);
  }

  const { warnings, selectedLegacyTargetsCount, archivedTargetsCount, selectedTargetsWithOptRulesCount } = useTargetingWarnings({
    selectedTargets,
  });

  const isStateEditAllowed = selectedTargets.length > selectedLegacyTargetsCount && selectedTargets.length != archivedTargetsCount;

  const isBidEditAllowed =
    selectedLegacyTargetsCount + selectedTargetsWithOptRulesCount < selectedTargets.length && selectedTargets.length != archivedTargetsCount;

  return (
    <>
      <Popover
        id={'optimizer-bulk-edit-popover'}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        slotProps={{ paper: { style: { width: 420 } } }}
      >
        <DialogTitle id="edit-selection-modal-title">Edit Selected Targets</DialogTitle>

        <DialogContent className="space-y-4">
          <span>
            You have selected {selectedTargets.length} Target{selectedTargets.length == 1 ? '' : 's'} to edit.
          </span>
          <Typography variant="subtitle2">Bulk Edit State</Typography>
          <AlSelect
            label={'Target State'}
            value={targetState}
            options={STATE_OPTIONS}
            onChange={handleTargetStateChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
            disabled={!isStateEditAllowed}
          />

          <div>
            <Typography variant="subtitle2">Bulk Edit Bids</Typography>

            <div className="flex gap-x-4">
              <AlSelect
                label={'Update action'}
                value={bidUpdateData.bidUpdateType}
                options={BID_UPDATE_OPTIONS}
                onChange={handleBidUpdateTypeChange}
                renderOption={(item) => item.label}
                valueExtractor={(item) => item.value}
                disabled={!isBidEditAllowed}
              />
              {bidUpdateData.bidUpdateType !== BidUpdateType.NO_CHANGE && (
                <>
                  <TextField
                    fullWidth
                    label={
                      bidUpdateData.bidUpdateType === BidUpdateType.SET_BID_TO_AMOUNT
                        ? 'New Bid ($)'
                        : bidUpdateData.bidUpdateType === BidUpdateType.INCREASE_BID_BY_AMOUNT ||
                            bidUpdateData.bidUpdateType === BidUpdateType.DECREASE_BID_BY_AMOUNT
                          ? 'Bid adjustment ($)'
                          : 'Bid adjustment (%) points'
                    }
                    value={newBidValue}
                    onChange={onNewBidValueChange}
                    inputProps={{ pattern: '[0-9]*' }}
                    InputProps={{
                      endAdornment: (
                        <div className="text-sm flex flex-nowrap whitespace-nowrap">
                          {bidUpdateData.bidUpdateType === BidUpdateType.SET_BID_TO_AMOUNT ||
                          bidUpdateData.bidUpdateType === BidUpdateType.INCREASE_BID_BY_AMOUNT ||
                          bidUpdateData.bidUpdateType === BidUpdateType.DECREASE_BID_BY_AMOUNT
                            ? '$'
                            : '%'}
                        </div>
                      ),
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <Divider />
        {warnings.length > 0 && (
          <div className="flex flex-col mx-5">
            {warnings.map((warning, index) => (
              <InfoMessage key={index} text={warning} />
            ))}
          </div>
        )}
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isApplyInProgress}
            onClick={handleApplyChanges}
            disabled={targetState === NO_CHANGE && bidUpdateData.bidUpdateType === BidUpdateType.NO_CHANGE}
          >
            Apply Changes
          </LoadingButton>
        </DialogActions>
      </Popover>

      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={updatedTargetsResponse}
      />
    </>
  );
};

export default TargetingBulkEditPopover;
