import { RouteObject } from 'react-router-dom';
import PlacementsPage from '../pages/PlacementsPage';

export const PLACEMENTS_ROUTES: RouteObject[] = [
  {
    path: 'placements',
    children: [
      {
        index: true,
        element: <PlacementsPage />,
      },
    ],
  },
];
