import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { AdGroupDTO } from '../campaign-contracts';

export class AdGroupModel {
  public id: string;
  public name: string;
  public state: string;
  public defaultBid: number;
  public entityType: TargetEntityType;

  constructor(args: AdGroupArguments) {
    this.id = args.id;
    this.name = args.name;
    this.state = args.state;
    this.defaultBid = args.defaultBid;
    this.entityType = args.entityType;
  }

  public static fromResponseArray(dtos: AdGroupDTO[]): AdGroupModel[] {
    return dtos.map(
      (dto) =>
        new AdGroupModel({
          id: dto.id,
          name: dto.name,
          state: dto.state,
          defaultBid: dto.default_bid,
          entityType: dto.entity_type,
        }),
    );
  }
}

export interface AdGroupArguments {
  id: string;
  name: string;
  state: string;
  defaultBid: number;
  entityType: TargetEntityType;
}
