import { CommonMetricsDTO } from '@/components/metrics/api/metrics-contracts';
import { UpdatedEntityDTO } from '@/modules/application/types/UpdatedEntity';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { FilterDTO } from '../../../../components/filter-builder/api/filters-contracts';
import { MetricField } from '../../../../components/metrics/models/CommonMetricsModel';
import { OptimizationPreset } from '../../components/optimization/OptimizerConfig';
import { TimelineModel } from '../../components/timeline/models/TimelineModel';
import { CampaignGroupMetricsModel } from './models/CampaignGroupMetricsModel';
import { CampaignModel } from './models/CampaignModel';

// Shared with targets
export enum EnabledPausedArchivedState {
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED',
  ARCHIVED = 'ARCHIVED',
}

export enum BudgetType {
  LIFETIME = 'LIFETIME',
  DAILY_BUDGET = 'DAILY_BUDGET',
}

export enum BidStrategyType {
  LEGACY_FOR_SALES = 'LEGACY_FOR_SALES',
  AUTO_FOR_SALES = 'AUTO_FOR_SALES',
  MANUAL = 'MANUAL',
  RULE_BASED = 'RULE_BASED',
  NONE = '',
}

export enum CampaignAdType {
  PRODUCTS = 'PRODUCTS',
  BRANDS = 'BRANDS',
  DISPLAY = 'DISPLAY',
}

// Only brands have bid optimization AUTO or MANUAL for other types it is empty string
export enum AmazonBrandsBidOptimization {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  NONE = '',
}

export enum CostType {
  CPC = 'CPC',
  VCPM = 'VCPM', // Cost per 1000 viewable impressions
  NONE = '',
}

export enum PlacementType {
  PLACEMENT_TOP = 'PLACEMENT_TOP',
  PLACEMENT_PRODUCT_PAGE = 'PLACEMENT_PRODUCT_PAGE',
  PLACEMENT_REST_OF_SEARCH = 'PLACEMENT_REST_OF_SEARCH',
  HOME = 'HOME',
  DETAIL_PAGE = 'DETAIL_PAGE',
  OTHER = 'OTHER',
}

export enum CreativeType {
  PRODUCT_COLLECTION = 'PRODUCT_COLLECTION',
  STORE_SPOTLIGHT = 'STORE_SPOTLIGHT',
  VIDEO = 'VIDEO',
  BRAND_VIDEO = 'BRAND_VIDEO',
  NONE = '',
}

export enum TargetingType {
  TARGETING = 'TARGETING',
  AUDIENCES = 'AUDIENCES',
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
  CONTEXTUAL = 'CONTEXTUAL',
  NONE = '',
}

export enum MultiAdGroupsEnabledType {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  NONE = '',
}

export interface CampaignWithTimelineDTO {
  campaigns: CampaignDTO[] | null;
  timeline: TimelineDTO;
  comparison_missing: boolean;
}

export interface CampaignsWithTimeline {
  profileId?: string; //TODO: check if back even can send this
  campaigns: CampaignModel[];
  timeline: TimelineModel;
  isComparisonDataMissing: boolean;
}

export interface CampaignDTO extends CommonMetricsDTO {
  i: string;
  n: string;
  p: string;
  ad: CampaignAdType;
  cr: CreativeType;
  co: CostType;
  mag: boolean | null;
  o: boolean | null;
  gi: number;
  gn: string;
  s: EnabledPausedArchivedState;
  sd: string | null;
  ed: string | null;
  tt: TargetingType;
  ba: number;
  bs: BidStrategyType;
  bo: AmazonBrandsBidOptimization;
  lo: string | null;
  cd: number[];
}

export interface TimelineDTO {
  x_axis: string[];
  y_axis: Record<MetricField, number[]>;
}

export interface IdNameDTO {
  id: string;
  name: string;
}

export interface AdGroupDTO {
  id: string;
  name: string;
  state: string;
  default_bid: number;
  entity_type: TargetEntityType;
}

export interface CampaignGroupsWithComparisonRequestDTO {
  dates: DateRangeDTO;
  compare_dates: DateRangeDTO;
}

export interface DateRangeDTO {
  start_date: string;
  end_date: string;
}

export interface CreateNewCampaignGroupsDTO {
  flow_type: FlowType;
  groups: NewCampaignGroupDTO[];
}

export interface NewCampaignGroupDTO {
  ad_type: CampaignAdType;
  name: string;
  tacos?: number;
  preset?: OptimizationPreset;
  bid_ceiling?: number;
}

export interface CampaignGroupDTO {
  id: number;
  ad_type: CampaignAdType;
  name: string;
  tacos?: number;
  preset?: OptimizationPreset;
  bid_ceiling?: number;
  total_campaigns: number;
}

export interface CampaignGroupMetricsDTO extends CampaignGroupDTO {
  metrics: CommonMetricsDTO;
}

export interface CampaignGroupsWithMetricsDTO {
  campaign_groups: CampaignGroupMetricsDTO[];
  comparison_missing: boolean;
}

export interface CampaignGroupsWithMetrics {
  campaignGroups: CampaignGroupMetricsModel[];
  isComparisonDataMissing: boolean;
}

export interface CreateGroupsDTO {
  groups: NewCampaignGroupDTO[];
}

export interface ChangeCampaignGroupDTO {
  campaign_id: string;
  group_id: number;
}

export interface UpdateCampaignsDTO {
  flow_type: FlowType;
  updates: CampaignUpdateDTO[];
}

export interface CampaignUpdateDTO {
  campaign_id: string;
  state?: EnabledPausedArchivedState;
  dynamic_bid_strategy?: BidStrategyType;
  bid_optimization?: AmazonBrandsBidOptimization;
  placement_updates?: PlacementUpdate[];
}

export interface PlacementUpdate {
  placement: PlacementType;
  percentage: number;
}

export interface PortfolioDTO {
  i: string;
  n: string;
  s: EnabledPausedArchivedState;
}

export interface UpdatedCampaignsDTO extends UpdatedEntityDTO {}

export interface GroupsMetricsRequest {
  filters: FilterDTO[];
}
