import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';
export interface ILinkCallbackCellRendererParams<T> {
  buttonText: string;
  callback: (rowData: T) => void;
  tooltip?: string;
}

export interface ILinkCallbackCellRendererProps extends ICellRendererParams, ILinkCallbackCellRendererParams<unknown> {}

const LinkCallbackCellRenderer: FunctionComponent<ILinkCallbackCellRendererProps> = ({ data, callback, buttonText, tooltip }) => {
  const handleClick = () => {
    if (data) {
      callback(data);
    }
  };

  return (
    <Tooltip title={tooltip}>
      <div
        onClick={handleClick}
        className="truncate cursor-pointer flex w-full items-center  group underline underline-offset-2 hover:text-primary-700"
      >
        <div>{buttonText}</div>
      </div>
    </Tooltip>
  );
};

export default LinkCallbackCellRenderer;
